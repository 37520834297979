// import { converBase64ToImage } from "convert-base64-to-image";
import { Col, Container, Row } from 'react-bootstrap';
import VideoThumbnail from 'react-video-thumbnail';
import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";

// eslint-disable-next-line
import GlobalURL, { FANCY } from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddMultimedia = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [mediatype, setMediatype] = useState("img");
    const [status, setStatus] = useState(false);
    const [preview, setPreview] = useState();
    const [file, setFile] = useState(null);
    // const [base64, setBase64] = useState(null);
    // const [pathToStore, setPathToStore] = useState();
    const formData = new FormData();

    function handleChange(e) {
        let uploadImagesList = e.target.files;
        console.log('uploadImagesList: ', uploadImagesList);
        let tempPreviewList = []

        if (!Object.entries(uploadImagesList).length) {
            document.getElementById("uploadFile").value = '';
            return setPreview(null);
        }

        if (e.target?.files[0]?.name?.includes('mp4') && (e.target?.files.length === 1)) {
            // setMediatype("vids");
            setFile(e.target.files[0]);
            setPreview([URL.createObjectURL(e.target.files[0])]);
            document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
            return setMediatype("vids");
        }
        // eslint-disable-next-line
        Object.entries(uploadImagesList).map((imgItem, ind) => {
            console.log('imgItem: ', imgItem[1].type.includes('image'), " indd:: ", ind);
            if (imgItem[1].type.includes('image')) {

                console.log('e.target.files[ind]: ', e.target.files[ind]);
                let mediaSize = e.target.files[ind].size / 1024; //size in kb
                if (mediaSize <= 20245) { //size limit is 20 mb

                    document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
                    // Swal.fire("File under size limit!");
                } else {
                    setFile(null);
                    Swal.fire(t("¡El tamaño del archivo debe ser de hasta 20 MB!"));
                    return null;
                }
                // formData.append("media", e.target.files[ind]);
                console.log('imgItem[ind]: ', e.target.files[ind]);
                tempPreviewList.push(URL.createObjectURL(imgItem[1]));
                console.log('URL.createObjectURL(e.target.files[1]): ', URL.createObjectURL(imgItem[1]));
            }
            else {
                Swal.fire("Multimedia", t("¡Por ahora, solo puedes subir varias imágenes y un solo vídeo!"), "warning");
            }
        })
        // map ends. . .

        setFile(e.target.files);
        setPreview(tempPreviewList)
    }

    const handleMedia = (e) => {
        e.preventDefault();
        formData.append("team_id", localStorage.getItem("team_id"));

        if (mediatype === 'img') {
            for (let i of file) {
                console.log("formdata: ", i);
                formData.append("media", i);
                // formData.append("thumbnail", i);
            }
        } else {
            formData.append("media", file);
        }

        // formData.append("media", file);
        // if (mediatype === "img")
        //     formData.append("thumbnail", file);

        console.log('original file: ', file);
        console.log('formData: ', formData);

        if (file) {
            setStatus(true);
            fetch(`${GlobalURL}/api/dev/team_moderator/multimedia/addMultimedia`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            })
                .then(function (res) {
                    if (res.ok) {
                        Swal.fire(t("multimedia añadido con éxito!"));
                        setFile("");
                        navigate(-1);
                    } else {
                        Swal.fire(t("Error del Servidor, ¡inténtalo de nuevo!"));
                    }
                })
                .catch(function (e) {
                    Swal.fire(t("multimedia no agregada, ¡inténtalo de nuevo!"));
                }).finally(() => {
                    setStatus(false);
                })
        } else {
            Swal.fire(t("Se requiere multimedia!"));
        }
    }

    const handleThumbnailForMedia = (thumbnail) => {
        console.log("thumbnail: ", thumbnail);
        // setBase64(thumbnail);
        // let fileBaseToImage = converBase64ToImage(base64, pathToStore);
        // console.log("thumbnail: ", fileBaseToImage);
        // const blob = dataURItoBlob(thumbnail);
        // console.log('+ new blob: ', blob);
        // formData.append('thumbnail', blob);

        // Convert Data URI to Blob
        const byteString = atob(thumbnail.split(',')[1]);
        const mimeString = thumbnail.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });
        // Convert Blob to File (assuming you have a filename)
        const file = new File([blob], 'thumbnail.png', { type: mimeString });
        console.log('ffile: ', file);

        // Append File to FormData
        formData.append('thumbnail', file);
    }

    // Convert Base64 to Blob
    // const dataURItoBlob = (dataURI) => {
    //     const byteString = atob(dataURI.split(',')[1]);
    //     const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    //     const ab = new ArrayBuffer(byteString.length);
    //     const ia = new Uint8Array(ab);
    //     for (let i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }
    //     const blob = new Blob([ab], { type: mimeString });
    //     return blob;
    // };

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='addLagend-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='addLagendsec common-dash-padding'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
                                                <img src="../img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>
                                                {t('AGREGAR MULTIMEDIA')}
                                            </h3>
                                            <div className='search-icon'>
                                            </div>
                                        </div>

                                        <div className='common-input-forms newsAddContent'>
                                            <form encType="multipart/form-data">

                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Foto/video')}</label>
                                                    <div className='file-input-sec'>

                                                        <input id="uploadFile" className="f-input"
                                                            placeholder={t('Inserte la foto o video de la media aquí')} />
                                                        <div className="fileUpload btn btn--browse">
                                                            <input id="uploadBtn" onChange={handleChange} type="file" multiple
                                                                accept='image/* video/*' className="upload" required />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='d-flex preview-box'>
                                                    {preview?.map((preview_media, i) => (
                                                        <div key={i} className='preview-imgSec'>
                                                            {mediatype === "img"
                                                                ? (<div className='preview-imgCon'>
                                                                    <img src={preview_media} alt=''></img>
                                                                </div>
                                                                )
                                                                : (<div className='preview-imgCon'>
                                                                    <video src={preview_media} ></video>
                                                                </div>
                                                                )
                                                            }
                                                        </div>))
                                                    }
                                                </div>

                                                <div className='hidden-thumbnail-preview'>
                                                    {console.log('mediatype: ', mediatype)}

                                                    (<VideoThumbnail
                                                        videoUrl={preview || ''}
                                                        thumbnailHandler={handleThumbnailForMedia}
                                                    // snapshotAtTime="5"
                                                    // width={120}
                                                    // height={80}
                                                    />)
                                                </div>

                                                <div className='news-btn-sec'>
                                                    {status
                                                        ? (<button className="green-btn loaderbtn signup-btn " disabled >
                                                            <span className="spinner-border spinner-border-sm" role="status"
                                                                aria-hidden="true"></span>
                                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                        </button>)
                                                        : (<button className='green-btn' onClick={handleMedia}>
                                                            {t('Guardar')}
                                                        </button>)
                                                    }
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMultimedia