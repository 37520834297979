import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { FiSearch } from "react-icons/fi";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const Billboard = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [banner, setBanner] = useState();
    const [searchWord, setSearchWord] = useState();
    const [searchList, setSearchList] = useState([]);
    const [bannerType, setBannerType] = useState('MI EQUIPO');
    const [bannerCategory, setBannerCategory] = useState('miteam');
    const [searchCliked, setSearchCliked] = useState(false);
    // const [errMsg, setErrMsg] = useState(false);
    const [currentBanner, setCurrentBanner] = useState();
    const [errMsg, setErrMsg] = useState(false);

    const images = ["jpg", "jpeg", "gif", "png", 'svg', "webp"];
    const [selectedOption, setSelectedOption] = useState('Cuadrícula');

    const [URLSearchParam, setURLSearchParam] = useSearchParams()

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        let banner_type_url = URLSearchParam.get("banner_type");
        console.log("from url banner_type: ", banner_type_url);
        if (banner_type_url) {
            handleBannerType(banner_type_url)
            setCurrentBanner(banner_type_url)
        }
        // URLSearchParam({})
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setSearchWord('');
    }, [bannerType])

    useEffect(() => {

    }, [])

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (banner) {
            let searchedItems = banner.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            console.log('searchedItems: ', searchList);
        }
    }

    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    // fetch for mibox & miteam banners
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/user/banner/getAllBannerByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    setErrMsg("¡Error Interno del Servidor!");
                }
            })
            .then(res => {
                setBanner(res.data);
            })
            .catch((err) => {
                setErrMsg("¡Error Interno del Servidor!");
            });
    }, [])

    //fetch for current banner
    useEffect(() => {
        setCurrentBanner()
        // const loadStatus = document.querySelector(".Loader");
        fetch(`${GlobalURL}/api/dev/user/banner/getCurrentBanner`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ "team_id": localStorage.getItem("team_id"), banner_type: bannerCategory })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                } else {
                    setErrMsg("!Error Interno del Servidor!");
                }
            })
            .then(res => {
                setCurrentBanner(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                setErrMsg("!Errir Interno del Servidor!");
            });
    }, [bannerCategory])

    const handleBannerType = (e̵̵ventKey, e) => {

        setURLSearchParam({ "banner_type": e̵̵ventKey });
        if (e̵̵ventKey === 'miteam') {
            setBannerType('MI EQUIPO')
        } else if (e̵̵ventKey === 'home') {
            setBannerType('HOME')
        } else if (e̵̵ventKey === "splash video") {
            setBannerType('SPLASH')
        } else if (e̵̵ventKey === "store") {
            setBannerType('TIENDA')
        }
        else {
            setBannerType('MI PALCO')
        }
        setBannerCategory(e̵̵ventKey)
    }

    // const hasDispaly = (banType) => {
    //     for (let item of currentBanner) {
    //         if (item.banner_type === banType) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }

    const getFormatedDate = (date) => {
        let tSlice = date.indexOf('T');
        let sliceDate = date.slice(0, tSlice);
        console.log('sliceDate: ', sliceDate);
        return sliceDate;
    }

    // const checkType = (url) => {
    //     if (images.includes(url?.split('.')?.at(-1))) {
    //         return <img src={url} alt="" />
    //     } else {
    //         return <video src={url} alt="" />
    //     }
    // }

    const checkType = (url) => {
        // console.log('extention: ', url?.split('.')?.at(-1), images.includes(url?.split('.')?.at(-1)));
        let extention = url?.split('.')?.at(-1);
        let res = images.find((ext) => {
            return extention.includes(ext);
        }
        )
        // console.log('res: ', res);

        if (images.includes(url?.split('.')?.at(-1)) || res) {
            return <img src={url} alt="" />
        } else {
            return <video src={url} alt="" />
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='billboard-page mt-5'>
                    <Container fluid>
                        <Row >
                            <Col xs lg="2"></Col>
                            <Col xs lg="8">
                                <div className='news-header'>
                                    <div className='back-sec'>
                                        <img src="./img/volver.png" alt="" onClick={() => { navigate('/homepage') }}
                                            style={{ cursor: "pointer" }} />
                                    </div>
                                    <h3 className='header-title inter-page-header-title'>{t('Cartelera')}</h3>
                                    {/* 
                                    {
                                        searchCliked
                                        && (<input type='search'
                                            placeholder='search...'
                                            value={searchWord}
                                            onChange={handleSearchTyped}
                                        />)
                                    }
                                    <div className='search-icon' onClick={handleSearchClicked}>
                                        <FiSearch />
                                    </div> 
                                    */}
                                    <div className="search-bar-main">
                                        <div className='search-icon' onClick={handleSearchClicked}>
                                            <FiSearch />
                                        </div>
                                        <div className="search-bar">
                                            {searchCliked
                                                && (<input type='search'
                                                    placeholder={t('Buscar...')}
                                                    value={searchWord}
                                                    onChange={handleSearchTyped}
                                                />)
                                            }
                                            {/* <input><div class="div1"></div></input> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='grid-list-sec'>

                                    <div className="radio-btns-sec">
                                        <div className="left-radios-sec">

                                            <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id="test1"
                                                    name="radio-group"
                                                    value="Cuadrícula"
                                                    checked={selectedOption === 'Cuadrícula'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="test1">{t('Cuadrícula')}</label>
                                            </div>

                                            <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id="test2"
                                                    name="radio-group"
                                                    value="Lista"
                                                    checked={selectedOption === 'Lista'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="test2">{t('Lista')}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="categories-dropdown dropdown-list">
                                        <Dropdown onSelect={handleBannerType}>
                                            <Dropdown.Toggle
                                                className="dropdown-btn"
                                                id="dropdown-basic"
                                                title='MI EQUIPO'
                                            >
                                                {bannerType}
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey={'miteam'}>
                                                    {t('MI EQUIPO')}
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey={'mibox'}>
                                                    {t('MI PALCO')}
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item eventKey={'home'}>
                                                    {t('HOME')}
                                                </Dropdown.Item> */}
                                                <Dropdown.Item eventKey={'splash video'}>
                                                    {t('SPLASH')}
                                                </Dropdown.Item>

                                                {/* <Dropdown.Item eventKey={'store'}>
                                                    {t('TIENDA')}
                                                </Dropdown.Item> */}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div className="radio-btns-sec" style={{ visibility: "hidden" }}>
                                        <div className="left-radios-sec">
                                            <div className="radio-sec" >
                                                <input type="radio" />
                                                <label htmlFor="test1">Cuadrícula</label>
                                            </div>
                                            <div className="radio-sec">
                                                <input type="radio" />
                                                <label htmlFor="test2">Lista</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='billboard-sec'>

                                    {selectedOption === 'Cuadrícula' && (
                                        <div className="cuadricula-content">
                                            {banner ?
                                                (<div className='billboard-sec-inner'>
                                                    <Row>
                                                        <Col xs lg="4"></Col>
                                                        {currentBanner?.length
                                                            ? (
                                                                <Col xs lg="4" className='current-banner banner-swip rounded swiper-navigate'
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Swiper navigation={true}
                                                                        spaceBetween={30}
                                                                        centeredSlides={true}
                                                                        slidesPerView={1}
                                                                        pagination={{
                                                                            clickable: true,
                                                                        }}
                                                                        modules={[Autoplay, Pagination, Navigation]}
                                                                        onSlideChange={() => console.log('slide change')}
                                                                        onSwiper={(swiper) => console.log(swiper)}
                                                                    >
                                                                        {currentBanner?.map((item, i) => {
                                                                            return (<SwiperSlide>
                                                                                <div className='myTeamBanner billboard-cards' key={i}
                                                                                    onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`,
                                                                                        { state: item })} >

                                                                                    <div className='card-heading'>
                                                                                        <h3>{`${t('BANNER DE')} ${bannerType}`}</h3>
                                                                                        <h3>{item.title}</h3>
                                                                                    </div>

                                                                                    <div className='card-img'>
                                                                                        {(item?.imageUrl) && checkType(item?.imageUrl)}
                                                                                    </div>
                                                                                </div>

                                                                            </SwiperSlide>)
                                                                        })}
                                                                    </Swiper>

                                                                </Col>
                                                            )
                                                            : (
                                                                <Col xs lg="4" className='current-banner rounded'>
                                                                    <div className='myTeamBanner billboard-cards' >
                                                                        <div className='card-heading'>
                                                                            <h3>{`BANNER DE ${bannerType}`}</h3>
                                                                            <h3>{t('No hay banner actual disponible!')}</h3>
                                                                        </div>
                                                                        <div className='card-img'>
                                                                            <img src='./img/blankimg.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }

                                                        <Col xs lg="4"></Col>
                                                        {searchList && searchWord
                                                            ? ((searchList.length) && searchList.map((item, i) => {
                                                                if (item.banner_type === bannerCategory) {
                                                                    return (
                                                                        <Col xs lg="4"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`, { state: item })}
                                                                            key={i} >
                                                                            <div className='myTeamBanner billboard-cards' >
                                                                                <div className='card-heading'>
                                                                                    <h3>{item?.title}</h3>
                                                                                </div>
                                                                                <div className='card-img'>
                                                                                    {(item?.imageUrl)
                                                                                        && checkType(item?.imageUrl)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                                return null;
                                                            })
                                                            )
                                                            : ((banner.length) && banner.map((item, i) => {
                                                                if (item.banner_type === bannerCategory) {
                                                                    return (
                                                                        <Col xs lg="4"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`,
                                                                                { state: item })}
                                                                            key={i} >
                                                                            <div className='myTeamBanner billboard-cards' >
                                                                                <div className='card-heading'>
                                                                                    <h3>{item?.title}</h3>
                                                                                </div>
                                                                                <div className='card-img'>
                                                                                    {(item?.imageUrl)
                                                                                        && checkType(item?.imageUrl)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                                return null;
                                                            }))
                                                        }
                                                    </Row>
                                                </div>)
                                                : (<div className="Loader d-flex justify-content-center align-items-center">
                                                    {errMsg ||
                                                        (<>
                                                            <div className="spinner-border my-5" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            <div className=" my-5" > &nbsp;
                                                                {t('Cargando')}...
                                                            </div>
                                                        </>
                                                        )
                                                    }
                                                </div>)
                                            }
                                        </div>
                                    )}

                                    {selectedOption === 'Lista' && (
                                        <div className="lista-content">
                                            {banner ?
                                                (<div className='billboard-sec-inner'>
                                                    <div className="lista-titles">
                                                        <div className='lista-name'>{t('Nombre')}</div>
                                                        <div className='lista-creation'>{t('Fecha de Creación')}</div>
                                                        <div className='lista-modification'>{t('Fecha de Creación')}</div>
                                                        <div className='lista-right'></div>
                                                    </div>

                                                    {currentBanner?.length
                                                        ? (currentBanner.map((item, i) => {
                                                            if (item.banner_type === bannerCategory) {
                                                                return (
                                                                    <div className='current-banner rounded' style={{ cursor: "pointer" }}
                                                                        key={i} onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`, { state: item })} >
                                                                        <div className='myTeamBanner billboard-cards' >
                                                                            <div className='lista-name ps-0'>
                                                                                <div className='card-img'>
                                                                                    {/* <img src={item?.imageUrl} alt="" /> */}
                                                                                    {(item?.imageUrl)
                                                                                        && checkType(item?.imageUrl)
                                                                                    }
                                                                                </div>
                                                                                <div className='card-heading'>
                                                                                    <h3>{item.title}</h3>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-creation'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-modification'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-right'></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                        )
                                                        : (<div className='current-banner rounded' >
                                                            <div className='myTeamBanner billboard-cards' >
                                                                <div className='lista-name ps-0'>
                                                                    <div className='card-img'>
                                                                        <img src='./img/blankimg.png' alt="" />
                                                                    </div>
                                                                    <div className='card-heading'>
                                                                        <h3>{`BANNER DE ${bannerType}`}</h3>
                                                                        <h3>{t('No hay banner actual disponible!')}</h3>
                                                                    </div>
                                                                </div>

                                                                <div className='lista-creation'>
                                                                    <div className='card-heading'>
                                                                        <p>{'N/A'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='lista-modification'>
                                                                    <div className='card-heading'>
                                                                        <p>{'N/A'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='lista-right'></div>
                                                            </div>
                                                        </div>
                                                        )
                                                    }

                                                    <Col xs lg="4"></Col>
                                                    {searchList && searchWord
                                                        ? (
                                                            (searchList.length) && searchList.map((item, i) => {
                                                                if (item.banner_type === bannerCategory) {
                                                                    return (
                                                                        <div className='rounded' style={{ cursor: "pointer" }}
                                                                            key={i} onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`, { state: item })} >
                                                                            <div className='myTeamBanner billboard-cards' >
                                                                                <div className='lista-name ps-0'>
                                                                                    <div className='card-img'>
                                                                                        {/* <img src={item?.imageUrl} alt="" /> */}
                                                                                        {(item?.imageUrl)
                                                                                            && checkType(item?.imageUrl)
                                                                                        }
                                                                                    </div>
                                                                                    <div className='card-heading'>
                                                                                        <h3>{item.title}</h3>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='lista-creation'>
                                                                                    <div className='card-heading'>
                                                                                        <p>{getFormatedDate(item.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='lista-modification'>
                                                                                    <div className='card-heading'>
                                                                                        <p>{getFormatedDate(item.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='lista-right'></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                return null;
                                                            })
                                                        )
                                                        : ((banner.length) && banner.map((item, i) => {
                                                            if (item.banner_type === bannerCategory) {
                                                                return (
                                                                    <div className='rounded' style={{ cursor: "pointer" }}
                                                                        key={i} onClick={() => navigate(`/billboard/editorcartelera/${item.banner_id}`,
                                                                            { state: item })} >
                                                                        <div className='myTeamBanner billboard-cards' >
                                                                            <div className='lista-name ps-0'>
                                                                                <div className='card-img'>
                                                                                    {/* <img src={item?.imageUrl} alt="" /> */}
                                                                                    {(item?.imageUrl)
                                                                                        && checkType(item?.imageUrl)
                                                                                    }
                                                                                </div>
                                                                                <div className='card-heading'>
                                                                                    <h3>{item.title}</h3>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-creation'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-modification'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-right'></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            return null;
                                                        }))
                                                    }
                                                </div>)
                                                : (<div className="Loader d-flex justify-content-center align-items-center">
                                                    <div className="spinner-border my-5" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                    <div className=" my-5" > &nbsp;
                                                        {t('Cargando')}...
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    )}

                                    <div className='billboard-btn-sec'>
                                        <button className='green-btn' onClick={() => { navigate('/billboard/addcartelera') }}>
                                            {t('Agregar Cartelera')}
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Billboard