import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

import translations from '../../../locals/translation';
import { useSelector } from 'react-redux';

export const options = {
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
        color: '#FEF6F2'
    },
    slices: {
        0: {
            offset: 0.1,
            color: '#005EAA'
        },
        1: { color: '#25762E' },
        2: { color: '#C37704' },
    },
    chartArea: {
        top: 80
    }
};

const PieChart = ({ UserWithMembership }) => {
    // console.log('UserWithMembership: from pichart section: ', UserWithMembership);

    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    // console.log('UserWithMembership: ', UserWithMembership);
    const [data, setData] = useState([
        ["Subscription", "Users"],
    ]);

    useEffect(() => {
        let newData = [
            ["Subscription", "Users"],
        ];
        let userWithGoldMS = 0, userWithPlatinumMS = 0, userWithDiamondMS = 0;

        // console.log('-- UserWithMembership:: ', UserWithMembership);
        if (UserWithMembership) {
            UserWithMembership?.forEach((element, i) => {
                switch (element?.membership_plans_new?.plan_category) {
                    case "gold":
                        userWithGoldMS += element?.count;
                        break;
                    case "diamond":
                        userWithDiamondMS += element?.count;
                        break;
                    case "platinum":
                        userWithPlatinumMS += element?.count;
                        break;
                    default:
                        break;
                }
            });

            newData.push(["Diamond", userWithDiamondMS]);
            newData.push(["platinum", userWithPlatinumMS]);
            newData.push(["Gold", userWithGoldMS]);

            setData([...newData]);
        } else {
            setData([
                ["Subscription", "Users"],
                ["Gold", 0],
                ["Diamond", 0],
                ["platinum", 0]
            ])
        }
    }, [UserWithMembership])

    const checkVisible = (data) => {
        // console.log('data: ', data);
        for (let item of data) {
            // console.log('item: ', item, typeof item[1]);
            if (item[1] && ((typeof item[1]) === "number")) {
                return true;
            }
        }
        return false;
    }

    return (
        <>
            {checkVisible(data)
                ? (<>
                    <div>
                        <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"280px"}
                            height={"250px"}
                        />
                    </div>
                    <h6 className="text-center fw-bold ms-2">
                        {t('DIRIGENTES DIGITALES')}
                    </h6>
                </>
                )
                : (
                    <></>
                )
            }
        </>
    )
}

export default PieChart;