import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';
// import { FiSearch } from "react-icons/fi"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GlobalURL from "../../BaseURL";
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const AddOtherSelectionNews = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };
    const [file, setFile] = useState(null);
    const formData = new FormData()

    const [teamStatus, setTeamStatus] = useState();
    useEffect(() => {
        let updateStatus = localStorage.getItem("national");
        if (updateStatus === 'null')
            setTeamStatus(null);
        else
            setTeamStatus(true);
    }, [])

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
        console.log(e.target.value)
        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    };

    const navigate = useNavigate();
    const [newsData, setNewsData] = useState({ title: "", content: "", sub_category: "", category: "other-selection" });

    const handleNewsContent = (e) => {
        setNewsData({ ...newsData, [e.target.name]: e.target.value });
    }

    //add news
    const handleSubmit = (e) => {
        e.preventDefault()
        const newsBtn = document.querySelector(".news-btn-sec");
        const errSpn = document.querySelector(".err-spn ");
        newsBtn.innerHTML = `<button class="green-btn loaderbtn signup-btn " disbaled >
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">&nbsp; Enviando...</span>
        </button>`;
        if (newsData.title.length && newsData.content.length) {
            formData.append("media", file)
            for (let formfields in newsData) {
                formData.append(formfields, newsData[formfields]);
            }
            fetch(`${GlobalURL}/api/news/addnews`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                    "team_id": localStorage.getItem("team_id")
                },
                body: formData
            })
                .then((response) => {
                    if (response.status === 404) {
                        console.log("status: ", response)
                        errSpn.innerHTML = '¡Error del Servidor!';
                        newsBtn.innerHTML = `<button class='green-btn' type='submit' >Guardar</button>`;
                    }
                    else if (response.status === 200) {
                        console.log("done!!");
                        Swal.fire("Noticia añadido con éxito!");
                        navigate(-1);
                        newsBtn.innerHTML = `<button class='green-btn' type='submit'  >Guardar</button>`;
                        return response.json();
                    }
                })
                .then(res => {
                    console.log("then 2nd: ")
                    console.log("----> ", res);
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                    errSpn.innerHTML = '¡Error del Servidor!';
                    newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
                });
        } else {
            //apply error message for news if fields are empty
            errSpn.innerHTML = ' ¡Campos requeridos!';
            newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='newsAdd-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="back_icon" />
                                        </div>
                                        <h3 className='header-title'>{t('Agregar Noticia')}</h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className='common-input-forms newsAddContent'>
                                        <form action="" onSubmit={handleSubmit}>

                                            <div className='common-form-control'>
                                                <label>{t('categoría de noticias')}</label>
                                                <select name='sub_category'
                                                    onChange={handleNewsContent}
                                                    value={newsData.sub_category}
                                                >
                                                    {teamStatus
                                                        ? (<>
                                                            <option value="">{t('Seleccione la categoría de noticias juveniles')}.</option>
                                                            <option value="senior">{t('Senior')}</option>
                                                            <option value="bats">{t('Los Murciélagos')}</option>
                                                            <option value="mensfutsal">{t('Mens Futsal')}</option>
                                                            <option value="womensfutsal">{t('Womens Futsal')}</option>
                                                            <option value="beachsoccer">{t('Futbol Playa')}</option>
                                                        </>)
                                                        : (<>
                                                            <option value="">{t('Seleccione la categoría de noticias juveniles')}.</option>
                                                            <option value="senior">{t('senior')}</option>
                                                            <option value="Los-Murciélagos">{t('Los Murciélagos')}</option>
                                                            <option value="Futsal">{t('Futsal')}</option>
                                                            <option value="Futbol-Playa">{t('Futbol Playa')}</option>
                                                        </>)
                                                    }
                                                </select>
                                            </div>

                                            <div className='common-form-control'>
                                                <label>{t('Título')}</label>
                                                <input placeholder={t('Inserte el título de la noticia aquí')} name='title' value={newsData.title}
                                                    onChange={handleNewsContent} />
                                            </div>

                                            <div className='common-form-control img-video-form-control'>
                                                <label>{t('Foto / Video')}</label>
                                                <div className='file-input-sec '>
                                                    <input id="uploadFile" className="f-input"
                                                        placeholder={t('Inserte la foto o video de la noticia aquí')} />
                                                    <div className="fileUpload btn btn--browse">

                                                        <input id="uploadBtn" onChange={handleFileSelect} type="file" className="upload" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='common-form-control'>
                                                <label>{t('Descripción')}</label>
                                                <input placeholder={t('Inserte la descripción de la noticia aquí')} name='content'
                                                    value={newsData.content} onChange={handleNewsContent} />
                                            </div>
                                            <div className='err-spn text-danger text-center'>  </div>
                                            <div className='news-btn-sec'>
                                                <button className='green-btn' type='submit'>{t('Guardar')}</button>
                                            </div>
                                        </form>
                                    </div>

                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddOtherSelectionNews;
