import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { setLanguage } from '../../redux/languageSlice';
import { useDispatch, useSelector } from 'react-redux';
import translations from '../../locals/translation';
import { FaUserCircle } from "react-icons/fa";
import Sidebar from '../Sidebar/Sidebar';
import Swal from 'sweetalert2';
import React from 'react';
import "./style.css";

const Navbar = ({ userDetails }) => {

  const dispatch = useDispatch(); // Get dispatch function

  // eslint-disable-next-line
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  }

  const language = useSelector(state => state.language.value);
  const navigate = useNavigate();
  const loc = useLocation();
  console.log('userDetails from navbar: ', userDetails);
  console.log('loc: ', loc);

  const changeLanguage = (lang) => {
    dispatch(setLanguage(lang)); // Dispatch action to update language
  };

  const handleLogout = () => {
    const swalWithBootstrapButtons = Swal.mixin();
    swalWithBootstrapButtons.fire({
      title: t("¿Cerrar sesión?"),
      text: t("¿Quieres cerrar sesión?"),
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: t("Sí"),
      denyButtonText: t(`Cancelar`),
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate('/login');
        swalWithBootstrapButtons.fire({
          title: t("Desconectado!"),
          icon: "success"
        });
      }
    });
  }

  return (
    <>
      <div className="navbar-sec" >
        <div className='navbar-container'>
          <div className='menu-bar'>
            {!(loc.pathname.includes('homepage'))
              && <Sidebar />}
          </div>
          <div className='logo-sec' onClick={() => navigate('/homepage')} style={{ cursor: "pointer" }}>
            <img src="/img/LogoMess.png" alt="brand_logo" />
          </div>
          <div className='user-profile-sec'>
            <div className='notification'>
              <IoIosNotificationsOutline /><span className='red-dot'></span>
            </div>
            <div className='user-profile' onClick={handleLogout} style={{ cursor: "pointer" }}>
              <span className='user-img'>
                {/* {console.log("ls-data pp : ", typeof localStorage.getItem('profile_image'))} */}
                {(localStorage.getItem('profile_image') === 'null')
                  ? (<FaUserCircle />)
                  : (<img className='user-profilePic' src={localStorage.getItem('profile_image')} alt='' />)
                }
              </span>
              
              <span className='user-name'>
                @{localStorage.getItem('userName')}
              </span>
            </div>

            <div className='select-lang' value={language}
              onChange={(e) => changeLanguage(e.target.value)}>
              <select>
                <option value="es">ES</option>
                <option value="en">ENG</option>
              </select>
            </div>

            {/* 
            <div className='select-lang'>
              <select>
                <option value="ES">ES</option>
                <option value="ENG">ENG</option>
              </select>
            </div> 
            */}

          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
