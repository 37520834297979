import io from 'socket.io-client';
export const URL = "https://stagging-dot-mess-ice-app.uc.r.appspot.com";

// export const URL = 'https://development-dot-mess-ice-app.uc.r.appspot.com';
// export const URL = "https://stagging-server01-dot-stagging-dot-mess-ice-app.uc.r.appspot.com";
// export const URL = "https://smashing-shrew-peaceful.ngrok-free.app";
// export const URL = "https://sunbird-top-happily.ngrok-free.app";
// export const URL = "https://mess-ice-app.uc.r.appspot.com";

// Establish the socket connection.
export const socket = io(URL, {
    autoConnect: false,
    transports: ["websocket"]
});
