import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GlobalURL from "../../BaseURL";
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const YouthNewsAdd = () => {
    const language = useSelector(state => state.language.value);
    const navigate = useNavigate();

    const [teamStatus, setTeamStatus] = useState();
    const [newsData, setNewsData] = useState({ title: "", content: "", sub_category: "", category: "youth" });
    const [editorContent, setEditorContent] = useState('');

    const [submitBtn, setSubmitBtn] = useState(false);
    const [errMsg, setErrMsg] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [file, setFile] = useState(null);
    const formData = new FormData()
    const editorRef = useRef(null);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content    
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerText);
        setNewsData({ ...newsData, description: editorRef.current.innerHTML })
        handleClose();
    };

    const handleFileSelect = (e) => {
        setFile(e.target.files);
        console.log(e.target.value)
        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    };

    useEffect(() => {
        let updateStatus = localStorage.getItem("national")
        console.log('updateStatus: ', updateStatus, " : ", typeof updateStatus);

        if (updateStatus === 'null')
            setTeamStatus(null);
        else
            setTeamStatus(true);
    }, [])

    const handleNewsContent = (e) => {
        setNewsData({ ...newsData, [e.target.name]: e.target.value });
    }

    //add news
    // const handleSubmitt = (e) => {
    //     e.preventDefault()
    //     const newsBtn = document.querySelector(".news-btn-sec");
    //     const errSpn = document.querySelector(".err-spn ");
    //     newsBtn.innerHTML = `<button class="green-btn loaderbtn signup-btn " disbaled >
    //         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    //         <span class="sr-only">&nbsp; Enviando...</span>
    //     </button>`;
    //     if (newsData.title.length && newsData.content.length) {
    //         formData.append("media", file)
    //         for (let formfields in newsData) {
    //             formData.append(formfields, newsData[formfields]);
    //         }
    //         console.log("news->> ", newsData);
    //         // fetch(`${GlobalURL}/api/news/addnews`, {
    //         fetch(`${GlobalURL}/api/dev/team_moderator/addnews`, {
    //             method: "POST",
    //             mode: "cors",
    //             headers: {
    //                 "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
    //                 "ngrok-skip-browser-warning": "69420",
    //                 "team_id": localStorage.getItem("team_id")
    //             },
    //             body: formData
    //         })
    //             .then((response) => {
    //                 if (response.status === 404) {
    //                     console.log("status: ", response)
    //                     errSpn.innerHTML = '¡Error del Servidor!';
    //                     newsBtn.innerHTML = `<button class='green-btn' type='submit' >Guardar</button>`;
    //                 }
    //                 else if (response.status === 200) {
    //                     console.log("done!!");
    //                     Swal.fire("Noticia añadido con éxito!");
    //                     navigate(-1);
    //                     newsBtn.innerHTML = `<button class='green-btn' type='submit'  >Guardar</button>`;
    //                     return response.json();
    //                 }
    //             })
    //             .then(res => {
    //                 console.log("then 2nd: ")
    //                 console.log("----> ", res);
    //             })
    //             .catch((err) => {
    //                 console.log("error occured: ", err);
    //                 errSpn.innerHTML = '¡Error del Servidor!';
    //                 newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
    //             });
    //     } else {
    //         //apply error message for news if fields are empty
    //         errSpn.innerHTML = ' ¡Campos requeridos!';
    //         newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
    //     }
    // }

    const handleSubmit = (e) => {
        e.preventDefault()

        // const newsBtn = document.querySelector(".news-btn-sec");
        // const errSpn = document.querySelector(".err-spn ");
        // newsBtn.innerHTML = `<button class="green-btn loaderbtn signup-btn " disabled >
        //     <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        //     <span class="sr-only">&nbsp; Enviando...</span>
        // </button>`;

        if (newsData.title.length && editorContent.length && file) {
            console.log('file before append: ', file);
            setSubmitBtn(true)
            for (let x of file) {
                formData.append("image", x)
                console.log('file[x]: ', x);
            }

            // formData.append("media", file.map((item)=>{
            //     return item
            // }))

            formData.append("title", newsData.title);
            formData.append("content", editorContent);
            formData.append("sub_category", newsData.sub_category);
            formData.append("category", newsData.category);

            console.log("title: ", newsData);
            console.log('editorContent: ', editorContent);

            fetch(`${GlobalURL}/api/dev/team_moderator/addnews`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                    "team_id": localStorage.getItem("team_id")
                },
                body: formData
            })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log("done!!");
                        navigate(-1);
                        setErrMsg(false)
                        Swal.fire("Noticia añadido con éxito!");
                        // newsBtn.innerHTML = `<button class='green-btn' type='submit'  >Guardar</button>`;
                        return response.json();
                    } else {
                        // console.log("status: ", response)
                        setErrMsg('¡Error del Servidor!');
                        // newsBtn.innerHTML = `<button class='green-btn' type='submit' >Guardar</button>`;
                    }
                })
                .catch(() => {
                    // console.log("error occured: ", err);
                    setErrMsg('¡Error del Servidor!');
                    // newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
                }).finally(() => {
                    setSubmitBtn(false);
                });
        } else {
            // console.log("title: ", newsData);
            // console.log('editorContent: ', editorContent);
            //apply error message for news if fields are empty
            setErrMsg('¡Campos requeridos!');
            // newsBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='newsAdd-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="back_icon" />
                                        </div>
                                        <h3 className='header-title'>{t("Agregar Noticia")}</h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className='common-input-forms newsAddContent'>
                                        <form action="" onSubmit={handleSubmit}>

                                            <div className='common-form-control'>
                                                <label>{t("categoría de noticias")}</label>
                                                <select name='sub_category'
                                                    onChange={handleNewsContent}
                                                    value={newsData.sub_category}
                                                >
                                                    {teamStatus
                                                        ? (<>
                                                            <option value="">{t("Seleccione la categoría de noticias juveniles")}.</option>
                                                            <option value="Sub 23">Sub 23</option>
                                                            <option value="Sub 20">Sub 20</option>
                                                            <option value="Sub 17">Sub 17</option>
                                                            <option value="Sub 15">Sub 15</option>
                                                        </>)
                                                        : (<>
                                                            <option value="">{t("Seleccione la categoría de noticias juveniles")}.</option>
                                                            <option value="reserve">Reserva </option>
                                                            <option value="fourth">Cuarta </option>
                                                            <option value="fifth">Quinta </option>
                                                            <option value="sixth">Sexta </option>
                                                            <option value="seventh">Séptima </option>
                                                            <option value="eighth">Eighth </option>
                                                            <option value="ninth">Novena </option>
                                                        </>)
                                                    }
                                                </select>
                                            </div>

                                            <div className='common-form-control'>
                                                <label>{t("Título")}</label>
                                                <input placeholder={t('Inserte el título de la noticia aquí')} name='title' value={newsData.title}
                                                    onChange={handleNewsContent} />
                                            </div>

                                            <div className='common-form-control img-video-form-control'>
                                                <label>{t("Foto / Video")}</label>
                                                {/* <input type='file' placeholder='Inserte la foto o video de la noticia aquí' /> */}
                                                <div className='file-input-sec '>
                                                    <input id="uploadFile" className="f-input" placeholder={t('Inserte la foto o video de la noticia aquí')} />
                                                    <div className="fileUpload btn btn--browse">
                                                        <input id="uploadBtn" onChange={handleFileSelect} type="file" className="upload" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='common-form-control'
                                                onClick={handleShow}>
                                                <label>{t('Descripción')}</label>
                                                <input type='text' name='description' readOnly
                                                    placeholder={`${t('Inserte aquí la descripción de la noticia')}`}
                                                />
                                            </div>

                                            <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                                <Modal.Header className="pb-0 mb-0" closeButton >
                                                    <Modal.Title>{t('Editar')}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>

                                                    <div className="custom-text-editor w-100">
                                                        <div className="toolbar">
                                                            <button onClick={handleBold}>
                                                                <img src="../img/bold.png" alt="bold" />
                                                            </button>
                                                            <button onClick={handleItalic}>
                                                                <img src="../img/italic.png" alt="italic" />
                                                            </button>
                                                            <button onClick={handleStrikeThrough}>
                                                                <img src="../img/strikethrough.png" alt="strikethrough" />
                                                            </button>
                                                            <div className="text-align-buttons">
                                                                <button onClick={() => handleTextAlign('Left')}>
                                                                    <img src="../img/left-align.png" alt="left-align" />
                                                                </button>
                                                                <button onClick={() => handleTextAlign('Center')}>
                                                                    <img src="../img/center-align.png" alt="center-align" />
                                                                </button>
                                                                <button onClick={() => handleTextAlign('Right')}>
                                                                    <img src="../img/right-align.png" alt="right-align" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="editor"
                                                            ref={editorRef}
                                                            contentEditable
                                                            // onInput={handleChange}
                                                            // onChange={handleChange}
                                                            dangerouslySetInnerHTML={{ __html: editorContent }}
                                                        ></div>

                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer className='border-0 p-0'>
                                                    <div className='news-btn-sec'>
                                                        <button className='green-btn'
                                                            onClick={handleGuardarClick}>
                                                            {t('GUARDAR')}
                                                        </button>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>

                                            <div className='err-spn text-danger text-center'>
                                                {errMsg}
                                            </div>
                                            <div className='news-btn-sec'>
                                                {submitBtn
                                                    ? (
                                                        <button className="green-btn loaderbtn signup-btn " disabled >
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true">
                                                            </span>
                                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                        </button>
                                                    )
                                                    : (
                                                        <button className='green-btn' type='submit'>
                                                            {t('Guardar')}
                                                        </button>
                                                    )
                                                }

                                            </div>
                                        </form>
                                    </div>

                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default YouthNewsAdd
