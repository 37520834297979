import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { FiSearch } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
// import { FANCY } from '../BaseURL';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EventPage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [editorContent, setEditorContent] = useState('');
    const [submitBtn, setSubmitBtn] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [file, setFile] = useState(null);
    const formData = new FormData();

    const [eventData, setEventData] = useState({
        title: "",
        event_type: "",
        description: "",
        event_date: "",
        streaming_link: ""
    });

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const editorRef = useRef(null);

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
        console.log(e.target.value);
        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    };

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content    
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerText);
        setEventData({ ...eventData, description: `<span>${editorRef.current.innerHTML}</span>` });
        handleClose();
    };

    const handleEventData = (e) => {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        // console.log("event data: ", eventData);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // const errSpn = document.querySelector(".err-spn ");
        if (eventData.title.length
            && eventData.event_type
            && file
            && eventData.description.length
            && eventData.event_date.length) {
            setSubmitBtn(true);
            eventData.team_id = localStorage.getItem("team_id");

            for (let item in eventData) {
                formData.append(item, eventData[item]);
            }
            formData.append("image", file);

            fetch(`${GlobalURL}/api/dev/home/addEvent`, {
                method: "POST",
                mode: "cors",
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
                // body: JSON.stringify(eventData)
            }).then((response) => {
                if (response.status === 200) {
                    setErrMsg(false)
                    Swal.fire(t("Eventos añadido con éxito!"));
                    navigate(-1);
                    setErrMsg(false);
                    return response.json();
                } else {
                    // console.log("status: ", response)
                    setErrMsg(t('¡Error del Servidor!'))
                }
            }).catch((err) => {
                // console.log("error occured: ", err);
                setErrMsg(t('¡Error del Servidor!'))
            }).finally(() => {
                setSubmitBtn(false)
            });
        } else {
            //apply error message for news if fields are empty
            // errSpn.innerHTML = ' ¡Campos requeridos!';
            setErrMsg(t('¡Campos requeridos!'));
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='event-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }} >
                                            <img src="../img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('Agregar Eventos')}
                                        </h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>
                                    
                                    <div className='event-page-content'>
                                        <div className='common-input-forms'>
                                        </div>
                                        {/* <div className='event-page-content'> */}
                                            <div className='common-input-forms'>
                                                <form action="" onSubmit={handleSubmit}>
                                                    <div className='common-form-control'>
                                                        <label>{t('Título')}</label>
                                                        <input type='text' name='title' value={eventData.title} onChange={handleEventData}
                                                            placeholder={t('Inserte el título del evento aquí')} />
                                                    </div>
                                                    <div className='common-form-control'>
                                                        <label>{t('Tipo de Evento')}</label>
                                                        <select name='event_type'
                                                            onChange={handleEventData}
                                                            value={eventData.event_type}
                                                        >
                                                            <option value="">{t('Escoja el tipo de evento (evento regular o exclusivo)')}</option>
                                                            {/* <option value="today">Hoy</option> */}
                                                            <option value="events">{t('Eventos')}</option>
                                                            <option value="exclusive-events">{t('Eventos Exclusivos')}</option>
                                                            <option value="experiences-messice">{t('Vivencias Mess Ice')}</option>
                                                        </select>
                                                    </div>
                                                    
                                                    {/* 
                                                    <div className='common-form-control'>
                                                        <label>Descripción</label>
                                                        <input type='text' name='description' value={eventData.description}
                                                         onChange={handleEventData}
                                                            placeholder='Ingrese la descripción del evento aquí' />
                                                    </div> 
                                                    */}

                                                    <div className='common-form-control img-video-form-control'>
                                                        <label>{t('Foto')}</label>
                                                        {/* <input type='file' placeholder='Inserte la foto o video de la noticia aquí' /> */}
                                                        <div className='file-input-sec '>
                                                            <input id="uploadFile" className="f-input"
                                                                placeholder={t('Inserte la foto o video de la eventos aquí')} />
                                                            <div className="fileUpload btn btn--browse">
                                                                <input id="uploadBtn" onChange={handleFileSelect} type="file" className="upload" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='common-form-control'
                                                        onClick={handleShow}>
                                                        <label>{t('Descripción')}</label>
                                                        <input type='text' placeholder={t('Inserte la descripción de la eventos aquí')}
                                                            name='description' readOnly
                                                        />
                                                    </div>
                                                    <div className='common-form-control'>
                                                        <label >{t('Seleccionar Fecha')}</label>
                                                        <input type='datetime-local' name='event_date' value={eventData.event_date}
                                                            onChange={handleEventData}
                                                            placeholder='Seleccione la fecha del evento' />
                                                    </div>
                                                    <div className='common-form-control'>
                                                        <label>{t('Link de Streaming / otro')}</label>
                                                        <input type='url' name='streaming_link' value={eventData.streaming_link}
                                                            onChange={handleEventData}
                                                            placeholder={t('Inserte el link del streaming o evento')} />
                                                    </div>
                                                    <div className='err-spn text-danger text-center'> {errMsg} </div>

                                                    <div className='news-btn-sec'>
                                                        {submitBtn
                                                            ? (<button className="green-btn loaderbtn signup-btn " disabled >
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                                                                </span>
                                                                <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                            </button>
                                                            )
                                                            : (
                                                                <button className='green-btn' type='submit'>{t('Guardar')}</button>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        {/* </div> */}
                                    </div>

                                    <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                        <Modal.Header className="pb-0 mb-0" closeButton >
                                            <Modal.Title>{t('Editar')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>

                                            <div className="custom-text-editor w-100">
                                                <div className="toolbar">
                                                    <button onClick={handleBold}>
                                                        <img src="../img/bold.png" alt="bold" />
                                                    </button>
                                                    <button onClick={handleItalic}>
                                                        <img src="../img/italic.png" alt="italic" />
                                                    </button>
                                                    <button onClick={handleStrikeThrough}>
                                                        <img src="../img/strikethrough.png" alt="strikethrough" />
                                                    </button>
                                                    <div className="text-align-buttons">
                                                        <button onClick={() => handleTextAlign('Left')}>
                                                            <img src="../img/left-align.png" alt="left-align" />
                                                        </button>
                                                        <button onClick={() => handleTextAlign('Center')}>
                                                            <img src="../img/center-align.png" alt="center-align" />
                                                        </button>
                                                        <button onClick={() => handleTextAlign('Right')}>
                                                            <img src="../img/right-align.png" alt="right-align" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div
                                                    className="editor"
                                                    ref={editorRef}
                                                    contentEditable
                                                    // onInput={handleChange}
                                                    // onChange={handleChange}
                                                    dangerouslySetInnerHTML={{ __html: editorContent }}
                                                ></div>

                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer className='border-0 p-0'>
                                            <div className='news-btn-sec'>
                                                <button className='green-btn' onClick={handleGuardarClick}>{t('GUARDAR')}</button>
                                            </div>
                                        </Modal.Footer>
                                    </Modal>

                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventPage
