import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { FiSearch } from "react-icons/fi";
import { FaPenClip } from "react-icons/fa6";
import { GrUpload } from "react-icons/gr";
import Modal from 'react-bootstrap/Modal';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EditExp = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const experienceData = useLocation().state;
    const [oldDescription, setOldDescription] = useState(experienceData.Description);
    const [newReqInsignia, setNewReqInsignia] = useState(experienceData.insignia_required);
    const [insigniaList, setInsigniaList] = useState();
    const [delExecute, setDelExecute] = useState(false);
    const [editExecute, setEditExecute] = useState(false);
    const [file, setFile] = useState(null);
    const [experience, setExperience] = useState({});
    const [show, setShow] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [editExperience, setEditExperience] = useState(false);
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    const formData = new FormData();
    const editorRef = useRef(null);
    console.log('item: ', experienceData);

    const handleShow = () => setShow(true);

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    // setErrorMsg(false)
                    return response.json();
                } else {
                    // setErrorMsg(true)
                }
            })
            .then(res => {
                // setErrorMsg(true)
                setInsigniaList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const handleRequiredExprience = (e) => {
        // experienceData.insignia_required = e.target.value;
        setNewReqInsignia(e.target.value);
    }

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content    
        experienceData.Description = editorRef.current.innerText;
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerText);
        handleClose();
    };

    useEffect(() => {
        setExperience(experienceData);
        // eslint-disable-next-line
    }, [])

    //handle edit experience
    const handleEditExperience = () => {
        const editBtn = document.querySelector(".blue-btn");
        const nodes = document.querySelectorAll('.edit-sec');

        if (editExperience) {
            //make editable state - true
            let prevDescription, prevTitle, prevGems, prevInsignia,
                newDescription, newTitle, newGems, newInsignia;

            setEditExperience(false);
            prevTitle = experience.title;
            prevDescription = experience.Description;
            prevGems = experience.gems_count;
            prevInsignia = experience.insignia_required;

            newTitle = nodes[0].innerText;
            if (editorContent.trim())
                newDescription = editorContent;
            else
                newDescription = experience.Description
            newGems = nodes[1].innerText;
            newInsignia = newReqInsignia;

            editBtn.innerHTML = 'EDITAR';
            for (let element of nodes) {
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }
            if ((prevDescription !== newDescription) ||
                (prevTitle !== newTitle) ||
                (prevGems !== newGems) ||
                (prevInsignia !== newInsignia) ||
                (file)) {
                Swal.fire({
                    title: t("Estas seguro"),
                    text: t('¿Quieres editar la experiencia?'),
                    showDenyButton: true,
                    confirmButtonText: t("Editar experiencia"),
                    denyButtonText: `Cancel`
                }).then((result) => {
                    setEditExecute(true)
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        formData.append('title', newTitle)
                        formData.append('description', newDescription)
                        formData.append('experience_id', experience.experience_id)
                        formData.append('gems_count', newGems)
                        formData.append('insignia_required', newInsignia)
                        if (file)
                            formData.append('media', file);
                        else
                            formData.append('media', experience.experience_media[0].url);

                        fetch(`${GlobalURL}/api/dev/miTeam/updateExperience`, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                "ngrok-skip-browser-warning": "69420",
                            },
                            body: formData
                        })
                            .then((res) => {
                                Swal.fire(t("Editar!"), "", "success");
                                navigate(-1);
                            })
                            .catch((err) => {
                                Swal.fire(t("Editar!"), t("No se puede editar, inténtalo después de un tiempo"), "error");
                                console.log(err)
                            }).finally(() => {
                                setEditExecute(false);
                            })
                    } else {
                        nodes[0].innerText = prevTitle;
                        // nodes[1].innerText = prevDescription;
                        experience.Description = oldDescription;
                        setOldDescription(oldDescription);
                        nodes[1].innerText = prevGems;
                        // nodes[2].innerText = prevInsignia;
                        // experienceData.insignia_required = oldReqInsignia;
                        setFile(null);
                        setEditExecute(false);
                    }
                });
            } else {
                // console.log("nothing changes....")
            }
        } else {
            //toggle the editable state 
            setEditExperience(true)
            editBtn.innerHTML = t('Guardar cambios');
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    // delete experience
    const handleNewsDeletion = () => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¡Quieres borrar!'),
            showDenyButton: true,
            confirmButtonText: t("borrar"),
            denyButtonText: `Cancel`
        }).then((result) => {
            if (result.isConfirmed) {
                setDelExecute(true)
                fetch(`${GlobalURL}/api/dev/miTeam/deleteExperience`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ experience_id: experienceData.experience_id })
                })
                    .then((res) => {
                        if (res.status === 200) {
                            console.log('res: ', res);
                            Swal.fire(t("borrar!"), "", "success");
                            navigate(-1);
                        } else {
                            Swal.fire(t("borrar!"), "", "error");
                        }
                    })
                    .catch((err) => {
                        Swal.fire(t("borrar!"), "", err);
                        console.log(err)
                    }).finally(() => {
                        setDelExecute(false);
                    })
            }
        });
    }

    const checkType = (url) => {
        console.log('url: ', url);
        if (file) {
            return <img src={URL.createObjectURL(file)} alt="" />
        } else {
            let extention = url?.split('.')?.at(-1);
            let res = images.find((ext) => {
                return extention.includes(ext);
            })
            if (!url) {
                return <img src='' alt="" />
            } else if (images.includes(url?.split('.')?.at(-1)) || res) {
                return <img src={url} alt="" />
            } else {
                return <video src={url} alt="" controls />
            }
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='editExp-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='exp-editPage'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate(-1) }} style={{ cursor: 'pointer' }}>
                                                <img src="../img/volver.png" alt="goback" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>{t('Vivencias Mess Ice')}</h3>
                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>
                                        <div className='editExp-description'>
                                            <div className='edit-exp-card'>
                                                <div className='card-heading'>
                                                    <h3 className='edit-sec' contentEditable='false'>{experienceData.title}</h3>
                                                </div>
                                                <div className='card-img editnewscard-img experience-video'>
                                                    {file
                                                        ? checkType(file.name)
                                                        : (experienceData?.experience_media[0]?.url)
                                                        && checkType(experienceData?.experience_media[0]?.url)
                                                    }
                                                    {editExperience
                                                        && (
                                                            <div className='img-edit-icon'>
                                                                <GrUpload />
                                                                <input id="uploadFile" onChange={handleFileSelect}
                                                                    type="file" className="upload" />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <h4>{t('Descripción')}</h4>
                                                {editExperience
                                                    && <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                        <FaPenClip />
                                                    </div>
                                                }
                                            </div>
                                            <div className='edit-exp-data'>
                                                <p className='desc '
                                                    dangerouslySetInnerHTML={{ __html: experienceData?.Description }}>
                                                </p>

                                                <div className='req-sec'>
                                                    <h4 className='req-heading'>
                                                        {t("REQUERIMIENTOS")}
                                                        </h4>
                                                    <ul className='req-list'>
                                                        <li>
                                                            <p>
                                                                <img src="../img/yellowdiamond.png" alt="" /> &nbsp;
                                                                <span className='edit-sec'>
                                                                    {experienceData.gems_count}
                                                                </span> &nbsp;
                                                                {t('gemas')}!
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>{t('Una insignia de')} &nbsp;
                                                                {/* 
                                                                <span className='edit-sec'>
                                                                    {experienceData.insignia_required}
                                                                </span>!
                                                                 */}
                                                                {editExperience
                                                                    ? (<select
                                                                        name='insignia_required'
                                                                        onChange={handleRequiredExprience}
                                                                        value={newReqInsignia}
                                                                    >
                                                                        {/* <option value="">{experienceData.insignia_required}</option> */}
                                                                        {insigniaList?.map((item) => {
                                                                            return (
                                                                                <option key={item.insignia_id} value={item.insignia_name_es}>
                                                                                    {item.insignia_name_es}
                                                                                </option>)
                                                                        })}
                                                                    </select>)
                                                                    : (<span className='edit-sec'>
                                                                        {experienceData.insignia_required || t("No requerido")} !
                                                                    </span>)
                                                                }
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div className='space-between-btn'>
                                            {editExecute
                                                ? (<button className="green-btn loaderbtn signup-btn" disabled >
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                </button>
                                                )
                                                : (<button className='blue-btn' onClick={handleEditExperience}>{t('Editar')}</button>)
                                            }
                                            {delExecute
                                                ? (<button className="green-btn loaderbtn signup-btn" disabled >
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                </button>
                                                )
                                                : (
                                                    <button className='red-btn' onClick={handleNewsDeletion}>{t('Eliminar')}</button>
                                                )
                                            }
                                            {/* <button className='green-btn'>Guardar</button> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>

                            <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                <Modal.Header className="pb-0 mb-0" closeButton >
                                    <Modal.Title>{t('Editar')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="custom-text-editor w-100">
                                        <div className="toolbar">
                                            <button onClick={handleBold}>
                                                <img src="../img/bold.png" alt="bold" />
                                            </button>
                                            <button onClick={handleItalic}>
                                                <img src="../img/italic.png" alt="italic" />
                                            </button>
                                            <button onClick={handleStrikeThrough}>
                                                <img src="../img/strikethrough.png" alt="strikethrough" />
                                            </button>
                                            <div className="text-align-buttons">
                                                <button onClick={() => handleTextAlign('Left')}>
                                                    <img src="../img/left-align.png" alt="left-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Center')}>
                                                    <img src="../img/center-align.png" alt="center-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Right')}>
                                                    <img src="../img/right-align.png" alt="right-align" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="editor"
                                            ref={editorRef}
                                            contentEditable
                                            // onInput={handleChange}
                                            // onChange={handleChange}
                                            dangerouslySetInnerHTML={experienceData.Description
                                                    ? ({ __html: experienceData.Description })
                                                    : ({ __html: editorContent })
                                            }
                                        ></div>

                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='border-0 p-0'>
                                    <div className='news-btn-sec'>
                                        <button className='green-btn' onClick={handleGuardarClick}>{t('GUARDAR')}</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>

                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditExp
