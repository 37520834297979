import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import Microlink from '@microlink/react';
import { FiPlus } from "react-icons/fi";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { socket } from '../../socket';
import GlobalURL from '../BaseURL';
import BarChart from './BarChart';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const DebateChatRoom = () => {

    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const stateData = useLocation();
    const [messageTyped, setMessageTyped] = useState();
    const [groupChat, setGroupChat] = useState();
    const [participantsList, setParticipantsList] = useState();
    const [mediaURL, setMediaURL] = useState();
    const [media, setMedia] = useState(null);
    const formData = new FormData();
    const [addMember, setAddMember] = useState();
    const [selectedMember, setSelectedMember] = useState();
    const [digitalManager, setDigitalManager] = useState(null);
    const [memberCount, setMemberCount] = useState(0);
    // const docTypes = ["docx", "doc", "pptx", "ppt", "xls", "xlsx", "txt", "pdf", "zip"];

    const [searchWord, setSearchWord] = useState();
    const [searchMemberList, setSearchMemberList] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [activeTabRequired, setActiveTabRequired] = useState(false);
    const [proposalBar, setProposalBarData] = useState('');

    const [mediaType, setMediaType] = useState('MEDIA');
    const [allMedia, setAllMedia] = useState();
    const [isWait, setIsWait] = useState();

    const inputRef = useRef(null);
    const lastMessageRef = useRef(null);

    //get the like, dislike and share count of proposal 
    useEffect(() => {
        console.log('stateData.state.: ', stateData.state.item.inbox_uid);
        if (!stateData.state.first) {
            fetch(`${GlobalURL}/api/dev/debate/chat/getdebatelikesdislikes`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ inbox_uid: stateData.state.item.inbox_uid, user_id: stateData.state.item.user_id })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        Swal.fire("error", t("No se pueden obtener datos de la propuesta!"), "error");
                    }
                })
                .then(res => {
                    setProposalBarData(res.data);
                    console.log('res.data: ', res.data);
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                });
        }
        // eslint-disable-next-line
    }, [stateData.state.first])

    //generate the media url.
    const handleMediaSelection = (e) => {
        setIsWait(true);
        setMedia(e.target.files[0])
        console.log('e.target.files[0]: ', e.target.files[0]);
        if (e.target.files[0]) {
            formData.append("media", e.target.files[0]);
            formData.append("inbox_uid", stateData.state.item.inbox_uid)

            fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then(function (res) {
                if (res.ok) {
                    return res.json()
                } else {
                    Swal.fire("Error", t("No se pueden enviar medios"));
                }
            }).then((resp) => {
                setIsWait(false)
                // console.log("media bmc url: ", resp.data);
                setMediaURL(resp.data);
            }).catch(function (e) {
                console.log(e)
                setIsWait(false);
                Swal.fire("Error", t("No se pueden enviar medios"));
            });
        } else {
            // console.log("select media");
            Swal.fire(t("seleccionar medios"));
        }
    }

    //media preview
    const getMediaPreview = () => {
        let mType = media.type;
        console.log("checking: while selection ", media.type.includes("application"));
        console.log('mType: ', media, mType);
        if (mType.includes('image')) {
            return <img src={mediaURL} alt={media.name}></img>
        }
        else if (mType.includes('video')) {
            return <video src={mediaURL} width='180px' />
        } else if (mType.includes('application')) {
            return (<div className='doc-preview'>
                {/* <a href={msg.multimedia_url} target='_blank'> */}
                <img src='/img/file-earmark-text.svg' alt='doc'></img>
                <p className='doc-para'>{media?.name}</p>
                {/* </a> */}
            </div>
            )
        }
    }

    function handleclickinput(e) {
        inputRef.current.click();
    }

    // useEffect(() => {
    //     let parameters = {
    //         inbox_uid: stateData.state.item.inbox_uid,
    //         receiverId: localStorage.getItem("user_id")
    //     }
    //     if (messageTyped?.length === 1) {
    //         socket.emit("start_typing", parameters);
    //     } else if (messageTyped?.length === 0) {
    //         socket.emit("stop_typing", parameters);
    //     }
    //     // eslint-disable-next-line
    // }, [messageTyped])

    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [groupChat])

    //get incoming message
    useEffect(() => {
        let inbox_uid = stateData.state.item.inbox_uid;
        // console.log("group id in debate chat room: ", inbox_uid);

        const messageHistory = (data) => {
            console.log("message history:- ", data);
            setGroupChat([...data.messages]);
        }

        const handleParticipants = (data) => {
            // console.log("participants: ", data.participants);
            setParticipantsList(data.participants);
            setMemberCount(data.total_participants);
        }

        socket.on('boardMeetingMessage', (data) => {
            console.log("BM: listen incoming msg continuosly: ", data);
            if (data)
                setGroupChat((prev) => [...prev, data.latestmessage]);
            // setMessageTyped("");
        });

        socket.on('smallTableMessage', (data) => {
            console.log("ST: listen incoming msg continuosly: ", data.messages[0]);
            if (data)
                setGroupChat((prev) => [...prev, data.messages[0]]);
            setMessageTyped("");
        });

        if (!inbox_uid) {
            navigate(-1);
            Swal.fire("Info", t("¡Primero seleccione la reunión del grupo!"), "warning");
            return;
        }

        let event_data = { inbox_uid };
        if (stateData.state.first) {
            socket.emit('get_message_history_in_board_meeting', event_data);
            socket.on('message_history_in_board_meeting', messageHistory);
        } else {
            socket.emit('get_message_history_in_small_table', event_data);
            socket.on('message_history_in_small_table', messageHistory);
        }

        socket.emit('get_group_info', event_data);
        socket.on('group_info', handleParticipants);

        return () => {
            socket.off('message_history_in_board_meeting', messageHistory);
            socket.off('message_history_in_small_table', messageHistory);
            socket.off('group_info', handleParticipants);
        }
        // eslint-disable-next-line 
    }, [socket])

    function urlify(text) {
        if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)) {
            return true;
        }
        return false;
    }

    const handleMsgDel = (e) => {
        let msg_id = e.target.closest(".li-img").id;
        // let msg_id = e.target.id;
        console.log('msg_id: ', msg_id);
        const messageHistory = (data) => {
            setGroupChat(data.messages);
        }

        Swal.fire({
            title: t("Estas seguro"),
            text: t("¿Quieres eliminar el mensaje?"),
            showDenyButton: true,
            confirmButtonText: t("Eliminar"),
            denyButtonText: t("Cancelar")
        }).then((result) => {
            if (result.isConfirmed) {
                let eventParameters = {
                    inbox_uid: stateData.state.item.inbox_uid,
                    message_id: msg_id,
                    role: "TEAM_MODERATOR",
                    group_type: stateData.state.first ? "BOARD_MEETING" : "SMALL_TABLE"
                }
                socket.emit("delete_message", eventParameters);
                socket.on('message_history_in_board_meeting', messageHistory);
            }
        });
    }

    // handle chats msg
    const renderChat = () => {
        if (groupChat) {
            if (groupChat.length) {
                return groupChat.map((msg, i) => {
                    //check message_chat type (img,video,text)
                    const checkMsgType = () => {
                        // if (msg.isTyping) {
                        //     return <img className='typing-gif' alt='' src="./img/typing.gif" />
                        // }
                        console.log("msg type: ", msg);
                        if (msg.message_type === 'TEXT') {
                            return <p>{msg?.message}</p>
                        }
                        else if (msg.message_type === 'IMAGE') {
                            return <img className='chat_img' alt='' src={msg.multimedia_url} />
                        }
                        else if (msg.message_type === 'VIDEO') {
                            return <video className='chat_img' src={msg.multimedia_url} controls />
                        }
                        else if (msg.message_type === 'LINK') {
                            return <p>{msg?.message}</p>
                        } else if (msg.message_type === 'DOC') {
                            //media.type.includes("application")
                            return (
                                <div className='d-flex doc-visual'>
                                    <a href={msg.multimedia_url} target='_blank' rel='noreferrer'>
                                        <img src='/img/file-earmark-text.svg' alt='doc'></img>
                                        <p>{msg.multimedia_url.split("/").at(-1)}</p>
                                    </a>
                                </div>
                            )
                        }
                    }

                    const readRecipent = () => {
                        if (msg.is_delivered) {
                            if (msg.is_read) {
                                return <img src='./img/check2-all-custom.svg' alt='read'></img>
                            }
                            return <img src='./img/check2-all.svg' alt='delivered'></img>
                        }
                        return <img src='./img/check2.svg' alt='sent'></img>
                    }

                    const getMsgSentTime = () => {
                        let time = new Date(msg.createdAt);
                        return <span>{`${time.getHours()}:${time.getMinutes()}`}</span>
                    }

                    //print msg.
                    // console.log('274 msg.user_id: ', msg.user_id);

                    // eslint-disable-next-line
                    if (msg?.user_id == localStorage.getItem("user_id")) {
                        return (
                            <div className="msg right-msg" key={msg.id}
                                // eslint-disable-next-line
                                ref={((i + 1) == groupChat.length) ? lastMessageRef : null}>
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className="right-mess-box-wrap">
                                            <div className="msg-text">
                                                {checkMsgType()}
                                                {getMsgSentTime()}
                                                {readRecipent()}
                                            </div>
                                            <div className='delete-img li-img' id={msg.id}
                                                onClick={handleMsgDel}>
                                                <img src="./img/delete.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="msg-img">
                                    <img src={msg.user.image_url
                                        ? msg.user.image_url
                                        : `./img/user.png`} alt='' />
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className={`msg left-msg ${stateData.state.first ? 'chat-custom-color-blue' : 'chat-custom-color-yellow'}`}
                                key={msg.id}
                                // eslint-disable-next-line
                                ref={((i + 1) == groupChat.length) ? lastMessageRef : null}>
                                <div className="msg-img">
                                    <img src={msg?.user?.image_url
                                        ? msg.user.image_url
                                        : `./img/user.png`} alt='' />
                                </div>
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className='left-mess-box-wrap'>
                                            <div className="msg-text">
                                                <div className="msg-user-name pb-2">
                                                    {msg?.user?.first_name} {msg?.user?.last_name}
                                                </div>
                                                {checkMsgType()}
                                                {getMsgSentTime()}
                                            </div>
                                            <div className='delete-img li-img' id={msg.id}
                                                onClick={handleMsgDel}>
                                                <img src="./img/delete.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            } else {
                return (
                    <div className="Loader d-flex justify-content-center">
                        <div className="my-5" role="status">
                        </div>
                        <div className=" my-5 my-auto" >
                            {t('iniciar conversación')}. . .
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="Loader d-flex justify-content-center align-items-center">
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <div className=" my-5 my-auto" > &nbsp;
                        {t('Cargando')}...
                    </div>
                </div>
            )
        }
    }

    //handle paricipants
    const renderParticipants = (updatedMemberList) => {
        if (updatedMemberList) {
            if (updatedMemberList.length) {
                return updatedMemberList.map((member) => {
                    //print msg.
                    if (!member.is_admin) {
                        return (
                            <li className='' key={member?.user_id}>
                                <div className='partcp-profile'>
                                    <div className='partcp-img'>
                                        <img src={member?.user?.image_url
                                            ? member?.user?.image_url
                                            : `./img/user.png`
                                        } alt="" />
                                        <p className='partcp-name'>
                                            {member?.user?.first_name} {member?.user?.last_name}
                                        </p>
                                    </div>

                                    <div className='delete-img'>
                                        <img src="./img/delete.png" alt=""
                                            onClick={() => handleRemoveMember(member?.user?.user_id)} />
                                    </div>
                                </div>
                            </li>
                        )
                    }
                    return null;
                })
            }

            // else {
            //     return (
            //         <div className="Loader d-flex justify-content-center">
            //             <div className="my-5" role="status">
            //                 {/* <span className="sr-only"></span> */}
            //             </div>
            //             <div className=" my-5 my-auto" >
            //                 start conversation. . .
            //             </div>
            //         </div>
            //     )
            // }

        } else {
            return (
                <div className="Loader d-flex justify-content-center align-items-center">
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <div className=" my-5 my-auto" > &nbsp;
                        {t('Cargando')}...
                    </div>
                </div>
            )
        }
    }

    const handleRemoveMember = (member_id) => {
        if (stateData.state.first) {
            Swal.fire({
                title: t("Estas seguro"),
                text: t('¿Quieres eliminar a los participantes!'),
                showDenyButton: true,
                confirmButtonText: t("Eliminar"),
                denyButtonText: t("Cancelar")
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'boardmeeting',
                        participant_id: member_id
                    }
                    socket.emit("remove_participant", eventParameters);
                    socket.on("group_logging", () => {
                        Swal.fire(t("Remoto!"), "", "success");
                    })
                }
            });
        }
        else {
            Swal.fire({
                title: t("Estas seguro"),
                text: t('¿Quieres eliminar a los participantes!'),
                showDenyButton: true,
                confirmButtonText: t("Eliminar"),
                denyButtonText: t("Cancelar")
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'smalltable',
                        participant_id: member_id
                    }
                    socket.emit("remove_participant", eventParameters);
                    socket.on("group_logging", () => {
                        Swal.fire(t("Remoto!"), "", "success");
                    })
                }
            });
        }
    }

    //handle send message
    const handleSendMessage = (e) => {
        e.preventDefault();
        console.log('stateData.state.first: ', stateData.state.first);
        if (stateData.state.first) {
            const messageHistory = (data) => {
                console.log("message history:- ", data);
                setMessageTyped("");
                // setGroupChat([...groupChat, data.messages]);
                setGroupChat([...data.messages]);
            }

            let inbox_uid = stateData.state.item.inbox_uid;
            let msgParameter = {
                inbox_uid: inbox_uid,
                message: messageTyped,
                message_type: "TEXT"
            }
            if (messageTyped?.trim()?.length) {
                if (mediaURL) {
                    setMediaURL(null);
                }
                if (urlify(messageTyped)) {
                    msgParameter.message_type = 'LINK';
                    // console.log('messageTyped: ', messageTyped);
                }
                socket.emit('send_message_in_board_meeting', msgParameter);
                socket.on('message_history_in_board_meeting', messageHistory);
            } else if (mediaURL) {
                if (media.type.includes('image')) {
                    msgParameter.message_type = 'IMAGE';
                }
                else if (media.type.includes('video')) {
                    msgParameter.message_type = 'VIDEO';
                }
                else if (media.type.includes("application")) {
                    console.log("inside sendFunc cheking for doc: ", media.type.includes("application"));
                    msgParameter.message_type = "DOC";
                }

                msgParameter.media_url = mediaURL;
                socket.emit('send_message_in_board_meeting', msgParameter);
                socket.on('message_history_in_board_meeting', messageHistory);
                setMedia(null);
                setMediaURL(null);
            }
        } else {
            //send_message_in_small_table
            const messageHistoryST = (data) => {
                console.log("this one msg his. exe: ", data);
                setMessageTyped("");
                setGroupChat([...data.messages]);
            }
            let inbox_uid = stateData.state.item.inbox_uid;
            let msgParameter = {
                inbox_uid: inbox_uid,
                message: messageTyped,
                message_type: "TEXT"
            }
            if (messageTyped?.trim()?.length) {
                if (urlify(messageTyped)) {
                    msgParameter.message_type = 'LINK';
                    // console.log('messageTyped: ', messageTyped);
                }
                socket.emit('send_message_in_small_table', msgParameter);
                socket.on('message_history_in_small_table', messageHistoryST);
            } else if (mediaURL) {
                if (media.type.includes('image')) {
                    msgParameter.message_type = 'IMAGE';
                }
                else if (media.type.includes('video')) {
                    msgParameter.message_type = 'VIDEO';
                }
                else if (media.type.includes("application")) {
                    console.log("inside small tab - sendFunc cheking for doc: ", media.type.includes("application"));
                    msgParameter.message_type = "DOC";
                }
                msgParameter.media_url = mediaURL;
                socket.emit('send_message_in_small_table', msgParameter);
                socket.on('message_history_in_small_table', messageHistoryST);
                setMedia(null);
                setMediaURL(null);
            }
        }
    }

    const handleAddParticipantes = () => {
        console.log("handle add paricepents called...");
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllUserByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                    // Swal.fire("error", "Not able to get particepants list", "error");
                }
            })
            .then(res => {
                setErrorMsg(true)
                setDigitalManager(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
        // if (digitalManager) {
        console.log("get digital manager: ", digitalManager);
        const memberList = document.querySelector(".add-users-list");
        memberList.classList.add("add-member-active");
        // }
    }

    const handleSelectMember = (e, user_data) => {
        if (selectedMember) {
            selectedMember.classList.remove("highlight");
        }
        setSelectedMember(e.target.closest(".legendlist"));
        e.target.closest(".legendlist").classList.add("highlight");
        setAddMember(user_data.user);
    }

    const handleAddParticepant = () => {
        let isPresent = false;
        if (selectedMember) {
            participantsList?.forEach((item) => {
                console.log("inside members list")
                // eslint-disable-next-line
                if (item.user.user_id == addMember.user_id) {
                    Swal.fire(t("¡Ya presente!"), "", "info");
                    isPresent = true;
                }
            })
            if (!isPresent) {
                let eventParameter = {
                    inbox_uid: stateData.state.item.inbox_uid,
                    group_type: "boardmeeting",
                    member_ids: [addMember.user_id]
                }
                socket.emit("add_participants", eventParameter);
                Swal.fire(t('Agregando miembro...'));
                socket.on('group_logging', () => {
                    Swal.fire(t("¡Miembro agregado!"), "", "success");
                });
            }
        } else {
            Swal.fire("error", t("¡Por favor seleccione miembro!"), "error");
        }
    }

    const handleCloseList = () => {
        const memberList = document.querySelector(".add-users-list");
        memberList.classList.remove("add-member-active");
    }

    const handleSearch = (e) => {
        setSearchWord(e.target.value);
        let newMemberList = participantsList.filter((item) => {
            return ((item.user.first_name.toLowerCase().includes(e.target.value.toLowerCase()))
                || (item.user.last_name.toLowerCase().includes(e.target.value.toLowerCase()))
            );
        })
        setSearchMemberList(newMemberList);
    }

    const getMediaForGroupChat = (media_type) => {
        console.log('media_type:inside get media for chats: ', media_type);
        fetch(`${GlobalURL}/api/dev/debate/chat/getinboxmedia`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({
                inbox_uid: stateData.state.item.inbox_uid,
                media_type: media_type
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true);
                }
            })
            .then(res => {
                setErrorMsg(true)
                setAllMedia(res.data);
                console.log('media/link res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }

    useEffect(() => {
        if (activeTabRequired) {
            try {
                if (mediaType)
                    getMediaForGroupChat(mediaType);
                console.log("media type: ", mediaType);
            }
            catch (error) {
                console.log("error occured: ", error);
            }
        }
        // eslint-disable-next-line
    }, [activeTabRequired, mediaType])

    const handleDeleteGroup = () => {
        if (stateData.state.first) {
            Swal.fire({
                title: t("Estas seguro"),
                text: t('¿Quieres eliminar el grupo?'),
                showDenyButton: true,
                confirmButtonText: t("Eliminar"),
                denyButtonText: t("Cancelar")
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'BOARD_MEETING',
                        role: 'TEAM_MODERATOR'
                    }
                    socket.emit("delete_group", eventParameters);

                    navigate(-1);
                    Swal.fire("Success", t("¡El grupo está eliminado!"))

                }
            });
        }
        else {
            Swal.fire({
                title: t("Estas seguro"),
                text: t('¿Quieres eliminar el grupo?'),
                showDenyButton: true,
                confirmButtonText: t("Eliminar"),
                denyButtonText: t(`Cancelar`)
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'SMALL_TABLE',
                        role: 'TEAM_MODERATOR'
                    }
                    socket.emit("delete_group", eventParameters);
                    navigate(-1);
                    Swal.fire("Success", t("¡El grupo está eliminado!"))
                }
            });
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='legend-page debate-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3">
                                    {activeTabRequired
                                        && (
                                            <div className='participatns-sec group-user-list'>
                                                <div className='msger-header my-auto'>
                                                    <p className='my-auto'>{t('Participantes')}</p>
                                                    <div
                                                        className="add-member-icon"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FiPlus
                                                            onClick={handleAddParticipantes}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                </div>
                                                <main className='msger partcpt-section'>
                                                    <div className='member-searchBox'>
                                                        <input type='text'
                                                            name='serachWord'
                                                            placeholder={t('Buscar...')}
                                                            onChange={handleSearch}
                                                            value={searchWord}
                                                        />
                                                    </div>

                                                    <ul className='participants-list'>
                                                        {searchWord?.length
                                                            ? (<>
                                                                {searchMemberList?.map((admin, i) => {
                                                                    if (admin.is_admin) {
                                                                        return (
                                                                            <li className='' key={i}>
                                                                                <div className='partcp-profile'>
                                                                                    <div className='partcp-img'>
                                                                                        <img
                                                                                            src={admin.user.image_url
                                                                                                ? admin.user.image_url
                                                                                                : `./img/user.png`}
                                                                                            alt="" />
                                                                                        <p className='partcp-name'>
                                                                                            {admin.user.first_name} {admin.user.last_name}
                                                                                        </p>
                                                                                    </div>

                                                                                    <span className='partcp-admin'>Admin</span>
                                                                                    {/* <div className='delete-img'>
                                                                                            <img src="./img/delete.png" alt="" />
                                                                                        </div> */}
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    return null;
                                                                })}
                                                                {renderParticipants(searchMemberList)}
                                                            </>
                                                            )
                                                            : (<>
                                                                {participantsList?.map((admin, i) => {
                                                                    if (admin.is_admin) {
                                                                        return (
                                                                            <li className='' key={i}>
                                                                                <div className='partcp-profile'>
                                                                                    <div className='partcp-img'>
                                                                                        <img
                                                                                            src={admin.user.image_url
                                                                                                ? admin.user.image_url
                                                                                                : `./img/user.png`}
                                                                                            alt="" />
                                                                                        <p className='partcp-name'>
                                                                                            {`${admin.user.first_name} ${admin.user.last_name}`}
                                                                                        </p>
                                                                                    </div>

                                                                                    <span className='partcp-admin'>{t("Admin")}</span>
                                                                                    {/* 
                                                                                    <div className='delete-img'>
                                                                                        <img src="./img/delete.png" alt="" />
                                                                                    </div> 
                                                                                    */}
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    return null;
                                                                })}
                                                                {renderParticipants(participantsList)}
                                                            </>
                                                            )
                                                        }
                                                    </ul>
                                                </main>
                                            </div>
                                        )
                                    }
                                </Col>

                                <Col xs lg="6">
                                    <div>
                                        <div className='add-users-list'>
                                            <div className="legend-box">
                                                <ul className="">
                                                    {digitalManager
                                                        ? (digitalManager.length
                                                            ? (digitalManager.map((item, i) => {
                                                                return (<li id="4" className="legendlist"
                                                                    key={i}
                                                                    onClick={(e) => handleSelectMember(e, item)}
                                                                    style={{ cursor: "pointer" }}>
                                                                    <div className="legendprofile">
                                                                        <img src={item?.user?.image_url
                                                                            ? item.user.image_url
                                                                            : './img/user.png'}
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="legendprofilename">
                                                                        <span className="legPro-name mb-1">
                                                                            <strong>{item?.user?.first_name} {item?.user?.last_name}</strong>
                                                                        </span>
                                                                        <span className="legPro-username fw-light">
                                                                            {item?.user?.username
                                                                                ? `@${item?.user.username}`
                                                                                : item?.user?.email
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </li>)
                                                            })
                                                            )
                                                            : (
                                                                <li className='legendlist ' >
                                                                    <div className='legendprofile'>
                                                                    </div>
                                                                    <div className='legendprofilename'>
                                                                        <span className='legPro-name mb-1' >
                                                                            <strong >
                                                                                {t('¡No se encontró ningún administrador digital!')}
                                                                            </strong>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                        : (errorMsg
                                                            ? (<li className='legendlist justify-content-center'>
                                                                <div className='legendprofile'>
                                                                </div>
                                                                <div className='legendprofilename text-center'>
                                                                    <span className='legPro-name mb-1' >
                                                                        <strong >{t('Ocurrió un error, ¡inténtalo de nuevo!')}</strong>
                                                                    </span>
                                                                </div>
                                                            </li>

                                                            )
                                                            : (<li className='legendlist  justify-content-center align-items-center'>
                                                                <div className='legendprofile'>
                                                                </div>
                                                                <div className='legendprofilename text-center'>
                                                                    <span className='legPro-name mb-1' >
                                                                        <strong >{t('Cargando')}...</strong>
                                                                    </span>
                                                                </div>
                                                            </li>)
                                                        )
                                                    }
                                                </ul>

                                                <div className='legendBtn mx-auto'>
                                                    <button className='red-btn' onClick={handleCloseList}>{t('CANCELAR')}</button>
                                                    <button className='blue-btn' onClick={handleAddParticepant}>{t('AGREGAR')}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='legend-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec'
                                                onClick={() => { navigate(-1, { replace: false }) }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title '>
                                                {t('REUNIÓN COMISIÓN DIRECTIVA - CONVERSACIÓN')}
                                            </h3>
                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>

                                        <div className='legend-tabs chat-sec-manage'>
                                            <Tabs defaultActiveKey="CONVERSACIÓN"
                                                onSelect={(eventKey) => {
                                                    eventKey === 'DETALLES' ? setActiveTabRequired(true) : setActiveTabRequired(false)
                                                }}
                                                id="fill-tab-example" fill>
                                                <Tab eventKey="CONVERSACIÓN" title={t("CONVERSACIÓN")}>
                                                    <div className='cal-list-card-sec'>
                                                        <div className='cal-list-card'>
                                                            <div className='left-sec'>

                                                                <div className='profile-img'>
                                                                    {stateData.state.item?.group_image_url
                                                                        ? <img src={stateData.state.item?.group_image_url}
                                                                            alt="" className='group-icon' />
                                                                        : <img src='./img/Image 44.png' alt="" />
                                                                    }
                                                                </div>

                                                                <span className='event-data'>
                                                                    <h4>{stateData.state.item.group_name}</h4>
                                                                    <p>{stateData.state.item.group_description}</p>
                                                                </span>
                                                            </div>

                                                            <div className='right-sec d-flex justify-content-center align-items-center'>
                                                                <div className='profile-img'>
                                                                    <RiDeleteBin6Line
                                                                        style={{
                                                                            color: 'crimson',
                                                                            cursor: 'pointer',
                                                                            borderRadius: '30px',
                                                                            width: '35px',
                                                                        }}
                                                                        onClick={handleDeleteGroup}
                                                                    />
                                                                </div>
                                                                <h3>{memberCount} {t('DDs')}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='chatPage-sec'>
                                                        <div className='participatns-sec'>
                                                            <div className='msger-header my-auto'>
                                                                <p className='my-auto'>Participantes</p>
                                                                <div
                                                                    className="add-member-icon"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <FiPlus
                                                                        onClick={handleAddParticipantes}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <main className='msger partcpt-section'>
                                                                <div className='member-searchBox'>
                                                                    <input type='text'
                                                                        name='serachWord'
                                                                        placeholder={t('Buscar...')}
                                                                        onChange={handleSearch}
                                                                        value={searchWord}
                                                                    />
                                                                </div>
                                                                <ul className='participants-list'>
                                                                    {searchWord?.length
                                                                        ? (<>
                                                                            {searchMemberList?.map((admin, i) => {
                                                                                if (admin.is_admin) {
                                                                                    return (
                                                                                        <li className='' key={i}>
                                                                                            <div className='partcp-profile'>
                                                                                                <div className='partcp-img'>
                                                                                                    <img
                                                                                                        src={admin.user.image_url
                                                                                                            ? admin.user.image_url
                                                                                                            : `./img/user.png`}
                                                                                                        alt="" />
                                                                                                    <p className='partcp-name'>
                                                                                                        {admin.user.first_name} {admin.user.last_name}
                                                                                                    </p>
                                                                                                </div>

                                                                                                <span className='partcp-admin'>Admin</span>
                                                                                                {/* <div className='delete-img'>
                                                                                                    <img src="./img/delete.png" alt="" />
                                                                                                </div> */}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                                return null;
                                                                            })}
                                                                            {renderParticipants(searchMemberList)}
                                                                        </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                {participantsList?.map((admin, i) => {
                                                                                    if (admin.is_admin) {
                                                                                        return (
                                                                                            <li className='' key={i}>
                                                                                                <div className='partcp-profile'>
                                                                                                    <div className='partcp-img'>
                                                                                                        <img
                                                                                                            src={admin.user.image_url
                                                                                                                ? admin.user.image_url
                                                                                                                : `./img/user.png`}
                                                                                                            alt="" />
                                                                                                        <p className='partcp-name'>
                                                                                                            {admin.user.first_name} {admin.user.last_name}
                                                                                                        </p>
                                                                                                    </div>

                                                                                                    <span className='partcp-admin'>Admin</span>
                                                                                                    {/* <div className='delete-img'>
                                                                                                        <img src="./img/delete.png" alt="" />
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                    return null;
                                                                                })}
                                                                                {renderParticipants(participantsList)}
                                                                            </>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </main>
                                                        </div>

                                                        <section className="msger">
                                                            <div className="msger-header">
                                                                <p>{t('Conversación')}</p>
                                                            </div>

                                                            <main className="msger-chat">
                                                                {renderChat()}
                                                            </main>

                                                            <div className="input-form">
                                                                <div className="input-form-wrap">
                                                                    <form className="msger-inputarea" onSubmit={handleSendMessage} >
                                                                        <div
                                                                            className="msger-attachment-btn left-btn"
                                                                            style={{ cursor: 'pointer' }}>
                                                                            <input type='file'
                                                                                // className="msger-attachment-btn left-btn"
                                                                                accept='image/*, video/*, audio/*,
                                                                                .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .zip'
                                                                                ref={inputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleMediaSelection}
                                                                            />
                                                                            <FiPlus
                                                                                onClick={handleclickinput}
                                                                            />
                                                                        </div>
                                                                        {<>
                                                                            {isWait && (
                                                                                <div className='previewMedia my-2'>
                                                                                    {t('Cargando media')}...
                                                                                </div>
                                                                            )}
                                                                            {mediaURL && (
                                                                                <div className='previewMedia'>
                                                                                    {getMediaPreview()}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                        }

                                                                        <input
                                                                            type="text"
                                                                            className="msger-input"
                                                                            value={messageTyped}
                                                                            onChange={(e) => { setMessageTyped(e.target.value) }}
                                                                            placeholder={t('Escribe un mensaje')} />

                                                                        <button className="msger-attachment-btn right-btn" type='submit'>
                                                                            <img src="./img/rightArr.png" alt="" />
                                                                        </button>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="DETALLES" title={t("DETALLES")}>
                                                    <div className='cal-list-card-sec'>
                                                        <div className='cal-list-card'>
                                                            <div className='left-sec'>
                                                                <div className='profile-img'>
                                                                    {stateData.state.item?.group_image_url
                                                                        ? <img src={stateData.state.item?.group_image_url} alt=""
                                                                            className='group-icon' />
                                                                        : <img src='./img/Image 44.png' alt="" />
                                                                    }
                                                                </div>
                                                                <span className='event-data'>
                                                                    <h4>{stateData.state.item.group_name}</h4>
                                                                    <p>{stateData.state.item.group_description}</p>
                                                                </span>
                                                            </div>
                                                            <div className='right-sec d-flex justify-content-center align-items-center'>
                                                                <h3>{memberCount} {t('DDs')}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='tabGallerySec'>
                                                        <div className='tabGalleryInner'>
                                                            <Tabs
                                                                defaultActiveKey="MEDIA"
                                                                className={`tabgalleryList ${stateData.state.first ? 'tab-custom-color-blue' : 'tab-custom-color-yellow'}`}
                                                                onSelect={(eventKey) => setMediaType(eventKey)}
                                                            >
                                                                <Tab eventKey="MEDIA" title={t("Medios")}>
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.length
                                                                                ? (allMedia?.map((item, i) => {
                                                                                    if (item.message_type === 'IMAGE') {
                                                                                        return (<div className='img-card' key={i}>
                                                                                            <img src={item?.multimedia_url} alt="" />
                                                                                        </div>)
                                                                                    }
                                                                                    else if (item.message_type === 'VIDEO') {
                                                                                        return (<div className='img-card' key={i}>
                                                                                            <video src={item?.multimedia_url} alt="" />
                                                                                        </div>)
                                                                                    }
                                                                                    return null;
                                                                                }))
                                                                                : (
                                                                                    <div className='ack-for-media'>
                                                                                        {t("¡No se encontraron medios!")}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            : (<li className='legendlist justify-content-center'>
                                                                                <div className='legendprofile'>
                                                                                </div>
                                                                                <div className='legendprofilename text-center align-items-center'>
                                                                                    <span className='legPro-name mb-1' >
                                                                                        <strong >{t('Cargando')}...</strong>
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>

                                                                <Tab eventKey="LINK" title={t("Enlaces")}>
                                                                    <div className='link-main-sec'>

                                                                        {console.log("link in the jsx: ", allMedia)}
                                                                        {allMedia
                                                                            ? (allMedia.length
                                                                                ? (allMedia?.map((link, i) => {
                                                                                    if (link.message_type === 'LINK') {
                                                                                        return (<div className='upper-div-section'>
                                                                                            <div className=''>
                                                                                                {/* <div>
                                                                                            <img src='./img/linkImg.png'></img>
                                                                                        </div> */}
                                                                                                <div className='link-middle-secion'>
                                                                                                    <Microlink
                                                                                                        url={link.message}
                                                                                                        media="logo"
                                                                                                        // size="small"
                                                                                                        size="normal"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='lower-link-section'>
                                                                                                {link.message}
                                                                                            </div>
                                                                                        </div>)
                                                                                    }
                                                                                    return null;
                                                                                }))
                                                                                : (
                                                                                    <div className='ack-for-link'>
                                                                                        {t('No se encontraron enlaces.')}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center align-items-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('Cargando')}...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                        {/* {(allMedia && (mediaType==='LINK'))
                                                                        ? (allMedia?.map((link) => {
                                                                            return (
                                                                                <div className='upper-div-section'>
                                                                                    <div className=''>
                                                                                        <div>
                                                                                            <img src='./img/linkImg.png'></img>
                                                                                        </div>
                                                                                        <div className='link-middle-secion'>
                                                                                            <Microlink
                                                                                                url={link.message}
                                                                                                media="logo"
                                                                                                // size="small"
                                                                                                size="normal"
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='lower-link-section'>
                                                                                        {link.message}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }))
                                                                        : (<li className='legendlist justify-content-center'>
                                                                            <div className='legendprofile'>
                                                                            </div>
                                                                            <div className='legendprofilename text-center'>
                                                                                <span className='legPro-name mb-1' >
                                                                                    <strong >{t("Enviando")}...</strong>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                        )
                                                                    } */}
                                                                    </div>
                                                                </Tab>

                                                                <Tab eventKey="DOC" title={t("Docs")} >
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.length
                                                                                ? (allMedia?.map((item, i) => {
                                                                                    if (item.message_type === 'DOC') {
                                                                                        return (
                                                                                            <div className='bg-light doc-preview-manage'>
                                                                                                <a href={item.multimedia_url} target='_blank' rel='noreferrer'>
                                                                                                    <img src='/img/file-earmark-text.svg' alt='doc'></img>
                                                                                                    <div className='doc-name'>
                                                                                                        {item.multimedia_url.split("/").at(-1)}
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>)
                                                                                    }
                                                                                    return null;
                                                                                }))
                                                                                : (
                                                                                    <div className=''>
                                                                                        {t('No hay documentos presentes.')}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                            : (
                                                                                <li className='legendlist justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center align-items-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('Cargando')}...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>

                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3">
                                    {!(stateData.state.first)
                                        && (
                                            <BarChart optionsList={proposalBar} />
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DebateChatRoom;