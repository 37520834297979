import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const News = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [news, setNews] = useState();
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    const [searchWord, setSearchWord] = useState();
    const [searchList, setSearchList] = useState([]);
    const [searchCliked, setSearchCliked] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Cuadrícula');
    const [errMsg, setErrMsg] = useState(false);

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (news) {
            let searchedItems = news.filter((item) => {
                console.log('item.title: ', item.title);
                return item.title?.toLowerCase().includes(word?.toLowerCase())
            })
            setSearchList(searchedItems);
            console.log('searchedItems: ', searchList);
        }
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // const loadStatus = document.querySelector(".Loader");
        fetch(`${GlobalURL}/api/news/getnews`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
                "team_id": localStorage.getItem("team_id")
            },
        })
            .then(async (response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setErrMsg(`${t('!Error Interno del Servido!')}`);
                }
            })
            .then(res => {
                setNews(res.data.newsPost);
                setErrMsg(false);
            })
            .catch((err) => {
                setErrMsg(`${t('!Error Interno del Servido!')}`);
                console.log("error occured: ", err);
            });
        // eslint-disable-next-line
    }, [])

    const checkType = (url) => {
        // console.log('extention: ', url?.split('.')?.at(-1), images.includes(url?.split('.')?.at(-1)));
        let extention = url?.split('.')?.at(-1);
        let res = images.find((ext) => {
            return extention.includes(ext);
        }
        )
        // console.log('res: ', res);

        if (images.includes(url?.split('.')?.at(-1)) || res) {
            return <img src={url} alt="" />
        } else {
            return <video src={url} alt="" />
        }
    }

    const getFormatedDate = (date) => {
        let tSlice = date.indexOf('T');
        let sliceDate = date.slice(0, tSlice);
        console.log('sliceDate: ', sliceDate);
        return sliceDate;
    }

    return (
        <>
            <div className='dashboard'>
                <div className='news-page'>
                    <div className='news-page-container'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col md="12" lg="2"></Col>

                                <Col md="12" lg="8">
                                    <div className='news-header'>
                                        <div className='back-sec'
                                            onClick={() => navigate('/homepage')}
                                            style={{ cursor: "pointer" }}>
                                            <img
                                                // src="../../../assests/images/volver.png"
                                                src="../img/volver.png"
                                                alt="back_icon" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('NOTICIAS')}
                                        </h3>

                                        <div className="search-bar-main">
                                            <div className='search-icon' onClick={handleSearchClicked}>
                                                <FiSearch />
                                            </div>
                                            <div className="search-bar">
                                                {searchCliked
                                                    && (<input type='search'
                                                        placeholder={t('Buscar...')}
                                                        value={searchWord}
                                                        onChange={handleSearchTyped}
                                                    />)
                                                }

                                                {/* <input><div class="div1"></div></input> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='grid-list-sec'>
                                        <div className="radio-btns-sec">
                                            <div className="left-radios-sec">
                                                <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        id="test1"
                                                        name="radio-group"
                                                        value="Cuadrícula"
                                                        checked={selectedOption === 'Cuadrícula'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label htmlFor="test1">{t('Cuadrícula')}</label>
                                                </div>

                                                <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                    <input
                                                        type="radio"
                                                        id="test2"
                                                        name="radio-group"
                                                        value="Lista"
                                                        checked={selectedOption === 'Lista'}
                                                        onChange={handleRadioChange}
                                                    />
                                                    <label htmlFor="test2">{t('Lista')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='news-page-content'>
                                        {selectedOption === 'Cuadrícula' &&
                                            (<div className='news-cards'>
                                                <Row >
                                                    {searchList && searchWord
                                                        ? (searchList.length
                                                            ? (searchList.map((newsItem, i) => {
                                                                return (
                                                                    <Col md="6" lg="4" key={i}>
                                                                        <div className='news-card'
                                                                            onClick={() => navigate(`/news/editnews/${newsItem.news_id}`,
                                                                                { state: newsItem })}
                                                                            style={{ cursor: "pointer" }} >
                                                                            <div className='news-heading'>
                                                                                <h4>{newsItem.title}</h4>
                                                                            </div>
                                                                            <div className='news-body'>
                                                                                {(newsItem.news_media.length
                                                                                    && newsItem.news_media[0].url)
                                                                                    && checkType(newsItem.news_media[0].url)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                            )
                                                            : (
                                                                <div className="Loader d-flex justify-content-center">
                                                                    {errMsg
                                                                        ? (errMsg)
                                                                        : (
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('No se encontraron noticias con la palabra clave')}
                                                                                {searchWord}!
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            )
                                                        )
                                                        : ((news)
                                                            ? (news.length
                                                                ? (news.map((newsItem, i) => {
                                                                    return (
                                                                        <Col md="6" lg="4" key={i}>
                                                                            <div className='news-card'
                                                                                onClick={() => navigate(`/news/editnews/${newsItem.news_id}`,
                                                                                    { state: newsItem })}
                                                                                style={{ cursor: "pointer" }} >
                                                                                <div className='news-heading'>
                                                                                    <h4>{newsItem.title}</h4>
                                                                                </div>
                                                                                <div className='news-body'>
                                                                                    {
                                                                                        (newsItem.news_media.length
                                                                                            && newsItem.news_media[0].url)
                                                                                        && checkType(newsItem.news_media[0].url)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center">
                                                                        {errMsg
                                                                            ? (errMsg)
                                                                            : (<div className=" my-5" > &nbsp;
                                                                                {t("¡No se encontraron noticias!")}
                                                                            </div>)
                                                                        }
                                                                    </div>
                                                                ))
                                                            : (
                                                                <div className="Loader d-flex justify-content-center align-items-center">
                                                                    {errMsg
                                                                        ? (errMsg)
                                                                        : (<>
                                                                            <div className="spinner-border my-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('Cargando')}...
                                                                            </div>
                                                                        </>)}
                                                                </div>
                                                            ))
                                                    }
                                                </Row>
                                            </div>)
                                        }

                                        {selectedOption === 'Lista' && (
                                            <div className="lista-content">
                                                {searchList && searchWord
                                                    ? (<>
                                                        <div className="lista-titles">
                                                            <div className='lista-name'>{t('Nombre')}</div>
                                                            <div className='lista-creation'>{t('Fecha de Creación')}</div>
                                                            <div className='lista-modification'>{t('Fecha de Creación')}</div>
                                                            <div className='lista-right'></div>
                                                        </div>

                                                        {searchList.length
                                                            ? (searchList.map((newsItem, i) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => navigate(`/news/editnews/${newsItem.news_id}`,
                                                                            { state: newsItem })} >
                                                                        <div className='myTeamBanner billboard-cards' >
                                                                            <div className='lista-name ps-0'>
                                                                                <div className='card-img'>
                                                                                    {(newsItem.news_media.length
                                                                                        && newsItem.news_media[0].url)
                                                                                        && checkType(newsItem.news_media[0].url)
                                                                                    }
                                                                                </div>
                                                                                <div className='card-heading'>
                                                                                    <h3>{newsItem.title}</h3>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-creation'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(newsItem.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-modification'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(newsItem.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-right'></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }))
                                                            : (
                                                                <div>
                                                                    <div className='myTeamBanner billboard-cards' >
                                                                        <div className='lista-name ps-0'>
                                                                            <div className='card-img'>
                                                                                <img src='./img/blankimg.png' alt="" />
                                                                            </div>
                                                                            <div className='card-heading'>
                                                                                <h3>{t('No se encontraron noticias con la palabra clave')} {searchWord} !</h3>
                                                                            </div>
                                                                        </div>

                                                                        <div className='lista-creation'>
                                                                            <div className='card-heading'>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='lista-modification'>
                                                                            <div className='card-heading'>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='lista-right'></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    )
                                                    : (news ?
                                                        (
                                                            <>
                                                                <div className="lista-titles">
                                                                    <div className='lista-name'>{t('Nombre')}</div>
                                                                    <div className='lista-creation'>{t('Fecha de Creación')}</div>
                                                                    <div className='lista-modification'>{t('Fecha de Creación')}</div>
                                                                    <div className='lista-right'></div>
                                                                </div>

                                                                {
                                                                    news.length
                                                                        ? (news.map((newsItem, i) => {
                                                                            return (
                                                                                <div
                                                                                    onClick={() => navigate(`/news/editnews/${newsItem.news_id}`,
                                                                                        { state: newsItem })} >
                                                                                    <div className='myTeamBanner billboard-cards' >
                                                                                        <div className='lista-name ps-0'>
                                                                                            <div className='card-img'>
                                                                                                {(newsItem.news_media.length
                                                                                                    && newsItem.news_media[0].url)
                                                                                                    && checkType(newsItem.news_media[0].url)
                                                                                                }
                                                                                            </div>
                                                                                            <div className='card-heading'>
                                                                                                <h3>{newsItem.title}</h3>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='lista-creation'>
                                                                                            <div className='card-heading'>
                                                                                                <p>{getFormatedDate(newsItem.createdAt)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='lista-modification'>
                                                                                            <div className='card-heading'>
                                                                                                <p>{getFormatedDate(newsItem.createdAt)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='lista-right'></div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }))
                                                                        : (
                                                                            <div>
                                                                                <div className='myTeamBanner billboard-cards' >
                                                                                    <div className='lista-name ps-0'>
                                                                                        <div className='card-img'>
                                                                                            <img src='./img/blankimg.png' alt="" />
                                                                                        </div>
                                                                                        <div className='card-heading'>
                                                                                            <h3>{t('¡No se encontraron noticias!')}</h3>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='lista-creation'>
                                                                                        <div className='card-heading'>
                                                                                            <p></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='lista-modification'>
                                                                                        <div className='card-heading'>
                                                                                            <p></p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='lista-right'></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                }
                                                            </>
                                                        )
                                                        : (<div className="Loader d-flex justify-content-center align-items-center">
                                                            {errMsg
                                                                ? (
                                                                    (errMsg)
                                                                )
                                                                : (<>
                                                                    <div className="spinner-border my-5" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    <div className=" my-5" > &nbsp;
                                                                        {t('Cargando')}...
                                                                    </div>
                                                                </>)}
                                                        </div>))
                                                }

                                            </div>
                                        )}

                                    </div>

                                    <div className='news-btn-sec'>
                                        <button className='green-btn'
                                            onClick={() => navigate('/news/addnews')}
                                            style={{ cursor: "pointer" }}>
                                            {t('Agregar Noticia')}
                                        </button>
                                        {/* <button className='red-btn'>Eliminar Noticia</button> */}
                                    </div>
                                </Col>
                                <Col md="12" lg="2"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default News