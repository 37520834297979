import React from 'react';
import { Chart } from "react-google-charts";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const BarChart = ({optionsList}) => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const optionsColor = ['green', 'yellow', '#67267a', '#f43c09'];

    const options = {
        // title: "Votes & proposal",
        // width: 600,
        height: 200,
        backgroundColor: 'transparent',
        color: "red",
        bar: { groupWidth: "80%" },
        legend: { position: "none" },
    };

    const data = [
        [
            "Element",
            "Density",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ],

    ];

    optionsList.forEach((element,i) => {
        data.push([element.option_text,element.votes,optionsColor[i],null])
    })

    return (
        <div className='chart-content'>
            <Chart
                chartType="BarChart"
                width="100%"
                // height="200px"
                data={data}
                options={options}
                loader={<span className='fw-lighter font-monospace my-2 text-primary'>{t("Cargando cuadro")}...</span>}
            />
        </div>
    )
}

export default BarChart