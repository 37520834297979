import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { GrDislike } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import { GrLike } from "react-icons/gr";
import GlobalURL from '../BaseURL';
import BarChart from './BarChart';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const ProposalAndVotes = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [votesData, setVotesData] = useState();
    const [filteredList, setFilteredList] = useState();
    const [insigniaList, setInsigniaList] = useState();
    const [filter, setFilter] = useState('Todas');

    const [currentTab, setCurrentTab] = useState('generales');
    const [searchList, setSearchList] = useState([]);
    const [searchWord, setSearchWord] = useState();
    const [searchCliked, setSearchCliked] = useState(false);

    const [proposalParam, setProposalParam] = useSearchParams({ "proposalType": "generales" });

    const handleFilter = (eventKey) => {
        setVotesData(() => ([...filteredList]));
        setFilter(eventKey);
        // if(eventKey==='Todas'){
        //     setVotesData(()=>([...filteredList]));
        // }
        if (eventKey === 'Técnico') {
            let data = filteredList.filter((item) => {
                return [4, 7, 10].includes(Number(item.proposalData.insignia_id))
            })
            setVotesData(data);
            console.log('technico filter: ', data);
        } else if (eventKey === 'Institucional') {
            let data = filteredList.filter((item) => {
                return [3, 6, 9].includes(Number(item.proposalData.insignia_id))
            })
            setVotesData(data);
            console.log('technico filter: ', data);
        } else if (eventKey === 'Infrastructura') {
            let data = filteredList.filter((item) => {
                return [2, 5, 8].includes(Number(item.proposalData.insignia_id))
            })
            setVotesData(data);
            console.log('technico filter: ', data);
        }
    }

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    useEffect(() => {
        setSearchWord('');
        if (filteredList)
            setVotesData([...filteredList]);
        // eslint-disable-next-line
    }, [currentTab])

    // check team selected or not!
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const loadStatus = document.querySelector(".Loader");
        const loadStatus2 = document.querySelector(".Loader2");
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllProposalResult`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                // console.log("errr: ", response)
                if (response.status === 404) {
                    loadStatus.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                    loadStatus2.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                    // console.log("status: ", response)
                }
                if (response.status === 403) {
                    // await refreshFirebaseToken();
                    loadStatus.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                    loadStatus2.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                    // console.log("statuss: ", response)
                }
                else if (response.status === 200) {
                    // console.log(response);                    
                    return response.json();
                }
            })
            .then(res => {
                console.log("ress: ", res);
                setVotesData(res.data);
                setFilteredList(res.data);
            })
            .catch((err) => {
                loadStatus.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                loadStatus2.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                // console.log("error occured: ", err);
            });
    }, [])

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(res => {
                setInsigniaList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const handleCurrentTab = (eventKey, i) => {
        setCurrentTab(eventKey);
        setProposalParam({ "proposalType": eventKey });
    }

    const getInsigniaBatch = (ins_id) => {
        // console.log("ins id: ", ins_id);
        if (insigniaList) {
            for (let item of insigniaList) {
                if (Number(item.insignia_id) === Number(ins_id))
                    return <img src={item.insignia_icon} className='insignia-badge' alt="" />
            }
        }
    }

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (currentTab === 'generales' && votesData) {
            let searchedItems = votesData?.filter((item) => {
                // console.log('checking: ', item?.proposalData?.content?.toLowerCase());
                return (item?.proposalData?.content?.toLowerCase().includes(word.toLowerCase())
                    && (item?.proposalData?.category?.toLowerCase() === 'general'));
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        }
        else if (currentTab === 'DE INSIGNIA' && votesData) {
            let searchedItems = votesData?.filter((item) => {
                // console.log('checking: ', item?.proposalData?.content?.toLowerCase());
                return (item?.proposalData?.content?.toLowerCase().includes(word.toLowerCase())
                    && (item?.proposalData?.category?.toLowerCase() === 'insignia'));
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='proposal-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3 ">
                                    <div className="admin-menu-sec">

                                        {currentTab === "DE INSIGNIA"
                                            && (<div className="admin-dropdown drop-down-manage-height">
                                                <Dropdown onSelect={handleFilter}>
                                                    <Dropdown.Toggle
                                                        className="menu-dropdown"
                                                        id="dropdown-basic"
                                                    >
                                                        {t(filter)}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey='Todas'>
                                                            {t('Todas')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey='Técnico'>
                                                            {t('Técnico')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Institucional">
                                                            {t('Institucional')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="Infrastructura">
                                                            {t('Infrastructura')}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            )}
                                    </div>
                                </Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate('/homepage')} style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>{t('PROPUESTAS & VOTOS')}</h3>
                                        <div className="search-bar-main">
                                            <div className='search-icon' onClick={handleSearchClicked}>
                                                <FiSearch />
                                            </div>
                                            <div className="search-bar">
                                                {searchCliked
                                                    && (<input type='search'
                                                        placeholder={t('Buscar...')}
                                                        value={searchWord}
                                                        onChange={handleSearchTyped}
                                                    />)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='proposal-vote-sec legend-tabs '>
                                        <Tabs
                                            defaultActiveKey={proposalParam.get("proposalType")}
                                            id="fill-tab-example"
                                            fill onSelect={handleCurrentTab}
                                        >
                                            <Tab className='billboard-sec-inne' eventKey="generales" title={t("GENERALES")}>
                                                <div className='general-tab-sec voteAndProposal'>
                                                    <Row>
                                                        {searchList && searchWord
                                                            ? ((searchList.length
                                                                ? (searchList.map((item) => {
                                                                    if (item.proposalData.category.toLowerCase() === 'general') {
                                                                        return (
                                                                            <Col xs lg="4 my-3" key={item.proposalData.proposal_id}>
                                                                                <div className='general-card'
                                                                                    onClick={() => {
                                                                                        navigate(`/proposalvote/editproposalvote${item.proposalData.proposal_id}`,
                                                                                            { state: item })
                                                                                    }}>
                                                                                    <div className='card-header'>
                                                                                        <div className="card-imgs">
                                                                                            <img src={item?.proposalData
                                                                                                ? item.proposalData?.media_url
                                                                                                : "./img/exp4.png"}
                                                                                                alt="" />
                                                                                        </div>
                                                                                        <div className='card-dates-sec ps-0'>
                                                                                            <div className='created-date-sec'>
                                                                                                <p>{t('creada')}</p>
                                                                                                <p >
                                                                                                    {`
                                                                                        ${new Date(item.proposalData.created_at
                                                                                                        .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getDate()} / 
                                                                                        ${new Date(item.proposalData.created_at
                                                                                                                .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getMonth() + 1} / 
                                                                                        ${new Date(item.proposalData.created_at
                                                                                                                .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getFullYear()}
                                                                                        `}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='wins-date-sec'>
                                                                                                <p>{t('vence')}</p>
                                                                                                <p >
                                                                                                    {`
                                                                                    ${new Date(item.proposalData.expiring_on
                                                                                                        .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getDate()} / 
                                                                                    ${new Date(item.proposalData.expiring_on
                                                                                                                .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getMonth() + 1} / 
                                                                                    ${new Date(item.proposalData.expiring_on
                                                                                                                .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getFullYear()}
                                                                                    `}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='content-sec'>
                                                                                            <div className='content-data'>
                                                                                                <p>
                                                                                                    {item.proposalData.content}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='like-sec'>
                                                                                                <GrLike />
                                                                                                <span>{item.proposalData.likes}</span>
                                                                                            </div>
                                                                                            <div className='dislike-sec'>
                                                                                                <GrDislike />
                                                                                                <span>
                                                                                                    {item.proposalData.dislikes}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='card-body'>
                                                                                        <div className='check-box-sec'>
                                                                                            <ul className='check-box-list'>
                                                                                                {item.optionsData.map((option) => {
                                                                                                    return (
                                                                                                        <li className='list-content' key={option.option_id}>
                                                                                                            <input type="checkbox" id={option.option_id} />
                                                                                                            <label htmlFor={option.option_id}>
                                                                                                                <span></span> {option.option_text}
                                                                                                            </label>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className='bar-chart-sec'>
                                                                                            <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                                            {/* barchart */}
                                                                                            <BarChart optionsList={item.optionsData} />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    return null

                                                                }))
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center">
                                                                        <div className="my-5" >
                                                                            {t('¡No se encontraron votos ni propuestas!')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                            : (votesData ?
                                                                (votesData.length
                                                                    ? (votesData.map((item) => {
                                                                        if (item.proposalData.category.toLowerCase() === 'general') {
                                                                            return (
                                                                                <Col xs lg="4 my-3" key={item.proposalData.proposal_id}>
                                                                                    <div className='general-card'
                                                                                        onClick={() => { navigate(`/proposalvote/editproposalvote/${item.proposalData.proposal_id}`, { state: item }) }}>
                                                                                        <div className='card-header'>
                                                                                            <div className="card-imgs">
                                                                                                <img src={item?.proposalData
                                                                                                    ? item.proposalData?.media_url
                                                                                                    : "./img/exp4.png"}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className='card-dates-sec ps-0'>
                                                                                                <div className='created-date-sec'>
                                                                                                    <p>{t('creada')}</p>
                                                                                                    <p >
                                                                                                        {`
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                            .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getDate()} / 
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                                    .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getMonth() + 1} / 
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                                    .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getFullYear()}
                                                                                                `}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='wins-date-sec'>
                                                                                                    <p>{t('vence')}</p>
                                                                                                    <p >
                                                                                                        {`
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                            .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getDate()} / 
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                                    .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getMonth() + 1} / 
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                                    .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getFullYear()}
                                                                                            `}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='content-sec'>
                                                                                                <div className='content-data'>
                                                                                                    <p>
                                                                                                        {item.proposalData.content}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='like-sec'>
                                                                                                    <GrLike />
                                                                                                    <span>{item.proposalData.likes}</span>
                                                                                                </div>
                                                                                                <div className='dislike-sec'>
                                                                                                    <GrDislike />
                                                                                                    <span>
                                                                                                        {item.proposalData.dislikes}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='card-body'>
                                                                                            <div className='check-box-sec'>
                                                                                                <ul className='check-box-list'>
                                                                                                    {item.optionsData.map((option) => {
                                                                                                        return (
                                                                                                            <li className='list-content' key={option.option_id}>
                                                                                                                <input type="checkbox" id={option.option_id} />
                                                                                                                <label htmlFor={option.option_id}>
                                                                                                                    <span></span> {option.option_text}
                                                                                                                </label>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div className='bar-chart-sec'>
                                                                                                <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                                                {/* barchart */}
                                                                                                <BarChart optionsList={item.optionsData} />
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                        return null

                                                                    }))
                                                                    : (
                                                                        <div className="Loader d-flex justify-content-center">
                                                                            <div className="my-5" >
                                                                                {t('¡No se encontraron votos ni propuestas!')}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ) :
                                                                (
                                                                    <div className="Loader2 d-flex justify-content-center align-items-center">
                                                                        <div className="spinner-border my-5" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('Cargando')}...
                                                                        </div>
                                                                    </div>
                                                                ))
                                                        }

                                                        {/* {(votesData?.length
                                                            && votesData?.find((item) => (item.proposalData.category.toLowerCase() !== "general")))
                                                            && (
                                                                <div className="Loader d-flex justify-content-center mx-auto">
                                                                    <div className=" my-5" > &nbsp;
                                                                        {t("¡No se encontró ningún votes!")}
                                                                    </div>
                                                                </div>
                                                            )} */}
                                                    </Row>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="DE INSIGNIA" title={t("DE INSIGNIA")}>
                                                <div className='general-tab-sec voteAndProposal proposal-Scroll'>
                                                    <Row>
                                                        {searchList && searchWord
                                                            ? (searchList.length
                                                                ? (searchList.map((item) => {
                                                                    if (item.proposalData.category.toLowerCase() === 'insignia') {
                                                                        return (
                                                                            <Col xs lg="4" key={item.proposalData.proposal_id}>
                                                                                <div className='general-card '
                                                                                    onClick={() => {
                                                                                        navigate(`/proposalvote/editproposalvote/${item.proposalData.proposal_id}`,
                                                                                            { state: item })
                                                                                    }}>
                                                                                    <div className='card-header'>
                                                                                        <div className="card-imgs">
                                                                                            <img src={item?.proposalData
                                                                                                ? item.proposalData?.media_url
                                                                                                : "./img/exp4.png"}
                                                                                                alt="" />
                                                                                        </div>
                                                                                        <div className='card-dates-sec'>
                                                                                            <div className='card-logo'>
                                                                                                {getInsigniaBatch(item?.proposalData?.insignia_id)}
                                                                                            </div>
                                                                                            <div className='created-date-sec'>
                                                                                                <p>{t('creada')}</p>
                                                                                                <p >
                                                                                                    {`
                                                                                            ${new Date(item.proposalData.created_at
                                                                                                        .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getDate()} / 
                                                                                            ${new Date(item.proposalData.created_at
                                                                                                                .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getMonth() + 1} / 
                                                                                            ${new Date(item.proposalData.created_at
                                                                                                                .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                            .getFullYear()}
                                                                                            `}
                                                                                                </p>
                                                                                            </div>

                                                                                            <div className='wins-date-sec'>
                                                                                                <p>{t('vence')}</p>
                                                                                                <p >
                                                                                                    {`
                                                                                        ${new Date(item.proposalData.expiring_on
                                                                                                        .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getDate()} / 
                                                                                        ${new Date(item.proposalData.expiring_on
                                                                                                                .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getMonth() + 1} / 
                                                                                        ${new Date(item.proposalData.expiring_on
                                                                                                                .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                            .getFullYear()}
                                                                                        `}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='content-sec'>
                                                                                            <div className='content-data'>
                                                                                                <p>
                                                                                                    {item.proposalData.content}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='like-sec'>
                                                                                                <GrLike />
                                                                                                <span>{item.proposalData.likes}</span>
                                                                                            </div>
                                                                                            <div className='dislike-sec'>
                                                                                                <GrDislike />
                                                                                                <span>
                                                                                                    {item.proposalData.dislikes}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='card-body'>
                                                                                        <div className='check-box-sec'>
                                                                                            <ul className='check-box-list'>
                                                                                                {item.optionsData.map((option) => {
                                                                                                    return (
                                                                                                        <li className='list-content' key={option.option_id}>
                                                                                                            <input type="checkbox" id={option.option_id} />
                                                                                                            <label htmlFor={option.option_id}>
                                                                                                                <span></span> {option.option_text}
                                                                                                            </label>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className='bar-chart-sec'>
                                                                                            <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                                            <BarChart optionsList={item.optionsData} />
                                                                                            {/* <div className='chart-content'>
                                                                                            <img src="./img/bar-chart.png" alt="" />
                                                                                        </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>)
                                                                    }
                                                                    return null
                                                                }))
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center">
                                                                        {/* <div className="spinner-border my-5" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div> */}
                                                                        <div className="my-5" >
                                                                            {t('¡No se encontraron votos ni propuestas!')}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            : (votesData
                                                                ? (votesData.length
                                                                    ? (votesData.map((item) => {
                                                                        if (item.proposalData.category.toLowerCase() === 'insignia') {
                                                                            return (
                                                                                <Col xs lg="4" key={item.proposalData.proposal_id}>
                                                                                    <div className='general-card in-badge'
                                                                                        onClick={() => {
                                                                                            navigate(`/proposalvote/editproposalvote/${item.proposalData.proposal_id}`,
                                                                                                { state: item })
                                                                                        }}>
                                                                                        <div className='card-header'>
                                                                                            <div className="card-imgs">
                                                                                                <img src={item?.proposalData
                                                                                                    ? item.proposalData?.media_url
                                                                                                    : "./img/exp4.png"}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className='card-dates-sec'>
                                                                                                <div className='card-logo'>
                                                                                                    {getInsigniaBatch(item?.proposalData?.insignia_id)}
                                                                                                </div>
                                                                                                <div className='created-date-sec'>
                                                                                                    <p>{t('creada')}</p>
                                                                                                    <p >
                                                                                                        {`
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                            .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getDate()} / 
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                                    .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getMonth() + 1} / 
                                                                                                ${new Date(item.proposalData.created_at
                                                                                                                    .slice(0, item.proposalData.created_at.indexOf('T')))
                                                                                                                .getFullYear()}
                                                                                                `}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='wins-date-sec'>
                                                                                                    <p>{t('vence')}</p>
                                                                                                    <p >
                                                                                                        {`
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                            .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getDate()} / 
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                                    .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getMonth() + 1} / 
                                                                                            ${new Date(item.proposalData.expiring_on
                                                                                                                    .slice(0, item.proposalData.expiring_on.indexOf('T')))
                                                                                                                .getFullYear()}
                                                                                            `}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='content-sec'>
                                                                                                <div className='content-data'>
                                                                                                    <p>
                                                                                                        {item.proposalData.content}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className='like-sec'>
                                                                                                    <GrLike />
                                                                                                    <span>{item.proposalData.likes}</span>
                                                                                                </div>
                                                                                                <div className='dislike-sec'>
                                                                                                    <GrDislike />
                                                                                                    <span>
                                                                                                        {item.proposalData.dislikes}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='card-body'>
                                                                                            <div className='check-box-sec'>
                                                                                                <ul className='check-box-list'>
                                                                                                    {item.optionsData.map((option) => {
                                                                                                        return (
                                                                                                            <li className='list-content' key={option.option_id}>
                                                                                                                <input type="checkbox" id={option.option_id} />
                                                                                                                <label htmlFor={option.option_id}>
                                                                                                                    <span></span> {option.option_text}
                                                                                                                </label>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            </div>

                                                                                            <div className='bar-chart-sec'>
                                                                                                <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                                                <BarChart optionsList={item.optionsData} />
                                                                                                {/* <div className='chart-content'>
                                                                                                <img src="./img/bar-chart.png" alt="" />
                                                                                            </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>)
                                                                        }
                                                                        return null
                                                                    }))
                                                                    : (
                                                                        <div className="Loader d-flex justify-content-center">
                                                                            {/* <div className="spinner-border my-5" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div> */}
                                                                            <div className="my-5" >
                                                                                {t('¡No se encontraron votos ni propuestas!')}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ) :
                                                                (
                                                                    <div className="Loader d-flex justify-content-center align-items-center">
                                                                        <div className="spinner-border my-5" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('Cargando')}...
                                                                        </div>
                                                                    </div>
                                                                ))
                                                        }
                                                    </Row>

                                                </div>
                                            </Tab>
                                        </Tabs>

                                        <div className='proposal-btn-sec'>
                                            <Row>
                                                <Col xs lg="4">
                                                    {/* <div className='proposal-btns'>
                                                        <button className='blue-btn'>Editar</button>
                                                    </div> */}
                                                </Col>
                                                <Col xs lg="4">
                                                    <div className='proposal-btns'>
                                                        <button className='green-btn'
                                                            onClick={() => navigate('/proposalvote/addproposalvote')}>
                                                            {t('Agregar Propuesta')}
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col xs lg="4">
                                                    {/* <div className='proposal-btns'>
                                                        <button className='red-btn'>Eliminar Propuesta</button>
                                                    </div> */}
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProposalAndVotes
