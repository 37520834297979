import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const StreamingPage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [todayStream, setTodayStream] = useState();
    const [nextWeekStream, setNextWeekStream] = useState();
    const [searchWord, setSearchWord] = useState();
    const [afterWeekStream, setAfterWeekStream] = useState();
    const [currentTab, setCurrentTab] = useState('hoy');
    const [searchList, setSearchList] = useState([]);
    const [searchCliked, setSearchCliked] = useState(false);
    const [errMsg, setErrMsg] = useState(false);

    const [streamParam, setStreamParam] = useSearchParams({ "streamType": "hoy" });

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    useEffect(() => {
        setSearchWord('');
    }, [currentTab])

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (currentTab === 'hoy' && todayStream) {
            let searchedItems = todayStream?.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        } else if (currentTab === 'PRÓXIMA-SEMANA' && nextWeekStream) {
            let searchedItems = nextWeekStream.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        } else if (currentTab === 'vale-la-pena-esperar' && afterWeekStream) {
            let searchedItems = afterWeekStream.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        }
    }

    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    const handleCurrentTab = (eventKey, i) => {
        setCurrentTab(eventKey);
        setStreamParam({ "streamType": eventKey });
    }

    // handle get streamings as per team.
    useEffect(() => {
        setTodayStream('');
        setNextWeekStream('');
        setAfterWeekStream('');
        fetch(`${GlobalURL}/api/dev/team_moderator/streaming/getAllStreaming`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrMsg(false)
                    return response.json();
                }
                else {
                    console.log("Status: ", response)
                    setErrMsg(true);
                }
            })
            .then(res => {
                setTodayStream(res.data.today);
                setNextWeekStream(res.data.thisWeek);
                setAfterWeekStream(res.data.afterThisWeek);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                setErrMsg(true)
            });
    }, [])

    // handle stream deletion
    const deleteStream = (streamId) => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¡Quieres borrar!'),
            showDenyButton: true,
            confirmButtonText: t('borrar'),
            denyButtonText: t('cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                // fetch(`${GlobalURL}/api/dev/team_moderator/streaming/deleteStream`, {
                fetch(`${GlobalURL}/api/dev/team_moderator/streaming/deleteStream`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ streaming_id: streamId })
                })
                    .then((res) => {
                        console.log('res.status: ', res.status);
                        console.log("event key current- ", currentTab);
                        if (res.status === 200) {
                            Swal.fire(t("borrar!"), t("Hecho!"), "success");
                            if (currentTab === 'hoy') {
                                let updatedTodayStream = todayStream.filter((item) => item.streaming_id !== streamId);
                                setTodayStream(updatedTodayStream);
                            } else if (currentTab === 'PRÓXIMA-SEMANA') {
                                let updatedNextWeekStream = nextWeekStream.filter((item) => item.streaming_id !== streamId);
                                setNextWeekStream(updatedNextWeekStream);
                            } else {
                                let updatedAfterWeekStream = nextWeekStream.filter((item) => item.streaming_id !== streamId);
                                setAfterWeekStream(updatedAfterWeekStream);
                            }
                        }
                        else {
                            Swal.fire(t("borror!"), t('¡Error al eliminar!'), "error");
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                console.log("event key current- ", currentTab);
            }
        });
    }

    return (
        <>
            <div className="dashboard">
                <div className="dashboard-container">
                    <div className="streamingPage">
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>

                                <Col xs lg="6">
                                    <div className="streamingPage-sec">
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => navigate("/homepage")} style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>

                                            <h3 className='header-title inter-page-header-title'>
                                                {t('Streaming')}
                                            </h3>

                                            <div className="search-bar-main">
                                                <div className='search-icon' onClick={handleSearchClicked}>
                                                    <FiSearch />
                                                </div>
                                                <div className="search-bar">
                                                    {
                                                        searchCliked
                                                        && (<input type='search'
                                                            placeholder={t('Buscar...')}
                                                            value={searchWord}
                                                            onChange={handleSearchTyped}
                                                        />)
                                                    }

                                                    {/* <input><div class="div1"></div></input> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='streaming-sec legend-tabs'>
                                            <Tabs
                                                defaultActiveKey={streamParam.get("streamType")}
                                                id="fill-tab-example"
                                                fill
                                                onSelect={handleCurrentTab}
                                            >
                                                <Tab eventKey="hoy" title={t("HOY")}>
                                                    <div className="todays-sec">
                                                        {searchList && searchWord
                                                            ? (
                                                                searchList.length
                                                                    ? (<div className="streaming-card-sec">
                                                                        {searchList?.map((item, i) => {
                                                                            return (
                                                                                <div key={i} className="streaming-card">
                                                                                    <div className="card-img">
                                                                                        <RiDeleteBin6Line style={{
                                                                                            position: 'absolute',
                                                                                            top: '6px',
                                                                                            left: '3px',
                                                                                            color: '#ff6868',
                                                                                            background: '#131313c4',
                                                                                            cursor: 'pointer',
                                                                                            padding: '7px',
                                                                                            borderRadius: '30px',
                                                                                            width: '35px',
                                                                                            height: '35px',
                                                                                            zIndex: '2',
                                                                                        }}
                                                                                            onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                        />
                                                                                        <div className="blurImg-sec">
                                                                                            <img src={item.thumbnail_url
                                                                                                ? `${item?.thumbnail_url}`
                                                                                                : './blankimg.png'
                                                                                            } alt="" />
                                                                                        </div>
                                                                                        <div className="profile-img">
                                                                                            <img src={item.thumbnail_url
                                                                                                ? `${item?.thumbnail_url}`
                                                                                                : './blankimg.png'
                                                                                            } alt="" />
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="card-content">
                                                                                        <p className="user-name">
                                                                                            {item.title}
                                                                                        </p>
                                                                                        <p className="user-hrs">{item.duration} ARG</p>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        }
                                                                    </div>
                                                                    )
                                                                    : (
                                                                        <div className="Loader d-flex justify-content-center mx-auto">
                                                                            <div className=" my-5" >
                                                                                {t('¡No hay transmisión disponible!')}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            )
                                                            : (todayStream?.length
                                                                ? (<div className="streaming-card-sec">
                                                                    {todayStream?.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="streaming-card">
                                                                                <div className="card-img">
                                                                                    <RiDeleteBin6Line style={{
                                                                                        position: 'absolute',
                                                                                        top: '6px',
                                                                                        left: '3px',
                                                                                        color: '#ff6868',
                                                                                        background: '#131313c4',
                                                                                        cursor: 'pointer',
                                                                                        padding: '7px',
                                                                                        borderRadius: '30px',
                                                                                        width: '35px',
                                                                                        height: '35px',
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                        onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                    />
                                                                                    <div className="blurImg-sec">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                    <div className="profile-img">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-content">
                                                                                    <p className="user-name">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    <p className="user-hrs">{item.duration} ARG</p>
                                                                                </div>
                                                                            </div>)
                                                                    })
                                                                    }
                                                                </div>
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center mx-auto">
                                                                        {errMsg
                                                                            ? (<div className="text-danger">{t('¡Error Interno del Servidor!')}</div>
                                                                            )
                                                                            : (todayStream?.length
                                                                                ? (<>
                                                                                    <div className="spinner-border m-5" role="status">
                                                                                        <span className="sr-only"></span>
                                                                                    </div>
                                                                                    <div className=" my-5" > &nbsp;
                                                                                        {t("Cargando")}...
                                                                                    </div>
                                                                                </>)
                                                                                : (
                                                                                    <div className=" my-5" >
                                                                                        {t('¡No hay transmisión disponible!')}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="PRÓXIMA-SEMANA" title={t("PRÓXIMA SEMANA")}>
                                                    <div className="todays-sec">
                                                        {searchList && searchWord
                                                            ? (searchList?.length
                                                                ? (<div className="streaming-card-sec">
                                                                    {searchList?.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="streaming-card">
                                                                                <div className="card-img">
                                                                                    <RiDeleteBin6Line style={{
                                                                                        position: 'absolute',
                                                                                        top: '6px',
                                                                                        left: '3px',
                                                                                        color: '#ff6868',
                                                                                        background: '#131313c4',
                                                                                        cursor: 'pointer',
                                                                                        padding: '7px',
                                                                                        borderRadius: '30px',
                                                                                        width: '35px',
                                                                                        height: '35px',
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                        onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                    />
                                                                                    <div className="blurImg-sec">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                    <div className="profile-img">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-content">
                                                                                    <p className="user-name">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    <p className="user-hrs">{item.duration} ARG</p>
                                                                                </div>
                                                                            </div>)
                                                                    })
                                                                    }
                                                                </div>
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center mx-auto">

                                                                        <div className=" my-5" >
                                                                            {t('¡No hay transmisión disponible!')}
                                                                        </div>

                                                                    </div>
                                                                )
                                                            )
                                                            : (nextWeekStream?.length
                                                                ? (<div className="streaming-card-sec">
                                                                    {nextWeekStream?.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="streaming-card">
                                                                                <div className="card-img">
                                                                                    <RiDeleteBin6Line style={{
                                                                                        position: 'absolute',
                                                                                        top: '6px',
                                                                                        left: '3px',
                                                                                        color: '#ff6868',
                                                                                        background: '#131313c4',
                                                                                        cursor: 'pointer',
                                                                                        padding: '7px',
                                                                                        borderRadius: '30px',
                                                                                        width: '35px',
                                                                                        height: '35px',
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                        onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                    />
                                                                                    <div className="blurImg-sec">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                    <div className="profile-img">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-content">
                                                                                    <p className="user-name">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    <p className="user-hrs">{item.duration} ARG</p>
                                                                                </div>
                                                                            </div>)
                                                                    })
                                                                    }
                                                                </div>
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                                        {errMsg
                                                                            ? (
                                                                                <div className="text-danger">
                                                                                    {t('¡Error Interno del Servidor!')}
                                                                                </div>
                                                                            )
                                                                            : (nextWeekStream?.length
                                                                                ? (<>
                                                                                    <div className="spinner-border m-5 " role="status">
                                                                                        <span className="sr-only"></span>
                                                                                    </div>
                                                                                    <div className=" my-5" > &nbsp;
                                                                                        {t('Cargando')}...
                                                                                    </div>
                                                                                </>)
                                                                                : (
                                                                                    <div className=" my-5" >
                                                                                        {t('¡No hay transmisión disponible!')}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="vale-la-pena-esperar" title={t("Vale la pena esperar")}>
                                                    <div className="todays-sec">
                                                        {searchList && searchWord
                                                            ? (searchList?.length
                                                                ? (<div className="streaming-card-sec">
                                                                    {searchList?.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="streaming-card">
                                                                                <div className="card-img">
                                                                                    <RiDeleteBin6Line style={{
                                                                                        position: 'absolute',
                                                                                        top: '6px',
                                                                                        left: '3px',
                                                                                        color: '#ff6868',
                                                                                        background: '#131313c4',
                                                                                        cursor: 'pointer',
                                                                                        padding: '7px',
                                                                                        borderRadius: '30px',
                                                                                        width: '35px',
                                                                                        height: '35px',
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                        onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                    />
                                                                                    <div className="blurImg-sec">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                    <div className="profile-img">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-content">
                                                                                    <p className="user-name">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    <p className="user-hrs">{item.duration} ARG</p>
                                                                                </div>
                                                                            </div>)
                                                                    })
                                                                    }
                                                                </div>
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center mx-auto">
                                                                        <div className=" my-5" >
                                                                            {t('¡No hay transmisión disponible!')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                            : (afterWeekStream?.length
                                                                ? (<div className="streaming-card-sec">
                                                                    {afterWeekStream?.map((item, i) => {
                                                                        return (
                                                                            <div key={i} className="streaming-card">
                                                                                <div className="card-img">
                                                                                    <RiDeleteBin6Line style={{
                                                                                        position: 'absolute',
                                                                                        top: '6px',
                                                                                        left: '3px',
                                                                                        color: '#ff6868',
                                                                                        background: '#131313c4',
                                                                                        cursor: 'pointer',
                                                                                        padding: '7px',
                                                                                        borderRadius: '30px',
                                                                                        width: '35px',
                                                                                        height: '35px',
                                                                                        zIndex: '2',
                                                                                    }}
                                                                                        onClick={() => { deleteStream(item?.streaming_id) }}
                                                                                    />
                                                                                    <div className="blurImg-sec">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                    <div className="profile-img">
                                                                                        <img src={item.thumbnail_url
                                                                                            ? `${item?.thumbnail_url}`
                                                                                            : './blankimg.png'
                                                                                        } alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-content">
                                                                                    <p className="user-name">
                                                                                        {item.title}
                                                                                    </p>
                                                                                    <p className="user-hrs">{item.duration} ARG</p>
                                                                                </div>
                                                                            </div>)
                                                                    })
                                                                    }
                                                                </div>
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                                        {errMsg
                                                                            ? (<div className="text-danger">
                                                                                {t('¡Error Interno del Servidor!')}
                                                                            </div>
                                                                            )
                                                                            : (afterWeekStream?.length
                                                                                ? (<>
                                                                                    <div className="spinner-border m-5" role="status">
                                                                                        <span className="sr-only"></span>
                                                                                    </div>
                                                                                    <div className=" my-5" > &nbsp;
                                                                                        {t('Cargando')}...
                                                                                    </div>
                                                                                </>)
                                                                                : (
                                                                                    <div className=" my-5" >
                                                                                        {t('¡No hay transmisión disponible!')}
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                </Tab>
                                            </Tabs>

                                        </div>
                                    </div>
                                    <div className='center-space-btn'>
                                        <button className='green-btn' onClick={() => navigate("/streaming/addstreaming")}>
                                            {t('Agregar Stream')}
                                        </button>
                                        {/* <button className='red-btn'>Eliminar Stream</button> */}
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StreamingPage
