import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FaRegCommentAlt } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";
// import { FiSearch } from "react-icons/fi";
import { GrUpload } from "react-icons/gr";
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const EditOtherSelectionNews = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const location = useLocation();
    const navigate = useNavigate();
    const [news, setNews] = useState({});
    const [editNews, setEditNews] = useState(false);
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    const [allComments, setAllComments] = useState(location.state.news_comments);
    const [file, setFile] = useState(null);
    const formData = new FormData();

    useEffect(() => {
        setNews(location.state);
        // eslint-disable-next-line
    }, [])

    // handle news deletion
    const handleNewsDeletion = () => {
        Swal.fire({
            title: "Are you sure?",
            text: 'Do you want to delete!',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `Cancel`
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${GlobalURL}/api/news/removenews`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                        "news_id": `${news.news_id}`
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            Swal.fire("Deleted!", "", "success");
                            navigate(-1);
                        }
                        else {
                            Swal.fire("Deleted!", "", "error");
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        });
    }

    //handle edit news
    const handleEditNews = () => {
        const editBtn = document.querySelector(".blue-btn");
        const nodes = document.querySelectorAll('.edit-sec');
        if (editNews) {
            //make editable state - true
            let prevContent, prevTitle, newContent, newTitle;
            setEditNews(false);
            prevTitle = news.title;
            prevContent = news.content;
            newTitle = nodes[0].innerText;
            newContent = nodes[1].innerText;

            editBtn.innerHTML = 'EDITAR';
            for (let element of nodes) {
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }
            if (!(prevContent === newContent) || !(prevTitle === newTitle) || (file)) {
                Swal.fire({
                    title: "Are you sure?",
                    text: 'Do you want to Edit News!',
                    showDenyButton: true,
                    confirmButtonText: "Edit News",
                    denyButtonText: `Cancel`
                }).then((result) => {
                    if (result.isConfirmed) {
                        formData.append('title', newTitle)
                        formData.append('content', newContent)
                        if (file)
                            formData.append('media', file);
                        else
                            formData.append('media', news.news_media[0].url);
                        console.log('formData: ', formData);

                        fetch(`${GlobalURL}/api/news/editnews`, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                "ngrok-skip-browser-warning": "69420",
                                "news_id": `${news.news_id}`
                            },
                            body: formData
                        })
                            .then((res) => {
                                Swal.fire("Edit!", "", "success");
                                navigate(-1);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        Swal.fire("Edit!", "", "error");
                        nodes[0].innerText = prevTitle;
                        nodes[1].innerText = prevContent;
                        setFile(null);
                    }
                });
            }
        } else {
            //toogle the editable state 
            setEditNews(true)
            editBtn.innerHTML = 'Guardar cambios';
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    //handle comment delete
    const handleCommentDel = (e) => {
        try {
            Swal.fire({
                title: "Are you sure?",
                text: 'Do you want to Delete Comment!',
                showDenyButton: true,
                confirmButtonText: "Delete Comment",
                denyButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(`${GlobalURL}/api/news/removecomment`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                            "ngrok-skip-browser-warning": "69420",
                            "comment_id": `${e.target.id}`
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire("Deleted!", "", "success");
                                const delCmtIndex = allComments.findIndex((cmt) => {
                                    return (cmt.comment_id === Number(e.target.id))
                                })
                                setAllComments([...allComments.slice(0, delCmtIndex), ...allComments.slice(delCmtIndex + 1)]);
                            }
                            else {
                                Swal.fire("Deleted!", "¡Error al eliminar el comentario!", "error");
                            }
                        })
                        .catch((err) => {
                            console.log("error occured: ", err);
                            Swal.fire("Deleted!", "¡Error al eliminar el comentario!", "error");
                        })
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    const checkType = (url) => {
        if (file) {
            return <img src={URL.createObjectURL(file)} alt="" />
        } else {
            let extention = url?.split('.')?.at(-1);
            let res = images.find((ext) => {
                return extention.includes(ext);
            }
            )
            if (!url) {
                return <img src='' alt="" />
            }
            else if (images.includes(url?.split('.')?.at(-1))||res) {
                return <img src={url} alt="" />
            } else {
                return <video src={url} alt="" controls />
            }
        }
    }
    
    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='edit-news-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3">
                                    <div className='comments-sec'>
                                        <div className='comment-sec-heading'>
                                            <h2>{t('COMENTARIOS')}</h2>
                                        </div>
                                        <div className='user-comment-area'>
                                            {(allComments && allComments.length > 0) ? (
                                                allComments.map(cmt => {
                                                    return (<>
                                                        <div className='user-comment' key={cmt.comment_id}>
                                                            <div className='left-sec'>
                                                                <div className='user-data'>
                                                                    <div className='user-img'>
                                                                        <img src={cmt.user
                                                                            && (cmt.user.image_url === null ? '' : cmt.user.image_url)} alt="" />
                                                                    </div>
                                                                    <div className='user-content'>
                                                                        <h5 className='user-name'>
                                                                            {cmt.user && cmt.user.first_name} {cmt.user && cmt.user.last_name}
                                                                            <span>
                                                                                &nbsp; @{(cmt.user && cmt.user.userName) || 'anónimo'}
                                                                            </span>
                                                                        </h5>
                                                                        <p>{cmt.content}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='notification-sec'>
                                                                    <span className='comment-sec'>
                                                                        <FaRegCommentAlt /> <span className='count'>
                                                                            {cmt.cmt_count > 0 ? cmt.cmt_count : "10"}</span>
                                                                    </span>
                                                                    <span className='like-sec'>
                                                                        <AiOutlineLike /> <span className='count'>{cmt.likes_count}</span>
                                                                    </span>
                                                                    <span className='share-sec'>
                                                                        <IoShareOutline /> <span className='count'>{0}</span>

                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className='right-sec'>
                                                                <span className='delete-icon' onClick={handleCommentDel}
                                                                    style={{ cursor: "pointer" }}>
                                                                    <img src="/img/delete.png" alt="" id={cmt.comment_id} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })
                                            ) : (
                                                <h4>{t('NO COMENTARIOS')}. . .</h4>
                                            )
                                            }

                                        </div>
                                    </div>
                                </Col>

                                <Col xs lg="6">

                                    <div className='edit-news-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                                <img src="/img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>{t('Editar Noticia')}</h3>
                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>
                                        <div className='editnews-Content'>
                                            <div className='editnews-card'>
                                                <h3 contentEditable='false' className='edit-sec'>{news.title}</h3>
                                                <div className='editnewscard-img'>
                                                    {file
                                                        ? checkType(file?.name)
                                                        : (news?.news_media && news?.news_media[0]?.url)
                                                        && checkType(news?.news_media[0]?.url)
                                                    }
                                                    {editNews && (
                                                        <div className='img-edit-icon'>
                                                            <GrUpload />
                                                            <input id="uploadFile" onChange={handleFileSelect}
                                                                type="file" className="upload" />
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            </div>
                                            <div className='editenews-data'>
                                                <p contentEditable='false' className='edit-sec'>{news.content}</p>
                                            </div>
                                        </div>
                                        <div className='editnews-btn-sec'>
                                            <button className='blue-btn' onClick={handleEditNews}>{t("EDITAR")}</button>
                                            <button className='red-btn' onClick={handleNewsDeletion}>{t("Eliminar Noticia")}</button>
                                            {/* <button className='green-btn' onClick={() => navigate(-1)}>GUARDAR</button> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditOtherSelectionNews;
