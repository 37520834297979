import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { Calendar, DateObject } from "react-multi-date-picker";
import colors from "react-multi-date-picker/plugins/colors";
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "react-calendar/dist/Calendar.css";
import { FiSearch } from "react-icons/fi";
// import { FANCY } from "../BaseURL";
import GlobalURL from "../BaseURL";
import Swal from "sweetalert2";
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EventDate = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [eventData, setEventData] = useState();
    const [eventDataUpdated, setEventDataUpdated] = useState();
    // const [delBtn, setDelBtn] = useState(false);
    // const [selectedEvent, setSelectedEvent] = useState(null);
    let [currDate, setCurrDate] = useState(new Date());
    const month = ["enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    // console.log('setCurrDate: ', setCurrDate);
    const [searchWord, setSearchWord] = useState();
    // const [statusOnChangeMonth, setStatusOnChangeMonth] = useState();
    const [errMsg, setErrMsg] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [searchCliked, setSearchCliked] = useState(false);

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    //handle verify team selection
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    //handle search typed
    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (eventDataUpdated) {
            let searchedItems = eventDataUpdated.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            console.log('searchedItems: ', searchList);
        }
    }

    useEffect(() => {
        let generalArr = [], exclusiveArr = [], exprieneceArr = [];
        eventData?.forEach((data) => {
            console.log("month case: ", currDate?.getMonth() + 1);

            let eventDate = new DateObject()
                .setDay(new Date(data.event_date.slice(0, data.event_date.indexOf('T'))).getDate())
                .add((new Date(data.event_date).getMonth() + 1) - (currDate.getMonth() + 1), "month")
                .add((new Date(data.event_date).getFullYear()) - (currDate.getFullYear()), "year");

            let type = data.event_type?.trim()?.toLowerCase();
            if (type === 'events' || type === 'general') {
                eventDate.color = "yellow";
                generalArr.push(eventDate);
            } else if (type === 'exclusive-events') {
                eventDate.color = "blue";
                exclusiveArr.push(eventDate);
            } else if (type === 'experiences-messice') {
                eventDate.color = "green"
                exprieneceArr.push(eventDate);
            }
        })

        let today = new DateObject().setDay(currDate.getDate())
        today.color = "red";
        setProps((props) => ({ ...props, value: [today, ...generalArr, ...exclusiveArr, ...exprieneceArr] }));
        // eslint-disable-next-line
    }, [eventData])

    const [props, setProps] = useState({
        multiple: true,
        plugins: [
            colors({ colors: ["green", "yellow", "blue", "red"] }),
        ],
    });

    useEffect(() => {
        // const loadStatus = document.querySelector(".Loader");
        fetch(`${GlobalURL}/api/dev/home/getAllEvents`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    setErrMsg('!Error Interno del Servidor!');
                }
            })
            .then(res => {
                setErrMsg(false);
                setEventData(res.data);
                handleFirstRender(res.data);
                console.log("res: ", res.data);
            })
            .catch(() => {
                setErrMsg("!Error Interno del Servidor!");
            });
        // eslint-disable-next-line 
    }, [])

    const handleSelection = (e, item) => {
        console.log(e.target, " item-> ", item);
        navigate(`/events/eventdetails/${item.event_id}`, { state: item });
        // if(selectedEvent){
        //     console.log("e: ",selectedEvent);
        //     selectedEvent.classList.remove("event-div");
        //     setSelectedEvent(null);
        // }
        // let div = e.target.closest(".cal-list-card");
        // div.classList.add("event-div");
        // setSelectedEvent(div);
    }

    const checkClass = (evtType) => {
        let type = evtType?.trim()?.toLowerCase();
        if (type === 'events' || type === 'general') {
            return 'yellow-clr'
        } else if (type === 'exclusive-events') {
            return 'blue-clr'
        } else if (type === 'experiences-messice') {
            return 'green-clr'
        } else {
            return 'red-clr'
        }
    }

    const handleFirstRender = (eventFetched) => {
        let chnagedEventDataOnMonth = [];

        eventFetched.forEach((item) => {
            let dateEvt = new Date(item.event_date);
            // console.log("item", dateEvt.getMonth() + 1, dateEvt.getFullYear());
            // eslint-disable-next-line
            if (((dateEvt.getMonth() + 1) == (currDate.getMonth() + 1))
                // eslint-disable-next-line
                && (dateEvt.getFullYear() == currDate.getFullYear())) {
                chnagedEventDataOnMonth.push(item);
            }
        })
        // setEventDataUpdated(res.data);
        setEventDataUpdated(chnagedEventDataOnMonth);
    }

    // useEffect(() => {
    //     if (statusOnChangeMonth) {
    //         console.log("use-status: ", statusOnChangeMonth);
    //         setCurrDate(new Date(statusOnChangeMonth));
    //     }
    // }, [statusOnChangeMonth])

    const handleEventDataOnMonthChnage = (e) => {
        // console.log("event data clicked: ", e.month.number, e.month.name, e.year);
        // setCurrDate(() => new Date(e.year, e.month.number));

        let chnagedEventDataOnMonth = [];
        eventData.forEach((item) => {
            let dateEvt = new Date(item.event_date);
            console.log("item", dateEvt.getMonth() + 1, dateEvt.getFullYear());
            // eslint-disable-next-line
            if (((dateEvt.getMonth() + 1) == e.month.number)
                // eslint-disable-next-line
                && (dateEvt.getFullYear() == e.year)) {
                chnagedEventDataOnMonth.push(item);
            }
        })

        if (e.month.number <= currDate.getMonth()) {
            // console.log('new Date(e.year,e.month.name,1): ', new Date(e.year, e.month.number - 1));
            let dateEvts;
            if (e.year < currDate.getFullYear()) {
                dateEvts = new Date(e.year - 1, e.month.number - 1);
            } else {
                dateEvts = new Date(e.year, e.month.number - 1);
            }
            setCurrDate(dateEvts)
            // setStatusOnChangeMonth(dateEvts);
        } else if (e.month.number >= currDate.getMonth()) {
            let dateEvts = new Date(e.year, e.month.number - 1);
            console.log('dateEvts: ', dateEvts);
            setCurrDate(dateEvts)
        }
        // console.log('chnagedEventDataOnMonth: ', chnagedEventDataOnMonth);

        setEventDataUpdated(() => {
            // setCurrDate(new Date(e.year,e.month.number))
            return chnagedEventDataOnMonth
        });
    }

    return (
        <>
            <div className='dashboard'>
                <div className='event-date-page'>
                    <Container fluid>
                        <Row className="justify-content-md-center">
                            <Col xs lg="2"></Col>
                            <Col xs lg="8">
                                <div className='event-page-content'>
                                    <div className='news-header'>
                                        <div className='back-sec'>
                                            <img src="./img/volver.png" alt=""
                                                onClick={() => navigate('/homepage')}
                                                style={{ cursor: "pointer" }} />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('Eventos')}
                                        </h3>

                                        <div className="search-bar-main">
                                            <div className='search-icon' onClick={handleSearchClicked}>
                                                <FiSearch />
                                            </div>
                                            <div className="search-bar">
                                                {searchCliked
                                                    && (<input type='search'
                                                        placeholder={t('Buscar...')}
                                                        value={searchWord}
                                                        onChange={handleSearchTyped}
                                                    />)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='event-calendar-sec'>
                                        <Row>
                                            <Col xs lg="4">
                                                <div className="event-classification my-4 d-flex gap-3">
                                                    <div className="dot-red roundec-circle"></div> {t('Hoy')}
                                                    <div className="dot-yellow roundec-circle"></div> {t("Eventos")}
                                                    <div className="dot-blue roundec-circle"></div> {t('Evento Exclusivo')}
                                                    <div className="dot-green roundec-circle"></div> {t("Vivencias Mess Ice")}
                                                </div>

                                                <div className='event-calendar'>
                                                    <Calendar
                                                        {...props}
                                                        onMonthChange={(e) => {
                                                            console.log('e: ', e);
                                                            // setCurrDate(() => new Date(e.year, e.month.number));
                                                            handleEventDataOnMonthChnage(e);
                                                        }}
                                                        className="custom-calendar"
                                                        containerStyle={{ width: "100%" }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xs lg="4">
                                                <div className='calendar-list-sec-box'>
                                                    <div className='cal-month custom-month-manage'>
                                                        <h4>
                                                            {month[currDate?.getMonth()]?.toUpperCase()} {currDate?.getFullYear()}
                                                        </h4>
                                                    </div>

                                                    <div className='cal-list-card-sec custom-list-manage'>
                                                        {searchList && searchWord
                                                            ? (searchList.length
                                                                ? searchList.map((item, i) => {
                                                                    return (
                                                                        <div className='cal-list-card event-click event-list-sec'
                                                                            style={{ cursor: "pointer" }}
                                                                            id={item.event_id}
                                                                            onClick={(e) => handleSelection(e, item)}
                                                                            key={i}>
                                                                            <div className='left-sec'>
                                                                                <span className={`date ${checkClass(item.event_type)}`}>
                                                                                    {new Date(item.event_date
                                                                                        .slice(0, item.event_date.indexOf('T')))
                                                                                        .getDate()}
                                                                                </span>
                                                                                <span className='event-data'>
                                                                                    <h4>{item.title}</h4>
                                                                                    <p>{item.streaming_link}</p>
                                                                                </span>
                                                                            </div>
                                                                            <div className='right-sec'>
                                                                                <h3>{item.duration} {t('hs.')}</h3>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : (<div className="Loader d-flex justify-content-center">
                                                                    {errMsg
                                                                        ? (<h4>
                                                                            {t("!Error Interno del Servidor!")}
                                                                        </h4>)
                                                                        : (<div className="empty-box my-5" >
                                                                            <h4>{t('No hay eventos con la palabra clave')} {searchWord}</h4>
                                                                        </div>

                                                                        )}
                                                                </div>
                                                                )
                                                            )
                                                            : (eventDataUpdated
                                                                ? (eventDataUpdated.length
                                                                    ? eventDataUpdated.map((item, i) => {
                                                                        return (
                                                                            <div className='cal-list-card event-click event-list-sec'
                                                                                style={{ cursor: "pointer" }}
                                                                                id={item.event_id}
                                                                                onClick={(e) => handleSelection(e, item)}
                                                                                key={i}>
                                                                                <div className='left-sec'>
                                                                                    <span className={`date ${checkClass(item.event_type)}`}>
                                                                                        {new Date(item.event_date
                                                                                            .slice(0, item.event_date.indexOf('T')))
                                                                                            .getDate()}
                                                                                    </span>
                                                                                    <span className='event-data'>
                                                                                        <h4>{item.title}</h4>
                                                                                        <p>{item.streaming_link}</p>
                                                                                    </span>
                                                                                </div>
                                                                                <div className='right-sec'>
                                                                                    <h3>{item.duration} {t('hs')}</h3>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : (<div className="Loader d-flex justify-content-center">
                                                                        {errMsg
                                                                            ? (<h4>
                                                                                {t("!Error Interno del Servidor!")}
                                                                            </h4>
                                                                            )
                                                                            : (
                                                                                <div className="empty-box my-5" >
                                                                                    <h4>{t('No Eventos')}!</h4>
                                                                                </div>

                                                                            )
                                                                        }
                                                                    </div>
                                                                    )
                                                                )
                                                                : (<div className="Loader d-flex justify-content-center align-items-center">
                                                                    {errMsg
                                                                        ? (
                                                                            <h4>
                                                                                {t("!Error Interno del Servidor!")}
                                                                            </h4>
                                                                        )
                                                                        : (<>
                                                                            <div className="spinner-border my-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('Cargando')}...
                                                                            </div>
                                                                        </>)
                                                                    }
                                                                </div>
                                                                ))
                                                        }

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className='event-btns'>
                                        <button className='green-btn'
                                            onClick={() => navigate('/events/addevent')}
                                            style={{ cursor: "pointer" }}>
                                            {t('Agregar Evento')}
                                        </button>
                                        {/* 
                                        <button className='red-btn' onClick={handleDeletionEvent}>
                                        {delBtn
                                            ? (
                                                <>
                                                    <span class="spinner-border spinner-border-sm" 
                                                    role="status" aria-hidden="true"></span>
                                                    <span class="sr-only">&nbsp; Enviando...</span>
                                                </>
                                                )
                                            : ('Eliminar evento')
                                        }
                                        </button> 
                                        */}
                                    </div>
                                </div>
                            </Col>
                            <Col xs lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EventDate;