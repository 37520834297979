import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import 'swiper/css';
import 'swiper/css/pagination';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddProposalVote = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [proposalData, setProposalData] = useState(
        {
            title: "",
            category: "",
            endDate: "",
            startDate: ""
        });
    const [optionData, setOptionData] = useState();
    const [proposalImage, setProposalImage] = useState(null);
    const [insigniaList, setInsigniaList] = useState();
    const [voteInsignia, setVoteInsignia] = useState();
    const [addBtn, setAddBtn] = useState(false);

    const formData = new FormData();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(res => {
                setInsigniaList(res.data);
                // console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const handleFileSelect = (e) => {
        // console.log(e.target.value)
        setProposalImage(e.target.files[0]);
        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    };

    const handleProposalData = (e) => {
        setProposalData({ ...proposalData, [e.target.name]: e.target.value });
    }

    const handleOptionData = (e) => {
        setOptionData({ ...optionData, [e.target.name]: e.target.value });
    }

    const handleSubmitProposal = (e) => {
        e.preventDefault();

        if (proposalData.title && proposalData.category && proposalData.endDate
            && proposalData.startDate && proposalImage
        ) {
            setAddBtn(true);
            formData.append("proposalImage", proposalImage);
            formData.append("team_id", localStorage.getItem("team_id"));

            for (let formfields in proposalData) {
                formData.append(formfields, proposalData[formfields]);
            }

            for (let formfields in optionData) {
                if (optionData[formfields])
                    formData.append(formfields, optionData[formfields]);
            }

            fetch(`${GlobalURL}/api/dev/team_moderator/createPraposal`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then((response) => {
                if (response.status === 200) {
                    Swal.fire("success", t("¡Voto creado exitosamente!"));
                    navigate(-1);
                    return response.json();
                }
            }).catch((err) => {
                // console.log("error occured: ", err);
                Swal.fire("error", t("¡Todos los campos son obligatorios!","error"));
            }).finally(()=>{
                setAddBtn(false);
            });
        } else {
            //apply error message for news if fields are empty.
            Swal.fire("", t("¡Todos los campos son obligatorios!"),"info");
            setAddBtn(false);
        }
    }

    const getInsigniaId = (insigniaName) => {
        setVoteInsignia(insigniaName)
        let id, type;
        insigniaList.forEach((item) => {
            if (item.insignia_name_es === insigniaName) {
                id = item.insignia_id;
                type = item.type_es;
            }
        })
        setProposalData({ ...proposalData, insignia_id: id, insignia_type: type });
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='add-streaming-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                            <img src="../img/volver.png" alt="back_icon" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('Agregar Propuestas')}
                                        </h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>
                                    <div className='common-input-forms addstreaming'>
                                        <form onSubmit={handleSubmitProposal} required>
                                            <div className='common-form-control vap-form-control'>
                                                <label>{t('Categoría de Propuesta')}</label>
                                                <select
                                                    name='category'
                                                    onChange={handleProposalData}
                                                    value={proposalData.category}
                                                >
                                                    <option de Propuesta value="">
                                                        {t('Elija categoría de la propuesta (General / Insignia - elegir camino)')}
                                                    </option>
                                                    <option value="general">{t('General')}</option>
                                                    <option value="insignia">{t('Insignia')}</option>
                                                </select>
                                            </div>

                                            {proposalData.category === 'insignia'
                                                && (<div className='common-form-control'>
                                                    <label>{t('Insignia requerida')}</label>
                                                    <select
                                                        name='voteInsignia'
                                                        onChange={(e) => getInsigniaId(e.target.value)}
                                                        value={voteInsignia}
                                                    >
                                                        <option value="">
                                                            {t('¡Seleccione insignias para votos y propuestas!')}                                                    </option>
                                                        {insigniaList?.map((item) => {
                                                            return (
                                                                <option key={item.insignia_id} value={item.insignia_name_es}>
                                                                    {item.insignia_name_es}
                                                                </option>)
                                                        })}
                                                    </select>
                                                </div>)
                                            }

                                            <div className='common-form-control vap-form-control'>
                                                <label>{t('Propuesta')}</label>
                                                <input placeholder={t('Inserte el título de la propuesta aquí')}
                                                    name='title'
                                                    onChange={handleProposalData}
                                                    value={proposalData.title} />
                                            </div>

                                            <div className='common-form-control vap-form-control' onClick={handleShow}>
                                                <label>{t('Opciones disponibles de Propuesta')}</label>
                                                <input placeholder={t('Ingresar texto de opción')}
                                                    name='title' disabled />
                                            </div>

                                            <div className='common-form-control vap-form-control img-video-form-control'>
                                                <label>{t('Imagenes de Propuesta')}</label>
                                                <div className='file-input-sec '>
                                                    <input id="uploadFile" className="f-input"
                                                        placeholder={t('Inserte la foto de la propuesta aquí')} />
                                                    <div className="fileUpload btn btn--browse">
                                                        <input id="uploadBtn" onChange={handleFileSelect}
                                                            type="file" className="upload" />
                                                    </div>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col xs lg="6">
                                                    <div className='common-form-control vap-form-control'>
                                                        <label>{t('Fecha de inicio de la propuesta')}</label>
                                                        <input placeholder={`${t('Inserte la fecha de la propuesta aquí (Inicio opcional)')}`}
                                                            type='date'
                                                            name='startDate'
                                                            value={proposalData.startDate}
                                                            onChange={handleProposalData}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col xs lg="6">
                                                    <div className='common-form-control vap-form-control'>
                                                        <label>{t('Fecha de finalización de la propuesta')}</label>
                                                        <input placeholder={`${t('Inserte la fecha de la propuesta aquí (Final - opcional)')}`}
                                                            type='date'
                                                            name='endDate'
                                                            value={proposalData.endDate}
                                                            onChange={handleProposalData}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className='news-btn-sec'>
                                                <button className='green-btn' type='submit' >
                                                    {addBtn
                                                        ? (<>{t("Enviando")}...</>)
                                                        : (t('Guardar'))}
                                                </button>
                                            </div>
                                        </form>

                                        <Modal show={show} className="proposal-popup common-popup"
                                            onHide={handleClose} centered>
                                            <Modal.Header closeButton >
                                                <Modal.Title>{t('OPCIONES DE PROPUESTA')}</Modal.Title>
                                            </Modal.Header>

                                            <Modal.Body>
                                                <div className="slider-details">
                                                    <form>
                                                        <ul className='praposal-options-list p-0'>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 1 de propuesta')}`}
                                                                    name='option1'
                                                                    value={optionData?.option1}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 2 de propuesta')}`}
                                                                    name='option2'
                                                                    value={optionData?.option2}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 3 de propuesta')}`}
                                                                    name='option3'
                                                                    value={optionData?.option3}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 4 de propuesta')}`}
                                                                    name='option4'
                                                                    value={optionData?.option4}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 5 de propuesta')}`}
                                                                    name='option5'
                                                                    value={optionData?.option5}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 6 de propuesta')}`}
                                                                    name='option6'
                                                                    value={optionData?.option6}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                            <li>
                                                                <input type="text"
                                                                    placeholder={`${t('Ingresar texto de opción 7 de propuesta')}`}
                                                                    name='option7'
                                                                    value={optionData?.option7}
                                                                    onChange={handleOptionData} />
                                                            </li>
                                                        </ul>
                                                    </form>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer className='border-0 p-0'>
                                                <div className='news-btn-sec '>
                                                    <button className='green-btn' onClick={handleClose}>{t('GUARDAR')}</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </Col>
                                <Col xs lg="3">
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddProposalVote
