import React from "react";
import { Navigate } from "react-router-dom";

// const ProtectedRoute = ({ children }) => {
//   const navigate = useNavigate();

//   const loginToken = JSON.parse(localStorage.getItem("access-token"));
//   const status = children.type.name.includes('Login');

//   if (loginToken) {
//     if (status) {
//       // navigate('/homepage');
//       // return ; 
//       return <Navigate to="/homepage" />;
//     }
//     return children;
//   } else {
//     if (status) {
//       return children;
//     }
//     // navigate('/login');
//     return <Navigate to="/login" replace />;
//   }
// };

const ProtectedRoute = ({ children }) => {
  console.log('from PP - children: ', children);
  // const navigate = useNavigate();

  const loginToken = JSON.parse(localStorage.getItem("login-status"));
  console.log('from PP - loginToken: ', loginToken);
  // const status = children.type.name.includes('Login');

  if (loginToken) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
