import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useRef } from 'react';
import { GrUpload } from "react-icons/gr";
// import { FiSearch } from 'react-icons/fi';
import { FaPenClip } from "react-icons/fa6";
import GlobalURL from '../BaseURL';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EditorBannerPage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate()
    const bannerData = useLocation().state
    const [file, setFile] = useState(null);
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    // const [editBannerDes, setEditBannerDes] = useState(false);
    const [oldDescription, setOldDescription] = useState(bannerData.description);
    const [editBanner, setEditBanner] = useState(false);
    const [delBtn, setDelBtn] = useState(false);
    const [editBillboard, setEditBillboard] = useState(false);
    const formData = new FormData();
    const [show, setShow] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const editorRef = useRef(null);

    const handleShow = () => setShow(true);

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content    
        bannerData.description = editorRef.current.innerText;
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerHTML);
        handleClose();
    };

    // deleteion of banner
    const handleBannerDeletion = () => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¡Quieres borrar!'),
            showDenyButton: true,
            confirmButtonText: t("Eliminar!"),
            denyButtonText: `Cancelar`
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setDelBtn(true);
                    fetch(`${GlobalURL}/api/dev/user/banner/deleteBannerById`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: JSON.stringify({ banner_id: bannerData.banner_id })
                    })
                        .then((res) => {
                            // setDelBtn(false)
                            Swal.fire(t("Eliminar!"), "", "success");
                            // delBtn.innerHTML = 'Eliminar Post';
                            navigate(-1);
                        })
                        .catch((err) => {
                            Swal.fire('Error!', `${err}`, "error");
                            // delBtn.innerHTML = 'Eliminar Post';
                            console.log(err)
                        }).finally(() => {
                            setDelBtn(false);
                        })
                } else {
                    // delBtn.innerHTML = 'Eliminar Post';
                    setDelBtn(false);
                }
            });
    }

    // handle update banner
    const handleEditBanner = () => {
        // const editBtn = document.querySelector(".blue-btn");
        const nodes = document.querySelectorAll('.edit-sec');
        if (editBanner) {
            //making editable state - true
            let prevDescription, prevTitle, newDescription, newTitle;
            setEditBanner(false);

            prevTitle = bannerData.title;
            prevDescription = bannerData.description;
            newTitle = nodes[0].innerText;

            if (editorContent.trim())
                newDescription = editorContent;
            else
                newDescription = bannerData.description

            // editBtn.innerHTML = 'EDITAR';
            for (let element of nodes) {
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }

            if (!(prevDescription === newDescription)
                || !(prevTitle === newTitle)
                || (file)) {
                Swal.fire({
                    title: t("Estas seguro"),
                    text: t('¿Quieres editar el carteles?'),
                    showDenyButton: true,
                    confirmButtonText: t("Editar carteles"),
                    denyButtonText: `Cancel`
                }).then((result) => {
                    if (result.isConfirmed) {
                        setEditBillboard(true);
                        // editBtn.innerHTML = `<span class=" loaderbtn signup-btn " disbaled >
                        //                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        //                         <span class="sr-only">&nbsp; Enviando...</span>
                        //                     </span>`;
                        formData.append('title', newTitle)
                        formData.append('description', newDescription)
                        // console.log('inside :: newDescription: ', newDescription);
                        formData.append('banner_id', bannerData.banner_id)
                        formData.append('image', file);

                        fetch(`${GlobalURL}/api/dev/user/banner/updateBanner`, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                "ngrok-skip-browser-warning": "69420",
                            },
                            body: formData
                        })
                            .then((res) => {
                                Swal.fire(t("Editar!"), "", "success");
                                navigate(-1);
                            })
                            .catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                setEditBillboard(false);
                            })
                    } else {
                        nodes[0].innerText = prevTitle;
                        // nodes[1].innerText = prevDescription;
                        bannerData.description = oldDescription;
                        setOldDescription(oldDescription);
                        setFile(null);
                        setEditBillboard(false);
                    }
                });
            } else {
                // console.log("nothing changes....")
            }
        } else {
            //toogle the editable state 
            setEditBanner(true);
            // editBtn.innerHTML = 'Guardar cambios';
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    const checkType = (url) => {
        console.log("ct called: ");
        if (file) {
            if (images.includes(url?.split('.')?.at(-1))) {
                return <img src={URL.createObjectURL(file)} alt="" />
            } else {
                console.log("in else, ", URL.createObjectURL(file))
                return <video src={URL.createObjectURL(file)} alt="" controls />
            }
        } else {
            let extention = url?.split('.')?.at(-1);
            let res = images.find((ext) => {
                return extention.includes(ext);
            }
            )
            if (!url) {
                return <img src='' alt="" />
            }
            else if (images.includes(url?.split('.')?.at(-1)) || res) {
                return <img src={url} alt="" />
            } else {
                return <video src={url} alt="" controls />
            }
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='editarbannerPage'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='editarbanner-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }} >
                                                <img src="../../img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>{t('Cartelera')}</h3>
                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>

                                        <div className='billboard-sec'>
                                            <div className='editorBannerSec'>
                                                <Row>
                                                    <Col xs lg="4"></Col>

                                                    <Col xs lg="4" >
                                                        <div className='myTeamBanner billboard-cards' >
                                                            <div className='card-heading'>
                                                                <h3>
                                                                    {`BANNER DE ${bannerData.banner_type === 'miteam' ? 'MI EQUIPO' : 'MI PALCO'}`}
                                                                </h3>
                                                            </div>
                                                            <div className='card-img editnewscard-img'>
                                                                {file
                                                                    ? checkType(bannerData.imageUrl)
                                                                    : (bannerData.imageUrl) && checkType(bannerData.imageUrl)
                                                                }
                                                                {/* {
                                                                    file
                                                                        ? <img src={URL.createObjectURL(file)} alt="" />
                                                                        : <img src={bannerData.imageUrl} alt="" />
                                                                } */}
                                                                {editBanner && (
                                                                    <div className='img-edit-icon'>
                                                                        <GrUpload />
                                                                        <input id="uploadFile" onChange={handleFileSelect}
                                                                            type="file" className="upload" />
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                            <div className='card-body'>
                                                                <h5 className='edit-sec'>{bannerData.title.toUpperCase()}</h5>
                                                                <div className='d-flex justify-content-between '>
                                                                    <h4>{t('Descripción')}</h4>
                                                                    {editBanner
                                                                        && <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                                            <FaPenClip />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className='edit-exp-data'>
                                                                    <p className='desc '
                                                                        dangerouslySetInnerHTML={{ __html: bannerData?.description }}>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='space-between-btn'>
                                                <button className='blue-btn' onClick={handleEditBanner}>
                                                    {/* EDITAR */}
                                                    {editBillboard
                                                        ? (<span className="loaderbtn signup-btn " disbaled >
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                        </span>
                                                        )
                                                        : (<span>
                                                            {t('Editar')}
                                                        </span>
                                                        )
                                                    }
                                                </button>
                                                <button className='red-btn' onClick={handleBannerDeletion}>
                                                    {delBtn
                                                        ? (<span className="red-btn loaderbtn signup-btn " disbaled >
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                        </span>
                                                        )
                                                        : (<span>
                                                            {t('Eliminar cartelera')}
                                                        </span>
                                                        )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>

                            <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                <Modal.Header className="pb-0 mb-0" closeButton >
                                    <Modal.Title>{t('Editar')}</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>

                                    <div className="custom-text-editor w-100">
                                        <div className="toolbar">
                                            <button onClick={handleBold}>
                                                <img src="../../img/bold.png" alt="bold" />
                                            </button>
                                            <button onClick={handleItalic}>
                                                <img src="../../img/italic.png" alt="italic" />
                                            </button>
                                            <button onClick={handleStrikeThrough}>
                                                <img src="../../img/strikethrough.png" alt="strikethrough" />
                                            </button>
                                            <div className="text-align-buttons">
                                                <button onClick={() => handleTextAlign('Left')}>
                                                    <img src="../../img/left-align.png" alt="left-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Center')}>
                                                    <img src="../../img/center-align.png" alt="center-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Right')}>
                                                    <img src="../../img/right-align.png" alt="right-align" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="editor"
                                            ref={editorRef}
                                            contentEditable
                                            // onInput={handleChange}
                                            // onChange={handleChange}
                                            dangerouslySetInnerHTML={
                                                bannerData.description
                                                    ? ({ __html: bannerData.description })
                                                    : ({ __html: editorContent })
                                            }
                                        ></div>

                                    </div>
                                </Modal.Body>

                                <Modal.Footer className='border-0 p-0'>
                                    <div className='news-btn-sec'>
                                        <button className='green-btn' onClick={handleGuardarClick}>
                                            {t('GUARDAR')}
                                        </button>
                                    </div>
                                </Modal.Footer>

                            </Modal>

                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditorBannerPage
