import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
// import GlobalURL from '../../BaseURL';
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const DigitalLeader = () => {
    // const [langKey, setLangKey] = useState('es');
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // console.log('translationnn: ', language);
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const digitalManagerData = useLocation().state.digitalManagerData;
    const gemsCount = useLocation().state.gemsCount;
    const [gemsHistory, setGemsHistory] = useState();
    const [errMsg, setErrMsg] = useState(false);
    // console.log('gemsCount: ', gemsCount);

    const dayList = ['domingo',
        'Lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado']

    // gems icon json
    const gemsExpend = [
        {
            spendType: "vote on",
            iconSrc: "../img/propuestas.png"
        },
        {
            spendType: "avail insignia",
            iconSrc: "../img/propuestas.png"
        },
        {
            spendType: "Purchased",
            iconSrc: "../img/chaticon.png"
        },
        {
            spendType: "Experience",
            iconSrc: "../img/VIVENCIASMESS_ICE.png"
        },
        {
            spendType: "Mesa Chica proposal",
            iconSrc: "../img/chaticon.png"
        }
    ]

    //get icons related to sent & spent history
    const getImgIcon = (item) => {
        for (let data of gemsExpend) {
            if (item.credit_source?.toLowerCase().includes(data.spendType.toLowerCase())) {
                return <img src={data.iconSrc} alt="" />
            }
            else if (item.debit_source?.toLowerCase().includes(data.spendType.toLocaleLowerCase())) {
                return <img src={data.iconSrc} alt="" />
            }
        }
    }

    //get gems history
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getGemsHistory`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ user_id: digitalManagerData.user_id })
        })
            .then(async (response) => {
                console.log("statuss: ", response)
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    setErrMsg(true)
                    Swal.fire("Error", t("¡No obtiene historial de gemas!"), "error");
                }
            })
            .then(res => {
                console.log("ress: ", res.data);
                setGemsHistory(res.data.history)
            })
            .catch((err) => {
                setErrMsg(true)
                console.log("error occured: ", err);
                Swal.fire("Error", t("¡No obtiene historial de gemas!"), "error");
            });
        // eslint-disable-next-line
    }, [digitalManagerData])

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='digital-leader-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='digi-leader-sec'>
                                        <div className="news-header">
                                            <div className="back-sec" alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                                <img src="../img/volver.png" alt="" />
                                            </div>
                                            <h3 className="header-title">{t("DIRIGENTE DIGITAL")}</h3>
                                            <div className="search-icon">
                                            </div>
                                        </div>

                                        <div className='digileader-container'>
                                            <div className='digi-profile-sec'>
                                                <div className="center-head">
                                                    {digitalManagerData?.user?.image_url
                                                        ? <img src={digitalManagerData?.user?.image_url} alt="" />
                                                        : <img src='../img/str4.png' alt="" />
                                                    }
                                                    <h3>
                                                        {digitalManagerData?.user?.first_name.toUpperCase()} &nbsp;
                                                        {digitalManagerData?.user?.last_name.toUpperCase()}
                                                    </h3>
                                                </div>
                                                <div className='games-sec'>
                                                    <div className="gems">
                                                        <img src="../img/Gemas.png" alt="gem" />
                                                        {gemsCount}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='gems-movements-sec'>
                                                <h3 className='gems-movements'>
                                                    {t("Movimientos de Gemas")}
                                                </h3>
                                                <div className='gems-card-list '>
                                                    {(gemsHistory)
                                                        ? (gemsHistory?.map((item, i) => {
                                                            if (item.credit) {
                                                                return (
                                                                    <div className='gems-card ' key={i}>
                                                                        <div className='left-side'>

                                                                            <div className='img-sec'>
                                                                                {getImgIcon(item)}
                                                                            </div>

                                                                            <div className='details-sec'>
                                                                                {/* <h5>{item.credit_source}</h5> */}
                                                                                <h5>
                                                                                    {language === "es"
                                                                                        ? (<>{item?.title?.title_es}</>)
                                                                                        : (<>{item?.title?.title_en}</>)
                                                                                    }
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className='right-side'>
                                                                            <p className='increasing'>+
                                                                                {item.updated_gems_count - item.intital_gems_count}
                                                                            </p>
                                                                            <p className='sub-hed'>
                                                                                {dayList[new Date(item.createdAt
                                                                                    .slice(0, item.createdAt.indexOf('T')))
                                                                                    .getDay()]
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <div className='gems-card' key={i}>
                                                                        <div className='left-side'>
                                                                            <div className='img-sec'>
                                                                                {getImgIcon(item)}
                                                                            </div>
                                                                            <div className='details-sec'>
                                                                                {/* <h5>{item.debit_source}</h5> */}
                                                                                <h5>{
                                                                                    language === "es"
                                                                                        ? (<>{item?.title?.title_es}</>)
                                                                                        : (<>{item?.title?.title_en}</>)
                                                                                }
                                                                                </h5>
                                                                                {/* <p>{t("enlace a la propuesta")}</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='right-side'>
                                                                            <p className='decreasing'>
                                                                                {
                                                                                    item.updated_gems_count - item.intital_gems_count
                                                                                }
                                                                            </p>
                                                                            <p className='sub-hed'>
                                                                                {dayList[new Date(item.createdAt
                                                                                    .slice(0, item.createdAt.indexOf('T'))).getDay()]
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        )
                                                        : (
                                                            (!(gemsHistory?.length === 0 || gemsHistory === null))
                                                                ? (
                                                                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                                        {errMsg
                                                                            ? (<div className="text-danger">
                                                                                {t("¡Error Interno del Servidor!")}
                                                                            </div>
                                                                            )
                                                                            : (<>
                                                                                <div className="spinner-border m-5" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className=" my-5" > &nbsp;
                                                                                    {t("Cargando")}...
                                                                                </div>
                                                                            </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                                : (<div className="Loader d-flex justify-content-center mx-auto">
                                                                    <div className="text-primary">
                                                                        {t("No hay historial de gemas disponible")}.
                                                                    </div>
                                                                </div>
                                                                )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalLeader
