import React from 'react';
import { Chart } from "react-google-charts";

const BarChart = ({ optionsList }) => {
    const data = [
        ["Element", "", { role: "style" }],
        ["likes", optionsList.likes_count || 2, "#25762e"],
        ["dislike", optionsList.dislikes_count || 5, "#0b5eaa"],
        ["share", 1, "#800b0f"],
    ];
    // const data = [
    //     ["Data", "Counts"],
    //     ["likes", optionsList.likes_count],
    //     ["Dislike", optionsList.dislikes_count],
    //     ["share", 6],
    // ];

    const options = {
        backgroundColor: 'transparent',
    };

    console.log("from barchart: ", optionsList);

    return (
        <div className='bar-chart-content'>
            <div className='bar-char-head'>
            Propuesta
            </div>
            <div className='bar-char-body'>
            {optionsList.title}
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="200px"
                    data={data}
                    options={options}
                />
            </div>
        </div>
    )
}

export default BarChart;