import { useLocation, useNavigate } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaRegCommentAlt } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";
// import { FiSearch } from "react-icons/fi";
import { FaPenClip } from "react-icons/fa6";
import { GrUpload } from "react-icons/gr";
import Modal from 'react-bootstrap/Modal';
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';
import 'swiper/css';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const EditYouthNews = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    const [news, setNews] = useState({});

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState(null);
    const [editNews, setEditNews] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    // const [oldDescription, setOldDescription] = useState(news.content);
    const [updatedContentState, setUpdatedContentState] = useState(false);
    const [allComments, setAllComments] = useState(location.state.news_comments);

    // const [searchParams, setSearchParams] = useSearchParams();
    // console.log('searchParams: ', searchParams);
    // searchParams.append("id", location.state.news_id);
    // console.log("setter func: ", setSearchParams);

    const formData = new FormData();
    const editorRef = useRef(null);

    useEffect(() => {
        setNews(location.state);
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    // Handle logic for saving the editor content    
    const handleGuardarClick = () => {
        // news.content = editorRef.current.innerText;
        setUpdatedContentState(true);
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerHTML);
        handleClose();
    };

    // handle news deletion
    const handleNewsDeletion = () => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¿Quieres Editar Noticias?'),
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `Cancel`
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${GlobalURL}/api/news/removenews`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                        "news_id": `${news.news_id}`
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            Swal.fire("Deleted!", "", "success");
                            navigate(-1);
                        }
                        else {
                            Swal.fire("Deleted!", "", "error");

                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        });
    }

    //handle edit news
    const handleEditNews = () => {
        const editBtn = document.querySelector(".blue-btn");
        const nodes = document.querySelectorAll('.edit-sec');
        if (editNews) {
            //make editable state - true
            let prevContent, prevTitle, newContent, newTitle;
            setEditNews(false);
            prevTitle = news.title;
            prevContent = news.content;
            newTitle = nodes[0].innerText;
            // newContent = nodes[1].innerText;

            if (editorContent.trim())
                newContent = editorContent;
            else
                newContent = news.content;

            console.log('newContent: ', newContent);
            console.log('prevContent: ', prevContent);
            console.log('prevContent==new: ', prevContent !== newContent);
            console.log('prevTitle !== newTitle: ', prevTitle !== newTitle);

            console.log('newTitle: ', newTitle);
            console.log('prevTitle: ', prevTitle);

            editBtn.innerHTML = 'EDITAR';

            for (let element of nodes) {
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }
            if ((prevContent !== newContent) || (prevTitle !== newTitle) || (file)) {
                Swal.fire({
                    title: t("Estas seguro"),
                    text: t('¿Quieres Editar Noticias!'),
                    showDenyButton: true,
                    confirmButtonText: t("Editar Noticias"),
                    denyButtonText: `Cancel`
                }).then((result) => {
                    if (result.isConfirmed) {
                        formData.append('title', newTitle)
                        formData.append('content', newContent)
                        if (file) {
                            // formData.append('media', file);
                            for (let fileObj of file) {
                                console.log("obj--: ", file)
                                // formData.append("media", location.state.news_media[0]);
                                formData.append("media", fileObj);
                            }
                        }
                        else
                            formData.append('media', news.news_media[0].url);
                        console.log('formData: ', formData);

                        fetch(`${GlobalURL}/api/news/editnews`, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                "ngrok-skip-browser-warning": "69420",
                                "news_id": `${news.news_id}`
                            },
                            body: formData
                        }).then((res) => {
                            if (res.status === 200) {
                                Swal.fire("Edit!", "", "success");
                                navigate(-1);
                            }
                            else {
                                Swal.fire("Edit", t("¡Error al actualizar!"), "error")
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                    } else {
                        // nodes[1].innerText = prevContent;
                        // console.log('oldDescription: ', oldDescription);
                        nodes[0].innerText = prevTitle;
                        // news.content = oldDescription;
                        news.content = prevContent;
                        setUpdatedContentState(false);
                        // setOldDescription(oldDescription);
                        setFile(null);
                    }
                });
            }
        } else {
            //toogle the editable state 
            setEditNews(true)
            editBtn.innerHTML = 'Guardar cambios';
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const handleFileSelect = (e, item) => {
        console.log("e.name: ", item);
        console.log("clicked file number: ", e)
        console.log("before update: ", file);
        setFile(e.target.files);
        console.log('e.target.files: ', e.target.files);
    };

    //handle comment delete
    const handleCommentDel = (e) => {
        try {
            Swal.fire({
                title: "Are you sure?",
                text: 'Do you want to Delete Comment!',
                showDenyButton: true,
                confirmButtonText: "Delete Comment",
                denyButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(`${GlobalURL}/api/news/removecomment`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                            "ngrok-skip-browser-warning": "69420",
                            "comment_id": `${e.target.id}`
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire("Deleted!", "", "success");
                                const delCmtIndex = allComments.findIndex((cmt) => {
                                    return (cmt.comment_id === Number(e.target.id))
                                })
                                setAllComments([...allComments.slice(0, delCmtIndex), ...allComments.slice(delCmtIndex + 1)]);
                            }
                            else {
                                Swal.fire("Deleted!", "¡Error al eliminar el comentario!", "error");
                            }
                        })
                        .catch((err) => {
                            console.log("error occured: ", err);
                            Swal.fire("Deleted!", "¡Error al eliminar el comentario!", "error");
                        })
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    const checkType = (url) => {
        if (file) {
            for (let x of file) {
                console.log('x: ', x);
                return <img src={URL.createObjectURL(x)} alt="" />
            }
        } else {
            let extention = url?.split('.')?.at(-1);
            let res = images.find((ext) => {
                return extention.includes(ext);
            }
            )
            if (!url) {
                return <img src='' alt="" />
            }
            else if (images.includes(url?.split('.')?.at(-1)) || res) {
                return <img src={url} alt="" />
            } else {
                return <video src={url} alt="" controls />
            }
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='edit-news-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3">
                                    <div className='comments-sec'>
                                        <div className='comment-sec-heading'>
                                            <h2>{t('COMENTARIOS')}</h2>
                                        </div>
                                        <div className='user-comment-area'>
                                            {(allComments && allComments.length > 0) ? (
                                                allComments.map(cmt => {
                                                    return (<>
                                                        <div className='user-comment' key={cmt.comment_id}>
                                                            <div className='left-sec'>
                                                                <div className='user-data'>
                                                                    <div className='user-img'>
                                                                        <img src={cmt.user
                                                                            && (cmt.user.image_url === null ? '' : cmt.user.image_url)}
                                                                            alt="" />
                                                                    </div>
                                                                    <div className='user-content'>
                                                                        <h5 className='user-name'>
                                                                            {cmt.user && cmt.user.first_name} {cmt.user && cmt.user.last_name}
                                                                            <span>
                                                                                &nbsp; @{(cmt.user && cmt.user.userName) || 'anónimo'}
                                                                            </span>
                                                                        </h5>
                                                                        <p>{cmt.content}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='notification-sec'>
                                                                    <span className='comment-sec'>
                                                                        <FaRegCommentAlt /> <span className='count'>
                                                                            {cmt.cmt_count > 0 ? cmt.cmt_count : "10"}</span>
                                                                    </span>
                                                                    <span className='like-sec'>
                                                                        <AiOutlineLike /> <span className='count'>
                                                                            {cmt.likes_count}
                                                                        </span>
                                                                    </span>
                                                                    <span className='share-sec'>
                                                                        <IoShareOutline /> <span className='count'>{0}</span>

                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className='right-sec'>
                                                                <span className='delete-icon' onClick={handleCommentDel} style={{ cursor: "pointer" }}>
                                                                    <img src="../../img/delete.png" alt="" id={cmt.comment_id} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })
                                            ) : (
                                                <h4>{t('NO COMENTARIOS')}. . .</h4>
                                            )
                                            }

                                        </div>
                                    </div>
                                </Col>

                                <Col xs lg="6">
                                    <div className='edit-news-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                                <img src="../../img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>{t('Editar Noticia')}</h3>
                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>
                                        <div className='editnews-Content'>
                                            <div className='editnews-card'>
                                                <h3 contentEditable='false' className='edit-sec'>
                                                    {news.title}
                                                </h3>
                                                <div className='editnewscard-img'>

                                                    <Swiper navigation={true}
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                        slidesPerView={1}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        modules={[Autoplay, Pagination, Navigation]}
                                                        onSlideChange={() => console.log('slide change')}
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                    >
                                                        {news?.news_media?.map((item) => {
                                                            return (<SwiperSlide>
                                                                {file
                                                                    ? checkType(file.name)
                                                                    : (news.news_media && item.url) && checkType(item.url)
                                                                }
                                                                {(editNews && news.news_media.length === 1) && (
                                                                    <div className='img-edit-icon'>
                                                                        <GrUpload />
                                                                        <input id="uploadFile" onChange={(e) => handleFileSelect(e, item)}
                                                                            type="file" className="upload" />
                                                                    </div>
                                                                )
                                                                }
                                                            </SwiperSlide>)
                                                        })}
                                                    </Swiper>

                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                {/* <p>
                                                    Mittelfeldspieler&nbsp;
                                                    <a title="Paul Pogba" href="/DE_DE/player/122153" target="_blank">Paul Pogba</a>&nbsp;
                                                    von <a title="Juventus Turin" href="/DE_DE/club/506" target="_blank">Juventus Turin</a>
                                                    &nbsp;hat am Donnerstagnachmittag via Instagram auf die wenige Stunden zuvor verk&uuml;ndete vierj&auml;hrige Sperre wegen Dopings reagiert

                                                    (<a href="/DE_DE/articleDetails/434456" target="_blank">zur Meldung</a>).
                                                    Der 30-J&auml;hrige teilte mit, er sei &bdquo;schockiert&ldquo; &uuml;ber das Urteil. Der Franzose k&uuml;ndigte zudem an, Berufung einlegen zu wollen.

                                                </p> */}
                                                <h4>{t('Descripción')}</h4>
                                                {editNews
                                                    && <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                        <FaPenClip />
                                                    </div>
                                                }
                                            </div>

                                            {/* 
                                            <div className='editenews-data'>
                                                <p contentEditable='false' className='edit-sec'>{news.content}</p>
                                            </div> 
                                            */}

                                            <p className='desc '
                                                dangerouslySetInnerHTML={{ __html: (updatedContentState ? (editorContent) : (news?.content)) }}>
                                            </p>
                                        </div>
                                        <div className='editnews-btn-sec'>
                                            <button className='blue-btn' onClick={handleEditNews}>{t('EDITAR')}</button>
                                            <button className='red-btn' onClick={handleNewsDeletion}>{t('Eliminar Noticia')}</button>
                                            {/* <button className='green-btn' onClick={() => navigate(-1)}>GUARDAR</button> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>

                            <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                <Modal.Header className="pb-0 mb-0" closeButton >
                                    <Modal.Title>
                                        {t('Editar')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="custom-text-editor w-100">
                                        <div className="toolbar">
                                            <button onClick={handleBold}>
                                                <img src="../../img/bold.png" alt="bold" />
                                            </button>
                                            <button onClick={handleItalic}>
                                                <img src="../../img/italic.png" alt="italic" />
                                            </button>
                                            <button onClick={handleStrikeThrough}>
                                                <img src="../../img/strikethrough.png" alt="strikethrough" />
                                            </button>
                                            <div className="text-align-buttons">
                                                <button onClick={() => handleTextAlign('Left')}>
                                                    <img src="../../img/left-align.png" alt="left-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Center')}>
                                                    <img src="../../img/center-align.png" alt="center-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Right')}>
                                                    <img src="../../img/right-align.png" alt="right-align" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="editor"
                                            ref={editorRef}
                                            contentEditable
                                            // onInput={handleChange}
                                            // onChange={handleChange}
                                            dangerouslySetInnerHTML={
                                                news.content
                                                    ? ({ __html: news.content })
                                                    : ({ __html: editorContent })
                                            }
                                        ></div>

                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='border-0 p-0'>
                                    <div className='news-btn-sec'>
                                        <button className='green-btn' onClick={handleGuardarClick}>{t('GUARDAR')}</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditYouthNews
