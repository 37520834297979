import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef, useTransition } from 'react';
// import { FiSearch } from "react-icons/fi";
import { FaPenClip } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import GlobalURL, { LocalTunnel } from '../BaseURL';
// import { FANCY } from '../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const LagendProfile = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [delBtn, setDelBtn] = useState(false);
    const [legendProfileData, setLegendProfileData] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const [show, setShow] = useState(false);
    const [editBtn, setEditBtn] = useState(false)
    const [actualLegendLoad, setActualLegendLoad] = useState(false);
    const legendData = useLocation().state;
    const [editorContent, setEditorContent] = useState('');
    const [playerBiography, setPlayerBiography] = useState(legendProfileData?.player?.biography);
    // console.log('legendData: ', legendData);
    const [updateStatus, setUpdateStatus] = useState(false);
    const editorRef = useRef(null);

    const [allTeam, setAllTeam] = useState([]);

    const [newLegendValue, setNewLegendValue] = useState();
    const [countryData, setCountryData] = useState();
    const [isPending, startTransition] = useTransition();
    console.log('isPending: ', isPending);

    useEffect(() => {
        setNewLegendValue(legendProfileData?.player)
    }, [legendProfileData])

    const handleShow = () => setShow(true);

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content  
        setPlayerBiography(editorRef.current.innerText)
        // news.content = editorRef.current.innerText;
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerHTML);
        handleClose();
    };

    // handle legend deletion
    const handleLegendDeletion = () => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('Quieres borrar'),
            showDenyButton: true,
            confirmButtonText: t("Eliminar"),
            denyButtonText: t('cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                setDelBtn(true);
                fetch(`${GlobalURL}/api/dev/team_moderator/deleteLegend`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ legend_id: legendData.legend_id })
                })
                    .then((res) => {
                        console.log('res: ', res);
                        if (res.status === 200) {
                            Swal.fire("Deleted!", "", "success");
                            navigate(-1);
                        }
                        else {
                            Swal.fire("Deleted!", t("¡Error al eliminar!"), "error");
                        }
                        setDelBtn(false);
                    })
                    .catch((err) => {
                        setDelBtn(false);
                        console.log(err)
                    })
            }
        });
    }

    //get all team
    useEffect(() => {
        startTransition(() => {
            fetch(`${GlobalURL}/api/team/getteam`, {
                method: "GET",
                mode: "cors",
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.log("status: ", response);
                    }
                }).then(res => {
                    setAllTeam(res.data);
                    console.log('res.datpa: ', res.data);
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                })
        })
    }, [])

    // get legend profile data
    useEffect(() => {
        // const loadStatus = document.querySelector(".Loader");
        fetch(`${GlobalURL}/api/dev/team_moderator/getLegendDetails`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ legend_id: legendData?.legend_id })
        })
            .then(async (response) => {
                console.log('response: ', response);
                if (response.status === 200) {
                    return response.json();
                } else {
                    setActualLegendLoad(t('¡Error Interno del Servidor!'))
                }
            })
            .then(res => {
                // console.log('res: ', res.data);
                setLegendProfileData(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                setActualLegendLoad(t('¡Error Interno del Servidor!'))
            });
        // eslint-disable-next-line
    }, [legendData])

    //get all country
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/getCountries`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log("status: ", response)
            }
        }).then((resp) => {
            setCountryData(resp.data)
            console.log('resp.data: ', resp.data);
        }).catch((err) => {
            console.log("error occured: ", err);
        });
    }, [])

    //handle edit legend
    const handleUpdateLegend = () => {
        const nodes = document.querySelectorAll('.edit-sec');
        if (isUpdate) {
            //make editable state - true
            setEditBtn(false);
            setIsUpdate(false);
            setUpdateStatus(false);

            for (let element of nodes) {
                // console.log("data: ",element.id)
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }

            if (checkUpdation(legendProfileData.player, nodes)
                || legendProfileData?.player?.biography !== playerBiography) {
                let updateLegendData = {};
                nodes.forEach((item) => {
                    if (item.innerText !== 'N/A') {
                        updateLegendData[item.id] = item.innerText;
                    }
                })

                if (editorContent)
                    updateLegendData.biography = editorContent;
                else
                    updateLegendData.biography = legendProfileData?.player?.biography;

                Swal.fire({
                    title: t("Estas seguro"),
                    text: t('¿Quieres editar la leyenda?'),
                    showDenyButton: true,
                    confirmButtonText: t("Editar leyenda"),
                    denyButtonText: t('Cancelar')
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log("final object: ", { ...newLegendValue, legend_id: legendData.legend_id });

                        fetch(`${LocalTunnel}/api/dev/team_moderator/legend/updateProfile`, {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                "ngrok-skip-browser-warning": "69420",
                            },
                            body: JSON.stringify({ ...newLegendValue, legend_id: legendData.legend_id })
                        })
                            .then((res) => {
                                if (res.status === 200) {
                                    Swal.fire(t("Editar!"), "", "success");
                                    navigate(-1);
                                } else {
                                    nodes.forEach(item => {
                                        if (legendProfileData.player)
                                            item.innerText = legendProfileData.player[item.id] || 'N/A';
                                        else
                                            item.innerText = 'N/A';
                                    })
                                    Swal.fire(t("Editar!"), t("¡Error al editar los datos de la leyenda!"), "error");
                                }
                            })
                            .catch((err) => {
                                nodes.forEach(item => {
                                    if (legendProfileData.player)
                                        item.innerText = legendProfileData.player[item.id] || 'N/A';
                                    else
                                        item.innerText = 'N/A';
                                })
                                Swal.fire(t("Editar!"), t("¡Error al editar los datos de la leyenda!"), "error");
                                console.log(err)
                            })
                    } else {
                        // update perv data back in nodes
                        // if (legendProfileData?.player?.biography)
                        //     nodes[0].innerText = 'NO HAY BIOGRAFÍA DISPONIBLE...';
                        setEditorContent(legendProfileData?.player?.biography)

                        nodes.forEach(item => {
                            if (legendProfileData.player)
                                item.innerText = legendProfileData.player[item.id] || 'N/A';
                            else
                                item.innerText = 'N/A';
                        })
                    }
                });
            }
        } else {
            //toogle the editable state 
            setIsUpdate(true);
            setEditBtn(false);
            setUpdateStatus(true)
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const checkUpdation = (prevData, newData) => {
        let res = false;
        if (prevData) {
            console.log("legend pro: if")
            newData.forEach((item) => {
                // eslint-disable-next-line
                if (item?.innerText != (prevData[item?.id] || 'N/A')) {
                    res = true;
                }
            })
        } else {
            console.log("legend pro: else")
            res = true;
        }
        return res;
    }

    const handleNewLegendData = (e) => {
        setNewLegendValue({ ...newLegendValue, [e.target.name]: e.target.value });
    }

    const getCountryId = (e) => {
        let id;
        for (let item of countryData) {
            if (item.name === e.target.value) {
                // console.log('item: ', item);
                // name = item.name;
                id = item.id;
                break;
            }
        }
        // setCountryId(id);
        setNewLegendValue({ ...newLegendValue, [e.target.name]: id });
    }

    const getTeamId = (e) => {
        let id;
        for (let item of allTeam) {
            if (item.team_name === e.target.value) {
                // name = item.team_name;
                id = item.team_id;
                break;
            }
        }
        // setCountryId(id);
        setNewLegendValue({ ...newLegendValue, [e.target.name]: id });
    }

    const checkValue = (type, teamVal) => {
        console.log('type: ', type);
        if (type === 'team') {
            if (typeof teamVal === 'number') {
                for (let item of allTeam) {
                    if (item.team_id === Number(teamVal)) {
                        return item.team_name;
                    }
                }
            }
            return teamVal;
        } else {
            console.log('typeof teamVal: ', typeof Number(teamVal));
            if (typeof Number(teamVal) === 'number') {
                for (let item of countryData) {
                    // console.log('item: ', item);
                    if (item.id === Number(teamVal)) {
                        return item.name;
                    }
                }
            }
            return teamVal;
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='teamAdminisration-profile lagend_profile legend-page-main'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>

                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('LEYENDAS')}
                                        </h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className='profile-desc-sec '>

                                        <Row className='profile-row-sec'>
                                            <Col xs lg="2">
                                                {legendData?.follower_count
                                                    && (<div className='follower-sec text-center'>
                                                        <p>{t('Seguidores')}</p>
                                                        <p>{legendData?.follower_count}</p>
                                                    </div>)
                                                }
                                            </Col>

                                            <Col xs lg="8">
                                                <div className='user-admin-profile lpi-sec'>
                                                    <div className="user-admin-profile-img">
                                                        {console.log("pp: ", legendData)}
                                                        <img src={legendProfileData?.player?.image_url
                                                            ? `${legendProfileData?.player?.image_url}`
                                                            : '../img/blankimg.png'
                                                        }
                                                            alt="" />
                                                    </div>
                                                    <h5>
                                                        {legendData?.complete_name
                                                            ? `${legendData?.complete_name}`
                                                            : t('Desconocido')
                                                        }
                                                    </h5>
                                                </div>
                                            </Col>

                                            <Col xs lg="2"></Col>
                                        </Row>
                                    </div>
                                    <div className='admin-profile-tabs-sec'>

                                    </div>
                                    <div className='profile-tabs-sec legend-tabs'>
                                        <Tabs
                                            defaultActiveKey="biografia"
                                            id="fill-tab-example"
                                            fill
                                        >
                                            <Tab eventKey="biografia"
                                                // title="BIOGRAFÍA"
                                                title={<span><img src='./img/laureles ico.svg' alt='profile'></img> {t("BIOGRAFÍA")}</span>}>
                                                <div className='statistics-tabs'>
                                                    <div className='feild-position-sec'>
                                                        {legendProfileData
                                                            ? (legendProfileData?.player
                                                                ? (<>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h4>{t('Descripción')}</h4>
                                                                        {isUpdate
                                                                            && <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                                                <FaPenClip />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <p className='desc '
                                                                        dangerouslySetInnerHTML={
                                                                            {
                                                                                __html: editorContent
                                                                                    || legendProfileData?.player?.biography
                                                                            }}>
                                                                    </p>
                                                                    {/* <p className='edit-sec' id='biography' contentEditable='false'>
                                                                        {legendProfileData?.player?.biography}
                                                                    </p> */}
                                                                </>
                                                                )
                                                                : (<>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h4>{t('Descripción')}</h4>
                                                                        {isUpdate
                                                                            && <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                                                                <FaPenClip />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <p className='desc '
                                                                        dangerouslySetInnerHTML={{ __html: playerBiography }}>
                                                                    </p>
                                                                    {/* 
                                                                        <p className='edit-sec' id='biography' contentEditable='false'>
                                                                        {legendProfileData?.player?.biography}
                                                                        </p> 
                                                                    */}
                                                                </>
                                                                    // <h4 className='text-center mx-auto my-5 edit-sec'
                                                                    //     id='biography' contentEditable='false'>
                                                                    //     No hay biografía disponible...
                                                                    // </h4>
                                                                ))
                                                            : (
                                                                <div className="Loader d-flex justify-content-center align-items-center">
                                                                    {actualLegendLoad
                                                                        ? (<span className='err-msg'>{actualLegendLoad}</span>)
                                                                        : (<>
                                                                            <div className="spinner-border my-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('Cargando')}...
                                                                            </div>
                                                                        </>
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="perfil" title={<span><img src='./img/profile.svg' alt='profile'></img> {t("PERFIL")}</span>}>
                                                <div className='profile-sec-tabs'>
                                                    <div className='personal-info-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t('Datos Personales')}</h5>
                                                        </div>
                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Edad')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control d-flex'>
                                                                            <input type='text' placeholder={t('ingrese Edad')} name='age'
                                                                                value={newLegendValue?.age} onChange={handleNewLegendData} />años
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Edad')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data '>
                                                                            <span className='edit-sec' contentEditable='false' id='age'>
                                                                                {legendProfileData?.player?.age
                                                                                    ? legendProfileData?.player?.age
                                                                                    : 'N/A'
                                                                                }
                                                                            </span>
                                                                            &nbsp;años
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {(!updateStatus)
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Posición')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='position'
                                                                        // contentEditable='false'
                                                                        >
                                                                            {legendProfileData?.player?.position
                                                                                ? `${legendProfileData?.player?.position}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("Posición")}</p>
                                                                    </Col>

                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <select name='position'
                                                                                onChange={handleNewLegendData}
                                                                                value={newLegendValue?.position}
                                                                            >
                                                                                <option value=""> {t("seleccione Posición")} </option>
                                                                                <option value="goalkeeper"> {t("Portero")} </option>
                                                                                <option value="attacker"> {t("Adelante")} </option>
                                                                                <option value="midfielder"> {t("Mediocampista")} </option>
                                                                                <option value="defender"> {t("Defensor")} </option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                        }

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("país")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <input type="text" list="data"
                                                                            name='country'
                                                                            value={checkValue("country", newLegendValue?.country)}
                                                                            placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                            onChange={getCountryId} />
                                                                        <datalist id="data">
                                                                            {countryData?.map((item, key) =>
                                                                                <option key={key} id={item.id} value={item.name} />
                                                                            )}
                                                                        </datalist>
                                                                    </Col>

                                                                    <Col xs lg="2"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("país")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='country'
                                                                        // contentEditable='false'
                                                                        >
                                                                            {legendProfileData?.player?.country
                                                                                ? `${legendProfileData?.player?.country}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>

                                                                    <Col xs lg="2"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("otra nacionalidad")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <input type="text" list="data2"
                                                                            name='other_nationality'
                                                                            className='text-center otr-nation'
                                                                            value={checkValue("team", newLegendValue?.other_nationality)}
                                                                            placeholder={t('¡Selecciona el país!')}
                                                                            onChange={getCountryId} />
                                                                        <datalist id="data2">
                                                                            {countryData?.map((item, key) =>
                                                                                <option key={key} id={item.id} value={item.name} />
                                                                            )}
                                                                        </datalist>
                                                                    </Col>
                                                                    <Col xs lg="2"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("otra nacionalidad")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='country'
                                                                        // contentEditable='false'
                                                                        >
                                                                            {legendProfileData?.player?.other_nationality
                                                                                ? `${legendProfileData?.player?.other_nationality}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="2"></Col>
                                                                </Row>
                                                            </div>
                                                            )}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("género")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <select name='gender'
                                                                                onChange={handleNewLegendData}
                                                                                value={newLegendValue?.gender}>
                                                                                <option value="">{t("Seleccione género")}</option>
                                                                                <option value="male">{t("Masculino")}</option>
                                                                                <option value="female">{t("Femenino")}</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("género")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='gender'
                                                                        // contentEditable='false'
                                                                        >
                                                                            {legendProfileData?.player?.gender
                                                                                ? `${legendProfileData?.player?.gender}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )}

                                                        {updateStatus
                                                            ? (
                                                                <div className='personal-info-card'>
                                                                    <Row>
                                                                        <Col xs lg="3">
                                                                            <p className='info-heading'>{t("Pie preferido")}</p>
                                                                        </Col>
                                                                        <Col xs lg="6">
                                                                            <div className='form-control'>
                                                                                <input type='text' placeholder={t('ingrese Pie preferido')} name='preferred_foot'
                                                                                    value={newLegendValue?.preferred_foot} onChange={handleNewLegendData} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs lg="3"></Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Pie preferido')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='preferred_foot'
                                                                            contentEditable='false'>
                                                                            {legendProfileData?.player?.preferred_foot
                                                                                ? `${legendProfileData?.player?.preferred_foot}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Peso')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control d-flex'>
                                                                            <input type='text' placeholder={t('ingrese Peso')}
                                                                                name='weight'
                                                                                value={newLegendValue?.weight} onChange={handleNewLegendData} /> Kgs.
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Peso')}</p>
                                                                    </Col>

                                                                    <Col xs lg="6">
                                                                        <p className='info-data' contentEditable='false'>
                                                                            <span className='edit-sec' id='weight'>
                                                                                {legendProfileData?.player?.weight
                                                                                    ? legendProfileData?.player?.weight
                                                                                    : 'N/A'
                                                                                }
                                                                            </span>
                                                                            Kgs.
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Estatura')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control d-flex'>
                                                                            <input type='text' placeholder={t('ingrese Peso')}
                                                                                name='height'
                                                                                value={newLegendValue?.height} onChange={handleNewLegendData} /> Cms.
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Estatura')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data ' contentEditable='false'>
                                                                            <span className='edit-sec' id='height' >
                                                                                {legendProfileData?.player?.height
                                                                                    ? legendProfileData?.player?.height
                                                                                    : 'N/A'
                                                                                }
                                                                            </span>
                                                                            Cms.
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {!updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Fecha de nacimiento')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='date_of_birth'
                                                                            contentEditable='false'>
                                                                            {legendProfileData?.player?.date_of_birth
                                                                                ? `${legendProfileData?.player?.date_of_birth}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (
                                                                <div className='personal-info-card'>
                                                                    <Row>
                                                                        <Col xs lg="3">
                                                                            <p className='info-heading'>{t("Fecha de nacimiento")}</p>
                                                                        </Col>
                                                                        <Col xs lg="6">
                                                                            <div className='form-control'>
                                                                                <input type='date' placeholder={t('ingrese Fecha de nacimiento')} name='date_of_birth'
                                                                                    value={newLegendValue?.date_of_birth} onChange={handleNewLegendData} />
                                                                            </div>

                                                                        </Col>
                                                                        <Col xs lg="3"></Col>
                                                                    </Row>
                                                                </div>
                                                            )}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('País de nacimiento')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type='text' placeholder={t('ingrese Fecha de nacimiento')} name='place_of_birth'
                                                                                value={newLegendValue?.place_of_birth} onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('País de nacimiento')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='place_of_birth' contentEditable='false'>
                                                                            {legendProfileData?.player?.place_of_birth
                                                                                ? `${legendProfileData?.player?.place_of_birth}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                    </div>

                                                    <div className='personal-info-sec career-details-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t('Datos de su carrera')}</h5>
                                                        </div>

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("identificador de Twitter")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input placeholder={t('ingrese identificador de Twitter')} name='twitter_handle'
                                                                                value={newLegendValue?.twitter_handle} onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("identificador de Twitter")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='place_of_birth' contentEditable='false'>
                                                                            {legendProfileData?.player?.twitter_handle
                                                                                ? `${legendProfileData?.player?.twitter_handle}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo Actual')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <input type="text" list="data1"
                                                                            name='current_team'
                                                                            value={checkValue("team", newLegendValue?.current_team)}
                                                                            placeholder={t('¡seleccione el nombre del equipo!')}
                                                                            onChange={getTeamId} />
                                                                        <datalist id="data1">
                                                                            {allTeam.map((member, key) => {
                                                                                // console.log("fmdfc: ",allTeam)
                                                                                return <option key={key} id={member.team_id} value={member.team_name} />
                                                                            }
                                                                            )}
                                                                        </datalist>
                                                                    </Col>
                                                                    {/* <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type='text' placeholder={t('ingrese Equipo Actual')} name='current_team'
                                                                                value={newLegendValue?.current_team} onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo Actual')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='actual_team' contentEditable='false'>
                                                                            {legendProfileData?.player?.current_team
                                                                                ? `${legendProfileData?.player?.current_team}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Competición Actual')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type='text' placeholder={t('ingrese Equipo Actual')} name='current_competition'
                                                                                value={newLegendValue?.current_competition} onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Competición Actual')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='current_competition'
                                                                            contentEditable='false'>
                                                                            {legendProfileData?.player?.current_competition
                                                                                ? `${legendProfileData?.player?.current_competition}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo Anterior')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <input type="text" list="data1"
                                                                            name='previous_team'
                                                                            value={checkValue("team", newLegendValue?.previous_team)}
                                                                            placeholder={t('¡seleccione el nombre del equipo!')}
                                                                            onChange={getTeamId} />
                                                                        <datalist id="data1">
                                                                            {allTeam.map((member, key) => {
                                                                                // console.log("fmdfc: ",allTeam)
                                                                                return <option key={key} id={member.team_id} value={member.team_name} />
                                                                            }
                                                                            )}
                                                                        </datalist>

                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo Anterior')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='previous_team' contentEditable='false'>
                                                                            {legendProfileData?.player?.previous_team
                                                                                ? `${legendProfileData?.player?.previous_team}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Competición Anterior')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type='text' placeholder={t('ingrese Competición Anterior')} name='previous_competition'
                                                                                value={newLegendValue?.previous_competition} onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Competición Anterior')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' >
                                                                            {legendProfileData?.player?.previous_competition
                                                                                ? `${legendProfileData?.player?.previous_competition}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('valor neto')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input placeholder={t('ingrese valor neto')} name='net_worth'
                                                                                value={newLegendValue?.net_worth}
                                                                                onChange={handleNewLegendData} />
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('valor neto')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='historical_team' >
                                                                            {legendProfileData?.player?.net_worth
                                                                                ? `${legendProfileData?.player?.net_worth}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Elo')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input placeholder={t('ingrese Elo')} name='elo'
                                                                                value={newLegendValue?.elo}
                                                                                onChange={handleNewLegendData} />
                                                                        </div>

                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Elo')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='historical_team' >
                                                                            {legendProfileData?.player?.elo
                                                                                ? `${legendProfileData?.player?.elo}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>

                                                            )}

                                                        {!updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo histórico')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='historical_team'
                                                                        // contentEditable='false'
                                                                        >
                                                                            {legendProfileData?.player?.historical_team
                                                                                ? `${legendProfileData?.player?.historical_team}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Equipo histórico')}</p>
                                                                    </Col>

                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type="text" list="data4" name='historical_team'
                                                                                value={checkValue("team", newLegendValue?.historical_team)}
                                                                                placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                                onChange={getTeamId} />
                                                                            <datalist id="data4">
                                                                                {allTeam?.map((item, key) =>
                                                                                    <option key={key} id={item.team_id} value={item.team_name} />
                                                                                )}
                                                                            </datalist>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )
                                                        }

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("número de camiseta")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input placeholder={t('ingrese número de camiseta')} name='jersey_number'
                                                                                value={newLegendValue?.jersey_number}
                                                                                onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t("número de camiseta")}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' id='jersey_number' >
                                                                            {legendProfileData?.player?.jersey_number
                                                                                ? `${legendProfileData?.player?.jersey_number}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>
                                                            )}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Último Dorsal')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input placeholder={t('ingrese Último Dorsal')} name='ultimate_dorsal'
                                                                                value={newLegendValue?.ultimate_dorsal}
                                                                                onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Último Dorsal')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec' >
                                                                            {legendProfileData?.player?.ultimate_dorsal
                                                                                ? `${legendProfileData?.player?.ultimate_dorsal}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                        {updateStatus
                                                            ? (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Dorsal más común')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <div className='form-control'>
                                                                            <input type='text' placeholder={t('ingrese valor neto')} name='most_common_dorsal'
                                                                                value={newLegendValue?.most_common_dorsal}
                                                                                onChange={handleNewLegendData} />
                                                                        </div>
                                                                    </Col>

                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)
                                                            : (<div className='personal-info-card'>
                                                                <Row>
                                                                    <Col xs lg="3">
                                                                        <p className='info-heading'>{t('Dorsal más común')}</p>
                                                                    </Col>
                                                                    <Col xs lg="6">
                                                                        <p className='info-data edit-sec'  >
                                                                            {legendProfileData?.player?.most_common_dorsal
                                                                                ? `${legendProfileData?.player?.most_common_dorsal}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs lg="3"></Col>
                                                                </Row>
                                                            </div>)}

                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </Col>
                                <Col xs lg="3">
                                </Col>
                            </Row>

                            <div className="billboard-btn-sec">
                                <button className="blue-btn"
                                    onClick={handleUpdateLegend}
                                // onClick={() => navigate("/editlegend", { state: legendProfileData.player })}
                                >
                                    {editBtn
                                        ? (<>
                                            <span className="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                        </>)
                                        : t('Editar')
                                    }
                                </button>

                                {/* <button className="green-btn">Guardar</button> */}
                                <button className="red-btn" onClick={handleLegendDeletion}>
                                    {delBtn
                                        ? (<>
                                            <span className="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                        </>)
                                        : t('Eliminar')
                                    }
                                </button>
                            </div>

                            <Modal show={show} className="institutional-popup common-popup" onHide={handleClose} centered>
                                <Modal.Header className="pb-0 mb-0" closeButton >
                                    <Modal.Title>{t('Editar')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="custom-text-editor w-100">
                                        <div className="toolbar">
                                            <button onClick={handleBold}>
                                                <img src="./img/bold.png" alt="bold" />
                                            </button>
                                            <button onClick={handleItalic}>
                                                <img src="./img/italic.png" alt="italic" />
                                            </button>
                                            <button onClick={handleStrikeThrough}>
                                                <img src="./img/strikethrough.png" alt="strikethrough" />
                                            </button>
                                            <div className="text-align-buttons">
                                                <button onClick={() => handleTextAlign('Left')}>
                                                    <img src="./img/left-align.png" alt="left-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Center')}>
                                                    <img src="./img/center-align.png" alt="center-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Right')}>
                                                    <img src="./img/right-align.png" alt="right-align" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="editor"
                                            ref={editorRef}
                                            contentEditable
                                            // onInput={handleChange}
                                            // onChange={handleChange}
                                            dangerouslySetInnerHTML={
                                                legendProfileData?.player?.biography
                                                    ? ({ __html: editorContent || legendProfileData?.player?.biography })
                                                    : ({ __html: editorContent })
                                            }>
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer className='border-0 p-0'>
                                    <div className='news-btn-sec'>
                                        <button className='green-btn' onClick={handleGuardarClick}>{t('GUARDAR')}</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </Container >
                    </div >
                </div >
            </div >
        </>
    )
}

export default LagendProfile