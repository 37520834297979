import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import GlobalURL from '../BaseURL';
import Modal from 'react-bootstrap/Modal';
// import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';
import Swal from 'sweetalert2';

const BillboardAddPost = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [banner, setBanner] = useState({ title: '', description: '', banner_type: '' });
    const [editorContent, setEditorContent] = useState('');
    const [sendBtn, setSendBtn] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const formData = new FormData();
    const [show, setShow] = useState(false);
    const editorRef = useRef(null);

    const handleClose = () => {
        // Close the modal
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleBold = () => {
        document.execCommand('bold', false, null);
    };

    const handleItalic = () => {
        document.execCommand('italic', false, null);
    };

    const handleStrikeThrough = () => {
        document.execCommand('strikeThrough', false, null);
    };

    const handleTextAlign = (alignment) => {
        document.execCommand('justify' + alignment, false, null);
    };

    const handleGuardarClick = () => {
        // Handle logic for saving the editor content    
        setEditorContent(editorRef.current.innerHTML);
        console.log('editorRef.current: ', editorRef.current.innerText);
        setBanner({ ...banner, description: editorRef.current.innerHTML })
        handleClose();
    };

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
        console.log('file: ', file);
        console.log(e.target.value);

        let mediaSize = (e.target.files[0].size)/1024;

        if(mediaSize <= 10240){
            // Swal.fire("Media under size limit!");
        }else{
            Swal.fire(t("¡El tamaño del archivo debe ser de hasta 6 MB!"));
            return;
        }

        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    };

    const handleBannerContent = (e) => {
        setBanner({ ...banner, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSendBtn(true)

        if (banner.title.length && banner.description.length && banner.banner_type.length) {
            formData.append("team_id", localStorage.getItem("team_id"))
            formData.append("image", file)

            for (let formfields in banner) {
                formData.append(formfields, banner[formfields]);
            }

            fetch(`${GlobalURL}/api/dev/user/banner/createBannerByTeam`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then((response) => {
                if (response.status === 200) {
                    navigate(-1);
                    setErrMsg(t("Cartelra añadido con éxito!"));
                    return response.json();
                } else {
                    setErrMsg(t('¡Error del Servidor!'));
                }
            }).catch((err) => {
                    setErrMsg(t('¡Error del Servidor!'));
                }).finally(() => {
                    setSendBtn(false);
                });
        } else {
            //apply error message for news if fields are empty
            setErrMsg(t('¡Campos requeridos!'));
            setSendBtn(false);
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='billboardAdd-post-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">

                                    <div className='billboardAdd-post'>

                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}>
                                                <img src="../img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>
                                                {t('Agregar Cartelera')}
                                            </h3>
                                            <div className='search-icon'>
                                            </div>
                                        </div>

                                        <div className='common-input-forms newsAddContent'>
                                            <form action="" onSubmit={handleSubmit}>

                                                <div className='common-form-control'>
                                                    <label>{t('Título')}</label>
                                                    <input placeholder={`${t('Inserte el título del Cartelera aquí')}`} name='title' required 
                                                        value={banner.title} onChange={handleBannerContent} />
                                                </div>

                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Foto / Video')}</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile" className="f-input" placeholder={`${t('Inserte la foto o video del post aquí')}`} />
                                                        <div className="fileUpload btn btn--browse">
                                                            <input id="uploadBtn" onChange={handleFileSelect} type="file" className="upload" accept='image/*, video/*' />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='common-form-control'>
                                                    <label>{t('Categoría')}</label>
                                                    <select placeholder={t('Seleccione la categoría')}
                                                        name='banner_type'
                                                        onChange={handleBannerContent}
                                                        value={banner.banner_type}
                                                    >
                                                        <option value="">{t('Seleccione la categoría')}</option>
                                                        <option value="miteam">{t('MI EQUIPO')}</option>
                                                        <option value="mibox">{t('MI PALCO')}</option>
                                                        <option value="splash video">{t('SPLASH')}</option>

                                                        {/* <option value="home">{t('HOME')}</option> */}
                                                        {/* <option value="store">{t('TIENDA')}</option> */}
                                                    </select>
                                                </div>

                                                <div className='common-form-control'
                                                    onClick={handleShow}>
                                                    <label>{t('Descripción')}</label>
                                                    <input type='text' placeholder={t('Inserte la descripción del post aquí')}
                                                        name='description' readOnly />
                                                </div>

                                                <div className='err-spn text-danger text-center'>
                                                    {errMsg}
                                                </div>

                                                <div className='news-btn-sec'>
                                                    {sendBtn
                                                        ? (
                                                            <button className="green-btn loaderbtn signup-btn " disabled >
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                            </button>
                                                        )
                                                        : (
                                                            <button type='submit' className='green-btn'>{t('Guardar')}</button>
                                                        )
                                                    }
                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                    <Modal show={show} className="institutional-popup common-popup"
                                        onHide={handleClose} centered>
                                            
                                        <Modal.Header className="pb-0 mb-0" closeButton >
                                            <Modal.Title>{t('Editar')}</Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body >
                                            <div className="custom-text-editor w-100">
                                                <div className="toolbar">
                                                    <button onClick={handleBold}>
                                                        <img src="../img/bold.png" alt="bold" />
                                                    </button>
                                                    <button onClick={handleItalic}>
                                                        <img src="../img/italic.png" alt="italic" />
                                                    </button>
                                                    <button onClick={handleStrikeThrough}>
                                                        <img src="../img/strikethrough.png" alt="strikethrough" />
                                                    </button>
                                                    <div className="text-align-buttons">
                                                        <button onClick={() => handleTextAlign('Left')}>
                                                            <img src="../img/left-align.png" alt="left-align" />
                                                        </button>
                                                        <button onClick={() => handleTextAlign('Center')}>
                                                            <img src="../img/center-align.png" alt="center-align" />
                                                        </button>
                                                        <button onClick={() => handleTextAlign('Right')}>
                                                            <img src="../img/right-align.png" alt="right-align" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className="editor"
                                                    ref={editorRef}
                                                    contentEditable
                                                    // onInput={handleChange}
                                                    // onChange={handleChange}
                                                    dangerouslySetInnerHTML={{ __html: editorContent }}
                                                ></div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className='border-0 p-0'>
                                            <div className='news-btn-sec'>
                                                <button className='green-btn' onClick={handleGuardarClick}>
                                                    {t('GUARDAR')}
                                                </button>
                                            </div>
                                        </Modal.Footer>
                                    </Modal>

                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillboardAddPost
