import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import './style.css';

const AdminPage = (props) => {
    const navigate = useNavigate();
    const [isWait, setIsWait] = useState(false);
    const [messageRequestData, setMessageRequestData] = useState(
        {
            title: '',
            message: '',
            team_id: localStorage.getItem("team_id")
        });

    const HandleMessageRequest = (e) => {
        setMessageRequestData({ ...messageRequestData, [e.target.name]: e.target.value });
    }

    const handleSubmitRequest = (e) => {
        e.preventDefault();
        setIsWait(true);

        fetch(`${GlobalURL}/api/dev/team_moderator/contactAdmin`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
                "team_id": localStorage.getItem("team_id")
            },
            body: JSON.stringify(messageRequestData)
        }).then((response) => {
                if (response.status === 200) {
                    navigate(-1);
                    setMessageRequestData({ title: '', message: '' });
                    Swal.fire("Success", "¡Solicitud enviada exitosamente!", "success");
                    return response.json();
                } else {
                    console.log("status: ", response)
                }
            })
            .catch(() => {
                Swal.fire("error", "¡Error Interno del Servidor!", "error");
            }).finally(() => {
                setIsWait(false)
            });
    }

    return (
        <>
            <div className='admin-page-sec vh-100'>
                <div className='login-wrapper'>
                    <div className='admin-container'>

                        {props.status === 'unAuth'
                            && (<div className='admin-logo'>
                                <img src="./img/LogoMess.png" alt="admin_logo" />
                            </div>)
                        }

                        <div className='admin-nav-sec' style={{ cursor: "pointer" }}
                            onClick={() => { navigate(-1, { replace: true }) }} >
                            <img src="./img/volver.png" alt="" />
                        </div>

                        <div className='admin-form'>
                            <form action="">

                                <div className='admin-form-control'>
                                    <input type="text" className='admin-input'
                                        name="title" placeholder='asunto'
                                        value={messageRequestData?.title}
                                        onChange={HandleMessageRequest} />
                                </div>

                                <div className='admin-form-control'>
                                    <input className='admin-input'
                                        placeholder='mensaje' readOnly />
                                </div>

                                <div className='admin-form-control'>
                                    <textarea className='admin-textarea'
                                        name='message' value={messageRequestData?.message}
                                        onChange={HandleMessageRequest}>
                                    </textarea>
                                </div>

                                {isWait
                                    ? (
                                        <button className="green-btn admin-form-btn loaderbtn signup-btn " disabled >
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span className="sr-only">&nbsp; Enviando...</span>
                                        </button>
                                    )
                                    : (
                                        <div className='admin-form-btn'>
                                            <button onClick={handleSubmitRequest}>Enviar</button>
                                        </div>
                                    )
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminPage;