import React, { useState } from 'react';
// import { auth } from '../../firebase';
import { Outlet } from "react-router-dom";
import Header from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const FullLayout = ({ userDetails }) => {
  const [flag, setFlag] = useState();
  const [flagStripColor, setFlagStripColor] = useState({});
  const [requestStripColor, setRequestStripColor] = useState();
  console.log('requestStripColor: in full layout ', requestStripColor);

  return (
    <>
      <Header userDetails={userDetails} />
      <Outlet context={{ setFlag, flagStripColor, setRequestStripColor }} />
      <Footer flag={flag} setFlagStripColor={setFlagStripColor} requestStripColor={requestStripColor} />
    </>
  );
};

export default FullLayout;
