import React, { useEffect, useState } from 'react';
import GlobalURL from '../../pages/BaseURL';
import './style.css'

// import { useSelector } from 'react-redux';
// import translations from '../../locals/translation';

const Footer = ({ flag, setFlagStripColor, requestStripColor }) => {
  console.log('flag 1stline: ', flag);
  // const [colorsUpdated, setColorsUpdated] = useState(false);

  const [flagStripeColor, SetFlagStripeColor] = useState();
  let team_id = localStorage.getItem("team_id");

  // const language = useSelector(state => state.language.value);
  // const t = (key) => {
  //   // Check if translations exist for the current language
  //   if (translations[language]) {
  //     // Access translation for the provided key
  //     const translation = translations[language][key];
  //     // Return translation if found, otherwise return the key itself
  //     return translation !== undefined ? translation : key;
  //   } else {
  //     // Return the key itself if translations for the current language are not available
  //     return key;
  //   }
  // };

  useEffect(() => {
    if (team_id) {
      fetch(`${GlobalURL}/api/dev/team_moderator/getThemeColorOfTeam`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ team_id })
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json();
          }
          else {
            console.log("error get flag colors ")
          }
        })
        .then(res => {
          SetFlagStripeColor(res.data);
          setFlagStripColor(res.data);
          console.log("res colourrss: ", res.data);
        })
        .catch((err) => {
          console.log("error get flag colors ");
        });
    }
    // eslint-disable-next-line
  }, [team_id,requestStripColor])

  return (
    <>
      <div className='footer'>
        <div className='footer-sec'>
          <div className='text-sec'>
            <p> {localStorage.getItem("team_name") || "MESS ICE"}</p>
          </div>

          <div className='footer-flag'>
            <div className='flag-img'>
              <img src={(flag || localStorage.getItem("flag")) || "./img/LogoMESS.png"} alt="" />
            </div>
            
            <div className='flag-colors'>
              <span className='grey-color'
                style={{ backgroundColor: `${(flagStripeColor?.theme_color1 || `#8297A0`)}` }}>
              </span>

              <span className='white-color'
                style={{ backgroundColor: `${(flagStripeColor?.theme_color2 || `#fff`)}` }}>
              </span>

              <span className='grey-color'
                style={{ backgroundColor: `${((flagStripeColor?.theme_color3 || flagStripeColor?.theme_color1) || `#8297A0`)}` }}>
              </span>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
