import { useLocation, useNavigate } from 'react-router-dom';
import { AudioRecorder } from 'react-audio-voice-recorder';
import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "react-chat-elements/dist/main.css";
import { socket } from '../../../socket';
import { FiPlus } from "react-icons/fi";
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const DigitalManager = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [media, setMedia] = useState(null);
    const [groupChat, setGroupChat] = useState();
    const digitalManagerData = useLocation().state;
    const [messageTyped, setMessageTyped] = useState("");
    const [userDetails, setUserDetails] = useState();
    console.log('userDetails: ', userDetails);

    // const [userChatDetails, setUserChatDetails] = useState({
    //     image_url: userDetails?.image_url,
    //     first_name: userDetails?.first_name,
    //     last_name: userDetails?.last_name
    // });

    const [mediaURL, setMediaURL] = useState();
    const [isWait, setIsWait] = useState();
    const [blockStatus, setBlockStatus] = useState(digitalManagerData?.user?.is_blocked);
    const [activeInsignia, setActiveInsignia] = useState();
    const [userInsigniaDetails, setUserInsigniaDetails] = useState();
    const [dmCountry, setDmCountry] = useState();

    const [chatBoxClicked, setChatBoxClicked] = useState(false);
    // const [audioMsg, setAudioMsg] = useState();

    const lastMessageRef = useRef(null);
    const inputRef = useRef(null);
    const formData = new FormData();

    const [userMembershipData, setUserMembershipData] = useState();

    // useEffect(() => {
    //     if (!userChatDetails.image_url && userDetails) {
    //         setUserChatDetails({
    //             image_url: userDetails?.image_url,
    //             first_name: userDetails?.first_name,
    //             last_name: userDetails?.last_name
    //         });
    //     }
    // }, [userDetails])

    //open chat box
    const connectSocket = () => {
        setChatBoxClicked(prev => !prev);
        document.querySelector(".chatBox-toggle").classList.toggle("showbox");
    }

    function handleclickinput(e) {
        inputRef.current.click();
    }

    // connecting socket
    useEffect(() => {
        socket.connect();
        socket.emit('connect_user', { user_id: localStorage.getItem("user_id") });
        socket.on('direct_messages_list', (data) => {
            let user_Details = data.users.find((item) => {
                // eslint-disable-next-line
                return item.user_id == digitalManagerData.user_id;
            })
            if (user_Details)
                setUserDetails(user_Details);
            else
                setUserDetails([]);
        })
        // eslint-disable-next-line
    }, [socket])

    //get chat history (moderator and user)
    useEffect(() => {
        if (chatBoxClicked) {
            console.log("get msg history: UE called")
            socket.emit("get_message_history",
                {
                    inbox_uid: userDetails?.inbox_details?.inbox_uid,
                    receiverId: digitalManagerData?.user_id
                });
            socket.on('message_history', (data) => {
                console.log('data getchat: ', data);
                if (data.messages?.length) {
                    setGroupChat(data.messages);
                }
                else {
                    setGroupChat([]);
                }

            })
            // socket.on('errorHandler', (data) => {
            //     if (data.includes('get_message_history')) {
            //         setGroupChat([]);
            //     }
            // })
            // eslint-disable-next-line
        }
        // eslint-disable-next-line
    }, [userDetails, chatBoxClicked])

    //listening incoming msg
    useEffect(() => {
        socket.on('privateMessage', (data) => {
            console.log("listen continuosly: ", data);
            // let newData = {...data}
            // console.log('{...data,user: userDetails}: ', {...data,user: {...userDetails}});
            // console.log("sdfksdfk: ",{...data,user:{userChatDetails?.image_url}});
            if (data) {
                // setGroupChat((prev) => [...prev, {...data,user:{image_url: userChatDetails?.image_url}}]);
                setGroupChat((prev) => [...prev, data]);
            }
            // setMessageTyped("");
        });
    }, [])

    //scroll to last msg after chats load
    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [groupChat])

    // display chats msg
    const renderChat = () => {
        console.log("chatsss:-- ", groupChat);

        if (groupChat) {
            if (groupChat.length) {
                return groupChat.map((msg, i) => {
                    //check message_chat type (img,video,text)
                    const checkMsgType = () => {
                        // console.log('msg.message_type: ', msg.message_type);
                        if (msg.message_type === 'TEXT') {
                            return <p>{msg?.message}</p>
                        } else if (msg.message_type === 'IMAGE') {
                            return <img className='chat_img' alt='' src={msg.multimedia_url} />
                        } else if (msg.message_type === 'VIDEO') {
                            return <video className='chat_img' src={msg.multimedia_url} controls />
                        } else if (msg.message_type === 'LINK') {
                            return <p>{msg?.message}</p>
                        } else if (msg.message_type === 'AUDIO') {
                            return <audio className='chat_img' src={msg.multimedia_url} controls />
                        } else if (msg.message_type === 'DOC') {
                            return (
                                <div className='d-flex doc-visual'>
                                    <a href={msg.multimedia_url} target='_blank' rel='noreferrer'>
                                        <img src='/img/file-earmark-text.svg' alt='doc'></img>
                                        <p>{msg.multimedia_url.split("/").at(-1)}</p>
                                    </a>
                                </div>
                            )
                        }
                    }

                    // console.log('msg.user_id: ', typeof msg.user_id);

                    //print msg.
                    // eslint-disable-next-line
                    if (msg.user_id == localStorage.getItem("user_id")) {
                        return (
                            <div className="msg right-msg" key={msg.id}
                                // eslint-disable-next-line
                                ref={((i + 1) == groupChat.length) ? lastMessageRef : null}
                            >
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className="msg-text">
                                            {checkMsgType()}
                                        </div>
                                        {/* 
                                            <div className="msg-text">
                                                <p>{msg?.message}</p>
                                            </div> 
                                        */}
                                    </div>
                                </div>
                                <div className="msg-img">
                                    <img src={msg?.user?.image_url ? msg?.user?.image_url : `./img/user.png`} alt='' />
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="msg left-msg" key={msg.id}
                                // eslint-disable-next-line
                                ref={((i + 1) == groupChat?.length) ? lastMessageRef : null}
                            >
                                <div className="msg-img">
                                    <img src={msg?.user?.image_url ? msg?.user?.image_url : `./img/user.png`} alt='' />
                                </div>
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className="msg-text">
                                            <div className="msg-user-name pb-2">
                                                {msg?.user?.first_name} {msg?.user?.last_name}
                                            </div>
                                            {checkMsgType()}
                                            {/* <p>{msg?.message}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            } else {
                return (
                    <div className="Loader d-flex justify-content-center">
                        <div className="my-5" role="status">
                            {/* <span className="sr-only"></span> */}
                        </div>
                        <div className=" my-5 my-auto" >
                            {t("iniciar conversación")}. . .
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="Loader d-flex justify-content-center align-items-center">
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <div className=" my-5 my-auto" > &nbsp;
                        {t("Cargando")}...
                    </div>
                </div>
            )
        }
    }

    //handle send message
    const handleSendMessage = (e) => {
        e.preventDefault();
        const messageHistory = (data) => {
            console.log("message history:- ", data);
            setMessageTyped("");
            setGroupChat(data.messages);
        }

        let uid = digitalManagerData.user_id;
        let msgParameter = {
            receiverId: uid,
            message: messageTyped,
            message_type: "TEXT"
        }
        if (messageTyped?.trim()?.length) {
            socket.emit('send_private_message_moderator', msgParameter);
            socket.on('message_history', messageHistory);
        } else if (mediaURL) {
            if (media.type.includes('image')) {
                msgParameter.message_type = 'IMAGE';
            } else if (media.type.includes('video')) {
                msgParameter.message_type = 'VIDEO';
            } else if (media.type.includes('application') || media.type.includes('text')) {
                msgParameter.message_type = 'DOC';
            } else if (media.type.includes('audio')) {
                msgParameter.message_type = 'AUDIO';
            }
            msgParameter.media_url = mediaURL;
            socket.emit('send_private_message_moderator', msgParameter);
            socket.on('message_history', messageHistory);
            setMedia(null);
            setMediaURL(null);
        }
    }

    //get multimedia URL.
    const handleMediaSelection = (e) => {
        if (e)
            setMedia(e.target.files[0])
        // console.log('e.target.files[0]: ', e.target.files[0]);
        console.log('media: ', media);
        // console.log('audio : ', audioMsg);

        if (e || media) {
            setIsWait(true);
            if (e)
                formData.append("media", e.target.files[0]);
            else
                formData.append("media", media);
            formData.append("inbox_uid", userDetails?.inbox_details?.inbox_uid)

            fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then(function (res) {
                if (res.ok) {
                    return res.json()
                }
            }).then((resp) => {
                setIsWait(false)
                console.log("media bmc url: ", resp.data);
                setMediaURL(resp.data);
            }).catch(function (e) {
                console.log(e)
                setIsWait(false);
                Swal.fire(t("Error, No se pueden compartir medios."));
            });
        } else {
            console.log("seleccione medios!");
        }
    }

    const handleMediaSelectionAudio = (audio) => {
        setIsWait(true);
        formData.append("media", audio);
        formData.append("inbox_uid", userDetails?.inbox_details?.inbox_uid)

        fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: formData
        }).then(function (res) {
            if (res.ok) {
                return res.json()
            }
        }).then((resp) => {
            setIsWait(false)
            console.log("media bmc url: ", resp.data);
            setMediaURL(resp.data);
        }).catch(function (e) {
            console.log(e)
            setIsWait(false);
            Swal.fire(t("Error, No se pueden compartir medios."));
        });
    }

    //get media preview before sending in chat
    const getMediaPreview = () => {
        let mType = media?.type;
        console.log('mType: ', mType);
        if (mType.includes('image')) {
            return <img src={mediaURL} alt={media.name}></img>
        }
        else if (mType.includes('video')) {
            return <video src={mediaURL} width='180px' />
        }
        else if (mType.includes('audio')) {
            return <audio src={mediaURL} alt={media.name} controls ></audio>
        }
        else if (mType.includes('application') || mType.includes('text')) {
            return (<div className='doc-preview'>
                <img src='/img/file-earmark-text.svg' alt='doc'></img>
                <p className='doc-para'>{media?.name}</p>
            </div>
            )
        }
    }

    //get insignia details
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getInsigniaByUserId`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ user_id: digitalManagerData?.user?.user_id })
        }).then(function (res) {
            if (res.ok) {
                return res.json()
            } else {
                console.log('error while geting user insignia badges: ');
                // Swal.fire("Error, Mientras obtiene detalles de Insignia.");
            }
        }).then((resp) => {
            setUserInsigniaDetails(resp.data);
        }).catch(function (e) {
            console.log('error while geting user insignia badges: ', e);
            // Swal.fire("Error, Mientras obtiene detalles de Insignia.");
        });
    }, [digitalManagerData])

    //get insignia details
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getUserMembershipDetailsTeammod`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify(
                {
                    user_id: digitalManagerData?.user?.user_id,
                    team_id: localStorage.getItem("team_id")
                })
        }).then(function (res) {
            if (res.ok) {
                return res.json()
            } else {
                console.log('error while geting user insignia badges: ');
                // Swal.fire("Error, Mientras obtiene detalles de Insignia.");
            }
        }).then((resp) => {
            setUserMembershipData(resp.data.membership);
            setDmCountry(resp.data.country)
            console.log('resp.data: ', resp.data);
        }).catch(function (e) {
            console.log('error while geting user insignia badges: ', e);
            // Swal.fire("Error, Mientras obtiene detalles de Insignia.");
        });
    }, [digitalManagerData])

    //generate the insignia list
    useEffect(() => {
        console.log('**userInsigniaDetails: ', userInsigniaDetails);
        if (userInsigniaDetails) {
            let tempInsigniaList = {};
            userInsigniaDetails.map(insignia => {
                let vali = insignia.insignium.insignia_name_es.toUpperCase().trim();
                tempInsigniaList[vali] = insignia.insignium.insignia_icon;
                // setActiveInsignia({
                //     ...activeInsignia,
                //     [insignia.insignium.insignia_name_es.toUpperCase().trim()]: insignia.insignium.insignia_icon
                // });
                return null;
            })
            console.log("inssgg:: ",tempInsigniaList)
            setActiveInsignia(tempInsigniaList);
        }
        // eslint-disable-next-line 
    }, [userInsigniaDetails])

    //handle block & unblock user
    const handleBlockManager = () => {
        console.log("clicked ");
        fetch(`${GlobalURL}/api/dev/team_moderator/blockUser`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify(
                {
                    user_id: digitalManagerData?.user?.user_id,
                    team_id: localStorage.getItem("team_id")
                })
        }).then(function (res) {
            if (res.ok) {
                setBlockStatus((!blockStatus));
                return res.json()
            } else {
                Swal.fire("Error", t("No se puede bloquear al usuario!"));
            }
        }).then((resp) => {
            // console.log("response: ",resp);
            if (resp.message.includes("unblocked"))
                Swal.fire("Success", t("Dirigente digital Desbloquear con éxito"));
            else
                Swal.fire("Success", t("Dirigente digital bloquear con éxito"));

        }).catch(function (e) {
            Swal.fire("Error", t("No se puede bloquear al usuario!"));
        });
    }

    const addAudioElement = (blob) => {
        const audioFile = new File([blob], 'recorded_audio.webm', { type: 'audio/webm' });
        setMedia(audioFile);
        handleMediaSelectionAudio(audioFile);
    };

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='digital-manager-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3">
                                    <div className='membersias-sec'>

                                        <div className="gems"
                                            onClick={() => navigate('/digitalmanager/gemshistory',
                                                { state: { digitalManagerData, gemsCount: digitalManagerData?.gems_count } })
                                            }
                                            style={{ cursor: "pointer" }}>
                                            <img src="../../img/Gemas.png" alt="gem" />
                                            {digitalManagerData?.gems_count} Gemas
                                        </div>

                                        <div className="available-membership">
                                            <h6>
                                                {t("membresías disponibles")}
                                            </h6>
                                            <ul className='available-body'>
                                                <li className={(userMembershipData?.gold?.count) ? "able" : 'disable'}>̵
                                                    <span>
                                                        <img src="../../img/gold-bar.png" alt='' />
                                                        Oro
                                                    </span>
                                                    <span>
                                                        {userMembershipData?.gold?.count
                                                            ? (`x${userMembershipData.gold.count}`)
                                                            : ('x0')
                                                        }
                                                    </span>
                                                </li>
                                                <li className={userMembershipData?.platinum?.count || 'disable'}>
                                                    <span>
                                                        <img src="../../img/green-bar.png" alt="" />
                                                        Platino
                                                    </span>
                                                    <span>
                                                        {userMembershipData?.platinum?.count
                                                            ? (`x${userMembershipData?.platinum?.count}`)
                                                            : ('x0')
                                                        }
                                                    </span>
                                                </li>
                                                <li className={userMembershipData?.diamond?.count || 'disable'}>
                                                    <span>
                                                        <img src="../../img/diamand.png" alt="" />
                                                        {t("Diamante")}
                                                    </span>
                                                    <span>
                                                        {userMembershipData?.diamond?.count
                                                            ? (`x${userMembershipData?.diamond?.count}`)
                                                            : ('x0')
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='news-btn-sec digital-manager-chat'>
                                        <button className='green-btn'
                                            onClick={connectSocket}
                                        >{t("Ventana de chat")}</button>
                                    </div>

                                    <div className='chatPage-sec chatBox-toggle'>
                                        <section className="msger">
                                            <main className="msger-chat">
                                                {renderChat()}
                                            </main>

                                            <div className="input-form">
                                                <div className="input-form-wrap other-wrap">
                                                    <form className="msger-inputarea mt-0" onSubmit={handleSendMessage} >
                                                        <div
                                                            className="msger-attachment-btn left-btn"
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <input type='file'
                                                                // className="msger-attachment-btn left-btn"
                                                                // accept='image/*, video/*, audio/*, .pdf'
                                                                accept='image/*, video/*, audio/*,
                                                                                .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .zip'
                                                                ref={inputRef} style={{ display: 'none' }}
                                                                onChange={handleMediaSelection} // multiple
                                                            />
                                                            <FiPlus onClick={handleclickinput} />
                                                        </div>

                                                        {<>
                                                            {isWait && (
                                                                <div className='previewMedia my-2'>
                                                                    {t("Cargando medios")}...
                                                                </div>
                                                            )}
                                                            {mediaURL && (
                                                                <div className='previewMedia audio-msg'>
                                                                    {getMediaPreview()}
                                                                </div>
                                                            )}
                                                        </>
                                                        }

                                                        <input
                                                            type="text"
                                                            className="msger-input"
                                                            value={messageTyped}
                                                            onChange={(e) => { setMessageTyped(e.target.value) }}
                                                            placeholder={t("Escribe un mensaje")} />

                                                        <button className="msger-attachment-btn right-btn right-50" type='submit'>
                                                            <img src="../../img/rightArr.png" alt="" />
                                                        </button>

                                                        <AudioRecorder
                                                            onRecordingComplete={addAudioElement}
                                                            audioTrackConstraints={{
                                                                noiseSuppression: true,
                                                                echoCancellation: true,
                                                            }}
                                                            // downloadOnSavePress={true}
                                                            downloadFileExtension="webm"
                                                        />

                                                    </form>

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </Col>

                                <Col xs lg="6">
                                    <div className="news-header">
                                        <div className="back-sec" alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                            <img src="../../img/volver.png" alt="" />
                                        </div>
                                        <h3 className="header-title">{t("DIRIGENTE DIGITAL")}</h3>
                                        <div className="search-icon">
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className="digital-leader">
                                        <div>
                                            <h6>{t("Seguidores")}</h6>
                                            <h6>{digitalManagerData?.following}</h6>
                                        </div>
                                        <div className="center-dl">
                                            <div className="center-head">
                                                {blockStatus
                                                    && <img className='block-icon' src='../../img/block-icon.png' alt=''></img>
                                                }
                                                {digitalManagerData?.user?.image_url
                                                    ? <img src={digitalManagerData?.user?.image_url} alt="" />
                                                    : <img src='../../img/str4.png' alt="" />
                                                }
                                                <h3>
                                                    {digitalManagerData?.user?.first_name.toUpperCase()}
                                                    {digitalManagerData?.user?.last_name.toUpperCase()}
                                                </h3>
                                            </div>
                                            <div className="personal-info">
                                                <h4>{t("Información Personal")}</h4>
                                                <div className="personal-data">
                                                    <span>{t("Nombre de usuario")}</span>
                                                    <span>
                                                        @{digitalManagerData?.user?.first_name.toLowerCase()}
                                                        {digitalManagerData?.user?.last_name.toLowerCase()}
                                                    </span>
                                                </div>

                                                <div className="personal-data">
                                                    <span>{t("Nombre")}</span>
                                                    <span>{digitalManagerData?.user?.first_name}</span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("Apellido")}</span>
                                                    <span>{digitalManagerData?.user?.last_name}</span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("Fecha de nacimiento")}</span>
                                                    <span>
                                                        {`${new Date(digitalManagerData?.user?.DOB).getDate()}
                                                        / ${new Date(digitalManagerData?.user?.DOB).getMonth()}
                                                        / ${new Date(digitalManagerData?.user?.DOB).getFullYear()}`
                                                        }
                                                    </span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("Género")}</span>
                                                    <span>{digitalManagerData?.user?.gender}</span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("País")}</span>
                                                    {/* <span>{digitalManagerData?.user?.country}</span> */}
                                                    <span>{dmCountry}</span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("Correo Electrónico")}</span>
                                                    <span>{digitalManagerData?.user?.email}</span>
                                                </div>
                                                <div className="personal-data">
                                                    <span>{t("Número de teléfono")}</span>
                                                    <span>{digitalManagerData?.user?.mobile}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h6>{t("Seguidores")}</h6>
                                            <h6>{digitalManagerData?.followers}</h6>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs lg="3">
                                    <div className="available-badge">
                                        {console.log("active insignia details: ", activeInsignia)}
                                        <h4>{t("Insignias Disponibles")}</h4>
                                        <div className="row first-row">
                                            <div className="col-12 pr-hier">
                                                {activeInsignia?.['DIRECTOR DEPORTIVO']
                                                    ? (
                                                        <span>
                                                            <img src={activeInsignia['DIRECTOR DEPORTIVO']} alt="" />
                                                        </span>
                                                    )
                                                    : (<>
                                                        <span className="op-31">
                                                            <img src="../../img/bg-1.png" alt="" />
                                                        </span>
                                                        <span className='lock-icon'>
                                                            <img src="../../img/candado.svg" alt="lockIcon" />
                                                        </span>
                                                    </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="row sec-row">

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['GERENTE DE OBRAS']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['GERENTE DE OBRAS']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className="op-31">
                                                                <img src="../../img/bg-2.png" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>
                                                <h6>{t("gerente de obras")}</h6>
                                            </div>

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['REPRESENTANTE INTERNACIONAL']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['REPRESENTANTE INTERNACIONAL']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className="op-31">
                                                                <img src="../../img/Insignia Representante Internacional.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }
                                                </span>
                                                <h6>{t("representante internacional")}</h6>
                                            </div>

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['DIRECTOR TÉCNICO']
                                                        ? (<>
                                                            <img src={activeInsignia['DIRECTOR TÉCNICO']} alt="" />
                                                        </>
                                                        )
                                                        : (<>
                                                            <span className="op-31 ">
                                                                <img src="../../img/Insignia Director Tecnico.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }
                                                </span>
                                                <h6>{t("director técnico")}</h6>
                                            </div>

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['SECRETARIO DE ESTADIO']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['SECRETARIO DE ESTADIO']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className="op-31 ">
                                                                <img src="../../img/insignia Secretario de Estadio.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>
                                                <h6>{t("secretario de estadio")}</h6>
                                            </div>

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['GERENTE DE MARKETING']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['GERENTE DE MARKETING']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className=" op-31">
                                                                <img src="../../img/Insignia Gerente de Marketing.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>

                                                <h6>{t("gerente de marketing")}</h6>
                                            </div>

                                            <div className="col-4">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['SECRETARIO TÉCNICO']
                                                        ? (<>
                                                            <img src={activeInsignia['SECRETARIO TÉCNICO']} alt="" />
                                                        </>
                                                        )
                                                        : (<>
                                                            <span className=" op-31">
                                                                <img src="../../img/Insignia Secretario Tecnico.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>
                                                <h6>{t("secretario técnico")}</h6>
                                            </div>

                                            <div className="col-4 dis-fade">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['SECRETARIO DE POLIDEPORTIVO']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['SECRETARIO DE POLIDEPORTIVO']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className=" op-31">
                                                                <img src="../../img/insignia Secretario de Polideportivo.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }
                                                </span>
                                                <h6>{t("secretario de polideportivo")}</h6>
                                            </div>

                                            <div className="col-4">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['RELACIONES PÚBLICAS']
                                                        ? (
                                                            <>
                                                                <img src={activeInsignia['RELACIONES PÚBLICAS']} alt="" />
                                                            </>
                                                        )
                                                        : (<>
                                                            <span className="op-31">
                                                                <img src="../../img/Insignia Relaciones Publicas.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>

                                                <h6>{t("relaciones públicas")}</h6>
                                            </div>

                                            <div className="col-4">
                                                <span className='lock-icon-wrapper'>
                                                    {activeInsignia?.['ANALISTA TÁCTICO']
                                                        ? (<>
                                                            <img src={activeInsignia['ANALISTA TÁCTICO']} alt="" />
                                                        </>)
                                                        : (<>
                                                            <span className=" op-31">
                                                                <img src="../../img/Insignia Analista Tactico.svg" alt="" />
                                                            </span>
                                                            <span className='lock-icon'>
                                                                <img src="../../img/candado.svg" alt="lockIcon" />
                                                            </span>
                                                        </>
                                                        )
                                                    }

                                                </span>
                                                <h6>{t("analista táctico")}</h6>
                                            </div>
                                        </div>

                                        <button className="red-btn mt-100" onClick={handleBlockManager}>{t("Bloquear / Desbloquear")}</button>
                                    </div>
                                </Col>

                            </Row>
                        </Container >
                    </div >
                </div >
            </div >
        </>
    )
}

export default DigitalManager;