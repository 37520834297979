import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { GrDislike } from "react-icons/gr";
// import { FiSearch } from "react-icons/fi";
import { GrLike } from "react-icons/gr";
import GlobalURL from '../BaseURL';
import BarChart from './BarChart';
import Swal from 'sweetalert2';
import "./style.css";
import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EditarPropuestas = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const pData = useLocation().state;
    const [editGranted, setEditGranted] = useState();
    const [editProposal, setEditProposal] = useState(false);
    const [startDate, setStartDate] = useState();
    const [insigniaList, setInsigniaList] = useState();

    const [newExpiringOn, setNewExpiringOn] = useState(new Date(pData.proposalData.expiring_on));
    console.log('edit proposal :: pData.proposalData.expiring_on: ', pData.proposalData.expiring_on);

    const [category, setCategory] = useState(pData.proposalData.category);
    const [insigniaId, setInsigniaId] = useState(pData.proposalData.insignia_id);
    const [voteInsignia, setVoteInsignia] = useState(pData.proposalData.insignia_id);
    const [insigniaType, setInsigniaType] = useState(pData.proposalData.insignia_type);
    // const [allInsignia, setAllInsignia] = useState();
    const [delBtn, setDelBtn] = useState(false);
    const formData = new FormData()
    console.log('pData: ', pData);


    useEffect(() => {
        let todayDate = new Date();

        let currDate = new Date(todayDate).getDate();
        let currMonth = new Date(todayDate).getMonth();
        let currYear = new Date(todayDate).getFullYear();

        let createDate = pData.proposalData.created_at;
        let tSlice = createDate.indexOf('T');
        let sliceDate = createDate.slice(0, tSlice);
        console.log('sliceDate: ', sliceDate);

        let startDate = new Date(sliceDate).getDate();
        let startMonth = new Date(sliceDate).getMonth();
        let startYear = new Date(sliceDate).getFullYear();

        let startFullDate = `${startDate}/${startMonth + 1}/${startYear}`;
        let currFullDate = `${currDate}/${currMonth + 1}/${currYear}`;

        console.log('currFullDate~: ', currFullDate);
        console.log('fullDate~: ', startFullDate);
        if (todayDate < new Date(sliceDate)) {
            setEditGranted(true);
        } else {
            setEditGranted(false);
        }
        setStartDate(startFullDate);

        // eslint-disable-next-line
    }, [pData])
    console.log(startDate)

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(res => {
                setInsigniaList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    // handle proposal deletion
    const handleProposalDeletion = () => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¡Quieres borrar!'),
            showDenyButton: true,
            confirmButtonText: t("borrar"),
            denyButtonText: `Cancel`
        }).then((result) => {
            if (result.isConfirmed) {
                setDelBtn(true)
                fetch(`${GlobalURL}/api/dev/team_moderator/deleteProposal`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ proposal_id: pData.proposalData.proposal_id })
                })
                    .then((res) => {
                        console.log('res: ', res);
                        if (res.status === 200) {
                            Swal.fire(t("borrar!"), "", "success");
                            navigate(-1);
                        }
                        else {
                            Swal.fire(t("borrar!"), t("¡Error al eliminar!"), "error");
                        }
                        setDelBtn(false)
                    })
                    .catch((err) => {
                        setDelBtn(false)
                        console.log(err)
                        Swal.fire(t("borrar!"), t("¡Error al eliminar!"), 'error');
                    })
            }
        });
    }

    //handle edit votes
    const handleEditProposal = () => {
        const nodes = document.querySelectorAll('.edit-sec');

        if (editProposal) {
            //make editable state - true
            setEditProposal(false);
            formData.append("category", category);
            if (category.toLowerCase() === 'general') {
                // setInsigniaType(null)
                formData.append("insignia_type", null);
                // formData.append("insignia_id", null);
            }
            else {
                formData.append("insignia_type", insigniaType);
                formData.append("insignia_id", insigniaId);
            }
            formData.append("expire_time", newExpiringOn)

            formData.append("proposal_id", pData.proposalData.proposal_id);
            let updatedOpt = [];
            let proposalText;
            nodes.forEach((ele, i) => {
                if (!i) {
                    proposalText = ele.innerText;
                    formData.append("title", ele.innerText);
                }
                else {
                    formData.append(`option${i}`, ele.innerText);
                    updatedOpt.push(ele.innerText)
                }
            })
            for (let element of nodes) {
                element.contentEditable = false;
                element.classList.remove('editable-sec');
            }

            let proposal = pData.proposalData;
            let option = pData.optionsData;
            let resultOption = optionCheck(option, updatedOpt)
            if ((proposal.category.toLowerCase() !== category.toLowerCase())
                || (proposal.proposalText?.trim() !== proposalText?.trim())
                || (proposal.insignia_type !== insigniaType) || resultOption) {
                Swal.fire({
                    title: t("Estas seguro"),
                    text: t('¿Quieres editar la propuesta?'),
                    showDenyButton: true,
                    confirmButtonText: t("Editar propuesta"),
                    denyButtonText: t("Cancelar")
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            fetch(`${GlobalURL}/api/dev/team_moderator/updateProposal`, {
                                method: "POST",
                                mode: "cors",
                                headers: {
                                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                                    "ngrok-skip-browser-warning": "69420",
                                },
                                body: formData
                            })
                                .then((res) => {
                                    console.log('res: ', res);
                                    if (res.status === 200) {
                                        Swal.fire(t("Editar!"), "", "success");
                                        navigate(-1);
                                    }
                                    else {
                                        setCategory(proposal.category);
                                        setInsigniaType(proposal.insignia_type);
                                        nodes[0].innerText = proposal.content;
                                        option.forEach((item, i) => {
                                            nodes[i + 1].innerText = item.option_text;
                                        })
                                        Swal.fire(t("Editar!"), t("¡Error al editar la propuesta!"), "error");
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        else {
                            setCategory(proposal.category);
                            setInsigniaType(proposal.insignia_type);
                            nodes[0].innerText = proposal.content;
                            option.forEach((item, i) => {
                                nodes[i + 1].innerText = item.option_text;
                            })
                        }
                    });
            }
        } else {
            //toggle the editable state 
            setEditProposal(true)
            for (let element of nodes) {
                element.contentEditable = true;
                element.classList.add('editable-sec');
            }
        }
    }

    const optionCheck = (PrevOption, newOptions) => {
        console.log('PrevOption: ', PrevOption, 'newOptions: ', newOptions);
        let res = false
        PrevOption.forEach((item, i) => {
            if (item.option_text !== newOptions[i]) {
                res = true;
            }
        })
        return res;
    }

    const getInsigniaId = (insigniaID) => {
        let id, type, name;
        insigniaList.forEach((item) => {
            // eslint-disable-next-line
            if (item.insignia_id == insigniaID) {
                id = item.insignia_id;
                type = item.type_es;
                name = item.insignia_name_es;
            }
        })
        setVoteInsignia(name)
        setInsigniaType(type);
        console.log('type: ', type);
        setInsigniaId(id);
        console.log('id: ', id);
    }

    const getInsigniaBatch = (ins_id) => {
        console.log("ins id: ", ins_id);
        if (insigniaList?.length) {
            for (let item of insigniaList) {
                if (Number(item.insignia_id) === Number(ins_id))
                    return <img src={item.insignia_icon} className='insignia-badge' alt="" />
            }
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='proposal-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                            <img src="../../img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>{t('Editar Propuestas')}</h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className='proposal-vote-sec legend-tabs edit-proposal-page'>
                                        <div className="categories-dropdown mt-0 propuestas-dropdown">
                                            {editProposal
                                                && (<Dropdown onSelect={(eventKey) => setCategory(eventKey)}>
                                                    <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic" >
                                                        {category}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <path d="M6 9L12 15L18 9" stroke="#fff" strokeWidth="2"
                                                                strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="general">
                                                            General
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="insignia">
                                                            Insignia
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>)
                                            }

                                            {((category?.trim().toUpperCase() === 'INSIGNIA') && editProposal)
                                                && (<>
                                                    <Dropdown onSelect={(eventKey) => getInsigniaId(eventKey)}>
                                                        <Dropdown.Toggle className="dropdown-btn insignia-dropdown" id="dropdown-basic"
                                                            defaultValue={voteInsignia}>
                                                            {/* {console.log("typeof voteInsignia",typeof voteInsignia)} */}
                                                            {(typeof voteInsignia === 'number'
                                                                ? getInsigniaId(voteInsignia)
                                                                : voteInsignia)
                                                                || "seleccione Insignias"}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M6 9L12 15L18 9" stroke="#fff"
                                                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className='insignia-menu'>
                                                            <Dropdown.Item eventKey={""}>
                                                                {t('seleccione Insignias')}
                                                            </Dropdown.Item>
                                                            {insigniaList?.map((item) => {
                                                                return (
                                                                    <Dropdown.Item key={item.insignia_id}
                                                                        eventKey={item.insignia_id}>
                                                                        {item.insignia_name_es}
                                                                    </Dropdown.Item>
                                                                )
                                                            })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </>)
                                            }
                                        </div>

                                        <div className='general-tab-sec'>
                                            <Row className='justify-content-center'>

                                                <Col xs lg="4 my-3" key={pData.proposalData.proposal_id}>
                                                    <div className='general-card vp-height' >
                                                        <div className='card-header'>

                                                            <div className="card-imgs">
                                                                <img src={pData?.proposalData
                                                                    ? pData.proposalData?.media_url
                                                                    : "./img/exp4.png"} alt="" />
                                                            </div>

                                                            <div className='card-dates-sec'>
                                                                {pData?.proposalData?.insignia_id
                                                                    && (<div className='card-logo '>
                                                                        {getInsigniaBatch(pData?.proposalData?.insignia_id)}
                                                                    </div>)
                                                                }
                                                                <div className='created-date-sec'>
                                                                    <p>{t('creada')}</p>
                                                                    <p >
                                                                        {`
                                                                ${new Date(pData.proposalData.created_at
                                                                            .slice(0, pData.proposalData.created_at.indexOf('T')))
                                                                                .getDate()} / 
                                                                ${new Date(pData.proposalData.created_at
                                                                                    .slice(0, pData.proposalData.created_at.indexOf('T')))
                                                                                .getMonth() + 1} / 
                                                                ${new Date(pData.proposalData.created_at
                                                                                    .slice(0, pData.proposalData.created_at.indexOf('T')))
                                                                                .getFullYear()}
                                                                `}
                                                                    </p>
                                                                </div>

                                                                {editProposal
                                                                    ? (
                                                                        <input type='date' name='expiring_on' className='proposal_expiring_date'
                                                                            onChange={(e) => setNewExpiringOn(e.target.value)}
                                                                            min={new Date(pData.proposalData.created_at, 'YYYY-MM-DD')}
                                                                            value={newExpiringOn}>
                                                                        </input>
                                                                    )
                                                                    : (
                                                                        <div className='wins-date-sec'>
                                                                            <p>{t('vence')}</p>
                                                                            <p >
                                                                                {`
                                                                    ${new Date(pData.proposalData.expiring_on
                                                                                    .slice(0, pData.proposalData.expiring_on.indexOf('T')))
                                                                                        .getDate()} / 
                                                                    ${new Date(pData.proposalData.expiring_on
                                                                                            .slice(0, pData.proposalData.expiring_on.indexOf('T')))
                                                                                        .getMonth() + 1} / 
                                                                    ${new Date(pData.proposalData.expiring_on
                                                                                            .slice(0, pData.proposalData.expiring_on.indexOf('T')))
                                                                                        .getFullYear()}
                                                                    `}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>

                                                            <div className='content-sec'>
                                                                <div className='content-data'>
                                                                    <p className='edit-sec' contentEditable='false'>
                                                                        {pData.proposalData.content}
                                                                    </p>
                                                                </div>
                                                                <div className='like-sec'>
                                                                    <GrLike />
                                                                    <span>{pData.proposalData.likes}</span>
                                                                </div>
                                                                <div className='dislike-sec'>
                                                                    <GrDislike />
                                                                    <span>
                                                                        {pData.proposalData.dislikes}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='card-body'>
                                                            <div className='check-box-sec'>
                                                                <ul className='check-box-list'>
                                                                    {pData.optionsData.map((option) => {
                                                                        return (
                                                                            <li className='list-content d-flex py-2'
                                                                                key={option.option_id}>
                                                                                <input type="checkbox" id={option.option_id} />
                                                                                <label htmlFor={option.option_id}>
                                                                                    <span>
                                                                                    </span>
                                                                                </label>
                                                                                <span className='edit-sec' contentEditable='false'>
                                                                                    {option.option_text}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>

                                                            <div className='bar-chart-sec'>
                                                                <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                <BarChart optionsList={pData.optionsData} />
                                                                {/* 
                                                                <div className='chart-content'>
                                                                    <img src="./img/bar-chart.png" alt="" />
                                                                </div> 
                                                                */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>

                                        <div className='proposal-btn-sec'>
                                            <Row className='justify-content-center'>

                                                <Col xs lg="4">
                                                    <div className='proposal-btns'>
                                                        <button className='blue-btn' onClick={handleEditProposal}
                                                            disabled={editGranted}>{t('Editar')}</button>
                                                    </div>
                                                </Col>

                                                <Col xs lg="4">
                                                    <div className='proposal-btns'>
                                                        {/* <button className='green-btn' >
                                                            GUARDAR
                                                        </button> */}
                                                    </div>
                                                </Col>

                                                <Col xs lg="4">
                                                    <div className='proposal-btns'>
                                                        <button className='red-btn' onClick={handleProposalDeletion}>
                                                            {delBtn
                                                                ? (
                                                                    <>
                                                                        <span className="spinner-border spinner-border-sm"
                                                                            role="status" aria-hidden="true"></span>
                                                                        <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                                    </>
                                                                )
                                                                : (t('Eliminar Propuesta'))
                                                            }
                                                        </button>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditarPropuestas