import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const LagendPage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [actual, setActual] = useState();
    const [historical, setHistorical] = useState();

    const [currentTab, setCurrentTab] = useState();
    const [searchList, setSearchList] = useState();
    const [searchWord, setSearchWord] = useState();
    const [searchCliked, setSearchCliked] = useState(false);
    const [actualLegendStatus, setActualLegendStatus] = useState(false);
    const [historyLegendStatus, setHistoryLegendStatus] = useState(false);

    const [legendTypeParam, setLegendTypeParam] = useSearchParams({ "tab": "legend" });

    // check team is selected of not
    useEffect(() => {

        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    //observe search btn click
    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    useEffect(() => {
        setSearchWord('');
    }, [currentTab])

    const handleCurrentTab = (eventKey, i) => {
        setCurrentTab(eventKey);
        setLegendTypeParam({ "tab": eventKey });
    }

    // useEffect for historical
    useEffect(() => {
        // const loadStatus = document.querySelector(".Loader");
        fetch(`${GlobalURL}/api/legend/historylegend`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    console.log("error: ", response)
                    setHistoryLegendStatus(t("!Error Interno del servidor!"))
                }
            }).then(res => {
                setHistoryLegendStatus(false);
                setHistorical(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                setHistoryLegendStatus(t("!Error Interno del servidor!"))
            });
        // eslint-disable-next-line
    }, [])

    // useEffect for actual
    useEffect(() => {
        fetch(`${GlobalURL}/api/legend/actuallegend`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setActualLegendStatus(t("!Error Interno del servidor!"))
                }
            }).then(res => {
                setActualLegendStatus(false)
                setActual(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                setActualLegendStatus(t("!Error Interno del servidor!"))
            });
        // eslint-disable-next-line
    }, [])

    //search digital managers in sub tabs.
    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (currentTab === 'legend' && historical.length) {
            let searchedItems = historical?.filter((item) => {
                return (item.complete_name.toLowerCase().includes(word.toLowerCase())
                    || item.complete_name.toLowerCase().includes(word.toLowerCase()));
            })
            setSearchList(searchedItems);
            console.log('legend searchedItems: ', searchedItems);
        } else if (currentTab === 'current' && actual.length) {
            let searchedItems = actual.filter((item) => {
                return (item.complete_name.toLowerCase().includes(word.toLowerCase())
                    || item.complete_name.toLowerCase().includes(word.toLowerCase()));
            })
            setSearchList(searchedItems);
            // console.log('oro searchedItems: ', searchList);
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='legend-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='legend-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate('/homepage') }}
                                                style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>
                                                {t('LEYENDAS')}
                                            </h3>
                                            <div className="search-bar-main">
                                                <div className='search-icon' onClick={handleSearchClicked}>
                                                    <FiSearch />
                                                </div>
                                                
                                                <div className="search-bar">
                                                    {searchCliked
                                                        && (<input type='search'
                                                            placeholder={t('Buscar...')}
                                                            value={searchWord}
                                                            onChange={handleSearchTyped}
                                                        />)
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <div className='legend-tabs new-legend-sec'>
                                            <Tabs defaultActiveKey={legendTypeParam.get("tab")}
                                                id="fill-tab-example" fill
                                                onSelect={handleCurrentTab}>

                                                <Tab eventKey="legend" title={t("Históricas")}>
                                                    <div className='historical-legends'>
                                                        {(searchList && searchWord)
                                                            ? (searchList.length
                                                                ? (searchList.map((legend) => {
                                                                    return (
                                                                        <div className='legend-card' key={legend.legend_id}
                                                                            onClick={() => navigate('/legendprofile', { state: legend })}>
                                                                            <img style={{ cursor: "pointer" }}
                                                                                className='legend-img' src={legend.media_url} alt="" />
                                                                            <div className='legend-profile'>
                                                                                <span className='profile-img'>
                                                                                    <img src={legend.profile_image} alt="" />
                                                                                </span>
                                                                                <p>{legend.complete_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }))
                                                                : (
                                                                    <div className="Loader mm-ack d-flex justify-content-center">
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('¡No se encontró ninguna leyenda histórica!')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                            : (historical
                                                                ? (historical?.length
                                                                    ? (historical.map((legend) => {
                                                                        return (
                                                                            <div className='legend-card' key={legend.legend_id}
                                                                                onClick={() => navigate('/legendprofile', { state: legend })}>
                                                                                <img style={{ cursor: "pointer" }} className='legend-img'
                                                                                    src={legend.media_url} alt="" />
                                                                                <div className='legend-profile'>
                                                                                    <span className='profile-img'>
                                                                                        <img src={legend.profile_image} alt=""
                                                                                            className='img-legend' />
                                                                                    </span>
                                                                                    <p>{legend.complete_name}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }))
                                                                    : (
                                                                        <div className="Loader mm-ack d-flex justify-content-center">
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('¡No se encontró ninguna leyenda histórica!')}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                : (<div className="Loader d-flex justify-content-center mm-ack mx-auto align-items-center">
                                                                    {historyLegendStatus
                                                                        ? (<span className='text-danger err-msg'>
                                                                            {historyLegendStatus}
                                                                        </span>)
                                                                        : (<>
                                                                            <div className="spinner-border m-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('Cargando')}...
                                                                            </div>
                                                                        </>)}
                                                                </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="current" title={t("Actuales")}>
                                                    <div className='historical-legends'>
                                                        {searchList && searchWord
                                                            ? (searchList.length
                                                                ? (searchList.map(legend => {
                                                                    return (<div className='legend-card' key={legend.legend_id}>
                                                                        <img className='legend-img' src={legend.media_url} alt=""
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate('/legendprofile', { state: legend })} />
                                                                        <div className='legend-profile'>
                                                                            <span className='profile-img'>
                                                                                <img alt="" className='legend-profile-pic'
                                                                                    src={legend?.profile_image || "./img/mess.png"} />
                                                                            </span>
                                                                            <p>{legend.complete_name}</p>
                                                                        </div>
                                                                    </div>)
                                                                }))
                                                                : (
                                                                    <div className="Loader mm-ack d-flex justify-content-center">
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('¡No se encontró ninguna leyenda real!')}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                            : (actual
                                                                ? (actual?.length
                                                                    ? (actual.map(legend => {
                                                                        return (
                                                                            <div className='legend-card' key={legend.legend_id}>
                                                                                <img className='legend-img' src={legend.media_url}
                                                                                    alt="" style={{ cursor: "pointer" }}
                                                                                    onClick={() => navigate('/legendprofile', { state: legend })} />
                                                                                <div className='legend-profile'>
                                                                                    <span className='profile-img'>
                                                                                        <img alt="" className='legend-profile-pic'
                                                                                            src={legend?.profile_image || "./img/mess.png"} />
                                                                                    </span>
                                                                                    <p>{legend.complete_name}</p>
                                                                                </div>
                                                                            </div>)
                                                                    }))
                                                                    : (
                                                                        <div className="Loader mm-ack d-flex justify-content-center">
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t('¡No se encontró ninguna leyenda real!')}
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                : (<div className="Loader mm-ack d-flex justify-content-center mx-auto align-items-center">
                                                                    {actualLegendStatus
                                                                        ? (actualLegendStatus)
                                                                        : (<>
                                                                            <div className="spinner-border m-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className="my-5" > &nbsp;
                                                                                {t('Cargando')}...
                                                                            </div>
                                                                        </>
                                                                        )}
                                                                </div>
                                                                )
                                                            )
                                                        }

                                                    </div>
                                                </Tab>
                                            </Tabs>

                                            <div className='center-btn-sec'>
                                                <button className='green-btn' onClick={() => navigate('/addlegend')} style={{ cursor: "pointer" }}>
                                                    {t('Agregar leyenda')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LagendPage