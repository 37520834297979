import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
// import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import "react-calendar/dist/Calendar.css";
import { FiSearch } from "react-icons/fi";
import React, { useEffect } from 'react';
import { socket } from '../../socket';
import GlobalURL from '../BaseURL';
import { useState } from 'react';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

function BoardMeeting() {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [first, setFirst] = useState(false);

    const [addFormDebate, setAddFormDebate] = useState(false);
    const handleClose = () => setAddFormDebate(false);
    const handleShow = () => setAddFormDebate(true);

    const [addFormSmallTab, setAddFormSmallTab] = useState(false);
    const handleCloseTab = () => setAddFormSmallTab(false);
    const handleShowTab = () => setAddFormSmallTab(true);

    const [allMemberList, setAllMemberList] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [addMemberList, setAddMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState();
    const [file, setFile] = useState(null);

    const [allBoardMeetings, setAllBoardMeetings] = useState(null);
    // const [imgPreview, setImgPreview] = useState();
    const [createDebate, setCreateDebate] = useState({
        board_name: "",
        board_member_ids: [],
        board_meeting_description: "",
        group_image_url: ""
    })

    const [allSmallTable, setAllSmallTable] = useState();
    const [insigniaList, setInsigniaList] = useState();
    const [selectedInsignia, setSelectedInsignia] = useState();
    const [selectionStatus, setSelectionStatus] = useState();
    const [smallTabUser, setSmallTabUser] = useState([]);
    const [createSmallTab, setCreateSmallTab] = useState({
        small_table_name: "",
        insignia_id: 0,
        proposal_title: "",
        small_table_member_ids: [],
        small_table_description: "",
        insignia_icon: "",
        team_id: localStorage.getItem("team_id")
    })

    const [searchWord, setSearchWord] = useState();
    const [searchList, setSearchList] = useState([]);
    const [searchCliked, setSearchCliked] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    // selection of the rooms
    useEffect(() => {
        console.log("rerender selection useEffect");
        if (first) {
            setSearchParams({ "debate": "salon-debate" });
        } else {
            setSearchParams({ "debate": "mesa-chica" });
        }
        // eslint-disable-next-line
    }, [first])

    //fetched the from search params
    useEffect(() => {
        console.log("searchParams in chats:: ", searchParams.get("debate"));
        let debateStatus = searchParams.get("debate");
        if (debateStatus === "salon-debate") {
            setFirst(true);
        } else {
            setFirst(false);
        }
        // eslint-disable-next-line
    }, [])

    const handleFile = (e) => {
        setFile(e.target.files[0]);
        console.log("target file: ", e.target.files);
        console.log(e.target.value);
        let formData = new FormData();
        formData.append("media", e.target.files[0]);
        formData.append("inbox_uid", "group_images");
        // document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
        fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: formData
        }).then(function (res) {
            if (res.ok) {
                return res.json()
            } else {
                Swal.fire("Error", t("No se pueden enviar medios"));
            }
        }).then((resp) => {
            // setIsWait(false)
            // console.log("media bmc url: ", resp.data);
            setCreateDebate({ ...createDebate, group_image_url: resp.data });
        }).catch(function (e) {
            console.log(e)
            // setIsWait(false);
            Swal.fire("Error", t("No se pueden enviar medios"));
        });
    }

    //select insignia to create the mesa chica
    const handleSelectedInsignia = (e, insignia_data) => {
        if (selectionStatus) {
            selectionStatus.classList.remove("highlight");
        }
        setSelectionStatus(e.target.closest(".insignia-item"));
        e.target.closest(".insignia-item").classList.add("highlight");
        setSelectedInsignia(insignia_data);
    }

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    // handle meething data
    const handleMeetingData = (e) => {
        if (first)
            setCreateDebate({ ...createDebate, [e.target.name]: e.target.value });
        else
            setCreateSmallTab({ ...createSmallTab, [e.target.name]: e.target.value });
    }

    // handle - socket connection
    useEffect(() => {
        socket.connect();
        socket.emit('connect_user', { user_id: localStorage.getItem("user_id") });

        const boardMeeting = (data) => {
            console.log('board meetings:', data.boardmeeting);
            setAllBoardMeetings(data.boardmeeting);
        }

        const smallTable = (data) => {
            console.log('smalltable meetings:', data.smalltable);
            setAllSmallTable(data.smalltable);
        }

        socket.on('board_meeting_list', boardMeeting);
        socket.on('small_table_list', smallTable);

        return () => {
            socket.off('board_meeting_list', boardMeeting);
            socket.off('small_table_list', smallTable);
        };
    }, [])

    // handle socket - create board meeting.
    const handleBoardMeeting = () => {
        if (first) {
            if (addMemberList?.length) {
                let userIdList = addMemberList?.map((item) => {
                    return item.user_id
                })
                createDebate.board_member_ids = userIdList;
                // createDebate.group_image_url = file;
                // createDebate.group_image_url = "https://storage.googleapis.com/messice/undefined/1709712940295.jpeg";
                console.log("user ID List: ", userIdList);
            }

            console.log("debate: ", createDebate);
            if (createDebate.board_name
                && createDebate.board_member_ids
                && createDebate.board_meeting_description
                && file) {

                socket.emit('create_board_meeting', createDebate);
                socket.on('board_meeting_list', (data) => {
                    handleClose();
                    setAddMemberList([]);
                    createDebate.board_member_ids = [];
                    Swal.fire(t("éxito"), t("¡Grupo creado exitosamente!"), "success");
                })
            }
            else {
                Swal.fire("Error", t("¡Rellene todos los campos obligatorios!"), "error");
            }
        }
        else {
            if (smallTabUser?.length) {
                let userIdList = smallTabUser?.map((item) => {
                    return item.user_id
                })
                createSmallTab.small_table_member_ids = userIdList;
                // console.log("small tab user ID List: ", userIdList);
            }
            if (createSmallTab.small_table_name
                && selectedInsignia
                // && createSmallTab.board_meeting_description
                && createSmallTab.proposal_title) {

                createSmallTab.small_table_description = createSmallTab.proposal_title;
                createSmallTab.insignia_id = selectedInsignia.insignia_id;
                createSmallTab.insignia_icon = selectedInsignia.insignia_icon;

                // console.log("small tabs:  ", createSmallTab);
                socket.emit('create_small_table', createSmallTab);
                handleCloseTab();
                Swal.fire("success", t("¡Grupo creado exitosamente!"), "success");
            }
            else {
                Swal.fire("Error", t("¡Rellene todos los campos obligatorios!"), "error");
            }
        }
    }

    //show add user list
    const handleAddUsers = () => {
        let div = document.querySelector(".meeting-user");
        div.classList.toggle("add-list-active");
    }

    //show all insignia list
    const handleInsignia = () => {
        let div = document.querySelector(".small-table-insignia");
        div.classList.toggle("add-list-active");
    }

    // handle render data.
    const renderDirectiveComponent = () => {
        if (searchList && searchWord) {
            if (searchList.length) {
                return (searchList.map((item, i) => {
                    return (
                        <div className='cal-list-card-sec' key={i}
                            onClick={() => navigate("/debatechatroom", { state: { item, first } })}
                        >
                            <div className='cal-list-card'>
                                <div className='left-sec'>
                                    <div className='profile-img'>
                                        {item?.group_image_url
                                            ? <img src={item?.group_image_url} className='table-list-icon' alt="" />
                                            : <img src='./img/Image 44.png' alt="" />
                                        }
                                    </div>
                                    <span className='event-data'>
                                        <h4>{item.group_name}</h4>
                                        <p>{item.group_description}</p>
                                    </span>
                                </div>
                                <div className='right-sec d-flex justify-content-center align-items-center' id={item}>
                                    {/* <div className='profile-img' >
                                            <RiDeleteBin6Line
                                                style={{
                                                    color: 'crimson',
                                                    cursor: 'pointer',
                                                    borderRadius: '30px',
                                                    width: '35px',
                                                }}
                                            />
                                        </div> 
                                    */}
                                    <h3>{item.total_participants} {t('DDs')}</h3>
                                    {/* <h3>548 DDs</h3> */}
                                </div>
                            </div>
                        </div>
                    )
                }))
            }
            else {
                return (<div className='cal-list-card-sec'>
                    <div className="Loader d-flex justify-content-center mx-auto">
                        <div className="my-5" >
                            {t('¡No se encontraron reuniones de la junta directiva!')}
                        </div>
                    </div>
                </div>)
            }
        }
        else {
            if (allBoardMeetings) {
                if (allBoardMeetings.length) {
                    return (allBoardMeetings.map((item, i) => {
                        return (
                            <div className='cal-list-card-sec' key={i}
                                onClick={() => navigate("/debatechatroom", { state: { item, first } })}
                            >
                                <div className='cal-list-card'>
                                    <div className='left-sec'>
                                        <div className='profile-img'>
                                            {item?.group_image_url
                                                ? <img src={item?.group_image_url} className='table-list-icon' alt="" />
                                                : <img src='./img/Image 44.png' alt="" />
                                            }
                                        </div>
                                        <span className='event-data'>
                                            <h4>{item.group_name}</h4>
                                            <p>{item.group_description}</p>
                                        </span>
                                    </div>

                                    <div className='right-sec d-flex justify-content-center align-items-center' id={item}>
                                        {/* <div className='profile-img' >
                                        <RiDeleteBin6Line
                                            style={{
                                                color: 'crimson',
                                                cursor: 'pointer',
                                                borderRadius: '30px',
                                                width: '35px',
                                            }}
                                        />
                                    </div> */}
                                        <h3>{item.total_participants} {t('DDs')}</h3>
                                        {/* <h3>548 DDs</h3> */}
                                    </div>
                                </div>
                            </div>
                        )
                    }))
                }
                else {
                    return (<div className='cal-list-card-sec'>
                        <div className="Loader d-flex justify-content-center mx-auto">
                            <div className="my-5" >
                                {t('¡No se encontraron reuniones de la junta directiva!')}
                            </div>
                        </div>
                    </div>)
                }
            }
            else {
                return (<div className='cal-list-card-sec'>
                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                        <div className="spinner-border my-5" role="status">
                            <span className="sr-only"></span>
                        </div>
                        <div className=" my-5" >&nbsp;
                            {t('Cargando')}...
                        </div>
                    </div>
                </div>)
            }
        }
    }

    //get all digital manager list.
    useEffect(() => {
        console.log("handle add paricepents called...");
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllUserByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                    // Swal.fire("error", "Not able to get particepants list", "error");
                }
            })
            .then(res => {
                setErrorMsg(true)
                setAllMemberList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
        // if (allMemberList) {
        // console.log("get digital manager: ", allMemberList);
        // const memberList = document.querySelector(".add-users-list");
        // memberList.classList.add("add-member-active");
        // }
    }, [])

    //add selected member in the chat room.
    const handleSelectMember = (e, user_data) => {
        if (selectedMember) {
            selectedMember.classList.remove("highlight");
        }
        setSelectedMember(e.target.closest(".legendlist"));
        e.target.closest(".legendlist").classList.add("highlight");
        if (first) {
            let isPresent = addMemberList.includes(user_data.user);
            if (isPresent) {
                Swal.fire("info", t("ya seleccionado!"));
            }
            else
                setAddMemberList([...addMemberList, user_data.user]);
        }
        else {
            let isPresent = smallTabUser.includes(user_data.user);
            if (isPresent) {
                Swal.fire("info", t("ya seleccionado!"));
            } else
                setSmallTabUser([...smallTabUser, user_data.user]);
        }
    }

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                }
            })
            .then(res => {
                setErrorMsg(true)
                setInsigniaList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const renderSmallTableComponent = () => {
        if (searchList && searchWord) {
            if (searchList.length) {
                return (searchList?.map((item, i) => {
                    return (
                        <div className='cal-list-card-sec' key={i}
                            onClick={() => navigate("/debatechatroom", { state: { item, first } })}
                        >
                            <div className='cal-list-card'>
                                <div className='left-sec'>
                                    <div className='profile-img '>
                                        {item?.group_image_url
                                            ? <img src={item?.group_image_url} className='group-icon' alt="" />
                                            : <img src='./img/Image 44.png' alt="" />
                                        }
                                    </div>
                                    <span className='event-data'>
                                        <h4>{item.group_name}</h4>
                                        <p>{item.group_description}</p>
                                    </span>
                                </div>
                                <div className='right-sec d-flex justify-content-center align-items-center'>
                                    <div className='profile-img'>
                                        <h3>{item.total_participants} {t('DDs')}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }))
            }
            else {
                return (<div className='cal-list-card-sec'>
                    <div className="Loader d-flex justify-content-center mx-auto">
                        <div className="my-5" >
                            {t('¡No se encontraron reuniones de mesa pequeña!')}
                        </div>
                    </div>
                </div>)
            }
        }
        else {
            if (allSmallTable) {
                console.log('special :: allSmallTable: ', allSmallTable);
                if (allSmallTable?.my_small_tables?.length) {
                    // console.log("small tabs data::: ", allSmallTable.my_small_tables);
                    return (allSmallTable?.my_small_tables?.map((item, i) => {
                        return (
                            <div className='cal-list-card-sec' key={i}
                                onClick={() => navigate("/debatechatroom", { state: { item, first } })}
                            >
                                <div className='cal-list-card'>
                                    <div className='left-sec'>
                                        <div className='profile-img '>
                                            {item?.group_image_url
                                                ? <img src={item?.group_image_url} className='group-icon' alt="" />
                                                : <img src='./img/Image 44.png' alt="" />
                                            }
                                        </div>
                                        <span className='event-data'>
                                            <h4>{item.group_name}</h4>
                                            <p>{item.group_description}</p>
                                        </span>
                                    </div>

                                    <div className='right-sec d-flex justify-content-center align-items-center'>
                                        <div className='profile-img'>
                                            {/* <RiDeleteBin6Line
                                            style={{
                                                color: 'crimson',
                                                cursor: 'pointer',
                                                borderRadius: '30px',
                                                width: '35px',
                                            }}
                                            onClick={() => { console.log("delete clicked") }}
                                        /> */}
                                        </div>
                                        <h3>{item.total_participants} {t('DDs')}</h3>
                                        {/* <h3>548 DDs</h3> */}
                                    </div>
                                </div>
                            </div>
                        )
                    }))
                }
                if (allSmallTable?.other_small_tables?.length) {
                    // console.log("other_small_tables data::: ", allSmallTable.other_small_tables);
                    return (allSmallTable?.other_small_tables?.map((item, i) => {
                        return (
                            <div className='cal-list-card-sec' key={i}
                                onClick={() => navigate("/debatechatroom", { state: { item, first } })}
                            >
                                <div className='cal-list-card'>
                                    <div className='left-sec'>
                                        <div className='profile-img '>
                                            {item?.group_image_url
                                                ? <img src={item?.group_image_url} className='group-icon' alt="" />
                                                : <img src='./img/Image 44.png' alt="" />
                                            }
                                        </div>
                                        <span className='event-data'>
                                            <h4>{item.group_name}</h4>
                                            <p>{item.group_description}</p>
                                        </span>
                                    </div>
                                    <div className='right-sec d-flex justify-content-center align-items-center'>
                                        <div className='profile-img'>
                                            {/* <RiDeleteBin6Line
                                            style={{
                                                color: 'crimson',
                                                cursor: 'pointer',
                                                borderRadius: '30px',
                                                width: '35px',
                                            }}
                                            onClick={() => { console.log("delete clicked") }}
                                        /> */}
                                        </div>
                                        <h3>{item.total_participants} {t('DDs')}</h3>

                                        {/* <h3>548 DDs</h3> */}
                                    </div>
                                </div>
                            </div>
                        )
                    }))
                }
                else {
                    return (<div className='cal-list-card-sec'>
                        <div className="Loader d-flex justify-content-center mx-auto">
                            <div className="my-5" >
                                {t('¡No se encontraron reuniones de mesa pequeña!')}
                            </div>
                        </div>
                    </div>)
                }
            }
            else {
                return (<div className='cal-list-card-sec'>
                    <div className="Loader d-flex justify-content-center mx-auto align-items-center">
                        <div className="spinner-border my-5" role="status">
                            <span className="sr-only"></span>
                        </div>
                        <div className=" my-5" >&nbsp;
                            {t('Cargando')}...
                        </div>
                    </div>
                </div>)
            }
        }
    }

    useEffect(() => {
        setSearchWord('');
    }, [first])

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (allBoardMeetings && first) {
            let searchedItems = allBoardMeetings.filter((item) => {
                return item.group_name.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        }
        else if ((!first) && (allSmallTable?.my_small_tables || allSmallTable?.other_small_tables)) {
            let searchedItemsOurST = allSmallTable?.my_small_tables.filter((item) => {
                return item.group_name.toLowerCase().includes(word.toLowerCase())
            })
            let searchedItemsOtherST = allSmallTable?.other_small_tables.filter((item) => {
                return item.group_name.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList([...searchedItemsOurST, ...searchedItemsOtherST]);
            // console.log('searchedItems: ', searchList);
        }
    }

    const handleSortList = (e) => {
        let clickedSort = e.target.innerText;

        if (clickedSort === "Fecha creación" || clickedSort === "Creation date") {
            console.log("handle sort clicked: ", e.target?.innerText, e.target.id);

            if (!first) {
                let newListOfGroup = [...allSmallTable.other_small_tables, ...allSmallTable.my_small_tables];
                console.log("all the list of smallTabs: ", newListOfGroup);
                newListOfGroup = newListOfGroup.sort((a, b) => {
                    // console.log("createdAt: ",a.createdAt);
                    // return new Date(a.createdAt) < new Date(b.createdAt)
                    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                });
                console.log("sorted: ", newListOfGroup);
                setAllSmallTable({ ...allSmallTable, my_small_tables: newListOfGroup });
            } else {
                let newListOfGroup = allBoardMeetings.sort((a, b) => {
                    // console.log("createdAt: ",a.createdAt);
                    // return new Date(a.createdAt) < new Date(b.createdAt)
                    return (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                });
                setAllBoardMeetings([...newListOfGroup]);
            }
        }

        else if (clickedSort === "Fecha modificación" || clickedSort === "Modification date") {
            console.log("handle sort clicked: ", e.target?.innerText);
            if (first) {
                let newUpdatedList = allBoardMeetings.sort((r, s) => {
                    return (new Date(s.updatedAt).getTime() - new Date(r.updatedAt).getTime());
                })
                setAllBoardMeetings([...newUpdatedList]);
            } else {
                let newListOfGroup = [...allSmallTable.other_small_tables, ...allSmallTable.my_small_tables];
                let newUpdatedList = newListOfGroup.sort((t, u) => {
                    return (new Date(t.updatedAt).getTime() - new Date(u.createdAt).getTime());
                })
                setAllSmallTable({ ...allSmallTable, my_small_tables: [...newUpdatedList] });
            }
        }

        else if (clickedSort === "Más popular" || clickedSort === "More popular") {
            console.log("handle sort clicked: ", e.target?.innerText);

            if (!first) {

                let newListOfGroup = [...allSmallTable.other_small_tables, ...allSmallTable.my_small_tables];
                let newPopularGroup = newListOfGroup.sort((m, n) => {
                    return (n.total_participants - m.total_participants);
                })

                // newPopularGroup.map((item) => {
                //     console.log("members count", item.total_participants);
                // })
                setAllSmallTable({ ...allSmallTable, my_small_tables: [...newPopularGroup] });
            }
            else {
                // console.log("board meeting: ", allBoardMeetings);
                let newPopularList = allBoardMeetings.sort((p, q) => {
                    return (q.total_participants - p.total_participants);
                })

                // console.log("sorted: ", allBoardMeetings.sort((p, q) => {
                //     return (q.total_participants - p.total_participants);
                // }));
                setAllBoardMeetings([...newPopularList]);
            }
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='edit-news-page board-meeting-page'>
                        <Container fluid>
                            <Row className='chat-sec-responsive'>
                                <Col xs lg="3" className='home-empty-col'></Col>

                                <Col xs lg="6">
                                    <div className='news-header-bm '>
                                        <div className='back-sec' onClick={() => { navigate('/homepage') }} style={{ cursor: 'pointer' }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title-bm inter-page-header-title'>{t('SALÓN DE DEBATES')}</h3>
                                        <div className="search-bar-main">
                                            <div className='search-icon' onClick={handleSearchClicked}>
                                                <FiSearch />
                                            </div>
                                            <div className="search-bar">
                                                {searchCliked
                                                    && (<input type='search'
                                                        placeholder={t('Buscar...')}
                                                        value={searchWord}
                                                        onChange={handleSearchTyped}
                                                    />)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='two-box'>
                                        <div className='box-bm-2'
                                            style={{ background: first ? 'transparent' : '#CB8B15', cursor: first ? 'pointer' : '' }}
                                            onClick={() => { setFirst(false) }}>
                                            <img src="./img/Mesa Chica.png" alt='' />
                                            <p className='logo-content'>
                                                {t('Mesa Chica')}
                                            </p>
                                        </div>

                                        <div className='box-bm-1'
                                            style={{ background: first ? '#CB8B15' : 'transparent', cursor: first ? '' : 'pointer' }}
                                            onClick={() => { setFirst(true); }} >
                                            <img src='./img/Reunion de Comision Directiva.png' alt="img" />
                                            <p className='logo-content'>
                                                {t('Reunión de')} <span> {t('Comisión Directiva')} </span>
                                            </p>
                                        </div>

                                    </div>

                                    <div className='calendar-list-sec-bm'>
                                        {first
                                            ? (renderDirectiveComponent())
                                            : (renderSmallTableComponent())
                                        }

                                        <Modal show={addFormDebate} className="boardMeeting-popup common-popup custom-popup-manage"
                                            onHide={handleClose} centered>
                                            <Modal.Body >
                                                <div className='addboardingsec'>
                                                    <form>
                                                        <div className='blur-form-sec'>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">{t('nombre de la REUNIÓN DE COMISIÓN DIRECTIVA')}</label>
                                                                <input className='blur-input'
                                                                    type="text" name='board_name'
                                                                    value={createDebate.board_name}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">{t("DESCRIPCIÓN")}</label>
                                                                <input className='blur-input'
                                                                    type="text" name='board_meeting_description'
                                                                    value={createDebate.board_meeting_description}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">{t('agrega dirigentes digitales')}</label>

                                                                <div className="fileUpload btn btn--browse">
                                                                    <input className="f-input blur-input upload"
                                                                        type="file" onChange={handleFile} />
                                                                </div>

                                                                {/* <div className='blur-input file-input-sec '>
                                                                    <input id="uploadFile" className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí' />
                                                                    <div className="fileUpload btn btn--browse">
                                                                        <input id="uploadBtn" onChange={handleFile}
                                                                          type="file"  className="upload" />
                                                                    </div>
                                                                </div> */}

                                                            </div>

                                                            <div className='debate-room-profile'>
                                                                <div
                                                                    className='blur-form-control d-flex blur-form-list text-center position-relative'
                                                                    onClick={handleAddUsers}>
                                                                    <img src="./img/cruz.png" alt="" />
                                                                    {addMemberList?.length
                                                                        ? (addMemberList.map((member) => {
                                                                            return (<div className='user-add-members'>
                                                                                <img src={member.image_url
                                                                                    ? member.image_url
                                                                                    : './img/user.png'}
                                                                                    alt="" />
                                                                            </div>)

                                                                        })
                                                                        )
                                                                        : (
                                                                            <>
                                                                                <img src="./img/user.png" alt="" />
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className='add-users-list meeting-user debateRoom'>
                                                                    <div className="legend-box">
                                                                        <ul className="">
                                                                            {allMemberList
                                                                                ? (allMemberList.length
                                                                                    ? (allMemberList.map((item, i) => {
                                                                                        return (<li id="4" className="legendlist"
                                                                                            key={i}
                                                                                            onClick={(e) => handleSelectMember(e, item)}
                                                                                            style={{ cursor: "pointer" }}>
                                                                                            <div className="legendprofile">
                                                                                                <img src={item?.user?.image_url
                                                                                                    ? item.user.image_url
                                                                                                    : './img/user.png'}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className="legendprofilename">
                                                                                                <span className="legPro-name mb-1">
                                                                                                    <strong>
                                                                                                        {item?.user?.first_name}
                                                                                                        {item?.user?.last_name}
                                                                                                    </strong>
                                                                                                </span>
                                                                                                <span className="legPro-username fw-light">
                                                                                                    {item?.user?.username
                                                                                                        ? `@${item.user.username}`
                                                                                                        : item.user?.email
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>)
                                                                                    })
                                                                                    )
                                                                                    : (
                                                                                        <li className='legendlist' >
                                                                                            <div className='legendprofile'>
                                                                                            </div>
                                                                                            <div className='legendprofilename'>
                                                                                                <span className='legPro-name mb-1' >
                                                                                                    <strong >
                                                                                                        {t('¡No se encontró ningún administrador digital!')}
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                )
                                                                                : (errorMsg
                                                                                    ? (<li className='legendlist  justify-content-center'>
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename text-center'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >{t('Ocurrió un error, ¡inténtalo de nuevo!')}</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                    )
                                                                                    : (<li className='legendlist align-items-center justify-content-center'>
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename text-center'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >{t('Cargando')}...</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>)
                                                                                )
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className='cutom-image-manage'>
                                                                    {createDebate.group_image_url
                                                                        ? (
                                                                            <img src={createDebate.group_image_url} alt='debateProfile'></img>
                                                                        )
                                                                        : (
                                                                            <img src='./img/blankimg.png' alt='blank-img' />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer className="border-0">
                                                <div className="form-group text-center d-flex">
                                                    <button className="green-btn mb-0 rounded-pill" onClick={handleBoardMeeting}>{t('CREAR')}</button>
                                                    <button className="red-btn mb-0 rounded-pill" onClick={handleClose}>{t('CANCELAR')}</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={addFormSmallTab} className="boardMeeting-popup common-popup"
                                            onHide={handleCloseTab} centered>
                                            <Modal.Body >
                                                <div className='addboardingsec'>
                                                    <form>
                                                        <div className='blur-form-sec'>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">
                                                                    {t('¿QUÉ TIPO DE MESA CHICA DESEAS CREAR?')}
                                                                </label>
                                                                <input className='blur-input'
                                                                    type="text" name='insignia_name'
                                                                    value={selectedInsignia?.insignia_name}
                                                                    onClick={handleInsignia} />
                                                            </div>

                                                            <div className='add-users-list small-table-insignia'>
                                                                <div className="legend-box">
                                                                    <ul className="">
                                                                        {insigniaList
                                                                            ? (insigniaList.length
                                                                                ? (insigniaList.map((item, i) => {
                                                                                    return (<li id="4"
                                                                                        className="legendlist insignia-item"
                                                                                        key={i}
                                                                                        onClick={(e) => handleSelectedInsignia(e, item)}
                                                                                        style={{ cursor: "pointer" }}>
                                                                                        <div className="legendprofile">
                                                                                            <img src={item.insignia_icon
                                                                                                ? item.insignia_icon
                                                                                                : './img/user.png'}
                                                                                                alt="" />
                                                                                        </div>
                                                                                        <div className="legendprofilename">
                                                                                            <span className="legPro-name mb-1">
                                                                                                <strong>
                                                                                                    {item.insignia_name}
                                                                                                </strong>
                                                                                            </span>

                                                                                        </div>
                                                                                    </li>)
                                                                                })
                                                                                )
                                                                                : (
                                                                                    <li className='legendlist' >
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >{t('¡No se encontraron insignias!')}</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )
                                                                            : (errorMsg
                                                                                ? (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('Ocurrió un error, ¡inténtalo de nuevo!')}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                )
                                                                                : (<li className='legendlist justify-content-center align-items-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >&nbsp;
                                                                                            <strong >{t('Cargando')}...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>)
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">{t('NOMBRE DE LA MESA CHICA')}</label>
                                                                <input className='blur-input'
                                                                    type="text" name='small_table_name'
                                                                    value={createSmallTab?.small_table_name}
                                                                    onChange={handleMeetingData} />
                                                            </div>

                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">{t('PROPUESTA')}</label>
                                                                <input className='blur-input' type="text"
                                                                    name='proposal_title'
                                                                    value={createSmallTab?.proposal_title}
                                                                    onChange={handleMeetingData} />
                                                            </div>

                                                            <div
                                                                className='blur-form-control'
                                                                onClick={handleAddUsers}>
                                                                <label htmlFor="">{t('AGREGA DIRIGENTES DIGITALES')}</label>
                                                            </div>

                                                            <div
                                                                className='blur-form-control d-flex blur-form-list text-center position-relative'
                                                                onClick={handleAddUsers}>

                                                                <img src="./img/cruz.png" alt="" />
                                                                {smallTabUser?.length
                                                                    ? (smallTabUser.map((member) => {
                                                                        return (<div className='user-add-members'>
                                                                            <img src={member.image_url
                                                                                ? member.image_url
                                                                                : './img/user.png'}
                                                                                alt="" />
                                                                        </div>)
                                                                    })
                                                                    )
                                                                    : (
                                                                        <>
                                                                            <img src="./img/user.png" alt="" />
                                                                        </>
                                                                    )
                                                                }
                                                            </div>

                                                            <div className='add-users-list meeting-user mesa-chica-add-member'>
                                                                <div className="legend-box">
                                                                    <ul className="">
                                                                        {allMemberList
                                                                            ? (allMemberList.length
                                                                                ? (allMemberList.map((item, i) => {
                                                                                    return (<li id="4" className="legendlist"
                                                                                        key={i}
                                                                                        onClick={(e) => handleSelectMember(e, item)}
                                                                                        style={{ cursor: "pointer" }}>
                                                                                        <div className="legendprofile">
                                                                                            <img src={item?.user?.image_url
                                                                                                ? item.user.image_url
                                                                                                : './img/user.png'}
                                                                                                alt="" />
                                                                                        </div>
                                                                                        <div className="legendprofilename">
                                                                                            <span className="legPro-name mb-1">
                                                                                                <strong>
                                                                                                    {item?.user?.first_name}
                                                                                                    {item?.user?.last_name}
                                                                                                </strong>
                                                                                            </span>
                                                                                            <span className="legPro-username fw-light">
                                                                                                {item?.user?.username
                                                                                                    ? `@${item.user.username}`
                                                                                                    : item.user?.email
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>)
                                                                                })
                                                                                )
                                                                                : (<li className='legendlist' >
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >
                                                                                                {t('¡No se encontró ningún administrador digital!')}
                                                                                            </strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                )
                                                                            )
                                                                            : (errorMsg
                                                                                ? (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('¡Ocurrió un error, inténtalo de nuevo!')}</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                )
                                                                                : (<li className='legendlist align-items-center justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{t('Cargando')}...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>)
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer className="border-0">
                                                <div className="form-group text-center d-flex">
                                                    <button className="green-btn mb-0 rounded-pill" onClick={handleBoardMeeting}>
                                                        {t('CREAR')}
                                                    </button>
                                                    <button className="red-btn mb-0 rounded-pill" onClick={handleCloseTab}>
                                                        {t('CANCELAR')}
                                                    </button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                    {first
                                        ? (
                                            <div className='space-between-btn'>
                                                <button className='green-btn' onClick={handleShow}>{t('AGREGAR RCD')}</button>
                                                {/* <button className='red-btn'>ELIMINAR RCD</button> */}
                                            </div>
                                        )
                                        : (
                                            <div className='space-between-btn'>
                                                <button className='green-btn' onClick={handleShowTab}>{t('AGREGAR MESA CHICA')}</button>
                                                {/* <button className='red-btn'>ELIMINAR RCD</button> */}
                                            </div>
                                        )
                                    }
                                </Col>

                                <Col xs lg="3">
                                    <div className='comments-sec-bm'>
                                        <div className='select-bm' onClick={() => { setShow(!show) }} >ordenar por<span>
                                            {show
                                                ? <IoIosArrowDown style={{ marginLeft: 60, width: 30, height: 25, }} />
                                                : <IoIosArrowUp style={{ marginLeft: 60, width: 30, height: 25, }} />
                                            }
                                        </span></div>
                                        <div className='options-bm' hidden={show} onClick={handleSortList}>
                                            <p id='low'>{t("Fecha creación")}</p>
                                            <p>{t("Fecha modificación")}</p>
                                            <p>{t("Más popular")}</p>
                                            {/* 
                                                <p>{t("Más querida")}</p>
                                                <p>{t("Más odiada")}</p>
                                            */}
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BoardMeeting;