import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
// import { FiSearch } from "react-icons/fi";
import "react-calendar/dist/Calendar.css";
import Microlink from '@microlink/react';
import GlobalURL from "../BaseURL";
import Swal from "sweetalert2";
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const EventDateEdit = () => {
  const language = useSelector(state => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const loc = useLocation();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState(loc.state);
  const [eventTime, setEventTime] = useState();
  const [delBtn, setDelBtn] = useState(false);
  // const [selectedEvent, setSelectedEvent] = useState(null);
  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const [eventDayInfo, setEventDayInfo] = useState({ eventDay: 1, eventDayName: days[0] });
  console.log('setEventData: ', setEventData);

  useEffect(() => {
    let eventDate = eventData.event_date;
    let tSlice = eventDate.indexOf('T');
    let actualEventDate = eventDate.slice(0, tSlice);

    let eventTime = eventDate.slice(tSlice + 1, eventDate.indexOf('.'));
    let time = new Date(`${actualEventDate} ${eventTime}`);

    let hours = time.getHours();
    let mins = time.getMinutes();

    if (time.getHours() > 12) {
      hours -= 12;
      setEventTime(`${hours}:${mins} PM`);
    } else if (hours === 12) {
      setEventTime(`12:${mins} PM`);
    } else {
      setEventTime(`${hours}:${mins} AM`);
    }

    let dateObj = new Date(actualEventDate)

    let startDate = dateObj.getDate();
    let startMonth = dateObj.getMonth();
    let startYear = dateObj.getFullYear();
    let dayIndx = dateObj.getDay();

    setEventDayInfo({ ...eventDayInfo, eventDay: startDate, eventDayName: days[dayIndx] })

    console.log('eventTime: ', new Date(`${actualEventDate} ${eventTime}`).getMinutes());
    console.log('slice date: ', actualEventDate, " slice time: ", eventTime);

    let startFullDate = `${startDate}/${startMonth + 1}/${startYear}`;
    console.log('startFullDate: ', startFullDate);
    // eslint-disable-next-line
  }, [eventData])

  // handle deletion of events
  const handleDeletionEvent = (eventId) => {
    if (eventId) {
      Swal.fire({
        title: t("Estas seguro"),
        text: t('¡Quieres borrar!'),
        showDenyButton: true,
        confirmButtonText: t("Borrar"),
        denyButtonText: t('Cancelar')
      })
        .then((result) => {
          if (result.isConfirmed) {
            setDelBtn(true);
            // fetch(`${GlobalURL}/api/dev/team_moderator/deleteEvent`, {
            fetch(`${GlobalURL}/api/dev/team_moderator/deleteEvent`, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
              },
              body: JSON.stringify({ event_id: eventId })
            }).then((resp) => {
              setDelBtn(false);
              console.log("Resp: ", resp);
              if (resp.status === 200) {
                navigate(-1);
                // let updatedData = eventData.filter((item)=>{
                //     // eslint-disable-next-line
                //     return item.event_id != selectedEvent.id;
                // })
                // setEventData(updatedData);
                Swal.fire("Success", t("¡Borrado exitosamente!"), "success");
              } else {
                Swal.fire("Error", t("¡Error al eliminar!"), "error");
              }
            }).catch((err) => {
              setDelBtn(false);
              console.log(err)
              Swal.fire("Error", t("¡Error al eliminar!"), "error");
            })
          }
        });
    } else {
      Swal.fire(t("Borrar"), t("¡Seleccione evento antes de eliminar!"), "info");
    }
  }

  return (
    <>
      <div className="dashboard">
        <div className="event-date-page">
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col xs lg="2"></Col>
              <Col xs lg="8">
                <div className="event-page-content">
                  <div className="news-header">

                    <div className="back-sec" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
                      <img src="../../img/volver.png" alt="" />
                    </div>

                    <h3 className="header-title inter-page-header-title">
                      {t('EDITAR EVENTO')}
                    </h3>

                    <div className="search-icon">
                      {/* <FiSearch /> */}
                    </div>

                  </div>

                  <div className="event-calendar-sec">
                    <Row>
                      <Col xs lg="3"></Col>
                      <Col xs lg="6">
                        <div className="event-edit-box">
                          <div className="edit-box-date mb-20">
                            <div className="date-box">
                              <span>
                                {eventDayInfo.eventDay}
                              </span>
                            </div>
                            <div className="day-box">
                              <span>
                                {/* Hoy Jueves */}
                                {eventDayInfo.eventDayName}
                              </span>
                            </div>
                          </div>
                          <div className="event-name-box justify-content-between mb-20">
                            <div className="event-name">
                              <span>
                                {/* Evento Exclusivo - Streaming del Kun Agüero */}
                                {eventData.event_type} - {eventData.title}
                              </span>
                            </div>
                            <div className="event-time">
                              <span>
                                {eventTime}
                              </span>
                            </div>
                          </div>

                          <div className="event-box-img mb-20">
                            <img src={eventData.image_url || "../../img/blankimg.png"} className="img-fluid evt-img" alt="" />
                          </div>

                          <div className="event-box-content mb-20">
                            <p className="mb-0" dangerouslySetInnerHTML={{ __html: eventData?.description }}>
                              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                               Vivamus in dui lacinia tortor porta bibendum. Fusce feugiat justo arcu, 
                               vitae condimentum felis iaculis in. Nullam nec varius odio. Cras felis nulla, 
                               imperdiet sed justo eget, vestibulum pulvinar urna. */}
                              {/* {eventData.description} */}
                            </p>
                          </div>
                          <div className="event-box-btn text-center">
                            {/* 
                              <button className="green-btn mb-0" style={{ cursor: "pointer" }}>
                                INSCRIBIRSE
                              </button> 
                            */}

                            {/* 
                              <div className="event-link-box">
                                <strong>Link: </strong>
                                {eventData.streaming_link}
                              </div> 
                            */}

                            <div className='upper-manage-section'>
                              <div className=''>

                                <div className='link-middle-secion'>
                                  <Microlink
                                    url={eventData.streaming_link}
                                    media="logo"
                                    size="small"
                                  // size="normal"
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </Col>
                      <Col xs lg="3"></Col>
                    </Row>
                  </div>

                  <div className="event-btns">
                    {/* <button className="blue-btn">EDITAR</button> */}
                    {/* 
                      <button
                        className="green-btn"
                        onClick={() => navigate("/addevents")}
                        style={{ cursor: "pointer" }}>
                        GUARDAR
                      </button> 
                    */}
                    <button className="red-btn" onClick={(e) => handleDeletionEvent(eventData.event_id)}>
                      {delBtn
                        ? (<>
                          <span className="spinner-border spinner-border-sm"
                            role="status" aria-hidden="true"></span>
                          <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                        </>
                        )
                        : (t('Eliminar evento'))
                      }
                    </button>
                  </div>
                </div>
              </Col>
              <Col xs lg="2"></Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default EventDateEdit;
