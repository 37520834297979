import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { GrUpload } from "react-icons/gr";
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const AddPlayer = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const formData = new FormData();
    const [file, setFile] = useState(null);

    const [errMsg, setErrMsg] = useState(false);
    const [editBtn, setEditBtn] = useState(false);

    const [allTeam, setAllTeam] = useState([]);
    const [countryData, setCountryData] = useState();
    const [playerData, setPlayerData] = useState({ ...useLocation().state, category: 'player' });

    //get all team
    useEffect(() => {
        fetch(`${GlobalURL}/api/team/getteam`, {
            method: "GET",
            mode: "cors",
            headers: {
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.log("status: ", response);
                }
            }).then(res => {
                setAllTeam(res.data);
                console.log('res.datpa: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    //get all country
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/getCountries`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log("status: ", response)
            }
        }).then((resp) => {
            setCountryData(resp.data)
            console.log('resp.data: ', resp.data);
        })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const handlePlayerData = (e) => {
        setPlayerData({ ...playerData, [e.target.name]: e.target.value });
    }

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };

    //edit player
    const handleEditPlayer = (e) => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t('¿Quieres editar el jugador?'),
            showDenyButton: true,
            confirmButtonText: t("Editar"),
            denyButtonText: t(`Cancelar`)
        }).then((result) => {
            if (result.isConfirmed) {
                setEditBtn(true);
                if (file) {
                    delete playerData.image_url;
                    formData.append("media", file);
                }
                if (!playerData.user_id) {
                    delete playerData.user_id;
                }
                for (let formfields in playerData) {
                    formData.append(formfields, playerData[formfields]);
                }

                if (playerData) {
                    fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerDetails`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: formData
                    }).then((response) => {
                        setErrMsg(false);
                        if (response.status === 200) {
                            navigate(-1);
                            Swal.fire("El jugador agregó con éxito!");
                            return response.json();
                        } else {
                            setErrMsg('¡Error del Servidor!');
                        }
                    })
                        .catch((err) => {
                            setErrMsg('¡Error del Servidor!');
                        }).finally(() => {
                            setEditBtn(false);
                        });
                } else {
                    //apply error message for player if required fields are empty
                    setErrMsg('¡Campos requeridos!')
                    setEditBtn(false);
                    console.log("player 1 data: ", playerData);
                }
            } else {
                // update perv data back in nodes
                navigate(-1);
            }
        });
    }

    const getTeamId = (e) => {
        let id;
        for (let item of allTeam) {
            if (item.team_name === e.target.value) {
                id = item.team_id;
                break;
            }
        }
        // setCountryId(id);
        setPlayerData({ ...playerData, [e.target.name]: id });
    }

    const getCountryId = (e) => {
        let id;
        for (let item of countryData) {
            if (item.name === e.target.value) {
                id = item.id;
                break;
            }
        }
        // setCountryId(id);
        setPlayerData({ ...playerData, [e.target.name]: id });
    }

    const checkValue = (type,teamVal)=>{
        console.log('type: ', type);
        if(type==='team'){
            if(typeof teamVal === 'number'){
                for (let item of allTeam) {
                    if (item.team_id === Number(teamVal)) {
                        return item.team_name;
                    }
                }
            }
            return teamVal;
        }else{
            console.log('typeof teamVal: ', typeof Number(teamVal));
            if(typeof Number(teamVal) === 'number' && countryData){
                for (let item of countryData) {
                    // console.log('item: ', item);
                    if (item.id === Number(teamVal)) {
                        return item.name;
                    }
                }
            }
            return teamVal;
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='teamAdminisration-profile'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => { navigate(-1, { replace: true }) }}
                                            style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>{t("JUGADOR")}</h3>
                                        <div className='search-icon'>
                                        </div>
                                    </div>
                                    <div className='profile-desc-sec'>
                                        <Row className='profile-row-sec'>
                                            <Col xs lg="2"></Col>

                                            <Col xs lg="8">
                                                <div className='user-admin-profile user-enc-img'>
                                                    <div className="user-admin-profile-img">
                                                        <div className=' img-video-form-control'>
                                                            <div className='editnewscard-img'>
                                                                {
                                                                    <img src={file
                                                                        ? `${URL.createObjectURL(file)}`
                                                                        : `${playerData?.image_url || './img/str4.png'}`
                                                                    } alt="" />
                                                                }
                                                                <div className='img-edit-icon'>
                                                                    <GrUpload />
                                                                    <input id="uploadFile" onChange={handleFileSelect}
                                                                        type="file" className="upload" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5>
                                                        <div className='common-form-control custom-player-name-heading'>
                                                            <input type="text"
                                                                placeholder={t('Nombre del jugador')}
                                                                name='player_name'
                                                                onChange={handlePlayerData}
                                                                value={playerData.player_name}
                                                            />
                                                        </div>
                                                    </h5>
                                                </div>
                                            </Col>

                                            <Col xs lg="2"></Col>
                                        </Row>
                                    </div>
                                    <div className='admin-profile-tabs-sec'></div>

                                    <div className='profile-tabs-sec legend-tabs manage-select-option'>
                                        <Tabs
                                            defaultActiveKey="perfil"
                                            id="fill-tab-example"
                                            fill
                                        >
                                            <Tab eventKey="perfil" title="PERFIL">

                                                <div className='profile-sec-tabs'>
                                                    <div className='personal-info-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t('Datos Personales')}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>

                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Edad')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('Edad del jugador')} name='age'
                                                                            value={playerData.age} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>

                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Posición")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <select name='position'
                                                                            onChange={handlePlayerData}
                                                                            value={playerData.position}
                                                                        >
                                                                            <option value="">{t("seleccione Posición")}</option>
                                                                            <option value="goalkeeper">{t("Portero")}</option>
                                                                            <option value="attacker">{t("Adelante")}</option>
                                                                            <option value="midfielder">{t("Mediocampista")}</option>
                                                                            <option value="defender">{t("Defensor")}</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("país")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data"
                                                                        name='country'
                                                                        value={checkValue("country",playerData?.country)}
                                                                        placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                        onChange={getCountryId} />
                                                                    <datalist id="data">
                                                                        {countryData?.map((item, key) =>
                                                                            <option key={key} id={item.id} value={item.name} />
                                                                        )}
                                                                    </datalist>
                                                                </Col>

                                                                <Col xs lg="2"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("otra nacionalidad")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data2"
                                                                        name='other_nationality'
                                                                        value={checkValue("country",playerData?.other_nationality)}
                                                                        placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                        onChange={getCountryId} />
                                                                    <datalist id="data2">
                                                                        {countryData?.map((item, key) =>
                                                                            <option key={key} id={item.id} value={item.name} />
                                                                        )}
                                                                    </datalist>
                                                                </Col>
                                                                <Col xs lg="2"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("género")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <select name='gender'
                                                                            onChange={handlePlayerData}
                                                                            value={playerData?.gender}
                                                                        >
                                                                            <option value="">{t("Seleccione género")}</option>
                                                                            <option value="male">{t("Masculino")}</option>
                                                                            <option value="female">{t("Femenino")}</option>
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Pie preferido")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Pie preferido')} name='preferred_foot'
                                                                            value={playerData.preferred_foot} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Peso")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Peso')} name='weight'
                                                                            value={playerData.weight} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Estatura")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Estatura')} name='height'
                                                                            value={playerData.height} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Fecha de nacimiento")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input type='date' placeholder={t('ingrese Fecha de nacimiento')} 
                                                                            name='date_of_birth'
                                                                            value={playerData?.date_of_birth} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('País de nacimiento')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese País de nacimiento')} name='place_of_birth'
                                                                            value={playerData.place_of_birth} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                    </div>

                                                    <div className='personal-info-sec career-details-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t('Datos de su carrera')}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("identificador de Twitter")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese identificador de Twitter')} name='twitter_handle'
                                                                            value={playerData.twitter_handle} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Equipo Actual')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data1"
                                                                        name='current_team'
                                                                        value={checkValue("team", playerData.current_team)}
                                                                        placeholder={t('¡seleccione el nombre del equipo!')}
                                                                        onChange={getTeamId} />
                                                                    <datalist id="data1">
                                                                        {allTeam.map((member, key) => {
                                                                            // console.log("fmdfc: ",allTeam)
                                                                            return <option key={key} id={member.team_id} value={member.team_name} />
                                                                        }
                                                                        )}
                                                                    </datalist>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Equipo Previo')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <input type="text" list="data1"
                                                                        name='previous_team'
                                                                        value={checkValue("team",playerData.previous_team)}
                                                                        placeholder={t('¡seleccione el nombre del equipo!')}
                                                                        onChange={getTeamId} />
                                                                    <datalist id="data1">
                                                                        {allTeam.map((member, key) => {
                                                                            return <option key={key} id={member.team_id} value={member.team_name} />
                                                                        }
                                                                        )}
                                                                    </datalist>

                                                                </Col>
                                                                <Col xs lg="2"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Competición Actual')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Competición Anterior')} name='current_competition'
                                                                            value={playerData.current_competition} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Competición Anterior")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Competición Anterior')} name='previous_competition'
                                                                            value={playerData.previous_competition} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('valor neto')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese valor neto')} name='net_worth'
                                                                            value={playerData.net_worth} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Elo')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Elo')} name='elo'
                                                                            value={playerData.elo} onChange={handlePlayerData} />
                                                                    </div>

                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t('Equipo histórico')}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input type="text" list="data4" name='historical_team'
                                                                            value={checkValue("team",playerData.historical_team)}
                                                                            placeholder={t('¡seleccione el nombre del equipo para recibir!')}
                                                                            onChange={getTeamId} />
                                                                        <datalist id="data4">
                                                                            {allTeam?.map((item, key) =>
                                                                                <option key={key} id={item.team_id} value={item.team_name} />
                                                                            )}
                                                                        </datalist>
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("número de camiseta")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese número de camiseta')} name='jersey_number'
                                                                            value={playerData.jersey_number} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Último Dorsal")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Último Dorsal')} name='ultimate_dorsal'
                                                                            value={playerData.ultimate_dorsal} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Dorsal más común")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <div className='form-control'>
                                                                        <input placeholder={t('ingrese Dorsal más común')} name='most_common_dorsal'
                                                                            value={playerData.most_common_dorsal} onChange={handlePlayerData} />
                                                                    </div>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>

                                    </div>
                                </Col>
                                <Col xs lg="3">

                                    <div className='profile-btns-sec'>
                                        <div className='profile-btsn-inner'>
                                            <div className='err-spn text-danger text-center'> {errMsg} </div>
                                            <button className='blue-btn my-5'
                                                onClick={handleEditPlayer}>
                                                {editBtn
                                                    ? (<>
                                                        <span className="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        <span className="sr-only">&nbsp; {t("Enviando")}...</span>
                                                    </>)
                                                    : (t('Editor jugador'))
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPlayer;