import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddExperience = () => {
  const language = useSelector(state => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [exprienceData, setExprienceData] = useState({
    title: "",
    description: "",
    gems_count: "",
    insignia_required: ""
  });

  const [editorContent, setEditorContent] = useState('');
  const [insigniaList, setInsigniaList] = useState();
  const [status, setStatus] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [show, setShow] = useState(false);
  const editorRef = useRef(null);
  const [file, setFile] = useState(null);
  const formData = new FormData();

  useEffect(() => {
    fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
        "ngrok-skip-browser-warning": "69420",
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(res => {
        setInsigniaList(res.data);
        console.log('res.data: ', res.data);
      })
      .catch((err) => {
        console.log("error occured: ", err);
      });
  }, [])

  const handleClose = () => {
    // Close the modal
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleBold = () => {
    document.execCommand('bold', false, null);
  };

  const handleItalic = () => {
    document.execCommand('italic', false, null);
  };

  const handleStrikeThrough = () => {
    document.execCommand('strikeThrough', false, null);
  };

  const handleTextAlign = (alignment) => {
    document.execCommand('justify' + alignment, false, null);
  };

  const handleGuardarClick = () => {
    // Handle logic for saving the editor content    
    setEditorContent(editorRef.current.innerHTML);
    console.log('editorRef.current: ', editorRef.current.innerText);
    setExprienceData({ ...exprienceData, description: editorRef.current.innerHTML })
    handleClose();
  };

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.value)
    document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
  };

  const handleExprienceContent = (e) => {
    setExprienceData({ ...exprienceData, [e.target.name]: e.target.value });
  }

  //add exprience.
  const handleSubmit = (e) => {
    e.preventDefault()
    setStatus(true);
    // const expBtn = document.querySelector(".news-btn-sec");
    // const errSpn = document.querySelector(".err-spn ");
    // expBtn.innerHTML = `<button class="green-btn loaderbtn signup-btn" disbaled >
    //         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    //         <span class="sr-only">&nbsp; Enviando...</span>
    //     </button>`;

    if (exprienceData.title.length
      && exprienceData.description.length
      && exprienceData.gems_count.length
      // && exprienceData.insignia_required
      && file
    ) {
      formData.append("media", file);
      formData.append("team_id", localStorage.getItem("team_id"));
      for (let formfields in exprienceData) {
        formData.append(formfields, exprienceData[formfields]);
      }
      fetch(`${GlobalURL}/api/dev/team_moderator/addExperience`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData
      })
        .then((response) => {
          setStatus(false)
          if (response.status === 200) {
            navigate(-1);
            Swal.fire(t("Vivencia añadido con éxito!"));
            // expBtn.innerHTML = `<button class='green-btn' type='submit'  >Guardar</button>`;
            return response.json();
          } else {
            console.log("status: ", response)
            setIsErr(t('¡Error del Servidor!'));
            // errSpn.innerHTML = '¡Error del Servidor!';
            // expBtn.innerHTML = `<button class='green-btn' type='submit' >Guardar</button>`;
          }
        })
        .catch((err) => {
          setStatus(false);
          // console.log("error occured: ", err);
          setIsErr(t('¡Error del Servidor!'));
          // errSpn.innerHTML = '¡Error del Servidor!';
          // expBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
        });
    } else {
      setStatus(false);
      //apply error message for experience if fields are empty
      // errSpn.innerHTML = ' ¡Campos requeridos!';
      setIsErr(t('¡Campos requeridos!'));
      // expBtn.innerHTML = `<button class='green-btn' type='submit'>Guardar</button>`;
    }
  }

  return (
    <>
      <div className='dashboard'>
        <div className='dashboard-container'>
          <Container fluid>
            <Row >
              <Col xs lg="3"></Col>

              <Col xs lg="6">
                <div className='addExp-sec'>
                  <div className='news-header'>
                    <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                      <img src="../img/volver.png" alt="" />
                    </div>
                    <h3 className='header-title inter-page-header-title'>
                      {t('Agregar Vivencia Mess Ice')}
                    </h3>
                    <div className='search-icon'>
                    </div>
                  </div>
                  <div className='AddExp-sec-content'>
                    <div className='common-input-forms newsAddContent'>
                      <form action="" onSubmit={handleSubmit}>

                        <div className='common-form-control'>
                          <label>{t('Título')}</label>
                          <input placeholder={t('Inserte el título de la Vivencia Mess Ice aquí')}
                            name='title' value={exprienceData.title}
                            onChange={handleExprienceContent} />
                        </div>

                        <div className='common-form-control'
                          onClick={handleShow}>
                          <label>{t('Descripción')}</label>
                          <input type='text' placeholder={t('Inserte la descripción de la Vivencia Mess Ice aquí')}
                            name='description' readOnly
                          />
                        </div>

                        <div className='common-form-control 
                        img-video-form-control'>
                          <label>{t('Foto / Video')}</label>
                          <div className='file-input-sec '>
                            <input id="uploadFile" className="f-input"
                              placeholder={t('Inserte la foto o video de la Vivencia Mess Ice aquí')} />
                            <div className="fileUpload btn btn--browse">
                              <input id="uploadBtn" onChange={handleFileSelect} type="file" className="upload" />
                            </div>
                          </div>
                        </div>

                        <div className='common-form-control'>
                          <label>{t('Gemas requeridas')}</label>
                          <input placeholder={t('Ingresa el requisito de gemas necesario para aplicar Mess Ice Experience')}
                            name='gems_count' value={exprienceData.gems_count}
                            onChange={handleExprienceContent} />
                        </div>

                        <div className='common-form-control'>
                          <label>{t('Insignia requerida')}</label>

                          {/* 
                          <input placeholder='Ingresa el requisito de insignia necesario para aplicar Mess Ice Experience'
                            name='insignia_required' value={exprienceData.insignia_required}
                            onChange={handleExprienceContent} /> 
                            */}

                          <select placeholder={t('Ingresa el requisito de insignia necesario para aplicar Mess Ice Experience')}
                            name='insignia_required'
                            onChange={handleExprienceContent}
                            value={exprienceData.insignia_required}
                          >
                            <option value="">
                              {t('Ingresa el requisito de insignia necesario para aplicar Mess Ice Experience')}
                            </option>

                            {insigniaList?.map((item) => {
                              return (
                                <option key={item.insignia_id} value={item.insignia_name_es}>
                                  {item.insignia_name_es}
                                </option>)
                            })}
                          </select>
                        </div>

                        <div className='err-spn text-danger text-center'>
                          {isErr}
                        </div>

                        <div className='news-btn-sec'>
                          {status
                            ? (<button className="green-btn loaderbtn signup-btn" disabled >
                              <span className="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                              <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                            </button>
                            )
                            : (
                              <button className='green-btn'>{t('Guardar')}</button>
                            )}
                        </div>

                      </form>
                    </div>
                  </div>
                </div>

                <Modal show={show} className="institutional-popup common-popup"
                  onHide={handleClose} centered>
                  <Modal.Header className="pb-0 mb-0" closeButton >
                    <Modal.Title>{t('Editar')}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="custom-text-editor w-100">
                      <div className="toolbar">
                        <button onClick={handleBold}>
                          <img src="../img/bold.png" alt="bold" />
                        </button>
                        <button onClick={handleItalic}>
                          <img src="../img/italic.png" alt="italic" />
                        </button>
                        <button onClick={handleStrikeThrough}>
                          <img src="../img/strikethrough.png" alt="strikethrough" />
                        </button>
                        <div className="text-align-buttons">
                          <button onClick={() => handleTextAlign('Left')}>
                            <img src="../img/left-align.png" alt="left-align" />
                          </button>
                          <button onClick={() => handleTextAlign('Center')}>
                            <img src="../img/center-align.png" alt="center-align" />
                          </button>
                          <button onClick={() => handleTextAlign('Right')}>
                            <img src="../img/right-align.png" alt="right-align" />
                          </button>
                        </div>
                      </div>
                      <div
                        className="editor"
                        ref={editorRef}
                        contentEditable
                        // onInput={handleChange}
                        // onChange={handleChange}
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                      ></div>

                    </div>
                  </Modal.Body>

                  <Modal.Footer className='border-0 p-0'>
                    <div className='news-btn-sec'>
                      <button className='green-btn' onClick={handleGuardarClick}>
                        {t('GUARDAR')}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>

              </Col>
              <Col xs lg="3"></Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default AddExperience;
