import React, { useState } from 'react';
import {NavLink} from 'react-router-dom'
import "./style.css"
import { IoMenu } from 'react-icons/io5';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
 
  return (
    <div>
      <button className='menu-icons-btn' onClick={toggleSidebar}>
        <IoMenu />
      </button>
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <ul className='sidebar-link-sec'>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/proposalvote">
                    <img src="./img/propuestas.png" alt="" /><span>PROPUESTAS & VOTOS</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/boardmeeting">
                    <img src="./img/chaticon.png" alt="" /><span>SALÓN DE DEBATES</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/news">
                    <img src="./img/noticias.png" alt="news_icon" /><span>NOTICIAS</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/billboard">
                    <img src="./img/cartelera.png" alt="" /><span>CARTELERA</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/streaming ">
                    <img src="./img/streaming.png" alt="" /><span>STREAMING</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/experience">
                    <img src="./img/VIVENCIASMESS_ICE.png" alt="" /><span>VIVENCIAS MESS ICE</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/events">
                    <img src="./img/Eventos.png" alt="" /><span>EVENTOS</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/teamadministration">
                    <img src="./img/AdminEquipo.png" alt="" /><span>ADMINISTRACIÓN DE EQUIPO</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/legendpage">
                    <img src="./img/Leyendas.png" alt="" /><span>LEYENDAS</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/multimedia">
                    <img src="./img/multimedia.png" alt="" /><span>MULTIMEDIA</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/institutional">
                    <img src="./img/Institucional.svg" alt="" /><span>INSTITUCIONAL</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={toggleSidebar} className="sidebarLink" to="/reachadmin">
                    <img src="./img/contactaradmin.png" alt="contect_admin" /><span>CONTACTAR ADMIN</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    </div>
  );
};

export default Sidebar;