import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import Tabs from 'react-bootstrap/Tabs';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab';
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';
import "./style.css"

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const DigitalSec = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [gold, setGold] = useState([]);
    const [diamond, setDiamond] = useState([]);
    const [platinum, setPlatinum] = useState([]);
    const [searchWord, setSearchWord] = useState();
    const [searchList, setSearchList] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [currentTab, setCurrentTab] = useState('todas');
    const [digitalManager, setDigitalManager] = useState();
    const [searchCliked, setSearchCliked] = useState(false);

    //check the current team is selected or not.
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire("EQUIPO!", "¡Selecciona el equipo primero!", "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    //observe search btn click
    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    //empty the search box on tab chnage
    useEffect(() => {
        setSearchWord((prev) => (prev));
        setSearchList();
        // handleSearchTyped();
    }, [currentTab])

    //search digital managers in sub tabs.
    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (currentTab === 'todas' && digitalManager?.length) {
            let searchedItems = digitalManager?.filter((item) => {
                console.log('item: ', item);
                if (item.plan_id !== 7)
                    return (item.user?.first_name?.toLowerCase()?.includes(word?.toLowerCase())
                        || item.user?.last_name?.toLowerCase()?.includes(word?.toLowerCase()));
                return null;
            })
            setSearchList(searchedItems);
            // console.log('todas searchedItems: ', searchedItems);

        } else if (currentTab === 'oro' && gold?.length) {
            let searchedItems = gold.filter((item) => {
                console.log("search for gold: ", item);
                return (item.user?.first_name?.toLowerCase()?.includes(word?.toLowerCase())
                    || item.user?.last_name?.toLowerCase()?.includes(word?.toLowerCase()));
            })
            setSearchList(searchedItems);
            // console.log('oro searchedItems: ', searchList);

        } else if (currentTab === 'platino' && platinum?.length) {
            let searchedItems = platinum.filter((item) => {
                return (item.user?.first_name?.toLowerCase()?.includes(word?.toLowerCase())
                    || item.user?.last_name?.toLowerCase()?.includes(word?.toLowerCase()));
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);

        } else if (currentTab === 'diamante' && diamond?.length) {
            let searchedItems = diamond.filter((item) => {
                return (item.user?.first_name?.toLowerCase()?.includes(word?.toLowerCase())
                    || item.user?.last_name?.toLowerCase()?.includes(word?.toLowerCase()));
            })
            setSearchList(searchedItems);
            // console.log('searchedItems: ', searchList);
        }
    }

    const handleCurrentTab = (eventKey, i) => {
        setCurrentTab(eventKey);
    }

    //seperate the digital managers in the other category
    useEffect(() => {
        let gold = [], plat = [], dia = [];
        console.log("digital managers: ", digitalManager);

        digitalManager?.forEach(userData => {
            if (userData?.plan_detail?.plan_category === "gold") {             // 1-gold
                gold.push(userData);
            }
            else if (userData?.plan_detail?.plan_category === "platinum") {   // 2-platinum
                plat.push(userData);
            }
            else if (userData?.plan_detail?.plan_category === "diamond") {    //3-diamond
                dia.push(userData);
            }
        })
        setGold(gold);
        setDiamond(dia);
        setPlatinum(plat);
    }, [digitalManager]);

    //get the all digital manager
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllUserByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrorMsg(false);
                    return response.json();
                } else {
                    errorMsg(true)
                }
            })
            .then(res => {
                setDigitalManager(res.data);
            })
            .catch((err) => {
                setErrorMsg(true)
                console.log("error occured: ", err);
            });
        // eslint-disable-next-line
    }, [])

    const getMembershipIcon = (planId) => {
        if (planId === "gold") {
            return <img src="../../img/gold-bar.png" alt="" />;
        }
        else if (planId === "platinum") {
            return <img src="../../img/platino ICO.svg" alt="" />;
        }
        else if (planId === "diamond") {
            return <img src="../../img/diamante ICO.png" alt="" />;
        }
    }

    return (
        <>
            <div className="dashboard">
                <div className="dashboard-container">
                    <div className='Digital-sec'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='digital-heading digital-manager-sec'>
                                        <div className='back-sec' onClick={() => { navigate('/homepage') }}>
                                            <img src="../../img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>{t("Dirigentes Digitales")}</h3>
                                        <div className="search-bar-main">
                                            <div className='search-icon' onClick={handleSearchClicked}>
                                                <FiSearch />
                                            </div>
                                            <div className="search-bar">
                                                {searchCliked
                                                    && (<input type='search'
                                                        placeholder={t('Buscar...')}
                                                        value={searchWord}
                                                        onChange={handleSearchTyped}
                                                    />)
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='tabs-section digital-tab-sec'>
                                        <div className='tabs-cards-sec h-530'>
                                            <Tabs
                                                onSelect={handleCurrentTab}
                                                defaultActiveKey="todas"
                                                id="fill-tab-example"
                                                fill >

                                                <Tab eventKey="todas" title={t("TODAS")} onSelect={handleCurrentTab}>
                                                    {searchList && searchWord
                                                        ? (searchList.length
                                                            ? (searchList.map((item, i) => {
                                                                console.log('item in jsx: ', item);
                                                                if (item.plan_id !== 7) {
                                                                    return (
                                                                        <div className='todas-card parent' key={i}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`, { state: item })}
                                                                        >
                                                                            <div className='left-sec'>
                                                                                <div className='profile-img'>
                                                                                    {item?.user?.image_url
                                                                                        ? <img src={item?.user?.image_url} alt="" />
                                                                                        : <img src='../../img/str4.png' alt="" />
                                                                                    }
                                                                                </div>
                                                                                <div className='profile-content'>
                                                                                    <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                    <p>{item?.user?.email}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='right-sec'>
                                                                                <span className='todas-img'>
                                                                                    {getMembershipIcon(item.plan_id)}
                                                                                    {/* <img src="../../img/gold-bar.png" alt="" /> */}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                return null
                                                            })
                                                            )
                                                            : (<div className="Loader d-flex justify-content-center mx-auto">
                                                                {errorMsg
                                                                    ? <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                    :
                                                                    <div className=" my-5" >
                                                                        {t("¡No hay ningún usuario disponible con nombre")} {searchWord}!
                                                                    </div>
                                                                }
                                                            </div>
                                                            )
                                                        )
                                                        : (digitalManager
                                                            ? (digitalManager.length
                                                                ? (digitalManager?.map((item, i) => {
                                                                    if (item.plan_id !== 7) {
                                                                        return (
                                                                            <div className='todas-card parent' key={i}
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => navigate(`/digitalmanager/profile/${item?.user?.user_id}`,
                                                                                    { state: item })}
                                                                            >
                                                                                <div className='left-sec'>
                                                                                    <div className='profile-img'>
                                                                                        {item?.user?.image_url
                                                                                            ? <img src={item?.user?.image_url} alt="" />
                                                                                            : <img src='../../img/str4.png' alt="" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='profile-content'>
                                                                                        <h5>
                                                                                            {item?.user?.first_name}
                                                                                            {item?.user?.last_name}
                                                                                        </h5>
                                                                                        <p>{item?.user?.email}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='right-sec'>
                                                                                    <span className='todas-img'>
                                                                                        {getMembershipIcon(item.plan_detail.plan_category)}
                                                                                        {/* <img src="../../img/gold-bar.png" alt="" /> */}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                                )
                                                                : (<div className="d-flex justify-content-center mx-auto">
                                                                    <div className="text-primary my-5" > &nbsp;
                                                                        {t("¡Usuario no encontrado!")}
                                                                    </div>
                                                                </div>
                                                                )
                                                            )
                                                            : (
                                                                <div className="Loader d-flex justify-content-center align-items-center">
                                                                    {errorMsg
                                                                        ? <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                        : <>
                                                                            <div className="spinner-border my-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t("Cargando")}...
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            ))
                                                    }
                                                </Tab>

                                                <Tab eventKey="oro" title={t("ORO")} onSelect={handleCurrentTab}>
                                                    {searchList && searchWord
                                                        ? ((searchList.length
                                                            ? (searchList.map((item, i) => {
                                                                return (
                                                                    <div className='todas-card parent' key={i}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`,
                                                                            { state: item })}
                                                                    >
                                                                        <div className='left-sec'>
                                                                            <div className='profile-img'>
                                                                                {item?.user?.image_url
                                                                                    ? <img src={item?.user?.image_url} alt="" />
                                                                                    : <img src='../../img/str4.png' alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className='profile-content'>
                                                                                <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                <p>{item?.user?.email}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='right-sec'>
                                                                            <span className='todas-img'>
                                                                                <img src="../../img/gold-bar.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            )
                                                            : (<div className="Loader d-flex justify-content-center mx-auto">
                                                                {errorMsg
                                                                    ? <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                    :
                                                                    <div className=" my-5" >
                                                                        {t("¡No hay ningún usuario disponible con nombre")} {searchWord}!
                                                                    </div>
                                                                }
                                                            </div>
                                                            )
                                                        ))
                                                        : (gold.length
                                                            ? (gold
                                                                ? (gold.map((item, i) => {
                                                                    return (
                                                                        <div className='todas-card parent' key={i}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`, { state: item })}
                                                                        >
                                                                            <div className='left-sec'>
                                                                                <div className='profile-img'>
                                                                                    {
                                                                                        item?.user?.image_url
                                                                                            ? <img src={item.user.image_url} alt="" />
                                                                                            : <img src='../img/str4.png' alt="" />
                                                                                    }
                                                                                </div>
                                                                                <div className='profile-content'>
                                                                                    <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                    <p>{item?.user?.email}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='right-sec'>
                                                                                <span className='todas-img'>
                                                                                    <img src="../img/gold-bar.png" alt="" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                                )
                                                                : (
                                                                    <div className="Loader d-flex justify-content-center align-items-center">
                                                                        {errorMsg
                                                                            ? <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                            : <>
                                                                                <div className="spinner-border my-5" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className=" my-5" > &nbsp;
                                                                                    {t("Cargando")}...
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <div className="d-flex justify-content-center mx-auto">
                                                                    <div></div>
                                                                    <div className="text-primary my-5" > &nbsp;
                                                                        {t("¡Ningún usuario encontrado con membresía de oro!")}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </Tab>

                                                <Tab eventKey="platino" title={t("PLATINO")} onSelect={handleCurrentTab}>
                                                    {searchList && searchWord
                                                        ? (searchList.length
                                                            ? (searchList.map((item, i) => {
                                                                return (
                                                                    <div className='todas-card parent' key={i}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`,
                                                                            { state: item })}
                                                                    >
                                                                        <div className='left-sec'>
                                                                            <div className='profile-img'>
                                                                                {
                                                                                    item?.user?.image_url
                                                                                        ? <img src={item.user.image_url} alt="" />
                                                                                        : <img src='../img/str4.png' alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className='profile-content'>
                                                                                <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                <p>{item?.user?.email}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='right-sec'>
                                                                            <span className='todas-img'>
                                                                                <img src="../img/platino ICO.svg" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }))
                                                            : (<div className="Loader d-flex justify-content-center mx-auto">
                                                                {errorMsg
                                                                    ? (<div className="text-danger">{t("¡Error Interno del Servidor!")}</div>)
                                                                    : (<div className=" my-5" >
                                                                        {t("¡No hay ningún usuario disponible con nombre")} {searchWord}!
                                                                    </div>)
                                                                }
                                                            </div>
                                                            )
                                                        )
                                                        : (platinum.length
                                                            ? (platinum
                                                                ? (platinum.map((item, i) => {
                                                                    return (
                                                                        <div className='todas-card parent' key={i}
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`,
                                                                                { state: item })}
                                                                        >
                                                                            <div className='left-sec'>
                                                                                <div className='profile-img'>
                                                                                    {
                                                                                        item?.user?.image_url
                                                                                            ? <img src={item.user.image_url} alt="" />
                                                                                            : <img src='../img/str4.png' alt="" />
                                                                                    }
                                                                                </div>
                                                                                <div className='profile-content'>
                                                                                    <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                    <p>{item?.user?.email}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='right-sec'>
                                                                                <span className='todas-img'>
                                                                                    <img src="../img/platino ICO.svg" alt="" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                                )
                                                                : (<div className="Loader d-flex justify-content-center align-items-center">
                                                                    {errorMsg
                                                                        ? <div className="text-danger">
                                                                            {t("¡Error Interno del Servidor!")}
                                                                        </div>
                                                                        : <>
                                                                            <div className="spinner-border my-5" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            <div className=" my-5" > &nbsp;
                                                                                {t("Cargando")}...
                                                                            </div>
                                                                        </>}
                                                                </div>
                                                                )
                                                            )
                                                            : (<div className="d-flex justify-content-center mx-auto">
                                                                <div className="text-primary my-5" > &nbsp;
                                                                    {t("¡Ningún usuario encontrado con membresía de platino!")}
                                                                </div>
                                                            </div>
                                                            ))
                                                    }
                                                </Tab>

                                                <Tab eventKey="diamante" title={t("DIAMANTE")} onSelect={handleCurrentTab}>
                                                    {searchList && searchWord
                                                        ? (searchList.length
                                                            ? (searchList.map((item, i) => {
                                                                return (
                                                                    <div className='todas-card parent' key={i}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => navigate(`/digitalmanager/profile/${item.user.user_id}`,
                                                                            { state: item })}
                                                                    >
                                                                        <div className='left-sec'>
                                                                            <div className='profile-img'>
                                                                                {item?.user?.image_url
                                                                                    ? <img src={item?.user.image_url} alt="" />
                                                                                    : <img src='../../img/str4.png' alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className='profile-content'>
                                                                                <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                <p>{item?.user?.email}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='right-sec'>
                                                                            <span className='todas-img'>
                                                                                <img src="../../img/diamante ICO.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })

                                                            )
                                                            : (<div className="Loader d-flex justify-content-center mx-auto">
                                                                {errorMsg
                                                                    ? (<div className="text-danger">{t("¡Error Interno del Servidor!")}</div>)
                                                                    : (<div className=" my-5" >
                                                                        {t("¡No hay ningún usuario disponible con nombre")} {searchWord}!
                                                                    </div>)
                                                                }
                                                            </div>
                                                            )
                                                        )
                                                        : (diamond.length
                                                            ? (diamond
                                                                    ? (diamond.map((item, i) => {
                                                                        return (
                                                                            <div className='todas-card parent' key={i}
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => navigate(`/digitalmanager/profile/${item?.user?.user_id}`,
                                                                                    { state: item })}
                                                                            >
                                                                                <div className='left-sec'>
                                                                                    <div className='profile-img'>
                                                                                        {item?.user?.image_url
                                                                                            ? <img src={item?.user.image_url} alt="" />
                                                                                            : <img src='../../img/str4.png' alt="" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className='profile-content'>
                                                                                        <h5>{item?.user?.first_name} {item?.user?.last_name}</h5>
                                                                                        <p>{item?.user?.email}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='right-sec'>
                                                                                    <span className='todas-img'>
                                                                                        <img src="../../img/diamante ICO.png" alt="" />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                    )
                                                                    : (<div className="Loader d-flex justify-content-center align-items-center">
                                                                        {errorMsg
                                                                            ? <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                            : <>
                                                                                <div className="spinner-border my-5" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className=" my-5" > &nbsp;
                                                                                    {t("Cargando")}...
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    )
                                                            )
                                                            : (<div className="d-flex justify-content-center mx-auto">
                                                                <div className="text-primary my-5" > &nbsp;
                                                                    {t("¡Ningún usuario encontrado con membresía de diamante!")}
                                                                </div>
                                                            </div>
                                                            ))
                                                    }
                                                </Tab>

                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSec
