import React, { useState, useTransition } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import GlobalURL from '../BaseURL';
// import  { FANCY } from '../BaseURL';
// import  { MESS_STAGE } from '../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddLagend = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const formData = new FormData();
    const [file, setFile] = useState(null);
    const [legendSearch, setLegendSearch] = useState('');
    const [isPending, startTransition] = useTransition();
    console.log('isPending: ', isPending);
    const [selectedLegend, setSelectedLegend] = useState();
    const [addLegendBtn, setAddLegendBtn] = useState(false);
    const [suggestedPlayers, setSuggestedPlayers] = useState();
    const [legendData, setLegendData] = useState({ legendSearch: "", catagory: "", type: "" });

    // const handleShow = () => {
    //     let div = document.querySelector(".legend-dropdown");
    //     div.classList.toggle('legend-active');
    // }

    function handleChange(e) {
        setFile(e.target.files[0]);
        // console.log("file val:: ", document.querySelector("#uploadFile"));
        setLegendData({ ...legendData, fileName: e.target.value.replace("C:\\fakepath\\", "") })
        // document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    }

    const handleLegendData = (e) => {
        setLegendData({ ...legendData, [e.target.name]: e.target.value });
    }

    const handleAddLegend = () => {
        // const sendBtn = document.querySelector(".news-btn-sec");
        if (legendData.legendSearch && legendData.catagory && legendData.type) {
            setAddLegendBtn(true);
            // sendBtn.innerHTML = `
            //                 <button className="green-btn loaderbtn signup-btn " disbaled >
            //                     <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            //                     <span className="sr-only">&nbsp; Enviando...</span>
            //                 </button>`;

            formData.append("image", file)
            formData.append("team_id", localStorage.getItem("team_id"))
            console.log("legend data: ", legendData)
            for (let key in legendData) {
                if (key === 'legendSearch')
                    formData.append('full_name', legendData[key])
                else
                    formData.append(key, legendData[key]);
            }

            fetch(`${GlobalURL}/api/dev/miteam/legends/addnewlegend`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then(function (res) {
                if (res.ok) {
                    // alert("Perfect! ");
                    Swal.fire(t("Leyenda añadido con éxito!"));
                    navigate(-1);
                    setLegendData({ legendSearch: "", catagory: "", type: "" });
                    setFile(null);
                    // setPreview("");
                    // sendBtn.innerHTML = `<button className='green-btn' onClick={handleAddLegend}>Guardar</button>`;
                } else {
                    Swal.fire(t("Error del Servidor, ¡inténtalo de nuevo!"));
                    // alert("Oops! ");
                }
            }).catch(function (e) {
                console.log(e)
                // sendBtn.innerHTML = `<button className='green-btn' onClick={handleAddLegend}>Guardar</button>`;
                Swal.fire(t("leyenda no agregada, ¡inténtalo de nuevo!"));
                // alert("Error submitting form!");
            }).finally(() => {
                setAddLegendBtn(false);
            });
        } else {
            console.log("invalid data: ", legendData);
            Swal.fire(t("¡Inválido!"));
            // alert(legendData);
        }
    }

    const handleLegendSearch = (e) => {
        setLegendData({ ...legendData, legendSearch: e.target.value })
        setSuggestedPlayers(null)
        let searchWord = e.target.value;
        setLegendSearch(searchWord);
        if (searchWord.length > 1) {
            let div = document.querySelector(".legend-dropdown");
            div.classList.add('legend-active');
            startTransition(() => {
                fetch(`${GlobalURL}/api/dev/team_moderator/searchPlayer`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ key: searchWord, team_id: localStorage.getItem("team_id") })
                }).then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                    else {
                        console.log("error: ", res);
                    }
                })
                    .then((resp) => {
                        setSuggestedPlayers(resp.data);
                        console.log('resp.data: ', resp.data);
                    })
                    .catch(function (e) {
                        console.log('err: ', e);
                        setSuggestedPlayers([])
                        Swal.fire("Error", t('Error del Servidor, ¡inténtalo de nuevo!'), "error")
                    });
            })
        } else {

        }
    }

    // handle the ignore btn
    const handleIgnore = (e) => {
        e.preventDefault();
        let div = document.querySelector(".legend-dropdown");
        div.classList.remove('legend-active');
    }

    // handle click of link blue btn
    const handleLinkLegend = (e) => {
        e.preventDefault();
        let selectedId = selectedLegend?.target?.closest('.legendlist').id;

        if (selectedId) {
            let obj = suggestedPlayers.find((player) => {
                // eslint-disable-next-line
                return player.player_id == selectedId;
            })
            setLegendSearch(obj.full_name);
            setLegendData({ ...legendData, legendSearch: obj.full_name, player_id: obj.player_id })

            let div = document.querySelector(".legend-dropdown");
            div.classList.remove('legend-active');
        }
    }

    //handle - highlight the legend selection. 
    const handleSelect = (e) => {
        selectedLegend?.target?.closest('.legendlist').classList.remove('highlight');
        e.target.closest('.legendlist').classList.add('highlight');
        setSelectedLegend(e);
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='addLagend-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='addLagendsec common-dash-padding'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate(-1) }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title inter-page-header-title'>{t('AGREGAR LEYENDA')}</h3>
                                            <div className='search-icon'>
                                            </div>
                                        </div>

                                        <div className='common-input-forms newsAddContent'>
                                            <form encType='multipart/form-data' action="" id="legend-form">
                                                <div className='common-form-control'>
                                                    <label> {t('Nombre Completo')} </label>

                                                    <input type="search"
                                                        placeholder={t("Inserte el nombre completo de la leyenda y verifique si el perfil existe")}
                                                        name='legendSearch'
                                                        onChange={handleLegendSearch}
                                                        value={legendSearch}
                                                    />
                                                    <div className={`legend-dropdown ${(legendSearch?.length > 1) ? 'legend-active' : ''}`}>
                                                        <div className='legend-box'>
                                                            <ul className=''>
                                                                {suggestedPlayers
                                                                    ? (suggestedPlayers?.length
                                                                        ? (suggestedPlayers?.map((playerData, i) => {
                                                                            return (
                                                                                <li key={i}
                                                                                    id={playerData?.player_id}
                                                                                    className='legendlist '
                                                                                    onClick={handleSelect}
                                                                                >
                                                                                    <div className='legendprofile'>
                                                                                        <img src={playerData?.image_url} alt="" />
                                                                                        {playerData?.is_verified
                                                                                            && (<div className='legendstatusbtn'>
                                                                                                <img src="./img/diamante1.png" alt="" />
                                                                                            </div>)}
                                                                                    </div>
                                                                                    <div className='legendprofilename'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >{playerData.full_name}</strong>
                                                                                        </span>
                                                                                        <span className='legPro-username fw-light'>
                                                                                            {playerData?.username}
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                        )
                                                                        : (<li className='legendlist ' >
                                                                            <div className='legendprofile'>
                                                                            </div>
                                                                            <div className='legendprofilename'>
                                                                                <span className='legPro-name mb-1' >
                                                                                    <strong >{t('No se encontró ningún jugador con este nombre.')}.</strong>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                        )
                                                                    )
                                                                    : (
                                                                        <li className='legendlist justify-content-center align-items-center' >
                                                                            <div className='legendprofile'>
                                                                            </div>
                                                                            <div className='legendprofilename text-center'>
                                                                                <span className='legPro-name mb-1' >
                                                                                    <strong >{t('Cargando')}...</strong>
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>

                                                            <div className='legendBtn'>
                                                                {/* <button className='green-btn'>CREAR</button> */}
                                                                <button className='red-btn' onClick={handleIgnore}>{t('IGNORAR')}</button>
                                                                <button className='blue-btn' onClick={handleLinkLegend}>{t('VINCULAR')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='common-form-control'>
                                                    <label>{t('Categoría')}</label>
                                                    <select placeholder={t('Seleccione la categoría de la leyenda (Histórica/Actual)')}
                                                        name='type'
                                                        onChange={handleLegendData}
                                                        value={legendData.type}
                                                    >
                                                        <option value="">{t('Seleccione la categoría de la leyenda (Histórica/Actual)')}</option>
                                                        <option value="history">{t('Histórica')}</option>
                                                        <option value="current">{t('Actual')}</option>
                                                    </select>
                                                </div>

                                                <div className='common-form-control'>
                                                    <label>{t('Rol')}</label>
                                                    <select placeholder={t('Seleccione el rol de la leyenda (Dirigente/Jugador)')}
                                                        name='catagory'
                                                        onChange={handleLegendData}
                                                        value={legendData.catagory}
                                                    >
                                                        <option value="">{t('Seleccione el rol de la leyenda (Dirigente/Jugador)')}</option>
                                                        <option value="leader">{t('Dirigente')}</option>
                                                        <option value="player">{t('Jugador')}</option>
                                                    </select>
                                                </div>

                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Foto')}</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile legenduploadFile"
                                                            className="f-input"
                                                            value={legendData?.fileName}
                                                            placeholder={t('Inserte la foto de la eventos aquí')} />
                                                        <div className="fileUpload btn btn--browse">
                                                            <input id="uploadBtn" type="file" onChange={handleChange}
                                                                className="upload" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        
                                        <div className='news-btn-sec'>
                                            {addLegendBtn
                                                ? (<button className="green-btn loaderbtn signup-btn " disbaled >
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                </button>)

                                                : (
                                                    <button className='green-btn' onClick={handleAddLegend}>{t('Guardar')}</button>
                                                )
                                            }
                                            {/* <button className='green-btn' onClick={handleAddLegend}>Guardar</button> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddLagend
