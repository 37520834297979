import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { useNavigate } from 'react-router-dom';
import { FaPenClip } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GlobalURL from '../BaseURL';
import 'swiper/css/pagination';
import Swal from "sweetalert2";
import './style.css';
import 'swiper/css';
import 'swiper/css/navigation';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const InstitutionalPage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const swiperRef = useRef(null);
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState(false);
    const [authority, setAuthority] = useState();
    const [history, setHistory] = useState();
    const [link, setLink] = useState();

    let swiperCount = 1;
    const [prevAuthTitle, setPrevAuthTitle] = useState();
    const [newAuthObject, setNewAuthObject] = useState({});

    const [historyObject, setHistoryObject] = useState();
    // const [linkPayload, setLinkPayload] = useState(); 

    const handleHistoryObject = (e) => {
        setHistoryObject({
            ...historyObject, [e.target.name]: e.target.value
        })
        setHistory({
            ...history, [e.target.name]: e.target.value
        })
    }

    // const swiper = new Swiper('.mySwiper');
    // const [newTitle, setNewTitle] = useState();
    // const [authName, setAuthName] = useState();
    // const [currentAuth, setCurrentAuth] = useState({});
    // const [authorityData, setAuthorityData] = useState({});

    // const handleCurrentAuth = (e) => {
    //     setCurrentAuth((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    // }

    // const handleNextPage = () => {
    //     // setSwipperCount((prev) => prev + 1);
    //     if (authName in authorityData) {
    //         authorityData[authName].push(currentAuth);
    //     } else {
    //         setAuthorityData({ ...authorityData, [authName]: [currentAuth] });
    //     }
    //     console.log(authorityData)
    // }

    // const handleAuthorityData = (e) => {
    //     console.log("auth:: ", authorityData)
    // }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [showHistory, setShowHistory] = useState(false);
    const handleCloseHistory = () => setShowHistory(false);

    const [showLink, setShowLink] = useState(false);
    const handleCloseLink = () => setShowLink(false);

    const handleShow = (e) => {
        e.preventDefault();
        if (authority && !Object.keys(authority)?.length) {
            setAuthority(prev => ({ ...prev, 'newData': [{}] }));
        }
        setShow(true)
    };

    const handleSaveHistory = () => {
        // console.log("final history:: ", { ...historyObject, ...history });
        let historyObj = { ...historyObject, ...history };
        let payLoad = {
            heading: historyObj['history_heading'],
            title: historyObj['history_title'],
            body: historyObj['history_description'],
            team_id: localStorage.getItem('team_id'),
            institutional_history_id: historyObj['institutional_history_id'],
        }
        console.log("payy: ", payLoad);
        const swalWithBootstrapButtons = Swal.mixin();
        swalWithBootstrapButtons.fire({
            title: "Estas seguro",
            text: "Quieres guardar.",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Ahorrar",
            denyButtonText: `Cancelar`,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${GlobalURL}/api/dev/team/institution/addHistory`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify(payLoad)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            swalWithBootstrapButtons.fire({
                                title: "¡Salvado!",
                                icon: "success"
                            });
                            return response.json();
                        }
                        else {
                            console.log("status: ", response)
                            swalWithBootstrapButtons.fire({
                                title: "¡Error Interno del Servidor!",
                                icon: "error"
                            });
                        }
                    }).catch((err) => {
                        console.log("error occured: ", err);
                        swalWithBootstrapButtons.fire({
                            title: "¡Error Interno del Servidor!",
                            icon: "error"
                        });
                    });
            }
        });
    }

    const authList = ['Presidente', 'Vicepresidente', 'Secretario General', 'Prosecretario', 'Tesorero', 'Profesorero',
        'Secretario', 'Ejecutivo', 'Prosecretario Ejecutivo', 'Vocales', 'Por el Ascenso', 'Por el Interior', 'Miembros Suplentes'];

    //check team is selected or not.
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    //get authority
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getAllAuthorities`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                }
                else {
                    setErrMsg(true);
                }
            })
            .then(res => {
                console.log('final autho: ', res.data);
                setAuthority(res.data);
            })
            .catch((err) => {
                setErrMsg(true);
                console.log("error occured: ", err);
            });
    }, [])

    //get history
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getHistory`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                }
                else {
                    setErrMsg(true);
                }
            })
            .then(res => {
                console.log('final res: ', res);
                setHistory(res.data);
            })
            .catch((err) => {
                setErrMsg(true);
                console.log("error occured: ", err);
            });
    }, [])


    const getLinkInstitue = () => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getWebsite`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ "team_id": localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                }
                else {
                    setErrMsg(true);
                }
            })
            .then(res => {
                console.log('final res: ', res);
                setLink(res.data);
            })
            .catch((err) => {
                setErrMsg(true);
                console.log("error occured: ", err);
            });
    }

    //get link
    useEffect(() => {
        getLinkInstitue();
    }, [])

    let newPayloadAuthObj = {};

    // handle add history
    const handleAddHistory = () => {
        setShowHistory(true);
        if (!Object?.keys(history)?.length)
            setHistory({ history_title: "origenes" });
        for (let item in history) {
            setHistoryObject(prev => ({ ...prev, [item]: history[item] }));
        }
    }

    const handleNextPage = () => {

        if (Object.keys(newAuthObject).length) {
            console.log("first save this one: ", newAuthObject);
            if (authority[newAuthObject.authority_title]) {
                authority[newAuthObject.authority_title].push(newAuthObject);
                newPayloadAuthObj[newAuthObject.authority_title] = [];
                newPayloadAuthObj[newAuthObject.authority_title].push(newAuthObject);
            }
            else {
                setAuthority(prev => ({ ...prev, [newAuthObject.authority_title]: [newAuthObject] }));
                newPayloadAuthObj[newAuthObject.authority_title] = [newAuthObject];
            }
            setNewAuthObject({});
            delete authority['newData'];
        }
        const swiper = document.querySelector('.mySwiper').swiper;
        // setSwiperCount(prev => (prev + 1));
        console.log("swiper countt:: ", swiperCount);
        // setAuthority(prev => ({ ...prev, [`newData${swiperCount}`]: [{}] }));
        setAuthority(prev => ({ ...prev, 'newData': [{}] }));
        setNewAuthObject({});
        // if (swiperRef.current) {
        //     const lastPageIndex = swiperRef.current.slides?.length - 1;
        //     console.log('lastPageIndex: ', swiperRef.current.slides);
        //     swiperRef.current?.slideTo(lastPageIndex, 0); // 0 is the speed of the slide transition
        //   }
        swiper.slideTo((swiperCount - 1), 200);
        // swiper.slideTo((Object.keys(authority)?.length), 200);
        // console.log('Object.keys(auth)?.length: ', Object.keys(newPayloadAuthObj)?.length);
    }

    const handleSave = () => {
        if (Object?.keys(newAuthObject)?.length) {
            console.log("first save this one: ", newAuthObject);
            if (authority[newAuthObject.authority_title]) {
                authority[newAuthObject.authority_title].push(newAuthObject);
                newPayloadAuthObj[newAuthObject.authority_title] = [];
                newPayloadAuthObj[newAuthObject.authority_title].push(newAuthObject);
            }
            else {
                setAuthority(prev => ({ ...prev, [newAuthObject.authority_title]: [newAuthObject] }));
                newPayloadAuthObj[newAuthObject.authority_title] = [newAuthObject];
            }

            setNewAuthObject({});
            delete authority['newData'];
        }
        console.log("new obj::", { ...newPayloadAuthObj, ...authority });

        // let setAuthList = []
        // authList.forEach((item) => {
        //     authority[item]?.forEach((element) => {
        //         delete element.authority_title;
        //     })
        // })

        console.log("final data: ", {
            team_id: localStorage.getItem("team_id"),
            authorities: [{ ...newPayloadAuthObj, ...authority }]
        });

        let payload = {
            team_id: localStorage.getItem("team_id"),
            authorities: [{ ...newPayloadAuthObj, ...authority }]
        };

        console.log("empty obj: ", payload?.authorities[0].newData);
        if (payload?.authorities[0].newData) {
            delete payload.authorities[0].newData;
            console.log("after update: ", payload?.authorities[0].newData);
        }

        const swalWithBootstrapButtons = Swal.mixin();
        swalWithBootstrapButtons.fire({
            title: t("Estas seguro"),
            text: t("Quieres guardar."),
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Ahorrar",
            denyButtonText: `Cancelar`,
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${GlobalURL}/api/dev/team_moderator/updateAuthorities`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify(payload)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            swalWithBootstrapButtons.fire({
                                title: "¡Salvado!",
                                icon: "success"
                            });
                            return response.json();
                        }
                        else {
                            console.log("status: ", response)
                            swalWithBootstrapButtons.fire({
                                title: "¡Error Interno del Servidor!",
                                icon: "error"
                            });
                        }
                    }).catch((err) => {
                        console.log("error occured: ", err);
                        swalWithBootstrapButtons.fire({
                            title: "¡Error Interno del Servidor!",
                            icon: "error"
                        });
                    });
            }
        });
    }

    const handleChangeAuthTitle = (e, item) => {
        if (Object.keys(item).length) {
            console.log("item: ", item);
            delete item[item.authority_title];
            item['authority_title'] = e.target.value;
            if (authority[e.target.value])
                authority[e.target.value].push(item);
            else
                setAuthority(prev => ({ ...authority, [e.target.value]: [item] }))
            // console.log("update auth dd :: ", authority)
            authority[prevAuthTitle].forEach((authData, i) => {
                if (item.authority_name === authData.authority_name && item.authority_description === authData.authority_description)
                    authority[prevAuthTitle].splice(i, 1);
                // console.log('authority[prevAuthTitle].[i]: ', i);
            })
            setAuthority((prev) => ({ ...prev }));
        }
        else {
            console.log("new slide added");
            // delete authority['newData'];
            // setAuthority((prev)=>({...prev, [e.target.value]:[{}]}))
            // setNewTitle(e.target.value);
            setNewAuthObject({ ...newAuthObject, [e.target.name]: e.target.value })
        }
    }

    const handleChangeAuthName = (e, item) => {
        // console.log('item: ', Object.keys(item).length);
        if (Object.keys(item).length) {
            authority[item.authority_title].forEach((authData, i) => {
                if (item.authority_name === authData.authority_name)
                    item.authority_name = e.target.value;
            })
            setAuthority((prev) => ({ ...prev }));
        } else {
            console.log("entered name in new slider");
            setNewAuthObject({ ...newAuthObject, [e.target.name]: e.target.value })
            // delete authority['newData'];
            // let newObj = {'authority_name':e.target.value};
            // authority[newTitle] = e.target.value;
            // authority[newTitle] = [...authority[newTitle], newObj];
            // setAuthority((prev)=>({...prev}));
        }
    }

    const handleChangeAuthDesc = (e, item) => {
        // console.log('item: ', Object.keys(item).length);
        if (Object.keys(item).length) {
            authority[item.authority_title].forEach((authData, i) => {
                if (item.authority_name === authData.authority_name)
                    item.authority_description = e.target.value;
            })
            setAuthority((prev) => ({ ...prev }));
        } else {
            console.log("entered details in new slider");
            setNewAuthObject({ ...newAuthObject, [e.target.name]: e.target.value })
        }
    }

    const handleChangeLink = (e) => {
        // console.log()
        setLink({ ...link, [e.target.name]: e.target.value });
        // setLinkPayload({...linkPayload, [e.target.name]:e.target.value});
    }

    const handleSaveLink = (e) => {
        e.preventDefault();
        if (link.web_link && link.web_description) {
            let formData = new FormData();
            formData.append("url", link.web_link);
            formData.append("description", link.web_description);
            formData.append("team_id", localStorage.getItem("team_id"));

            const swalWithBootstrapButtons = Swal.mixin();
            swalWithBootstrapButtons.fire({
                title: "Estas seguro",
                text: "Quieres guardar.",
                icon: "warning",
                showDenyButton: true,
                confirmButtonText: "Ahorrar",
                denyButtonText: `Cancelar`,
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(`${GlobalURL}/api/dev/team/institution/addWebsiteData`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            // "Content-Type": "application/json",
                            "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: formData
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                getLinkInstitue();
                                swalWithBootstrapButtons.fire({
                                    title: "¡Salvado!",
                                    icon: "success"
                                });
                                handleCloseLink();
                                return response.json();
                            }
                            else {
                                console.log("status: ", response)
                                swalWithBootstrapButtons.fire({
                                    title: "¡Error Interno del Servidor!",
                                    icon: "error"
                                });
                            }
                        }).catch((err) => {
                            console.log("error occured: ", err);
                            swalWithBootstrapButtons.fire({
                                title: "¡Error Interno del Servidor!",
                                icon: "error"
                            });
                        });
                }
            });
        }
    }

    const handleAddLink = () => {
        setShowLink(true);
    }

    return (
        <div className='dashboard'>
            <div className='dashboard-container'>
                <div className='newsAdd-page institutional-page'>
                    <Container fluid>
                        <Row className="justify-content-md-center">
                            <Col xs lg="3"></Col>
                            <Col xs lg="6">
                                <div className='news-header'>
                                    <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                        <img src="./img/volver.png" alt="back_icon" />
                                    </div>
                                    <h3 className='header-title inter-page-header-title'>{t('Institucional')}</h3>
                                    <div className='search-icon'>
                                        {/* <FiSearch /> */}
                                    </div>
                                </div>

                                <div className='common-input-forms newsAddContent authority_content'>
                                    <form>
                                        <div className='common-form-control scrollbar'>
                                            <div className='auth-heading-lable auth-relative d-flex'>
                                                <label>{t("Autoridades")}</label>
                                                <span onClick={handleShow}>
                                                    <FaPenClip />
                                                </span>
                                            </div>
                                            {errMsg
                                                ? (<span className='errSpn'>
                                                    {t('¡Error al obtener detalles de la Autoridad!')}
                                                </span>)
                                                : (
                                                    <div className='text-start common-input-content'>
                                                        {authority && (Object.keys(authority).length)
                                                            ? (Object.keys(authority).map((item) => {
                                                                return authority[item].map((data, i) => {
                                                                    return (
                                                                        <React.Fragment key={i} >
                                                                            <p >
                                                                                <span className='fw-bold'>
                                                                                    {data?.authority_title} :
                                                                                </span>
                                                                                {data.authority_name}
                                                                            </p>
                                                                            <br />
                                                                            <p>
                                                                                {data.authority_description}
                                                                            </p>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }))
                                                            : (
                                                                <div className='errSpn text-center'>
                                                                    {t('No hay autoridad disponible')}.
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='common-form-control scrollbar' >

                                            <div className='history-heading-lable d-flex auth-relative'>
                                                <label>Historia</label>
                                                <span onClick={handleAddHistory}>
                                                    <FaPenClip />
                                                </span>
                                            </div>
                                            {errMsg
                                                ? (
                                                    <span className='errSpn'>
                                                        {t('¡Error al obtener detalles del historial!')}
                                                    </span>
                                                )
                                                : (
                                                    <div className='text-start common-input-content'>
                                                        {history?.history_title
                                                            ? (
                                                                <div className='text-start common-input-content'>
                                                                    <p>
                                                                        <strong>{history.history_title}</strong>
                                                                    </p>
                                                                    <p>
                                                                        <strong>{history.history_heading}</strong>
                                                                    </p><br />
                                                                    <p>
                                                                        {history.history_description}
                                                                    </p>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className='errSpn text-center'>
                                                                    {t('No hay Historia disponible')}.
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='common-form-control common-link-control scrollbar d-flex' >
                                            <div>
                                                {
                                                    link?.web_logo
                                                    && <div className='link-institute-shield'>
                                                        <img src={link?.web_logo} alt="" />
                                                    </div>
                                                }
                                            </div>
                                            <div className='flex-grow-1'>
                                                <div className='site-heading-lable d-flex auth-relative'>
                                                    <label>{t("Sitio Web")}</label>
                                                    <span onClick={handleAddLink}>
                                                        <FaPenClip />
                                                    </span>
                                                </div>
                                                <div className='link-div' >
                                                    {errMsg
                                                        ? (
                                                            <div className='text-center'>
                                                                <p className='errSpn mb-0'> {errMsg && (t('¡Error al obtener el enlace!'))} </p>
                                                                <input type='url' placeholder={t('Inserte el enlace del sitio')} name='link' disabled />
                                                            </div>
                                                        )
                                                        : (
                                                            <div className='text-center'>
                                                                <p className='mb-0'>{link && link.web_link}</p>
                                                                <div className='link-description-manage'>
                                                                    <input className='' placeholder={t('Inserte el enlace del sitio')}
                                                                        value={link && link.web_description} name='content' disabled />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='err-spn text-danger text-center'>  </div> */}
                                        {/* <div className='news-btn-sec'>
                                            <button className='green-btn' type='submit' >Agregar</button>
                                        </div> */}

                                        <Modal show={show}
                                            className="inst-popup institutional-popup common-popup"
                                            onHide={handleClose} centered>
                                            <Modal.Header closeButton >
                                                <Modal.Title>
                                                    {t('AUTORIDADES')}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                                <Swiper navigation={true} ref={swiperRef}
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    // autoplay={{
                                                    //     delay: 2500,
                                                    //     disableOnInteraction: false,
                                                    // }}
                                                    pagination={{
                                                        clickable: true,
                                                    }}

                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="mySwiper"
                                                >
                                                    {authority && Object.keys(authority)?.map(authObject => {
                                                        return authority[authObject]?.map((item, i) => {
                                                            swiperCount++;
                                                            return (<SwiperSlide>
                                                                <div className="text-center page-counter">
                                                                    <p>{t("PASO")} {swiperCount - 1}</p>
                                                                </div>
                                                                <div className="slider-details mt-50">
                                                                    <label>
                                                                        <select placeholder='seleccionar autoridad'
                                                                            onChange={(e) => (handleChangeAuthTitle(e, item))}
                                                                            name="authority_title"
                                                                            value={item.authority_title}
                                                                            defaultValue={item.authority_title}
                                                                            onFocus={(e) => setPrevAuthTitle(e.target.value)}
                                                                        >
                                                                            <option value="">{t('seleccionar autoridad')}</option>
                                                                            {authList.map((item, i) => {
                                                                                return (<option key={i} value={item}>{item}</option>)
                                                                            })}
                                                                        </select>
                                                                    </label>
                                                                    <textarea name="authority_name" className='authority_name'
                                                                        onChange={(e) => handleChangeAuthName(e, item)}
                                                                        value={item.authority_name}
                                                                        placeholder={t('Inserta los name')}
                                                                    />
                                                                    <textarea name="authority_description" className='authority_description'
                                                                        onChange={(e) => handleChangeAuthDesc(e, item)}
                                                                        value={item.authority_description}
                                                                        placeholder={t('Inserta los detalles')}
                                                                    />
                                                                </div>
                                                            </SwiperSlide>
                                                            )
                                                        })
                                                    })
                                                    }
                                                </Swiper>
                                            </Modal.Body>
                                            <Modal.Footer className='border-0 p-0'>
                                                <div className='news-btn-sec '>
                                                    {/* <button className='blue-btn me-0 ' onClick={() => console.log("new auth obj: ", newAuthObject)}>ANTERIOR</button> */}
                                                    <button className='green-btn  ' onClick={handleSave} >{t('GUARDAR')}</button>
                                                    {/* <button className='green-btn  ' onClick={() => console.log("authlist: ", authority)} >GUARDAR</button> */}
                                                    <button className='yellow-btn ' onClick={handleNextPage} >{t('Agregar')}</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showHistory}
                                            className="institutional-popup common-popup"
                                            onHide={handleCloseHistory} centered>
                                            <Modal.Header className='pb-0 mb-0' closeButton >
                                                <Modal.Title>
                                                    {t('Historia')}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                                <Swiper
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    // autoplay={{
                                                    //     delay: 2500,
                                                    //     disableOnInteraction: false,
                                                    // }}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    navigation={false}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="mySwiper"
                                                >
                                                    {(history && Object?.keys(history)?.length)
                                                        && <SwiperSlide>
                                                            <div className="text-center page-counter">
                                                                {/* <p>PASO {swiperCount - 1}</p> */}
                                                            </div>
                                                            <div className="slider-details">
                                                                <label>
                                                                    {/* <select placeholder='seleccionar autoridad'
                                                                        onChange={(e) => (handleChangeAuthTitle(e, item))}
                                                                        name="authority_title"
                                                                        value={item.authority_title}
                                                                        defaultValue={item.authority_title}
                                                                        onFocus={(e) => setPrevAuthTitle(e.target.value)}
                                                                    >
                                                                        <option value="">seleccionar autoridad</option>
                                                                        {authList.map((item, i) => {
                                                                            return (<option key={i} value={item}>{item}</option>)
                                                                        })}
                                                                    </select> */}
                                                                    <p>{history.history_title}</p>
                                                                </label>
                                                                <textarea name="history_heading" className='authority_name'
                                                                    onChange={(e) => handleHistoryObject(e)}
                                                                    value={history.history_heading}
                                                                    placeholder={t('Inserta los heading')}
                                                                />
                                                                <textarea name="history_description" className='authority_description'
                                                                    onChange={(e) => handleHistoryObject(e)}
                                                                    value={history.history_description}
                                                                    placeholder={t('Inserta los detalles')}
                                                                />
                                                            </div>
                                                        </SwiperSlide>

                                                    }
                                                </Swiper>
                                            </Modal.Body>
                                            <Modal.Footer className='border-0 p-0'>
                                                <div className='news-btn-sec '>
                                                    {/* <button className='blue-btn me-0 ' onClick={() => console.log("new auth obj: ", newAuthObject)}>ANTERIOR</button> */}
                                                    <button className='green-btn' onClick={handleSaveHistory} >{('GUARDAR')}</button>
                                                    {/* <button className='green-btn  ' onClick={() => console.log("authlist: ", authority)} >GUARDAR</button> */}
                                                    {/* <button className='yellow-btn ' onClick={handleNextPage} >Agregar</button> */}
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={showLink}
                                            className="institutional-popup common-popup"
                                            onHide={handleCloseLink} centered>
                                            <Modal.Header className='pb-0 mb-0' closeButton >
                                                <Modal.Title>
                                                    {t('Sitio Web')}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Swiper
                                                    spaceBetween={30}
                                                    centeredSlides={true}
                                                    // autoplay={{
                                                    //     delay: 2500,
                                                    //     disableOnInteraction: false,
                                                    // }}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    navigation={false}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    className="mySwiper"
                                                >
                                                    <SwiperSlide>
                                                        <div className="text-center page-counter">
                                                            <p>{t('Enlace de página web')}</p>
                                                        </div>
                                                        <div className="slider-details">
                                                            <label>
                                                                {t('Web enlace')}.
                                                            </label>
                                                            <textarea name="web_link"
                                                                onChange={(e) => handleChangeLink(e)}
                                                                value={link?.web_link}
                                                                placeholder={t('Inserta los name')}
                                                                className='authority_name'
                                                            />
                                                            <textarea name="web_description"
                                                                onChange={(e) => handleChangeLink(e)}
                                                                value={link?.web_description}
                                                                placeholder={t('Inserta los detalles')}
                                                                className='authority_description'
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </Modal.Body>
                                            <Modal.Footer className='border-0 p-0'>
                                                <div className='news-btn-sec '>
                                                    <button className='green-btn' onClick={handleSaveLink} >
                                                        {t('GUARDAR')}
                                                    </button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                    </form>
                                </div>
                            </Col>
                            <Col xs lg="3"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default InstitutionalPage