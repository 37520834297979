import React, { useEffect, useState } from 'react';
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import './style.css';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import translations from '../../locals/translation';
import { useSelector } from 'react-redux';
import GlobalURL from '../BaseURL';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TiersList = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    //check team is selected or not.
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    const navigate = useNavigate();
    const [tierData, setTierData] = useState();
    const [selectedTier, setSelectedTier] = useState();
    const [selectedTierData, setSelectedTierData] = useState();

    console.log('tierData: ', tierData);
    console.log('selectedTierData: ', selectedTierData);

    useEffect(() => {
        let teamTier = localStorage.getItem("tier");
        console.log('UE called - teamTier: ', teamTier);
        if (teamTier !== 'null') {
            handleSelectedTier(teamTier);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const swalWithBootstrapButtons = Swal.mixin();
        fetch(`${GlobalURL}/api/dev/team_moderator/getTierList`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Autherization': `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            // body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                swalWithBootstrapButtons.fire({
                    title: t("¡Error Interno del Servidor!"),
                    icon: "error"
                });
            }
        }).then((res) => {
            console.log('resData: ', res);
            setTierData(res.data);
        }).catch((err) => {
            console.log('error: ', err);
            swalWithBootstrapButtons.fire({
                title: t("¡Error Interno del Servidor!"),
                icon: "error"
            });
        })
        // eslint-disable-next-line
    }, [])

    // handle selected tier.
    const handleSelectedTier = (eventKey) => {
        setSelectedTier(eventKey)
        setSelectedTierData()
        const swalWithBootstrapButtons = Swal.mixin();
        fetch(`${GlobalURL}/api/dev/team_moderator/getPlansByTier`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Autherization': `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ tier: eventKey })
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                swalWithBootstrapButtons.fire({
                    title: t("¡Error Interno del Servidor!"),
                    icon: "error"
                });
            }
        }).then((res) => {
            console.log('resData: ', res);
            setSelectedTierData(res.data);
        }).catch((err) => {
            console.log('error: ', err);
            swalWithBootstrapButtons.fire({
                title: t("¡Error Interno del Servidor!"),
                icon: "error"
            });
        })
    }

    const handleSendTierData = (e) => {
        e.preventDefault()
        const swalWithBootstrapButtons = Swal.mixin();
        fetch(`${GlobalURL}/api/dev/team_moderator/saveTierForTeam`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Autherization': `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ tier: selectedTier, team_id: localStorage.getItem("team_id") })
        }).then((response) => {
            if (response.status === 200) {
                swalWithBootstrapButtons.fire({
                    title: t("¡Nivel guardado exitosamente!"),
                    icon: "success"
                });
                navigate(-1);
            } else {
                swalWithBootstrapButtons.fire({
                    title: t("¡Error Interno del Servidor!"),
                    icon: "error"
                });
            }
        }).catch((err) => {
            console.log('error: ', err);
            swalWithBootstrapButtons.fire({
                title: t("¡Error Interno del Servidor!"),
                icon: "error"
            });
        })
    }

    const checkIcon = (categoryName) => {
        if (categoryName.toLowerCase() === 'gold') {
            return <img src='../../img/gold-bar.png' alt=''></img>
        } else if (categoryName.toLowerCase() === 'platinum') {
            return <img src='../../img/green-bar.png' alt=''></img>
        } else if (categoryName.toLowerCase() === "diamond") {
            return <img src='../../img/diamand.png' alt=''></img>
        } else {
            return false;
        }
    }

    return (
        <div className='dashboard'>
            <div className='dashboard-container'>
                <div className='newsAdd-page institutional-page'>
                    <Container fluid>
                        <Row className="justify-content-md-center">
                            <Col xs lg="3"></Col>
                            <Col xs lg="6">
                                <div className='news-header'>
                                    <div className='back-sec' onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                        <img src="./img/volver.png" alt="back_icon" />
                                    </div>
                                    <h3 className='header-title inter-page-header-title'>{t('Nivel de equipo')}</h3>
                                    <div className='search-icon'>
                                    </div>
                                </div>

                                <div className='common-input-forms newsAddContent authority_content'>
                                    <form>
                                        <div className='common-form-control scrollbar'>
                                            <div className='auth-heading-lable auth-relative d-flex align-items-center'>
                                                <Col xs lg="5">
                                                    <div className='equipo_lable'>
                                                        <label className='flex-start'>{t("Equipo")} :</label>
                                                    </div>
                                                </Col>

                                                <Col xs lg="4">
                                                    <div className='equipo_name'>
                                                        {localStorage.getItem("team_name").toUpperCase()}
                                                    </div>
                                                </Col>

                                                <Col xs lg="3">
                                                    <div className='link-institute-shield'>
                                                        <img src={localStorage.getItem("flag")} alt="team_flag" />
                                                    </div>
                                                </Col>
                                            </div>

                                            <div className='text-start common-input-content'>
                                            </div>

                                        </div>

                                        <div className='common-form-control scrollbar' >
                                            <div className='history-heading-lable d-flex auth-relative'>
                                                <Col xs lg="6">
                                                    <label>{t("Seleccionar nivel")} : </label>
                                                </Col>

                                                <Col xs lg="6">
                                                    <div className="categories-dropdown dropdown-list tier-drop-down">
                                                        <Dropdown onSelect={handleSelectedTier}>
                                                            <Dropdown.Toggle
                                                                className="dropdown-btn"
                                                                id="dropdown-basic"
                                                            >
                                                                {selectedTier || t("Elegir nivel")}
                                                            </Dropdown.Toggle >

                                                            <Dropdown.Menu className=''>
                                                                {tierData?.map((item, i) => {
                                                                    return (
                                                                        <Dropdown.Item eventKey={item.tier_name} key={i}>
                                                                            {item.tier_name}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </Col>
                                            </div>

                                            <div className='text-start common-input-content'>
                                            </div>
                                        </div>

                                        {(selectedTierData)
                                            ? (<div className='auth-heading-lable auth-relative d-flex align-items-center'>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>{t("Niveles")}</StyledTableCell>
                                                                <StyledTableCell align="center">{t("Categoría del plan")}</StyledTableCell>
                                                                <StyledTableCell align="right">{t("Duración")}&nbsp;</StyledTableCell>
                                                                <StyledTableCell align="right">{t("Precio")}&nbsp;</StyledTableCell>
                                                                <StyledTableCell align="right">{t("Divisa")}&nbsp;</StyledTableCell>
                                                                <StyledTableCell align="center">{t("Descripción")}&nbsp;</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            {selectedTierData?.map((row, i) => (
                                                                <StyledTableRow key={i}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {row?.tier}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="right">
                                                                        {checkIcon(row?.plan_category)}
                                                                        {row?.plan_category}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="right">{row?.duration}</StyledTableCell>
                                                                    <StyledTableCell align="right">{row?.price}</StyledTableCell>
                                                                    <StyledTableCell align="right">{row?.currency}</StyledTableCell>
                                                                    <StyledTableCell align="left">{row?.description}</StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>)
                                            : (selectedTier
                                                && (<div className="Loader d-flex justify-content-center align-items-center">
                                                    <div className="spinner-border my-5" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                    <div className=" my-5" > &nbsp;
                                                        {t('Cargando')}...
                                                    </div>
                                                </div>)
                                            )
                                        }

                                        {selectedTierData
                                            && (<div className='news-btn-sec'>
                                                <button className='green-btn' type='submit'
                                                    onClick={handleSendTierData}>{t("Guardar")}</button>
                                            </div>)
                                        }

                                    </form>
                                </div>
                            </Col>
                            <Col xs lg="3"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default TiersList