import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const ExperiencePage = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [experience, setExperience] = useState();
    const [searchWord, setSearchWord] = useState();
    const [searchList, setSearchList] = useState([]);
    const images = ["jpg", "gif", "png", 'svg', "jpeg", "webp"];
    const [searchCliked, setSearchCliked] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Cuadrícula');

    const handleSearchClicked = () => {
        if (searchCliked)
            setSearchCliked(false);
        else
            setSearchCliked(true);
    }

    // useEffect(() => {
    //     // console.log("word:: ",searchWord)
    //     if (experience) {
    //         let searchedItems = experience.filter((item) => {
    //             return item.title.toLowerCase().includes(searchWord.toLowerCase())
    //         })
    //         setSearchList(searchedItems);
    //     }
    // }, [searchWord])

    const handleSearchTyped = (e) => {
        setSearchWord(e.target.value)
        let word = e.target.value;

        if (experience) {
            let searchedItems = experience.filter((item) => {
                return item.title.toLowerCase().includes(word.toLowerCase())
            })
            setSearchList(searchedItems);
            console.log('searchedItems: ', searchList);
        }
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/miTeam/getAllExperience`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setErrMsg('¡Error Interno del Servidor!');
                }
            })
            .then(res => {
                setErrMsg(true);
                setExperience(res.data);
            })
            .catch((err) => {
                setErrMsg('¡Error Interno del Servidor!');
            });
    }, [])

    const checkType = (url) => {
        let extention = url?.split('.')?.at(-1);
        let res = images.find((ext) => {
            return extention?.includes(ext);
        })
        if (images?.includes(url?.split('.').at(-1)) || res) {
            return <img src={url} alt="" />
        } else {
            return <video src={url} alt="" />
        }
    }

    const getFormatedDate = (date) => {
        let tSlice = date.indexOf('T');
        let sliceDate = date.slice(0, tSlice);
        console.log('sliceDate: ', sliceDate);
        return sliceDate;
    }

    return (
        <>
            <div className='dashboard'>
                <div className='experience-page'>
                    <Container fluid>
                        <Row >
                            <Col xs lg="2"></Col>
                            <Col xs lg="8">
                                <div className='news-header'>
                                    <div className='back-sec'>
                                        <img src="./img/volver.png" alt=""
                                            onClick={() => navigate('/homepage')}
                                            style={{ cursor: "pointer" }} />
                                    </div>
                                    <h3 className='header-title inter-page-header-title'>{t('Vivencias Mess Ice')}</h3>
                                    <div className="search-bar-main">
                                        <div className='search-icon' onClick={handleSearchClicked}>
                                            <FiSearch />
                                        </div>
                                        <div className="search-bar">
                                            {searchCliked
                                                && (<input type='search'
                                                    placeholder={t('Buscar...')}
                                                    value={searchWord}
                                                    onChange={handleSearchTyped}
                                                />)
                                            }

                                            {/* <input><div class="div1"></div></input> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='grid-list-sec'>
                                    <div className="radio-btns-sec">
                                        <div className="left-radios-sec">
                                            <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id="test1"
                                                    name="radio-group"
                                                    value="Cuadrícula"
                                                    checked={selectedOption === 'Cuadrícula'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="test1">{t('Cuadrícula')}</label>
                                            </div>
                                            <div className={`radio-sec ${selectedOption === 'Lista' ? 'selected' : ''}`}>
                                                <input
                                                    type="radio"
                                                    id="test2"
                                                    name="radio-group"
                                                    value="Lista"
                                                    checked={selectedOption === 'Lista'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label htmlFor="test2">{t('Lista')}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='experience-sec common-dash-padding'>
                                    {selectedOption === 'Cuadrícula' &&
                                        (<div className='experience-card-sec'>
                                            <Row>
                                                {searchList && searchWord
                                                    ? (searchList.length
                                                        ? (searchList.map((item, i) => {
                                                            return (<Col xs lg="4"
                                                                key={i} onClick={() => { navigate('/experience/editexperience', { state: item }) }}>
                                                                <div className='exp-card news-card'>
                                                                    <div className='card-heading'>
                                                                        <h3>{item.title}</h3>
                                                                    </div>
                                                                    <div className='card-img news-card news-body'>
                                                                        {checkType(item.experience_media[0]?.url)}
                                                                    </div>
                                                                </div>
                                                            </Col>)
                                                        })
                                                        )
                                                        : (
                                                            <div className="Loader d-flex justify-content-center">
                                                                <div className=" my-5" > &nbsp;
                                                                    {t('No se encontró ninguna experiencia con la palabra clave')} {searchWord}!
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                    : (experience
                                                        ? (experience.length
                                                            ? (experience.map((item, i) => {
                                                                return (<Col xs lg="4"
                                                                    key={i} onClick={() => { navigate('/experience/editexperience', { state: item }) }}>
                                                                    <div className='exp-card news-card'>
                                                                        <div className='card-heading'>
                                                                            <h3>{item.title}</h3>
                                                                        </div>
                                                                        <div className='card-img news-card news-body'>
                                                                            {checkType(item.experience_media[0]?.url)}
                                                                        </div>
                                                                    </div>
                                                                </Col>)
                                                            })
                                                            )
                                                            : (
                                                                <div className="Loader d-flex justify-content-center">
                                                                    <div className=" my-5" > &nbsp;
                                                                        {t('¡No se encontró ninguna experiencia!')}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                        : (<div className="Loader d-flex justify-content-center align-items-center">
                                                            {errMsg
                                                                ? (
                                                                    <span className='text-danger err-msg'>{errMsg}</span>
                                                                )
                                                                : (<>
                                                                    <div className="spinner-border my-5" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    <div className=" my-5" > &nbsp;
                                                                        {t('Cargando')}...
                                                                    </div>
                                                                </>)
                                                            }
                                                        </div>
                                                        )
                                                    )
                                                }
                                            </Row>
                                        </div>)
                                    }
                                    
                                    {selectedOption === 'Lista' &&
                                        (<div className="lista-content">
                                            {searchList && searchWord
                                                ? (<>
                                                        <div className="lista-titles">
                                                            <div className='lista-name'>{t('Nombre')}</div>
                                                            <div className='lista-creation'>{t('Fecha de Creación')}</div>
                                                            <div className='lista-modification'>{t('Fecha de Creación')}</div>
                                                            <div className='lista-right'></div>
                                                        </div>

                                                        {searchList.length
                                                            ? (searchList.map((item, i) => {
                                                                return (
                                                                    <div onClick={() => { navigate('/experience/editexperience', { state: item }) }} >
                                                                        <div className='myTeamBanner billboard-cards' >

                                                                            <div className='lista-name ps-0'>
                                                                                <div className='card-img'>
                                                                                    <div className='card-img news-card news-body'>
                                                                                        {checkType(item.experience_media[0]?.url)}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='card-heading'>
                                                                                    <h3>{item.title}</h3>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-creation'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-modification'>
                                                                                <div className='card-heading'>
                                                                                    <p>{getFormatedDate(item.createdAt)}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-right'></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }))
                                                            : (
                                                                <div >
                                                                    <div className='myTeamBanner billboard-cards' >
                                                                        <div className='lista-name ps-0'>
                                                                            <div className='card-img'>
                                                                                <div className='card-img news-card news-body'>
                                                                                    <img src='./img/blankimg.png' alt="" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-heading'>
                                                                                <h3>{t('No se encontró ninguna experiencia con la palabra clave')} {searchWord}!</h3>
                                                                            </div>
                                                                        </div>

                                                                        <div className='lista-creation'>
                                                                            <div className='card-heading'>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='lista-modification'>
                                                                            <div className='card-heading'>
                                                                                <p></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='lista-right'></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                                : (experience
                                                    ? (
                                                        <>
                                                            <div className="lista-titles">
                                                                <div className='lista-name'>{t('Nombre')}</div>
                                                                <div className='lista-creation'>{t('Fecha de Creación')}</div>
                                                                <div className='lista-modification'>{t('Fecha de Creación')}</div>
                                                                <div className='lista-right'></div>
                                                            </div>
                                                            {experience.length
                                                                ? (experience.map((item, i) => {
                                                                    return (
                                                                        <div onClick={() => { navigate('/experience/editexperience', { state: item }) }} >
                                                                            <div className='myTeamBanner billboard-cards' >
                                                                                <div className='lista-name ps-0'>
                                                                                    <div className='card-img'>
                                                                                        <div className='card-img news-card news-body'>
                                                                                            {checkType(item.experience_media[0]?.url)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='card-heading'>
                                                                                        <h3>{item.title}</h3>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='lista-creation'>
                                                                                    <div className='card-heading'>
                                                                                        <p>{getFormatedDate(item.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='lista-modification'>
                                                                                    <div className='card-heading'>
                                                                                        <p>{getFormatedDate(item.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='lista-right'></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }))
                                                                : (
                                                                    <div >
                                                                        <div className='myTeamBanner billboard-cards' >
                                                                            <div className='lista-name ps-0'>
                                                                                <div className='card-img'>
                                                                                    <div className='card-img news-card news-body'>
                                                                                        <img src='./img/blankimg.png' alt="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='card-heading'>
                                                                                    <h3>{t('¡No se encontró ninguna experiencia')}</h3>
                                                                                </div>
                                                                            </div>

                                                                            <div className='lista-creation'>
                                                                                <div className='card-heading'>
                                                                                    <p></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-modification'>
                                                                                <div className='card-heading'>
                                                                                    <p></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='lista-right'></div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                    : (<div className="Loader d-flex justify-content-center align-items-center">
                                                        <div className="spinner-border my-5" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        <div className=" my-5" > &nbsp;
                                                            {t('Cargando')}...
                                                        </div>
                                                    </div>
                                                    ))
                                            }
                                        </div>
                                        )
                                    }
                                </div>

                                <div className='exp-btn-sec'>
                                    <button className='green-btn'
                                        onClick={() => navigate('/experience/addexperience')}
                                        style={{ cursor: "pointer" }}>
                                        {t('Agregar Vivencia')}
                                    </button>
                                </div>
                            </Col>
                            <Col xs lg="2"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ExperiencePage