// import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from 'recharts';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useTransition } from 'react';
import GlobalURL from '../../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const TeamAdministrationProfile = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const teamPlayer = useLocation().state;
    const [teamId, setTeamId] = useState();
    const [allTeam, setAllTeam] = useState();
    const [delBtn, setDelBtn] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [errMsgTeam, setErrMsgTeam] = useState(false);
    const [teamStatus, setTeamStatus] = useState();
    const [playerTeam, setPlayerTeam] = useState();
    const [trophyData, setTrophyData] = useState({});
    const [playerTrophy, setPlayerTrophy] = useState();
    const [playerDetails, setPlayerDetails] = useState();
    const [teamDescription, setTeamDescription] = useState();
    const [suggestedPlayers, setSuggestedPlayers] = useState();
    const [searchUser, setSearchUser] = useState(false);

    const [userExsistName, setUserExsistName] = useState();
    const [isPending, startTransition] = useTransition();
    console.log('isPending: ', isPending);
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [searchedPlayerUid, setSearchedPlayerUid] = useState();

    // const [file, setFile] = useState(null);
    // const formData = new FormData()
    // const [file, setFile] = useState(null);
    // const [playerCountryData, setPlayerCountryData] = useState({})
    // const [position, setPosition] = useState(playerDetails?.position);
    // console.log('teamPlayer: ', teamPlayer);
    // const [editBtn, setEditBtn] = useState(false);
    // const formData = new FormData();
    // const [countryData, setCountryData] = useState();
    // const [isUpdate, setIsUpdate] = useState(false);

    // const data = [
    //     { name: 'Ritmo', x: 21 },
    //     { name: 'Tiro', x: 22 },
    //     { name: 'Pase', x: -32 },
    //     { name: 'Regate', x: -14 },
    //     { name: 'Defensa', x: -51 },
    //     { name: 'Fisica', x: 16 }
    // ];

    // const handleFileSelect = (e)=> {
    //     setFile(e.target.files[0]);
    //     console.log(e.target.value)
    //     document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    // };

    // const handleFileSelect = (e) => {
    //     setFile(e.target.files[0]);
    // };

    //handle state - trophy data

    const handleTrophyData = (e) => {
        setTrophyData({ ...trophyData, [e.target.name]: e.target.value })
    }

    //get all team
    useEffect(() => {
        fetch(`${GlobalURL}/api/team/getteam`, {
            method: "GET",
            mode: "cors",
            headers: {
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 404) {
                    console.log("status: ", response);
                }
                else if (response.status === 200) {
                    return response.json();
                }
            }).then(res => {
                setAllTeam(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const getTeamId = (e) => {
        let id;
        for (let item of allTeam) {
            if (item.team_name === e.target.value) {
                id = item.team_id;
                break;
            }
        }
        setTeamId(id);
    }

    //handle trophy add
    const handleAddTrophy = (e) => {
        e.preventDefault();
        // setAddBtn(true);
        // if(file){
        //     formData.append("media",file);
        // }
        // for(let formfields in trophyData){
        //     formData.append(formfields,trophyData[formfields]);
        // }
        trophyData['player_id'] = teamPlayer.player_id;
        if (trophyData) {
            fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerTrophy`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify(trophyData)
            })
                .then((response) => {
                    // setAddBtn(false);
                    setErrMsg(false);
                    if (response.status === 200) {
                        // navigate(-1);
                        getTrophyDataForPlayer();
                        Swal.fire("trophy añadido con éxito!");
                        setTrophyData({});
                        return response.json();
                    } else {
                        console.log("status: ", response)
                        setErrMsg('¡Error del Servidor!');
                    }
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                    setErrMsg('¡Error del Servidor!');
                    // setAddBtn(false);
                });
        } else {
            //apply error message for player if required fields are empty
            setErrMsg('¡Campos requeridos!')
            // setAddBtn(false);
        }
    }

    //handle player team data (current/previous)
    const handlePlayerTeamData = (e) => {
        e.preventDefault();
        // trophyData['player_id'] = teamPlayer.player_id;
        if (teamStatus && teamDescription && teamId) {
            fetch(`${GlobalURL}/api/dev/team_moderator/addTeamPlayerHistory`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify(
                    {
                        category: teamStatus,
                        description: teamDescription,
                        team_id: teamId,
                        player_id: teamPlayer?.player_id
                    })
            })
                .then((response) => {
                    // setAddBtn(false);
                    setErrMsg(false);
                    if (response.status === 200) {
                        // navigate(-1);
                        getPlayerPreviousTeamData();
                        Swal.fire("equipo añadido con éxito!");
                        return response.json();
                    } else {
                        console.log("status: ", response)
                        setErrMsg('¡Error del Servidor!');
                    }
                })
                .catch((err) => {
                    console.log("error occured: ", err);
                    setErrMsg('¡Error del Servidor!');
                    // setAddBtn(false);
                });
        } else {
            //apply error message for player if required fields are empty
            setErrMsg('¡Campos requeridos!')
            // setAddBtn(false);
        }
    }

    // handle Player deletion
    const handlePlayerDeletion = () => {
        Swal.fire({
            title: "Estas seguro",
            text: '¿Quieres eliminarlo?',
            showDenyButton: true,
            confirmButtonText: "Borrar",
            denyButtonText: `Cancelar`
        }).then((result) => {
            if (result.isConfirmed) {
                setDelBtn(true);
                fetch(`${GlobalURL}/api/dev/team_moderator/deletePlayer`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ player_id: teamPlayer?.player_id })
                })
                    .then((res) => {
                        setDelBtn(false);
                        console.log('res: ', res);
                        if (res.status === 200) {
                            Swal.fire("Deleted!", "", "success");
                            navigate(-1);
                        }
                        else {
                            Swal.fire("Deleted!", "¡Error al eliminar!", "error");
                        }
                    })
                    .catch((err) => {
                        setDelBtn(false);
                        console.log(err)
                    })
            }
        });
    }

    const getPlayerPreviousTeamData = () => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getPlayerPreviousData`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ player_id: teamPlayer?.player_id, team_id: localStorage.getItem("team_id") })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsgTeam(false);
                    return response.json();
                } else {
                    setErrMsgTeam(true);
                }
            })
            .then(res => {
                setPlayerTeam(res.data);
                console.log('res.data: from api ', res.data);
            })
            .catch((err) => {
                setErrMsgTeam(true);
                console.log("error occured: ", err);
            })
    }

    //get player previous & current teams
    useEffect(() => {
        getPlayerPreviousTeamData();
        // eslint-disable-next-line
    }, [teamPlayer])

    //get player full details teams
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getPlayerDetailsById`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ player_id: teamPlayer?.player_id })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                } else {
                    setErrMsg(true);
                }
            })
            .then(res => {
                setPlayerDetails(res.data);
            })
            .catch((err) => {
                setErrMsg(true);
                console.log("error occured: ", err);
            });
    }, [teamPlayer])

    const getTrophyDataForPlayer = () => {
        fetch(`${GlobalURL}/api/dev/team_moderator/getPlayerTrophy`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ player_id: teamPlayer?.player_id })
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setErrMsg(false);
                    return response.json();
                } else {
                    setErrMsg(true);
                }
            })
            .then(res => {
                setPlayerTrophy(res.data);
            })
            .catch((err) => {
                setErrMsg(true);
                console.log("error occured: ", err);
            });
    }

    //get player trophies data
    useEffect(() => {
        getTrophyDataForPlayer()
        // eslint-disable-next-line
    }, [teamPlayer])

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/getCountries`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem('access-token'))}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    // Swal.fire("Jugadore añadido con éxito!");
                    return response.json();
                } else {
                    console.log("status: ", response)
                    // setErrMsg('¡Error del Servidor!');
                }
            }).then((resp) => {
                // setCountryData(resp.data)
                console.log('resp.data: ', resp.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
                // setErrMsg('¡Error del Servidor!');
                // setAddBtn(false);
            });
    }, [])

    // const getCountryId = (e) => {
    //     let id;
    //     for (let item of countryData) {
    //         if (item.name === e.target.value) {
    //             id = item.id;
    //             break;
    //         }
    //     }
    //     // setCountryId(id);
    //     setPlayerCountryData({ ...playerCountryData, [e.target.name]: e.target.value })
    // }

    //handle edit player
    // const handleUpdatePlayer = () => {
    //     const nodes = document.querySelectorAll('.edit-sec');
    //     if (isUpdate) {
    //         //make editable state - true
    //         setEditBtn(false);
    //         setIsUpdate(false);
    //         for (let element of nodes) {
    //             element.contentEditable = false;
    //             element.classList.remove('editable-sec');
    //         }
    //         // formData.delete("position");
    //         console.log('playerDetails.position:: ', playerDetails.position);
    //         if (playerDetails.position)
    //             formData.append("position", position || playerDetails.position);

    //         if (playerCountryData?.country)
    //             formData.append("country", playerCountryData.country);
    //         else
    //             formData.append("country", playerDetails?.country)
    //         if (playerCountryData?.other_nationality)
    //             formData.append("country", playerCountryData.other_nationality);
    //         else
    //             formData.append("country", playerDetails?.other_nationality)

    //         console.log("pos:: ", position);
    //         if (checkUpdation(teamPlayer, nodes)) {
    //             for (let formfields of nodes) {
    //                 if (formfields.innerText.trim() !== 'N/A') {
    //                     formData.append(formfields.id, formfields.innerText);
    //                 }
    //             }
    //             if (file)
    //                 formData.append("media", file);

    //             formData.append("player_id", teamPlayer?.player_id);
    //             Swal.fire({
    //                 title: "Estas seguro",
    //                 text: '¿Quieres editar el jugador?',
    //                 showDenyButton: true,
    //                 confirmButtonText: "Edit",
    //                 denyButtonText: `Cancel`
    //             }).then((result) => {
    //                 if (result.isConfirmed) {
    //                     setEditBtn(true);
    //                     fetch(`${GlobalURL}/api/dev/team_moderator/addPlayerDetails`, {
    //                         method: "POST",
    //                         mode: "cors",
    //                         headers: {
    //                             "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
    //                             "ngrok-skip-browser-warning": "69420",
    //                         },
    //                         // body: JSON.stringify(updatePlayerData)
    //                         body: formData
    //                     })
    //                         .then((res) => {
    //                             setEditBtn(false);
    //                             if (res.status === 200) {
    //                                 Swal.fire("Edit!", "", "success");
    //                                 navigate(-1);
    //                             } else {
    //                                 nodes.forEach(item => {
    //                                     if (teamPlayer)
    //                                         item.innerText = teamPlayer[item.id] || 'N/A';
    //                                     else
    //                                         item.innerText = 'N/A';

    //                                 })
    //                                 Swal.fire("Edit!", "Error in edit Player data!", "error");
    //                             }
    //                         })
    //                         .catch((err) => {
    //                             setEditBtn(false);
    //                             nodes.forEach(item => {
    //                                 if (teamPlayer)
    //                                     item.innerText = teamPlayer[item.id] || 'N/A';
    //                                 else
    //                                     item.innerText = 'N/A';

    //                             })
    //                             Swal.fire("Edit!", "Error in edit player data!", "error");
    //                             console.log(err)
    //                         })
    //                 } else {
    //                     // update perv data back in nodes
    //                     nodes.forEach(item => {
    //                         if (teamPlayer)
    //                             item.innerText = teamPlayer[item.id] || 'N/A';
    //                         else
    //                             item.innerText = 'N/A';
    //                     })
    //                 }
    //             });
    //         }
    //     } else {
    //         //toogle the editable state 
    //         setIsUpdate(true);
    //         setEditBtn(false);
    //         for (let element of nodes) {
    //             element.contentEditable = true;
    //             element.classList.add('editable-sec');
    //         }
    //     }
    // }

    // const checkUpdation = (prevData, newData) => {
    //     let res = false;
    //     if (prevData) {
    //         newData.forEach((item) => {
    //             // eslint-disable-next-line
    //             if (item?.innerText != (prevData[item?.id] || 'N/A')) {
    //                 res = true;
    //             }
    //         })
    //     } else {
    //         res = true;
    //     }
    //     return res;
    // }

    const handleLinkPlayer = (e) => {
        console.log("e");
        setSearchUser(prev => !prev);
    }

    const handlePlayerSearch = (e) => {
        setSuggestedPlayers(null)
        let searchWord = e.target.value;
        // setPlayerSearch(searchWord);
        setUserExsistName({ ...userExsistName, [e.target.name]: e.target.value });

        if (searchWord.length > 1) {
            let div = document.querySelector(".legend-dropdown");
            div.classList.add('legend-active');

            startTransition(() => {
                fetch(`${GlobalURL}/api/dev/team_moderator/searchAllUser`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ key: searchWord, team_id: localStorage.getItem("team_id") })
                })
                    .then((res) => {
                        if (res.status === 200) {
                            return res.json();
                        }
                        else {
                            console.log("error: ", res);
                        }
                    })
                    .then((resp) => {
                        setSuggestedPlayers(resp.data);
                        console.log('resp.data: ', resp.data);
                    })
                    .catch(function (e) {
                        console.log('err: ', e);
                        setSuggestedPlayers([])
                        Swal.fire("Error", t("error del servidor, por favor inténtalo de nuevo!"), "error")
                    });
            })
        } else {
            let div = document.querySelector(".legend-dropdown");
            div.classList.remove('legend-active');
        }
    }

    //handle - highlight the legend selection.
    const handleSelect = (e) => {
        selectedPlayer?.target?.closest('.legendlist').classList.remove('highlight');
        e.target.closest('.legendlist').classList.add('highlight');
        setSelectedPlayer(e);
        // setSearchedPlayerData();
        setSearchedPlayerUid(() => e?.target?.closest('.legendlist').id);
        console.log('selectedPlayer.id: ', e?.target?.closest('.legendlist').id);
    }
    // handle the ignore btn
    const handleIgnore = (e) => {
        e.preventDefault();
        let div = document.querySelector(".legend-dropdown");
        div.classList.remove('legend-active');
    }

    // handle click of link blue btn
    const handleLinkLegend = (e) => {
        e.preventDefault();
        let div = document.querySelector(".legend-dropdown");
        div.classList.remove('legend-active');
        console.log("searchPlayerUid: ", searchedPlayerUid);
        console.log("player id: ", teamPlayer.player_id);

        if (searchedPlayerUid && teamPlayer.player_id) {
            fetch(`${GlobalURL}/api/dev/team_moderator/linkPlyerToUser`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ player_id: teamPlayer.player_id, user_id: searchedPlayerUid })
            }).then((response) => {
                if (response.status === 200) {
                    Swal.fire(t("Jugador vinculado!"), t("El jugador está vinculado con el dirigentes digitales."), "success");
                } else if (response.status === 400) {
                    Swal.fire(t("Jugador vinculado!"), t("Este jugador ya está vinculado con otro dirigentes digital"), "info");
                } else {
                    Swal.fire("Error", t("¡Error Interno del Servidor!"), "error");
                }
            }).catch((error) => {
                console.log('error: ', error);
                Swal.fire("Error", t("¡Error Interno del Servidor!"), "error");
            })
        } else {
            console.log("field required! user id & player id");
            Swal.fire(t("VINCULAR JUGADORE"), t("¡Seleccione usuario para vincular con el jugador!"), "warning");
        }

        // console.log("selected data: ", selectedPlayer?.target?.closest('.legendlist').id);
        // let userId = selectedPlayer?.target?.closest('.legendlist').id;
        // let userDetails = suggestedPlayers.find((user) => {
        //     // eslint-disable-next-line
        //     return user.user_id == selectedPlayer?.target?.closest('.legendlist').id;
        // })
        // console.log('userDetails: ', userDetails);
        // setUserExsistName((prev) => ({ ...prev, player_name: `${userDetails?.first_name} ${userDetails?.last_name}` }));

    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='teamAdminisration-profile'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>

                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec' onClick={() => { navigate(-1, { replace: true }) }}
                                            style={{ cursor: "pointer" }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>

                                        <h3 className='header-title inter-page-header-title'>{t("JUGADOR")}</h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>

                                    </div>
                                    
                                    <div className='profile-desc-sec'>
                                        <Row className='profile-row-sec'>

                                            <Col xs lg="2">
                                                {playerDetails?.follower_count > 0
                                                    && (
                                                        <div className='follower-sec text-center'>
                                                            <p>{t("Seguidores")}</p>
                                                            <p>{playerDetails?.follower_count}</p>
                                                        </div>
                                                    )
                                                }
                                            </Col>

                                            <Col xs lg="8">
                                                <div className='user-admin-profile user-enc-img'>
                                                    <div className="user-admin-profile-img">
                                                        {/* {
                                                            isUpdate
                                                                ? (
                                                                    <div className='editnewscard-img'>
                                                                        {
                                                                            <img src={file
                                                                                ? `${URL.createObjectURL(file)}`
                                                                                : `${playerDetails?.image_url || './img/str4.png'}`
                                                                            } alt="" />
                                                                        }
                                                                        <div className='img-edit-icon'>
                                                                            <GrUpload />
                                                                            <input id="uploadFile" onChange={handleFileSelect}
                                                                                type="file" className="upload" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                                : ( */}
                                                        <div className='editnewscard-img'>
                                                            <img src={playerDetails?.image_url || "./img/str4.png"} alt="" />
                                                        </div>
                                                        {/* )
                                                        } */}
                                                    </div>
                                                    <h5>
                                                        {playerDetails?.player_name
                                                            ? `${playerDetails?.player_name}`
                                                            : 'Unknown'
                                                        }
                                                        {/* {isUpdate
                                                        && (
                                                            <input type="text"
                                                                placeholder='Nombre del jugador'
                                                                name='player_name'
                                                            />
                                                        )} */}
                                                    </h5>
                                                    <div className='merge-player-section'>
                                                        {searchUser &&
                                                            <div>
                                                                <div className='common-form-control'>
                                                                    {/* <input placeholder='Nombre del jugador' name='player_name'
                                                                value={PlayerData.player_name} onChange={handlePlayerData} /> */}
                                                                    {/* <label>Nombre Completo</label> */}

                                                                    <input type="search"
                                                                        placeholder={t("Buscar usuario...")}
                                                                        name='player_name'
                                                                        onChange={handlePlayerSearch}
                                                                        value={userExsistName?.player_name}
                                                                    />
                                                                    
                                                                    <div className={`legend-dropdown ${((userExsistName?.player_name)?.length > 1) ? 'legend-active' : ''}`}>
                                                                        <div className='legend-box'>
                                                                            <ul className=''>
                                                                                {suggestedPlayers
                                                                                    ? (suggestedPlayers?.length
                                                                                        ? (suggestedPlayers?.map((playerData, i) => {
                                                                                            return (
                                                                                                <li key={i} id={playerData.user_id}
                                                                                                    className='legendlist'
                                                                                                    onClick={handleSelect}
                                                                                                >
                                                                                                    <div className='legendprofile'>
                                                                                                        <img src={playerData?.image_url || "./img/str4.png"} alt="" />
                                                                                                        {playerData.is_verified
                                                                                                            && (<div className='legendstatusbtn'>
                                                                                                                <img src="./img/diamante1.png" alt="" />
                                                                                                            </div>)}
                                                                                                    </div>
                                                                                                    <div className='legendprofilename'>
                                                                                                        <span className='legPro-name mb-1' >
                                                                                                            <strong >{playerData.first_name} {playerData.last_name}</strong>
                                                                                                        </span>
                                                                                                        <span className='legPro-username fw-light'>
                                                                                                            {playerData?.username}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                        )
                                                                                        : (<li className='legendlist' >
                                                                                            <div className='legendprofile'>
                                                                                            </div>
                                                                                            <div className='legendprofilename'>
                                                                                                <span className='legPro-name mb-1' >
                                                                                                    <strong >{t('No se encontró ningún jugador con este nombre')}</strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                        )
                                                                                    )
                                                                                    : (
                                                                                        <li className='legendlist  justify-content-center align-items-center'>
                                                                                            <div className='legendprofile'>
                                                                                            </div>
                                                                                            <div className='legendprofilename text-center'>
                                                                                                <span className='legPro-name mb-1' >
                                                                                                    <strong >{t('Cargando')}...</strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>

                                                                            <div className='legendBtn'>
                                                                                {/* <button className='green-btn'>CREAR</button> */}
                                                                                <button className='red-btn' onClick={handleIgnore}>{t('IGNORAR')}</button>
                                                                                <button className='blue-btn' onClick={handleLinkLegend}>{t('VINCULAR')}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div>
                                                            <button className='blue-btn' onClick={handleLinkPlayer}>
                                                                {t("Vincular reproductor con usuario")}
                                                            </button>
                                                        </div>                                                        

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs lg="2"></Col>
                                        </Row>
                                    </div>
                                    <div className='admin-profile-tabs-sec'>

                                    </div>
                                    <div className='profile-tabs-sec legend-tabs'>
                                        <Tabs
                                            defaultActiveKey="perfil"
                                            id="fill-tab-example"
                                            fill
                                        >
                                            <Tab eventKey="perfil"
                                                title={<span><img src='./img/profile.svg' alt='profile'></img> {t("PERFIL")}</span>}>
                                                <div className='profile-sec-tabs'>

                                                    <div className='personal-info-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t("Datos Personales")}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Edad")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data'>
                                                                        <span className='edit-sec' contentEditable='false' id='age'>
                                                                            {playerDetails?.age
                                                                                ? playerDetails?.age
                                                                                : 'N/A'
                                                                            }
                                                                        </span>{t("años")}
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            {/* <div className='form-control'> */}
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Posición")}</p>
                                                                </Col>
                                                                <Col xs lg="6" className='text-center'>
                                                                    {/* {isUpdate
                                                                        ? (
                                                                            <select name='type'
                                                                                onChange={(e) => setPosition(e.target.value)}
                                                                                value={position}
                                                                            >
                                                                                <option value="goalkeeper">GoalKeeper</option>
                                                                                <option value="attacker">Forward</option>
                                                                                <option value="midfielder">Midfielder</option>
                                                                                <option value="defender">Defender</option>
                                                                            </select>
                                                                        )
                                                                        : ( */}
                                                                    <p className='info-data' >
                                                                        {playerDetails?.position
                                                                            ? `${playerDetails?.position}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                    {/* )
                                                                    } */}
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                            {/* </div> */}
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("país")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    {/* {isUpdate
                                                                        ? (<>
                                                                            <input type="text" list="data"
                                                                                name='country'
                                                                                placeholder='¡seleccione el nombre del equipo para recibir!'
                                                                                onChange={getCountryId} />
                                                                            <datalist id="data">
                                                                                {countryData?.map((item, key) =>
                                                                                    <option key={key} id={item.id} value={item.name} />
                                                                                )}
                                                                            </datalist>
                                                                        </>
                                                                        )
                                                                        : ( */}
                                                                    <p className='info-data edit-sec' id='country'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.country
                                                                            ? `${playerDetails?.country}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                    {/* )
                                                                    } */}
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("otra nacionalidad")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='other_nationality' contentEditable='false'>
                                                                        {playerDetails?.other_nationality
                                                                            ? `${playerDetails?.other_nationality}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("género")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='gender'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.gender
                                                                            ? `${playerDetails?.gender}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Pie preferido")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='preferred_foot'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.preferred_foot
                                                                            ? `${playerDetails?.preferred_foot}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Estatura")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data ' contentEditable='false'>
                                                                        <span className='edit-sec' id='height' >

                                                                            {playerDetails?.height
                                                                                ? playerDetails?.height
                                                                                : 'N/A'
                                                                            }
                                                                        </span>
                                                                        Cms.
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Peso")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data' contentEditable='false'>
                                                                        <span className='edit-sec' id='weight'>
                                                                            {playerDetails?.weight
                                                                                ? playerDetails?.weight
                                                                                : 'N/A'
                                                                            }
                                                                        </span>
                                                                        Kgs.
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Fecha de nacimiento")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='date_of_birth'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.date_of_birth
                                                                            ? `${playerDetails?.date_of_birth}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("País de nacimiento")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='place_of_birth' contentEditable='false'>
                                                                        {playerDetails?.place_of_birth
                                                                            ? `${playerDetails?.place_of_birth}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                    </div>

                                                    <div className='personal-info-sec career-details-sec'>
                                                        <div className='personal-info-heading'>
                                                            <h5>{t("Datos de su carrera")}</h5>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{("identificador de Twitter")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='twitter_handle'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.twitter_handle
                                                                            ? `${playerDetails?.twitter_handle}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Equipo Actual")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='actual_team'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.current_team
                                                                            ? `${playerDetails?.current_team}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Equipo Anterior")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='previous_team' contentEditable='false'>
                                                                        {playerDetails?.previous_team
                                                                            ? `${playerDetails?.previous_team}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Competición Actual")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data'>
                                                                        <p className='info-data edit-sec' id='current_competition'
                                                                            contentEditable='false'>
                                                                            {playerDetails?.current_competition
                                                                                ? `${playerDetails?.current_competition}`
                                                                                : 'N/A'
                                                                            }
                                                                        </p>
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Competición Anterior")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='previous_competition'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.previous_competition
                                                                            ? `${playerDetails?.previous_competition}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("valor neto")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='net_worth'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.net_worth
                                                                            ? `${playerDetails?.net_worth}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Elo")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='elo'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.elo
                                                                            ? `${playerDetails?.elo}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Equipo histórico")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='historical_team'
                                                                        contentEditable='false'>
                                                                        {playerDetails?.historical_team
                                                                            ? `${playerDetails?.historical_team}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("número de camiseta")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='jersey_number' contentEditable='false'>
                                                                        {playerDetails?.jersey_number
                                                                            ? `${playerDetails?.jersey_number}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Último Dorsal")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='ultimate_dorsal' contentEditable='false'>
                                                                        {playerDetails?.ultimate_dorsal
                                                                            ? `${playerDetails?.ultimate_dorsal}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>

                                                        <div className='personal-info-card'>
                                                            <Row>
                                                                <Col xs lg="3">
                                                                    <p className='info-heading'>{t("Dorsal más común")}</p>
                                                                </Col>
                                                                <Col xs lg="6">
                                                                    <p className='info-data edit-sec' id='most_common_dorsal' contentEditable='false'>
                                                                        {playerDetails?.most_common_dorsal
                                                                            ? `${playerDetails?.most_common_dorsal}`
                                                                            : 'N/A'
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs lg="3"></Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            {/* 
                                            <Tab eventKey="estadistics"
                                                title={<span><img src='./img/Icono Clasificacion.png' alt=''></img> {t("ESTADISTICAS")}</span>}>
                                                <div className='statistics-tabs'>
                                                    <div className='feild-position-sec'>
                                                        <Row>
                                                            <Col xs lg="6">
                                                                <div className='feild-sec'>
                                                                    <h4 className='feild-sec-heading'>Posición en el Campo</h4>
                                                                    <div className='feild-card'>
                                                                        <p>Posición principal</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='diff_position'>
                                                                            {playerDetails?.diff_position
                                                                                ? `${playerDetails?.diff_position}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card '>
                                                                        <p>Otras posiciones</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='other_position'>
                                                                            {teamPlayer?.other_position
                                                                                ? `${teamPlayer?.other_position}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xs lg="3">
                                                                <div className='feild-sec-info'>
                                                                    <p className='green-text'>
                                                                        posicion principal
                                                                    </p>
                                                                    <p className='per-sec'>
                                                                        <span>Delantero</span>
                                                                        <span>84%</span>
                                                                    </p>
                                                                    <p className='green-text'>
                                                                        posicion principal
                                                                    </p>
                                                                    <p className='per-sec'>
                                                                        <span>Delantero</span>
                                                                        <span>84%</span>
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col xs lg="3">
                                                                <div className='feild-sec-img'>
                                                                    <img src="./img/ftFeild.png" alt="" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs lg="6">
                                                                <div className='attribute-sec feild-sec'>
                                                                    <h4 className='feild-sec-heading'>Atributos</h4>
                                                                    <div className='feild-card'>
                                                                        <p>Ritmo</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='ritmo'>
                                                                            {teamPlayer?.ritmo
                                                                                ? `${teamPlayer?.ritmo}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card'>
                                                                        <p>Tiro</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='tiro'>
                                                                            {teamPlayer?.tiro
                                                                                ? `${teamPlayer?.tiro}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card'>
                                                                        <p>Pase</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='pase'>
                                                                            {teamPlayer?.pase
                                                                                ? `${teamPlayer?.pase}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card'>
                                                                        <p>Regate</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='regate'>
                                                                            {teamPlayer?.regate
                                                                                ? `${teamPlayer?.regate}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card'>
                                                                        <p>Defensa</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='defensa'>
                                                                            {teamPlayer?.defensa
                                                                                ? `${teamPlayer?.defensa}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>
                                                                    <div className='feild-card'>
                                                                        <p>Fisica</p>
                                                                        <h5 className='edit-sec' contentEditable='false' id='fisica'>
                                                                            {teamPlayer?.fisica
                                                                                ? `${teamPlayer?.fisica}`
                                                                                : 'N/A'
                                                                            }
                                                                        </h5>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col xs lg="6">
                                                                <div className='attribute-img-sec pb-2'>
                                                                    <RadarChart height={300} width={400}
                                                                        outerRadius="80%" data={data}>
                                                                        <PolarGrid />
                                                                        <PolarAngleAxis dataKey="name" />
                                                                        <Radar dataKey="x" stroke="green"
                                                                            fill="green" fillOpacity={0.5} />
                                                                    </RadarChart>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Tab>  
                                            */}

                                            <Tab eventKey="Equipos"
                                                title={<span><img src='./img/team.svg' alt='profile'></img> {t("EQUIPOS")}</span>}>
                                                <div className='equipment-tab-sec'>
                                                    <div className='equipment-card-sec'>
                                                        {/* <div className='equipment-card'>
                                                            <h5>Agregar equipo</h5>
                                                        </div> */}
                                                        <form className='text-center squipos-sec' onSubmit={handlePlayerTeamData}>
                                                            <div className='common-form-control'>
                                                                {/* <label>selecciona un equipo</label> */}
                                                                <input type="text" list="data"
                                                                    placeholder='selecciona un equipo'
                                                                    onChange={getTeamId} />
                                                                <datalist id="data">
                                                                    {allTeam?.map((item, key) =>
                                                                        <option key={key} id={item.team_id} value={item.team_name} />
                                                                    )}
                                                                </datalist>
                                                            </div>
                                                            {/* <div className='equipment-card'>
                                                            <h5>Agregar descripción</h5>
                                                        </div> */}

                                                            <div className='common-form-control'>
                                                                {/* <label>estado del equipo</label> */}
                                                                <select name='event_type'
                                                                    onChange={(e) => setTeamStatus(e.target.value)}
                                                                    value={teamStatus}
                                                                >
                                                                    <option value="">{t("Seleccione el estado del equipo del jugador (actual/anterior)")}</option>
                                                                    {/* <option value="today">Hoy</option> */}
                                                                    <option value="current">{t("equipo actual")}</option>
                                                                    <option value="previous">{t("equipo anterior")}</option>
                                                                </select>
                                                            </div>
                                                            <div className='trophy-card'>
                                                                <div className='common-form-control'>
                                                                    <div className='form-control'>
                                                                        <input type='text' placeholder={t("Agregar descripción")}
                                                                            value={teamDescription} onChange={(e) => setTeamDescription(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className='green-btn' type='submit'>
                                                                {t("Enviar Equipos")}
                                                            </button>
                                                        </form>

                                                        <div className='equipment-list-sec'>
                                                            <ul className='equipment-list'>
                                                                {console.log('playerTeam: in jsx: ', playerTeam, errMsg)}
                                                                {playerTeam
                                                                    ? (playerTeam.length
                                                                        ? (playerTeam?.map(item => {
                                                                            if (item.category !== 'previous') {
                                                                                return (
                                                                                    <li>
                                                                                        <span className='equp-list-content py-1'>
                                                                                            <img src={item.team_logo_url || './img/logo.png'} alt="" />
                                                                                            <span className='equ-list-desc'>{item?.team_name}: {item.description}</span>
                                                                                        </span>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                            if (item.category === 'previous') {
                                                                                return (
                                                                                    <li>
                                                                                        <span className='equp-list-content py-1'>
                                                                                            <img src={item.team_logo_url || './img/logo.png'} alt="" />
                                                                                            <span className='equ-list-desc'>{item?.team_name}: {item.description}</span>
                                                                                        </span>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                            return null;
                                                                        })
                                                                        )
                                                                        : (
                                                                            <div>
                                                                                {t("¡No se encontró ningún equipo anterior!")}
                                                                            </div>
                                                                        )
                                                                    )
                                                                    : (<div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                                        {errMsgTeam
                                                                            ? (
                                                                                <div className="text-danger">
                                                                                    {t("¡Error Interno del Servidor!")}
                                                                                </div>
                                                                            )
                                                                            : (<>
                                                                                <div className="spinner-border m-5" role="status">
                                                                                    <span className="sr-only"></span>
                                                                                </div>
                                                                                <div className=" my-5" >
                                                                                    {t("Cargando")}...
                                                                                </div>
                                                                            </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    )
                                                                }
                                                                {/* 
                                                                <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq1.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq2.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className='equp-list-content'>
                                                                    <img src="./img/eq3.png" alt="" />
                                                                    <span className='equ-list-desc'>River Plate (Argentina): Julián Alvarez comenzó su carrera profesional en el club argentino River Plate, donde jugó desde 2003 hasta 2005.</span>
                                                                </span>
                                                            </li> 
                                                            */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="trofeos"
                                                title={<span><img src='./img/trophy.svg' alt='profile'></img> {t("TROFEOS")}</span>}
                                            // title={t("TROFEOS")}
                                            >
                                                <div className='trophy-tab-sec'>
                                                    <div className='trophy-sec-card'>

                                                        <form className='text-center squipos-sec' onSubmit={handleAddTrophy}>
                                                            <div className='trophy-card'>
                                                                <div className='common-form-control'>
                                                                    <input type='text' placeholder={t('Ingrese Trofeo')} name='trophy_name'
                                                                        value={trophyData.trophy_name} onChange={handleTrophyData} />
                                                                </div>
                                                            </div>
                                                            <div className='trophy-card'>
                                                                <div className='common-form-control'>
                                                                    <input type='text' placeholder={t('Ingrese liga')} name='leauge_name'
                                                                        value={trophyData.leauge_name} onChange={handleTrophyData} />
                                                                </div>
                                                            </div>
                                                            <div className='trophy-card'>
                                                                <div className='common-form-control'>
                                                                    <input type='date' placeholder={t('Ingrese Fecha')} name='date_awarded'
                                                                        value={trophyData.date_awarded} onChange={handleTrophyData} />
                                                                </div>
                                                            </div>

                                                            <button className='green-btn' type='submit'>
                                                                {t("Enviar trofeo")}
                                                            </button>
                                                        </form>
                                                    </div>

                                                    <div className='trophy-profile-sec'>
                                                        {(playerTrophy?.length)
                                                            ? (playerTrophy?.map(item => {
                                                                return (
                                                                    <div className='trophy-profile-card'>
                                                                        <div className='trophy-img'>
                                                                            <img src={(item?.trophy?.logo) ? (item?.trophy?.logo) : "./img/trophyImg.png"} alt="" />
                                                                        </div>
                                                                        <div className='trophy-details-sec'>
                                                                            <h4>{item?.trophy_name} {item?.leauge_name && `of leauge ${item.leauge_name}`}</h4>
                                                                            <p className='trophydate'>{item?.date_awarded}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            )
                                                            : (<div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                                    {errMsg
                                                                        ? (
                                                                            <div className="text-danger">{t("¡Error Interno del Servidor!")}</div>
                                                                        )
                                                                        : (playerTrophy?.length
                                                                            ? (
                                                                                <>
                                                                                    <div className="spinner-border m-5" role="status">
                                                                                        <span className="sr-only"></span>
                                                                                    </div>
                                                                                    <div className=" my-5" >
                                                                                        {t("Cargando")}...
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            : (
                                                                                <>
                                                                                    <div className="text-light my-5">{t("¡No se encontró ningún trofeo!")}</div>
                                                                                </>
                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </Col>

                                <Col xs lg="3">
                                    <div className='profile-btns-sec'>
                                        <div className='profile-btsn-inner'>
                                            <button className='green-blue-btn'
                                                // onClick={handleUpdatePlayer}
                                                onClick={() => navigate("/editplayer", { state: playerDetails })}
                                            >
                                                {/* {editBtn
                                                    ? (<>
                                                        <span className="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        <span className="sr-only">Enviando...</span>
                                                    </>)
                                                    :  */}
                                                {t("Editar / Guardar")}
                                                {/* } */}
                                            </button>
                                            <button className='red-btn' onClick={handlePlayerDeletion}>
                                                {delBtn
                                                    ? (<>
                                                        <span className="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        <span className="sr-only">{t("Enviando")}...</span>
                                                    </>)
                                                    : (t('Eliminar'))
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamAdministrationProfile;