import { useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
// import { FiSearch } from "react-icons/fi";
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const MultimediaImages = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [images, setImages] = useState();
    const [videos, setVideos] = useState();
    const [show, setShow] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [previewMedia, setPreviewMedia] = useState();

    const [mediaParam, setMediaParam] = useSearchParams({ "type": "Imágenes" })

    // open the modal
    const handleShow = (mediaUrl, type) => {
        setPreviewMedia(() => ({ media_url: mediaUrl, media_type: type }));
        setShow(true)
    };

    // Close the modal
    const handleClose = () => {
        setShow(false);
    };

    // verify team selection
    useEffect(() => {
        let teamStatus = localStorage.getItem("team_id");
        if (!teamStatus) {
            Swal.fire(t("EQUIPO!"), t("¡Selecciona el equipo primero!"), "info");
            navigate("/homepage");
        }
        // eslint-disable-next-line
    }, [])

    // handle media deletion
    const deleteMultimedia = (mediaId) => {
        Swal.fire({
            title: t("Estas seguro"),
            text: t("Quieres borrar"),
            showDenyButton: true,
            confirmButtonText: t("Eliminar"),
            denyButtonText: t("cancelar")
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${GlobalURL}/api/dev/team_moderator/multimedia/deleteMultimedia`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({ multimedia_id: mediaId })
                })
                    .then((res) => {
                        console.log('res.status: ', res.status);
                        if (res.status === 200) {
                            Swal.fire(t("Eliminar!"), "Done!", "success");
                            let mediaType = images.findIndex(item => (mediaId === item.multimedia_id))
                            console.log('mediaType: ', mediaType);

                            if (mediaType >= 0) {
                                let updatedImages = images.filter((item) => (item.multimedia_id !== mediaId))
                                setImages(updatedImages);
                            } else {
                                let updatedVids = videos.filter((item) => (item.multimedia_id !== mediaId))
                                setVideos(updatedVids);
                            }
                        }
                        else {
                            Swal.fire(t("Eliminar!"), t("¡Error al eliminar!"), "error");
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        });
    }

    // useEffect for images
    useEffect(() => {
        // const loadStatus = document.querySelector(".Loader");
        // check that api for multimedia het images
        fetch(`${GlobalURL}/api/dev/miteam/multimedia/getImagesByTeamId`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response);
                    return response.json();
                }
                else {
                    setErrMsg(t('¡Error Interno del Servidor!'));
                }
            }).then(res => {
                // let revData = res.data.reverse();
                let revData = res.data;
                setErrMsg(false);
                setImages(revData);
            })
            .catch((err) => {
                setErrMsg(t('¡Error Interno del Servidor!'));
                // loadStatus.innerHTML = `<div class="text-danger">¡Error Interno del Servidor!</div>`
                console.log("error occured: ", err);

            });
        // eslint-disable-next-line
    }, [])

    // useEffect for video
    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/miteam/multimedia/getVideosByTeamId`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") })
        })
            .then((response) => {
                if (response.status === 404) {
                    console.log("status: ", response)
                }
                else if (response.status === 200) {
                    console.log(response);
                    return response.json();
                }
            }).then(res => {
                setVideos(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])

    const handleMediaTab = (tab) => {
        console.log("tabs selected: ", tab);
        setMediaParam({ "type": tab });
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='legend-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3"></Col>

                                <Col xs lg="6">
                                    <div className='legend-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate('/homepage') }} style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>

                                            <h3 className='header-title inter-page-header-title'>
                                                {t('MULTIMEDIA')}
                                            </h3>

                                            <div className='search-icon'>
                                                {/* <FiSearch /> */}
                                            </div>
                                        </div>

                                        <div className='legend-tabs'>
                                            {images
                                                ? (<Tabs
                                                    defaultActiveKey={mediaParam.get("type")}
                                                    id="fill-tab-example"
                                                    onSelect={handleMediaTab}
                                                    fill >
                                                    <Tab eventKey="Imágenes" title={t("Imágenes")}>
                                                        <div className='historical-legends'>
                                                            {images?.length
                                                                ? (images.map(image => {
                                                                    return (<div className='legend-card'
                                                                        key={image.multimedia_id} >

                                                                        <img className='legend-img'
                                                                            alt="" id={image.multimedia_id}
                                                                            onClick={() => handleShow(image.multimedia_url, "image")}
                                                                            src={image.multimedia_url} />
                                                                        {/*
                                                                            <img className='legend-img' src={image.multimedia_url} 
                                                                                data-src="./img/leg2.png"
                                                                                alt={image.multimedia_id} /> 
                                                                            */}
                                                                        <RiDeleteBin6Line style={{
                                                                            position: 'absolute',
                                                                            top: '6px',
                                                                            left: '3px',
                                                                            color: '#ff6868',
                                                                            background: '#131313c4',
                                                                            cursor: 'pointer',
                                                                            padding: '7px',
                                                                            borderRadius: '30px',
                                                                            width: '35px',
                                                                            height: '35px',
                                                                        }}
                                                                            onClick={() => { deleteMultimedia(image.multimedia_id) }}
                                                                        />
                                                                    </div>
                                                                    );
                                                                }))
                                                                : (errMsg
                                                                    ? (errMsg)
                                                                    : (<div className="Loader d-flex justify-content-center mm-ack">
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('¡No se encontraron medios!')}
                                                                        </div>
                                                                    </div>
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="Videos" title="Videos">
                                                        <div className='historical-legends'>
                                                            {videos?.length
                                                                ? (videos.map(video => {
                                                                    return (
                                                                        <div className='legend-card'
                                                                            key={video.multimedia_id}>
                                                                            <video className='legend-img' id={video.multimedia_id}
                                                                                onClick={() => handleShow(video.multimedia_url, "video")}
                                                                                poster={video.thumbnail_url}
                                                                                src={video.multimedia_url} alt={video.multimedia_id} />

                                                                            <RiDeleteBin6Line style={{
                                                                                position: 'absolute',
                                                                                top: '6px',
                                                                                left: '3px',
                                                                                color: '#ff6868',
                                                                                background: '#131313c4',
                                                                                cursor: 'pointer',
                                                                                padding: '7px',
                                                                                borderRadius: '30px',
                                                                                width: '35px',
                                                                                height: '35px',
                                                                            }}
                                                                                onClick={() => { deleteMultimedia(video.multimedia_id) }}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }))
                                                                : (errMsg
                                                                    ? (errMsg)
                                                                    : (<div className="Loader d-flex justify-content-center mm-ack">
                                                                        <div className=" my-5" > &nbsp;
                                                                            {t('¡No se encontraron medios!')}
                                                                        </div>
                                                                    </div>)
                                                                )
                                                            }
                                                        </div>

                                                    </Tab>
                                                </Tabs>)
                                                : (errMsg
                                                    ? (errMsg)
                                                    : (<div className="Loader d-flex justify-content-center mx-auto align-items-center">
                                                        <div className="spinner-border m-5" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        <div className=" my-5" > &nbsp;
                                                            {t("Cargando")}...
                                                        </div>
                                                    </div>)
                                                )
                                            }

                                            <div className='center-btn-sec'>
                                                <button className='green-btn' onClick={() => { navigate('/multimedia/addmultimedia') }}>
                                                    {t('Agregar multimedia')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>

                            <Modal show={show} className="institutional-popup common-popup"
                                onHide={handleClose} centered >

                                <Modal.Header className="pb-0 mb-0" closeButton >
                                    <Modal.Title>{t('MULTIMEDIA')}</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <div className="custom-text-editor w-100">
                                        <div className='text-center preview-multimedia-large'>
                                            {/* {console.log("meddia: ",previewMedia)} */}
                                            {(previewMedia?.media_type === "image")
                                                ? (<div className='legend-media-manage'>
                                                    <img src={previewMedia.media_url} alt='media'></img>
                                                </div>)
                                                : (<video src={previewMedia?.media_url} alt="media" controls></video>)
                                            }
                                        </div>
                                    </div>
                                    {/* 
                                    <div className="custom-text-editor w-100">
                                        <div className="toolbar">
                                            <button onClick={handleBold}>
                                                <img src="../img/bold.png" alt="bold" />
                                            </button>
                                            <button onClick={handleItalic}>
                                                <img src="../img/italic.png" alt="italic" />
                                            </button>
                                            <button onClick={handleStrikeThrough}>
                                                <img src="../img/strikethrough.png" alt="strikethrough" />
                                            </button>
                                            <div className="text-align-buttons">
                                                <button onClick={() => handleTextAlign('Left')}>
                                                    <img src="../img/left-align.png" alt="left-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Center')}>
                                                    <img src="../img/center-align.png" alt="center-align" />
                                                </button>
                                                <button onClick={() => handleTextAlign('Right')}>
                                                    <img src="../img/right-align.png" alt="right-align" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="editor"
                                            ref={editorRef}
                                            contentEditable
                                            // onInput={handleChange}
                                            // onChange={handleChange}
                                            dangerouslySetInnerHTML={{ __html: editorContent }}
                                        ></div>

                                    </div>
                                    */}
                                </Modal.Body>

                                {/* 
                                <Modal.Footer className='border-0 p-0'>
                                    <div className='news-btn-sec'>
                                        <button className='green-btn' onClick={handleGuardarClick}>
                                            {t('GUARDAR')}
                                        </button>
                                    </div>
                                </Modal.Footer> 
                                */}
                            </Modal>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultimediaImages
