import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import GlobalURL from '../BaseURL';
import Swal from 'sweetalert2';
import "./style.css";

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddStreaming = () => {
    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [media, setMedia] = useState(null);
    const [errMsg, setErrMsg] = useState(false);
    const [btnText, setBtnText] = useState(false);
    const [streamData, setStreamData] = useState(
        {
            title: "",
            duration: "",
            start_time: "",
            streaming_url: ""
        }
    );

    const formData = new FormData();

    function handleChange(e) {
        setMedia(e.target.files[0]);
        document.getElementById("uploadFile").value = e.target.value.replace("C:\\fakepath\\", "");
    }

    const handleStreamData = (e) => {
        setStreamData({ ...streamData, [e.target.name]: e.target.value });
    }

    //add new streaming.
    const handleSubmit = (e) => {
        e.preventDefault();
        if (streamData.title && streamData.duration
            && streamData.start_time && media
            && streamData.streaming_url) {
            setErrMsg("")
            setBtnText(true);
            formData.append("media", media)
            formData.append("team_id", localStorage.getItem("team_id"))
            for (let key in streamData) {
                formData.append(key, streamData[key]);
            }
            fetch(`${GlobalURL}/api/dev/team_moderator/streaming/addStream`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${JSON.parse(window.localStorage.getItem("access-token"))}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then(function (res) {
                if (res.ok) {
                    Swal.fire(t("Stream añadido con éxito!"));
                    navigate(-1);
                    setStreamData({ title: "", duration: "", start_time: "", streaming_url: "" })
                    setMedia(null);
                    setBtnText(false);
                } else {
                    Swal.fire(t("Error del Servidor, ¡inténtalo de nuevo!"));
                    setBtnText(false);
                }
            }).catch(function (e) {
                setBtnText(false);
                setErrMsg(t('leyenda no agregada, ¡inténtalo de nuevo!'))
            });
        } else {
            setErrMsg(t('campos requeridos!'))
        }
    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='add-streaming-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3"></Col>
                                <Col xs lg="6">
                                    <div className='news-header'>
                                        <div className='back-sec'
                                            onClick={() => navigate(-1)}
                                            style={{ cursor: "pointer" }}>
                                            <img src="../img/volver.png" alt="back_icon" />
                                        </div>
                                        <h3 className='header-title inter-page-header-title'>
                                            {t('Agregar Stream')}
                                        </h3>
                                        <div className='search-icon'>
                                            {/* <FiSearch /> */}
                                        </div>
                                    </div>

                                    <div className='common-input-forms addstreaming'>
                                        <form onSubmit={handleSubmit} >
                                            {/* <div className='common-form-control'>
                                                <label>selecciona un equipo</label>
                                                <input type="text" list="data" 
                                                    placeholder='¡seleccione el nombre del equipo para recibir Stream!' 
                                                    onChange={getTeamId} />
                                                    <datalist id="data">
                                                        {allTeam?.map((item, key) =>
                                                            <option key={key} id={item.team_id} value={item.team_name} />
                                                        )}
                                                    </datalist>
                                            </div> */}

                                            <div className='common-form-control'>
                                                <label>{t('Nombre de Stream')}</label>
                                                <input type='text' placeholder={t('Inserte el título de la Stream aquí')}
                                                    name='title' value={streamData.title} onChange={handleStreamData} />
                                            </div>
                                            <div className='common-form-control img-video-form-control'>
                                                <label>{t('Foto/Video')}</label>
                                                <div className='file-input-sec'>
                                                    <input id="uploadFile" className="f-input" placeholder={t('Inserte la foto de la Stream aquí')} />
                                                    <div className="fileUpload btn btn--browse">
                                                        <input id="uploadBtn" onChange={handleChange} type="file" accept="image/*" className="upload" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='common-form-control'>
                                                <label>{t("Fecha de Stream")}</label>
                                                <input type="datetime-local" step='1' placeholder={t('Inserte la fecha del stream aquí')}
                                                    name='start_time' value={streamData.start_time} onChange={handleStreamData} />
                                            </div>
                                            <div className='common-form-control'>
                                                <label>{t("Duración")}</label>
                                                {/* <input type="time" placeholder='Inserte la duración de la transmisión aquí.' 
                                        name='duration' value={streamData.duration} onChange={handleStreamData} /> */}
                                                <input type="number" min='0' max='20' placeholder={t("Inserte la duración de la transmisión aquí")}
                                                    name='duration' value={streamData.duration} onChange={handleStreamData} />
                                            </div>
                                            <div className='common-form-control'>
                                                <label>{t('URL de Streaming')}</label>
                                                <input type='url' placeholder={t('Inserte el link al stream aquí')} name='streaming_url'
                                                    value={streamData.streaming_url} onChange={handleStreamData} />
                                            </div>
                                            <div className='err-div text-center text-danger'>
                                                {errMsg}
                                            </div>
                                            <div className='news-btn-sec'>
                                                <button className='green-btn' type='submit' >
                                                    {btnText
                                                        ? (<>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span className="sr-only">&nbsp; {t('Enviando')}...</span>
                                                        </>)
                                                        : (t('Guardar'))
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Col>
                                <Col xs lg="3">
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddStreaming
